import { Grid, Box,  Typography, Card, Divider} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { currency } from 'utils/Constants';

export const FundingSourceCard = ({item}) => {

  return (
    <Grid item lg={6} xs={12}>
      <Card sx={{p: '16px', width: '100%'}}>
        <Box sx={{display: 'flex', justifyContent:'space-between', alignItems:'center', textAlign: 'left', width: '100%'}}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography fontWeight="bold" my="0px">{item?.name}</Typography>
            <Typography sx={{fontSize: '12px'}}>{item?.subtype} - {item?.mask}</Typography>
          </Box>
          <MoreVertIcon />
        </Box>
        <Divider sx={{my: '0.5rem'}}/>
        <Box sx={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
          <Typography fontWeight="bold" my="0px">{currency(item?.balances?.available)}</Typography>
          <Typography sx={{fontSize: '12px'}}>Available Balance</Typography>
        </Box>
      </Card>
    </Grid>
  )
}
