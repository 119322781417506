import {CloseSharp} from '@mui/icons-material'
import {Box, Divider, Drawer, IconButton, CircularProgress} from '@mui/material'
import { socketClient } from 'client/socket.client'
import { ImagePreview } from 'components/ImagePreview'
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react'

const LabelField = props => {
  const {heading, value} = props

  return value ? (
    <Box my={2}>
      <p className="text-start text-sm text-slate-600">{heading}</p>
      <p className="text-sm">{value}</p>
    </Box>
  ) : (
    ''
  )
}
export const DetailViewModal = forwardRef((props, ref) => {
  const [id, setId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [data, setData] = useState({})
  const [drawerProps, setDrawerProps] = useState({})

  useImperativeHandle(
    ref,
    () => ({
      show(id, drawerProps) {
        setId(id)
        setOpen(true)
        setDrawerProps(drawerProps)
      }
    }),
    []
  )

  useEffect(() => {
    if (!id) {
      return
    }

    setLoading(true)

    socketClient
      .service('shelters')
      .get(id, {query: {$populate: ['logo', 'verifiedBy']}})
      .then(data => {
        setData(data)
        setLoading(false)
      })
  }, [id])

  return (
    <Drawer
      open={open}
      {...drawerProps}
      PaperProps={{style: {width: 'auto'}}}
      ModalProps={{
        onClose: () => setOpen(false)
      }}
    >
      {loading ?
        <Box className="p-4" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box> :
        <Box className="p-4">
          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <p className="text-base font-bold my-4">{data?.name}</p>
            <IconButton onClick={() => setOpen(false)}>
              <CloseSharp />
            </IconButton>
          </Box>
          <Divider className="!mb-4" />
          <ImagePreview
            media={data?.logo}
            className="w-28 h-28 rounded-xl object-contain border border-gray-200"
          />
          <div className="flex flex-col md:flex-row">
            <div className="w-[20rem] text-sm">
              <LabelField heading="" value={data?.description} />
              <LabelField heading="Address" value={data?.place} />
              <LabelField heading="Website" value={data?.website} />
              <LabelField heading="Phone" value={data?.phone}/>
              <LabelField heading="TIN" value={`${data?.TIN}`} />
              <LabelField heading="Active" value={data?.active ? 'Yes' : 'No'} />
              <LabelField heading="Accept Donation" value={data?.canAcceptDonation ? 'Yes' : 'No'} />
              <LabelField heading="Verified" value={data?.verified ? 'Yes' : 'No'} />
              <LabelField heading="Verified By" value={data?.verifiedBy ? `${data?.verifiedBy?.firstName} ${data?.verifiedBy?.lastName}` : null} />
            </div>
          </div>
        </Box>
      }
    </Drawer>
  )
})
