import React, { useEffect, useState } from 'react'
import { ResponsiveLine } from '@nivo/line'

const ChartLabel = (props) => (
  <div style={{ position: 'relative'}}>
    <div style={{ position: 'absolute', top: 12, left: 12 }}>
      <h4>{props.title}</h4>
    </div>
  </div>
)

export const LineChart = (props) => {
  const {
    title = false,
    data,
    xLegend,
    yLegend,
  } = props
  return (
    <>
      { title && <ChartLabel title={props.title} /> }
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
          reverse: false
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: xLegend,
          legendOffset: 36,
          legendPosition: 'middle'
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: yLegend,
          legendOffset: -40,
          legendPosition: 'middle'
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        // legends={[
        //   {
        //       anchor: 'bottom-right',
        //       direction: 'column',
        //       justify: false,
        //       translateX: 100,
        //       translateY: 0,
        //       itemsSpacing: 0,
        //       itemDirection: 'left-to-right',
        //       itemWidth: 80,
        //       itemHeight: 20,
        //       itemOpacity: 0.75,
        //       symbolSize: 12,
        //       symbolShape: 'circle',
        //       symbolBorderColor: 'rgba(0, 0, 0, .5)',
        //       effects: [
        //           {
        //               on: 'hover',
        //               style: {
        //                   itemBackground: 'rgba(0, 0, 0, .03)',
        //                   itemOpacity: 1
        //               }
        //           }
        //       ]
        //   }
        // ]}
      />
    </>
  )
}
