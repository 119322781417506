import React, { useCallback, useState } from 'react';
import { SnackbarContext } from './SnackbarContext';
import { Alert, Snackbar } from '@mui/material';

export const SnackbarContextProvider = ({ children }) =>{
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('info')

  const [config, setConfig] = useState({
    open: false,
    verticalPosition: 'bottom',
    horizontalPosition: 'right'
  })

  const handleClose = () => {
    setConfig({...config, open: false})
  };

  const showToast = useCallback((message) => {
    setMessage(message)
    setConfig({...config, open: true})
  }, [config])

  return <SnackbarContext.Provider value={{config, message, severity, setConfig, setSeverity, showToast}}>
    {children}
    <Snackbar
      anchorOrigin={{
        vertical: config.verticalPosition,
        horizontal: config.horizontalPosition
      }}
      open={config.open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  </SnackbarContext.Provider>
}
