import React, {useEffect, useState} from 'react'
import {Button, Modal, TextField, Typography} from '@mui/material'
import {useTheme} from '@mui/material/styles'
import {useFormik} from 'formik'
import * as yup from 'yup'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import {ModalLayout} from 'components/layouts/ModalLayout'
// import {DatePicker} from '@mui/lab'

const validationSchema = yup.object({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string().matches(/^\+[0-9]+$/, 'Invalid Phone number. Phone number must be start with country code. eg +1' ),
  roles: yup.array().min(1, 'Select at least one role').required('Roles are required')
})

const rolesOptions = [
  {
    label: 'User',
    role: 'user'
  },
  {
    label: 'Admin',
    role: 'admin'
  },
  {
    label: 'Vendor',
    role: 'vendor'
  },
  {
    label: 'Finder',
    role: 'finder'
  },
  {
    label: 'Staff',
    role: 'staff'
  }
]

export const AddEditModal = props => {
  const {open, handleModal, id, Loader, loading = false, create, patch, get, data, item} = props

  const formik = useFormik({
    initialValues: {
      firstName: item?.firstName,
      lastName: item?.lastName,
      email: item?.email,
      phone: item?.phone,
      roles: item?.roles ? item.roles : []
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      // Handle form submission here
      const result =  id ? await patch(id, values) : await create(values)
      handleModal(result)
      console.log(values)
    }
  })

  useEffect(() => {
    if (data) {
      formik.setValues({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        roles: data?.roles ? data.roles : []
      })
    }
  }, [data, item])

  useEffect(() => {
    if (id && open) {
      get(id)
    }
  }, [id])

  return (
    <Modal open={open} onClose={handleModal}>
      <ModalLayout
        title={`${id ? 'Edit' : 'Create'} User`}
        onClose={handleModal}
        LoaderComponent={Loader}
        loading={loading}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col md:flex-row">
            <TextField
              label="First Name"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
              margin="normal"
              className="!w-full !md:w-1/2 !mx-1"
            />
            <TextField
              label="Last Name"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              margin="normal"
              className="!w-full !md:w-1/2 !mx-1"
            />
          </div>
          <div className="flex flex-col md:flex-row">
            <TextField
              label="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              margin="normal"
              className="!w-full !md:w-1/2 !mx-1"
            />
            <TextField
              label="Phone"
              name="phone"
              value={formik.values.phone}
              onInput={event => {
                event.target.value = event.target.value.replace(/^-/, '')
              }}
              onChange={(e) => {
                formik.setFieldValue('phone', e.target.value.replace(/[^0-9+]/g, ''))
              }}
              inputProps={{ inputMode: 'numeric' }}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              margin="normal"
              className="!w-full !md:w-1/2 !mx-1"
            />
          </div>
          <div className="flex flex-col md:flex-row w-full px-1">
            <FormControl
              fullWidth
              margin="normal"
              error={formik.touched.roles && Boolean(formik.errors.roles)}
            >
              <InputLabel>Roles</InputLabel>
              <Select
                name="roles"
                multiple
                value={formik.values.roles}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputProps={{'aria-label': 'roles'}}
              >
                {rolesOptions.map(item => (
                  <MenuItem key={item.role} value={item.role}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Button type="submit" variant="contained" color="primary" className="!mx-1" fullWidth>
            Submit
          </Button>
        </form>
      </ModalLayout>
    </Modal>
  )
}
