import React, {useRef} from 'react'
import './ImageUploader.css'
import CameraAltIcon from '@mui/icons-material/CameraAlt';

export const ImageUploader = props => {
  const {selectedImages, setSelectedImages} = props
  const imageInputRef = useRef(null)

  const handleImageChange = event => {
    const files = event.target.files
    const reader = new FileReader()
    reader.onloadend = () => {
      setSelectedImages({file: files[0], base64: reader.result})
    }
    reader.readAsDataURL(files[0])
  }

  const handleImagePreviewClick = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click()
    }
  }

  return (
    <div className="lost-pet-upload-dialog-container mt-4">
      {selectedImages ? (
        <div>
          <img
            src={selectedImages?.base64}
            alt="Petimage"
            className="preview"
            onClick={handleImagePreviewClick}
          />
          <input
            type="file"
            id="imageInput"
            accept="image/*"
            ref={imageInputRef}
            onChange={handleImageChange}
            style={{display: 'none'}}
          />
        </div>
      ) : (
        <div id="upload-area" className="upload-area">
          <div className="drag-drop-label">
            <CameraAltIcon />
          </div>
          <input
            id="imageInput"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{width: '100%', height: '100%', opacity: 0}}
          />
        </div>
      )}
    </div>
  )
}
