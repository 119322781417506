import React, {useEffect, useState} from 'react'
import Link from '@mui/material/Link'
import {Button, Grid, Container, Typography, Box, Divider} from '@mui/material'
import appLogo from 'images/app-logo.png'
import banner from 'images/login_right_img.png'
import CallIcon from '@mui/icons-material/Call'
import Email from '@mui/icons-material/Email'
import {GoogleSignInButton} from './GoogleSignInButton'
import {FacebookSignInButton} from './FacebookSignInButton'
import {AppleSignInButton} from './AppleSignInButton'
import {guestOnly} from './components/guestOnly'
import {PhoneAuthentication} from './PhoneAuthentication'
import {EmailAuthentication} from './EmailAuthentication'
import {RecaptchaVerifier} from 'firebase/auth'
import {auth} from '../../firebase/auth'
import { PrivacyPolicy } from 'modules/pet-found/component/PrivacyPolicy'

export const Login = guestOnly(props => {
  const [signInModeEmail, setSignInModeEmail] = useState(true)
  const [loginOption, setLoginOption] = useState(true)

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible'
    })
  }, [])

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      height={{
        sm: '100vh',
      }}
    >
      <Container maxWidth="md">
        <Box
          my={{xs: 6, sm: 0, md: 0, lg: 0}}
          sx={{
            border: '1px solid #000000',
            borderRadius: '16px',
            overflow: 'hidden'
          }}
        >
          <Grid container rowSpacing={1}>
            <Grid
              item
              md={6}
              xs={12}
              pt={4}
              pb={6}
              px={{xs: 2, sm: 2, md: 6}}
              container
              direction="column"
              alignItems="flex-start"
            >
              <Box textAlign="center" width="100%">
                <Link href="#">
                  <img src={appLogo} alt="Logo" style={{margin: '0 auto'}} />
                </Link>
              </Box>
              <Typography variant="h6" component="h6" pt={{xs: 3, sm: 3, md: 0}} pb={0} mx={'auto'}>
                Nice to see you again
              </Typography>

              {signInModeEmail ? (
                <EmailAuthentication handleLoginOption={show => setLoginOption(show)}/>
              ) : (
                <PhoneAuthentication handleLoginOption={show => setLoginOption(show)} />
              )}
              <div id="recaptcha-container"></div>
              {loginOption && (
                <Box sx={{width: '100%'}} mt={5}>
                  <Divider />
                </Box>
              )}
              {loginOption && (
                <div className="w-full">
                  <div className="w-full flex justify-center mt-3 mb-5">
                    <Typography component="h6">or sign in with</Typography>
                  </div>
                  <div className="w-full flex flex-col md:flex-row justify-between">
                    <GoogleSignInButton
                      variant="outlined"
                      color="dark"
                      size="large"
                      title="Google"
                      className="!mt-4 md:!mt-0"
                      style={{border: '1px solid #cdcccc'}}
                    />
                    <FacebookSignInButton
                      variant="outlined"
                      color="dark"
                      size="large"
                      title="Facebook"
                      className="!mt-4 md:!mt-0 md:!mx-1"
                      style={{border: '1px solid #cdcccc'}}
                    />
                    <AppleSignInButton
                      variant="outlined"
                      color="dark"
                      size="large"
                      title="Apple"
                      className="!mt-4 md:!mt-0 "
                      style={{border: '1px solid #cdcccc'}}
                    />
                  </div>
                  <Button
                    className="w-full !mt-4 !shadow-none "
                    variant="contained"
                    size="large"
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                      marginBottom: '8px',
                      border: '1px solid #cdcccc'
                    }}
                    onClick={() => setSignInModeEmail(!signInModeEmail)}
                  >
                    {signInModeEmail ? <CallIcon className="mr-2" /> : <Email className="mr-2" />}
                    {signInModeEmail ? 'Sign in with phone' : 'Sign in with email'}
                  </Button>
                </div>
              )}
            </Grid>
            <Grid item md={6} sx={{display: {sm: 'none', xs: 'none', md: 'block'}}}>
              <img src={banner} alt="Right" style={{height: '100%'}} />
            </Grid>
          </Grid>
        </Box>
        <PrivacyPolicy />
      </Container>
    </Grid>
  )
})
