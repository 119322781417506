import React, { useContext, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { Button, CircularProgress, styled } from '@mui/material';
import {ReactComponent as CsvIcon} from '../asset/csv-icon.svg'
import { SnackbarContext } from './snackbar/SnackbarContext';
import { useUploadCSV } from 'hooks/useUploadCSV';
import {Download, Upload} from '@mui/icons-material';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const CsvDropDown = (props) => {
  const {service} = props
  const [anchorEl, setAnchorEl] = useState(null);
  const {setSeverity, showToast} = useContext(SnackbarContext)
  const {uploadCSV, loading} = useUploadCSV()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadTemplate = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}/import/template/${service}.csv`, "_blank", "noreferrer")
    handleClose();
  };

  const handleFileChange = event => {
    handleClose()
    const files = event.target.files
    uploadCSV(files[0], service)
      .then(data => {
        console.log(data)
        setSeverity('success')
        showToast('File upload successfully')
      })
      .catch(e => {
        console.log(e)
        setSeverity('error')
        showToast('Failed to upload csv. Please try again.')
      })
  }

  return loading ? <CircularProgress style={{width: '24px', height: '24px'}} /> : (
    <div>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <CsvIcon width="24px" height="24px" color='dark'/>
      </IconButton>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDownloadTemplate} style={{fontWeight: 'normal'}}><Download fontSize="small" />Download CSV Template</MenuItem>
        <MenuItem>
          <Button
            component="label"
            role={undefined}
            tabIndex={-1}
            color='inherit'
            style={{
              textTransform: 'none', 
              fontSize: '16px'
            }}
            startIcon={<Upload />}
            sx={{
              fontFamily: `'Roboto', 'Helvetica', 'Arial', sans-serif !important`,
            }}
            width="100px"
          > 
            Upload
            <VisuallyHiddenInput type="file" accept=".csv" onChange={handleFileChange}/>
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
};

