import React, {useContext, useRef, useState} from 'react'
import Box from '@mui/material/Box'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  IconButton
} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import {Insights} from './Insights'
import {ProductDetailModal} from './components/ProductDetailModal'
import './Products.css'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {DeleteModal} from './components/DeleteModal'
import {ConnectedComponent} from 'components/service/ConnectedComponent'
import {storeSocketClient} from '../client/socket.client'
import {ProductEditModal} from './components/ProductEditModal'

const columns = [
  {id: 'name', label: 'Name'},
  {id: 'price', label: 'Price'},
  {id: 'quantity', label: 'In Stock'},
  {id: 'sku', label: 'SKU'},
  {id: 'isActive', label: 'Status'},
  {id: 'approval', label: ''},
  {id: 'actions', label: ''}
]

export const Products = props => {
  const detailModalRef = useRef(null)
  const editModalRef = useRef(null)
  const {list, loading, Loader, Paginator, handleSearch, patch, refreshTable} = props
  const {setSeverity, showToast} = useContext(SnackbarContext)
  const [showDeleteModal, setDeleteModal] = useState(false)
  const [id, setId] = useState(null)

  const onClick = id => {
    detailModalRef.current.show(id, {
      anchor: 'right'
    })
  }

  const editClick = (event, id) => {
    event.stopPropagation()
    editModalRef.current.show(id, {
      anchor: 'right'
    })
  }

  const handleRequest = async (event, productId, value) => {
    event.stopPropagation()
    try {
      const result = await patch(productId, {
        approved: value
      })
      if (result) {
        refreshTable()
        setSeverity('success')
        showToast(`${value ? 'Product is approved' : 'Product approval revoked'}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onDeleteClick = (e, id) => {
    e.stopPropagation()
    setId(id)
    setDeleteModal(true)
  }

  const onClose = () => {
    setId(null)
    setDeleteModal(false)
  }

  const onDelete = () => {
    setId(null)
    setDeleteModal(false)
    refreshTable()
  }

  const onEdit = () => {
    setId(null)
    refreshTable()
  }

  return (
    <Box mt={3}>
      <Insights />
      <ProductDetailModal ref={detailModalRef} />
      <ProductEditModal ref={editModalRef} onEdit={onEdit} />
      {showDeleteModal && (
        <ConnectedComponent
          Component={DeleteModal}
          service="products"
          id={id}
          open={showDeleteModal}
          client={storeSocketClient}
          onDelete={onDelete}
          onClose={onClose}
        />
      )}

      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        {/* <Typography sx={{textAlign: 'right', m: 1}}>
          <Tooltip title="Create Product">
            <IconButton color="primary">
              <Add />
            </IconButton>
          </Tooltip>
        </Typography> */}
        <Grid className="!p-4" container alignItems="center" spacing="10px">
          <Grid item xs={12} lg={2} md={4} gap="10px">
            <TextField
              label="Name"
              type="text"
              size="small"
              InputLabelProps={{
                shrink: true
              }}
              onChange={e => {
                handleSearch('name', e.target.value)
                // handleSearch({
                //   firstName: e.target.value,
                //   lastName: e.target.value
                // })
              }}
            />
          </Grid>
        </Grid>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.length ? (
                list.map((row, index) => (
                  <TableRow key={index} onClick={() => onClick(row._id)} hover>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>$ {row.price}</TableCell>
                    <TableCell>{row.quantity}</TableCell>
                    <TableCell>{row.sku}</TableCell>
                    <TableCell>{row.isActive ? 'Published' : 'Unknown'}</TableCell>
                    <TableCell align="center" style={{width: '12rem'}}>
                      <Button variant="outlined" onClick={e => handleRequest(e, row?._id, !row?.approved)}>
                        {row?.approved ? 'Revoke approval' : 'Approve'}
                        {console.log(row?.approved)}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton size="small" onClick={e => editClick(e, row?._id)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton size="small" onClick={e => onDeleteClick(e, row?._id)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Typography>No Products</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Paginator />
        </TableContainer>
      </Paper>
    </Box>
  )
}
