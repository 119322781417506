import React, {useContext, useRef} from 'react'
import Box from '@mui/material/Box'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  Button,
  Avatar
} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import Add from '@mui/icons-material/Add'
import {Insights} from './Insights'
import {Delete, Edit} from '@mui/icons-material'
import {AddEditModal} from './modals/AddEditModal'
import {DeleteModal} from './modals/DeleteModal'
import {storeSocketClient} from '../client/socket.client'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {useNavigate} from 'react-router-dom'
import {ImagePreview} from 'components/ImagePreview'
import placeholder from 'images/avatar_placeholder.svg'

const columns = [
  {id: 'name', label: 'Name'},
  {id: 'vendor', label: 'Vendor'},
  {id: 'approved', label: ''},
  {id: 'products', label: ''},
  {id: 'actions', label: ''}
]

export const Brands = props => {
  const {list, loading, Loader, Paginator, handleSearch, refreshTable} = props
  const editModalRef = useRef(null)
  const deleteModalRef = useRef(null)
  const {setSeverity, showToast} = useContext(SnackbarContext)
  const navigate = useNavigate()

  const showModal = (event, id = null) => {
    if (event) event.stopPropagation()
    editModalRef.current.show(id)
  }

  const showDeleteModal = (event, id = null) => {
    if (event) event.stopPropagation()
    deleteModalRef.current.show(id)
  }

  const handleRequest = async (event, productId, value) => {
    event.stopPropagation()
    try {
      const result = await storeSocketClient.service('brands').patch(productId, {
        approved: value
      })
      if (result) {
        refreshTable()
        setSeverity('success')
        showToast(`${value ? 'Brand is approved' : 'Brand approval revoked'}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onAddEdit = () => refreshTable()

  const onDelete = () => refreshTable()

  const showProducts = (e, id) => {
    e.stopPropagation()
    navigate(`/store/brand-products/${id}`)
  }
  return (
    <Box mt={3}>
      <Insights />
      <AddEditModal ref={editModalRef} onAddEdit={onAddEdit} />
      <DeleteModal ref={deleteModalRef} onDelete={onDelete} />
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <div className="p-4 flex justify-between">
          <TextField
            label="Name"
            type="text"
            size="small"
            InputLabelProps={{
              shrink: true
            }}
            onChange={e => {
              handleSearch('name', e.target.value)
            }}
          />

          <Tooltip title="Create User">
            <IconButton color="primary" onClick={e => showModal(e)}>
              <Add />
            </IconButton>
          </Tooltip>
        </div>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {columns.map(column => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.length ? (
                list.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {row?.icon ? (
                        <ImagePreview
                          media={row?.icon}
                          service="media"
                          client={storeSocketClient}
                          className="w-10 h-10  rounded-full"
                          fallbackSrc={placeholder}
                        />
                      ) : (
                        <Avatar src={placeholder}></Avatar>
                      )}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      {row.vendor?.firstName} {row.vendor?.lastName}
                    </TableCell>
                    <TableCell align="center" style={{width: '12rem'}}>
                      <Button variant="outlined" onClick={e => handleRequest(e, row?._id, !row?.approved)}>
                        {row?.approved ? 'Revoke approval' : 'Approve'}
                        {console.log(row?.approved)}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button onClick={e => showProducts(e, row?._id)}>Products</Button>
                    </TableCell>
                    <TableCell>
                      <IconButton size="small" onClick={e => showModal(e, row?._id)}>
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton size="small" onClick={e => showDeleteModal(e, row?._id)}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Typography>No Brands</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Paginator />
        </TableContainer>
      </Paper>
    </Box>
  )
}
