import {Box, Button, Modal} from '@mui/material'
import {ModalLayout} from 'components/layouts/ModalLayout'

import {connectService} from 'components/service/connectService'

export const DeleteModal = props => {
  const {open, handleModal, id, remove} = props
  const handleDelete = async () => {
    const result = await remove(id)
    handleModal(result)
  }
  return (
    <Modal
      open={open}
      onClose={handleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout title="Are you sure ?" onClose={handleModal}>
        <Box>
          <div>
            <p>This will delete the item permanently. You can not undo this action. </p>
            <div className="flex justify-end mt-4">
              <Button className="!mt-4" variant="contained" color="primary" onClick={handleDelete}>
                Confirm
              </Button>
            </div>
          </div>
        </Box>
      </ModalLayout>
    </Modal>
  )
}
