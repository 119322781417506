import {PetProfileImage} from '../public-report/PetProfileImage'
import {AdditionalPetDetail} from './AdditionalPetDetail'
import {BasicDetail} from './BasicDetail'
import {PrivacyPolicy} from '../component/PrivacyPolicy'
import { LostPetPoster } from './LostPetPoster'
import { DownloadAppFloatingSection } from 'components/DownloadAppFloatingSection'

export const PetPublicProfile = props => {
  const {data} = props

  return (
    <div>
      <div className="m-auto w-full md:w-9/12 xl:w-10/12 2xl:w-7/12 px-4 pt-2 lg:px-16 mb-32">
        <div
          style={{
            border: '1px solid #E0E0E0',
            borderRadius: '8px'
          }}
          className="flex flex-col"
        >
          <div className="px-8 pt-8 pb-4">
            <div className="flex flex-col">
              <div className="flex flex-col md:flex-row">
                <div className="flex flex-col lg:flex-row flex-1">
                  <PetProfileImage src={data?.profile?.url} />
                  <BasicDetail data={data} />
                </div>
                <div className="my-8 md:my-0 lg:mt-0 flex justify-center">
                  {/* <img src={SamplePoster} alt="lost pet poster" className=" w-64 md:w-64 lg:w-72 object-cover rounded-xl" /> */}
                  <LostPetPoster data={data}/>
                </div>
              </div>
              <div className="mt-4">
                <AdditionalPetDetail data={data} />
              </div>
            </div>
          </div>
        </div>
        <PrivacyPolicy />
      </div>
      <DownloadAppFloatingSection className="justify-center"/>
    </div>
  )
}
