import * as React from 'react'
import {AppLayout} from 'modules/layout/AppLayout'
import {DataTable} from './components/DataTable'
import {connectService} from 'components/service/connectService'
import InviteModal from './components/InviteModal'
import {useState} from 'react'
import {DeleteModal} from './components/DeleteModal'

export const Browse = connectService(
  props => {
    const {refreshTable} = props
    const [selectedItem, setSelectedItem] = useState(null)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    const handleSuccess = () => {
      refreshTable()
    }

    const handleDeleteModal = (data = null) => {
      if (data) refreshTable()
      setSelectedItem(null)
      setOpenDeleteModal(!openDeleteModal)
    }

    const handleOnDelete = item => {
      setSelectedItem(item)
      setOpenDeleteModal(!openDeleteModal)
    }

    return (
      <AppLayout heading="Invitations">
        <div className="w-full flex justify-end">
          <InviteModal handleSuccess={handleSuccess} />
        </div>

        <div className="mt-2">
          <DataTable onDelete={handleOnDelete} {...props} />
        </div>
        {openDeleteModal && (
          <DeleteModal
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            handleModal={handleDeleteModal}
            id={selectedItem}
          />
        )}
      </AppLayout>
    )
  },
  'invites',
  {
    query: {
      $populate: 'invitedBy',
      status: { $ne:'success' }
    }
  }
)
