import React, {useState} from 'react'
import Modal from '@mui/material/Modal'
import {motion} from 'framer-motion'
import Box from '@mui/material/Box'
import {Button, Drawer, Tab} from '@mui/material'
import cross from 'images/crosss.svg'
import {Grid} from '@mui/material'
import Typography from '@mui/material/Typography'
import {Tabs} from '@mui/material'
import {UserHeaderInfo} from './UserHeaderInfo'
import {Activity} from './tabs/Activity'
import {Pets} from './tabs/pets'
import {Flags} from './tabs/Flags'
import {EditDetail} from './EditDetail'
import {ConnectedComponent} from 'components/service/ConnectedComponent'

const tabs = ['Pets']
const DetailModal = props => {
  const {openDetailModal, handleDetailModal, handleAddPetModal, handleEditPetModal, age, handleChange} = props
  const [activeTab, setActiveTab] = useState(0)

  const onClose = () => {
    setActiveTab(0)
    handleDetailModal()
  }
  return (
    <Drawer anchor="right" open={openDetailModal} onClose={onClose}>
      <Box height="100vh" maxWidth="md" sx={{marginLeft: 'auto', background: '#fff', overflowY: 'scroll'}}>
        <Box textAlign="right" width="100%">
          <Button onClick={onClose} sx={{position: 'absolute', right: '0'}}>
            <img src={cross} alt="close"/>
          </Button>
        </Box>
        <UserHeaderInfo {...props} />
        <Grid container>
          <ConnectedComponent Component={EditDetail} service="users" id={props?.data?._id} />
          <Grid item md={8} xs={12} py={3} px={3}>
            <Box fullWidth>
              <Tabs
                value={activeTab}
                onChange={(e, value) => setActiveTab(value)}
                variant="scrollable"
                scrollButtons="auto"
                className="!mb-4"
              >
                {tabs.map((tab, index) => (
                  <Tab key={index} label={tab} sx={{fontWeight: 'bold'}} />
                ))}
              </Tabs>

              {/* {activeTab === 0 && <Typography>Content for Tab 1</Typography>}
              {activeTab === 1 && <Activity />} */}
              {(activeTab === 0 && props?.data?._id) && (
                <ConnectedComponent
                  Component={Pets}
                  service="pets"
                  config={{query: {user: props?.data?._id, $populate: ['type', 'subtype', 'profile']}}}
                />
              )}
              {/* {activeTab === 3 && <Typography>Content for Tab 2</Typography>}
              {activeTab === 4 && <Flags handleChange={handleChange} />} */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  )
}

export default DetailModal
