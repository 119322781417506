import {useEffect, useRef} from 'react'
import {
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Typography,
  Avatar,
  Chip,
  IconButton,
} from '@mui/material'
import {Delete, Edit} from '@mui/icons-material'
import {StyledTable, StyledTableRow, StyledTableHeadCell, StyledTableCell} from 'components/StyledComponent'
import {ImagePreview} from 'components/ImagePreview'
import placeholder from 'images/avatar_placeholder.svg'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import {useNavigate} from 'react-router-dom'
import { DetailViewModal } from '../modals/DetailViewModal'
export const DataTable = props => {
  const navigate = useNavigate()
  const {list, loading, Loader, Paginator, onEdit, onDelete, isRefreshTable, setRefreshTable, refreshTable} =
    props

  const detailModalRef = useRef(null)

  useEffect(() => {
    if (isRefreshTable) {
      refreshTable()
      setRefreshTable(false)
    }
  }, [isRefreshTable])

  const onClick = id => {
    detailModalRef.current.show(id, {
      anchor: 'right'
    })
  }

  return (
    <div>
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <TableContainer>
          <StyledTable>
            <TableHead>
              <StyledTableRow>
                <StyledTableHeadCell></StyledTableHeadCell>
                <StyledTableHeadCell>Name</StyledTableHeadCell>
                <StyledTableHeadCell>Website</StyledTableHeadCell>
                <StyledTableHeadCell>Phone</StyledTableHeadCell>
                <StyledTableHeadCell>TIN</StyledTableHeadCell>
                <StyledTableHeadCell>Accept Donation</StyledTableHeadCell>
                <StyledTableHeadCell>Active</StyledTableHeadCell>
                <StyledTableHeadCell></StyledTableHeadCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {list.length ? (
                list.map((row, index) => (
                  <StyledTableRow
                    key={index}
                    odd={index % 2 !== 0 ? 1 : 0}
                    onClick={() => onClick(row?._id)}
                    style={{cursor: 'pointer'}}
                  >
                    <StyledTableCell className='w-full md:w-20'>
                      {row?.logo ? (
                        <ImagePreview
                          media={row?.logo}
                          className="w-12 h-12  rounded-full"
                          fallbackSrc={placeholder}
                        />
                      ) : (
                        <Avatar src={placeholder}></Avatar>
                      )}
                    </StyledTableCell>
                    <StyledTableCell data-label="Name">{row.name ? row.name : '-'}</StyledTableCell>
                    <StyledTableCell data-label="Website">{row.website ? row.website : '-'}</StyledTableCell>
                    <StyledTableCell data-label="Phone">{row.phone ? row.phone : '-'}</StyledTableCell>
                    <StyledTableCell data-label="TIN">{row.TIN ? row.TIN : '-'}</StyledTableCell>
                    <StyledTableCell data-label="Accept Donation">
                      {row?.canAcceptDonation ? (
                        <Chip label="Yes" color="success" size="small" variant="outlined" />
                      ) : (
                        <Chip label="No" color="error" size="small" variant="outlined" />
                      )}
                    </StyledTableCell>
                    <StyledTableCell data-label="Active">
                      {row.active ? (
                        <Chip label="Yes" color="success" size="small" variant="outlined" />
                      ) : (
                        <Chip label="No" color="error" size="small" variant="outlined" />
                      )}
                    </StyledTableCell>
                    <StyledTableCell size="small">
                      <IconButton onClick={(e) => {
                        e.stopPropagation()
                        onEdit(row._id)
                      }}>
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton onClick={(e) => {
                        e.stopPropagation()
                        navigate(`pet-codes/${row._id}`)
                      }}>
                        <QrCode2Icon fontSize="small" />
                      </IconButton>
                      <IconButton onClick={(e) => {
                        e.stopPropagation()
                        onDelete(row._id)
                      }}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : loading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={8} align="center">
                    <Loader />
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={8} align="center">
                    <Typography>No Data </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </StyledTable>
          <Paginator />
        </TableContainer>
      </Paper>
      <DetailViewModal ref={detailModalRef} />
    </div>
  )
}
