import { Box, Button, Grid, IconButton, Modal, TextField, Typography } from "@mui/material"
import cross from 'images/crosss.svg';
import uploadicon from 'images/newimages/uploadicon.svg'

const editAdd = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
 
  width: 700,
  
  '@media (max-width:767px)': {
    width: '95%',
  },
};

export const AddEditModal = ({open, handleModal, item}) => {
  
  const handleImageUpload =() => {

  }
  return (
  <Modal open={open} onClose={handleModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
    <Box  sx={ editAdd }>
      <Typography variant="h5" component="h5" px={2} pt={2} color="transparent.contrastText" sx={{ fontSize: '20px', fontWeight: '600'}}>{item ? 'Edit' : 'Add'} Wellness Type</Typography>
      <Box sx={{ p: { lg: 9, md: 6, sm: 4, xs: 4 } }}>                     
        <Box textAlign="right" width="100%" >
            <Button onClick={handleModal} style={{ position: 'absolute', right: '-9px', top: '-3px'}}><img src={cross} /></Button>
        </Box>
        <Grid  container  direction="row"  justifyContent="space-between"  alignItems="center">
          <Grid item xs={12} sm={12} md={6} mb={2} sx={{ px: { xs: 0, sm: 0, md: 2 } }}>                   
              <TextField id="standard-basic" label="Pet Type*" variant="standard" fullWidth/>
          </Grid>
          <Grid item xs={12} sm={12} md={6} mb={2} sx={{ px: { xs: 0, sm: 0, md: 2 } }}>                   
              <TextField id="standard-basic" label="Group" variant="standard" fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={6} mb={2} sx={{ px: { xs: 0, sm: 0, md: 2 } }}>                   
              <TextField id="standard-basic" label="Wellness Type Name*" variant="standard" fullWidth />
          </Grid>
          <Grid item xs={12} sm={12}  md={6} mb={2} sx={{ px: { xs: 0, sm: 0, md: 2 } }}>                   
              <TextField id="standard-basic" label="Status" variant="standard" fullWidth />
          </Grid>
          <Grid item xs={12} sm={12}  md={6} sx={{ mb: { xs: 2, sm: 2, md: 2 }, px: { xs: 0, sm: 0, md: 2 } }}>                     
              <TextField id="standard-basic" label="Sort Order" variant="standard" fullWidth />
          </Grid>
          
          <Grid item xs={12} sm={12}  md={6}  sx={{ mb: { xs: 2, sm: 2, md: 0 }, px: { xs: 0, sm: 0, md: 2 } }}>                   
            <input type="file" accept="image/*" id="image-upload-input" onChange={handleImageUpload} style={{ display: 'none' }}/>
            <label htmlFor="image-upload-input">
              <IconButton color="primary" component="span" >
              <img src={uploadicon} />
              </IconButton>
            </label>
            <Typography variant="span" component="span" color="transparent.contrastText" sx={{fontSize: '15px'}}> Upload Icon</Typography>
          </Grid>
        </Grid>
        
        <Box fullWidth textAlign='center' mt={3}>
          <Button variant="contained" color='primary' size="large" sx={{padding: '8px 56px'}}>
            {item ? 'Save': 'Submit'}
          </Button>
        </Box>
      </Box>
    </Box>
  </Modal>
  )
}