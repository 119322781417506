import {Grid} from '@mui/material'
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'
import VaccinesIcon from '@mui/icons-material/Vaccines'
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement'
import PropTypes from 'prop-types'

const AdditionalArrayData = props => {
  return (
    <div>
      <div style={{color: '#ED1690'}}>
        <props.Icon className="mr-4" /> <span className="text-xl">{props.title}</span>
      </div>
      <ul className="list-disc ml-4">
        {props?.data?.map((item, key) => {
          return <li className="ml-4 my-1 text-sm">{item?.name}</li>
        })}
      </ul>
    </div>
  )
}

export const AdditionalPetDetail = props => {
  const {data, loading, Loader} = props
  return (
    <div>
      {loading ? (
        <div className="flex justify-center">
          <Loader />
        </div>
      ) : (
        <Grid container>
          <Grid item sm={12} md={4} lg={4}>
            <AdditionalArrayData title="Activities" Icon={DirectionsRunIcon} data={data?.activities} />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <AdditionalArrayData title="Wellness" Icon={SelfImprovementIcon} data={data?.wellness} />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <AdditionalArrayData title="Vaccine" Icon={VaccinesIcon} data={data?.vaccination} />
          </Grid>
        </Grid>
      )}
    </div>
  )
}

AdditionalPetDetail.prototype = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  Loader: PropTypes.elementType
}
