import {Box, Button, Checkbox, FormControlLabel, Modal, TextField} from '@mui/material'
import {connectService} from 'components/service/connectService'
import {useEffect, useMemo, useState} from 'react'
import {ModalLayout} from 'components/layouts/ModalLayout'
import * as yup from 'yup'
import {useFormik} from 'formik'
import {isEmpty, keys, pick} from 'lodash'
import {IconUploader} from 'components/IconUploader'

const CreateSchema = yup.object({
  name: yup.string().required('Name is required')
})

const EditSchema = yup.object({
  name: yup.string().required('Name is required')
})

const initialValues = {
  name: '',
  group: '',
  active: true,
  icon: ''
}
export const AddEditModal = connectService(
  props => {
    const {open, handleModal, id, data, setData, setOpen, loading, Loader, create, patch} = props
    const [icon, setIcon] = useState(null)
    const [iconError, showIconError] = useState(false)

    const initialData = useMemo(() => {
      return pick(data, keys(initialValues))
    }, [data])

    const formik = useFormik({
      initialValues: id
        ? {...initialData, active: data.hasOwnProperty('active') ? data.active : true}
        : initialValues,
      enableReinitialize: true,
      validationSchema: id ? EditSchema : CreateSchema,
      onSubmit: values => {
        if (!icon && isEmpty(values.icon)) {
          showIconError(true)
          return
        }
        const payload = {...values, icon: icon ? icon?._id : values.icon}
        id ? patch(id, payload) : create(payload)
      }
    })

    useEffect(() => {
      if (!id) {
        setData({})
      }
    }, [id])

    const onClose = () => {
      setData({})
      setOpen(false)
    }

    useEffect(() => {
      if (data?.icon) {
        setIcon(data?.icon)
      }
    }, [data])

    useEffect(() => {
      showIconError(false)
    }, [icon])

    return (
      <Modal
        open={open}
        onClose={handleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalLayout
          title={`${id ? 'Edit' : 'Add'} Pet Type`}
          onClose={onClose}
          LoaderComponent={Loader}
          loading={loading}
          id={id}
          showCopyIdButton={!!id}
        >
          <form onSubmit={formik.handleSubmit}>
            <div>
              <TextField
                id="standard-basic"
                label="Name"
                variant="outlined"
                {...formik.getFieldProps('name')}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
              />
            </div>
            {!id && (
              <div className="mt-3">
                <TextField
                  id="standard-basic"
                  label="Group"
                  variant="outlined"
                  {...formik.getFieldProps('group')}
                  fullWidth
                />
              </div>
            )}
            <div className="my-4">
              <div className="flex justify-between items-center">
                <IconUploader title="Upload Image" icon={icon} setIcon={setIcon} pathKey="assets/pet-types" />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.active}
                      onChange={e => formik.setFieldValue('active', e.target.checked)}
                    />
                  }
                  label="Active"
                />
              </div>
              {iconError && <p style={{color: 'red', marginLeft: '2PX'}}>Please upload image*</p>}
            </div>

            <Box>
              <Button variant="contained" color="primary" fullWidth size="large" type="submit">
                {id ? 'Edit' : 'Submit'}
              </Button>
            </Box>
          </form>
        </ModalLayout>
      </Modal>
    )
  },
  'pet-types',
  {query: {$populate: ['icon']}}
)
