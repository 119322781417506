import {Box, Button, Modal} from '@mui/material'
import {ModalLayout} from 'components/layouts/ModalLayout'
import {connectService} from 'components/service/connectService'
import PropTypes from 'prop-types'
/**
 * DeleteModal
 *
 * @param {string} id - pet-finder object id, It is required.
 * @param {function} handleModal
 * @param {boolean} open - boolean value to manage modal open/close state
 * @param {function} remove - function to send remove request
 */
export const DeleteModal = connectService(props => {
  const {open, handleModal, id, remove} = props
  return (
    <Modal
      open={open}
      onClose={handleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout title="Are you sure ?" onClose={handleModal}>
        <Box>
          <div>
            <p>This will delete the item permanently. You can not undo this action. </p>
            <div className="flex justify-end mt-4">
              <Button
                className="!mt-4"
                variant="contained"
                color="primary"
                onClick={() => {
                  remove(id)
                  handleModal()
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Box>
      </ModalLayout>
    </Modal>
  )
}, 'pet-finder')

DeleteModal.prototype = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  handleModal: PropTypes.func,
  remove: PropTypes.func
}
