import React, {useContext, useRef} from 'react'
import Box from '@mui/material/Box'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  Button
} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import Add from '@mui/icons-material/Add'
import {Delete, Edit} from '@mui/icons-material'
import {AddEditModal} from './modals/AddEditModal'
import {DeleteModal} from './modals/DeleteModal'
import {storeSocketClient} from '../client/socket.client'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'

const columns = [
  {id: 'name', label: 'Name'},
  {id: 'address', label: 'address'},
  {id: 'phone', label: 'Phone'},
  {id: 'supportContact', label: 'Support Contact'},
  {id: 'vendor', label: 'Vendor'},
  {id: 'approved', label: 'Approval'},
  {id: 'viewShop', label: ''},
  {id: 'actions', label: ''}
]

export const Shops = props => {
  const {list, loading, Loader, Paginator, handleSearch, refreshTable} = props
  const editModalRef = useRef(null)
  const deleteModalRef = useRef(null)
  const {setSeverity, showToast} = useContext(SnackbarContext)
  const showModal = (event, id = null) => {
    if (event) event.stopPropagation()
    editModalRef.current.show(id)
  }

  const showDeleteModal = (event, id = null) => {
    if (event) event.stopPropagation()
    deleteModalRef.current.show(id)
  }

  const handleRequest = async (event, id, value) => {
    event.stopPropagation()
    try {
      const result = await storeSocketClient.service('store').patch(id, {
        approved: value
      })
      if (result) {
        console.log(result)
        refreshTable()
        setSeverity('success')
        showToast(`${value ? 'Vendor Store has been approved' : 'Store approval revoked'}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onAddEdit = () => refreshTable()

  const onDelete = () => refreshTable()

  return (
    <Box mt={3}>
      <AddEditModal ref={editModalRef} onAddEdit={onAddEdit} />
      <DeleteModal ref={deleteModalRef} onDelete={onDelete} />
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <div className="p-4 flex justify-between">
          <TextField
            label="Name"
            type="text"
            size="small"
            InputLabelProps={{
              shrink: true
            }}
            onChange={e => {
              handleSearch('name', e.target.value)
            }}
          />

          <Tooltip title="Create User">
            <IconButton color="primary" onClick={e => showModal(e)}>
              <Add />
            </IconButton>
          </Tooltip>
        </div>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.length ? (
                list.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.supportContact}</TableCell>
                    <TableCell>
                      {row.vendor?.firstName} {row.vendor?.lastName}
                    </TableCell>
                    <TableCell align="center">
                      <Button variant="outlined" onClick={e => handleRequest(e, row?._id, !row?.approved)}>
                        {row?.approved ? 'Revoke' : 'Approve'}
                        {console.log(row?.approved)}
                      </Button>
                    </TableCell>
                    <TableCell align="center" style={{width: '7rem', paddingLeft: 0, paddingRight: 0}}>
                      <Button
                        disabled={!row?.approved}
                        onClick={() => {
                          window.open(`${process.env.REACT_APP_NOAH_MARKET_BASE_URL}/shops/${row?.slug}`, '_blank', 'noreferrer');
                        }}
                      >
                        View Shop
                      </Button>
                    </TableCell>
                    <TableCell>
                      <IconButton size="small" onClick={e => showModal(e, row?._id)}>
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton size="small" onClick={e => showDeleteModal(e, row?._id)}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Typography>No Shops found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Paginator />
        </TableContainer>
      </Paper>
    </Box>
  )
}
