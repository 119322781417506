import {feathers} from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import auth from '@feathersjs/authentication-client'
import { AUTHENTICATION_KEY } from './common'
import store from 'store/store'
import { AuthActions } from 'store/modules/authentication/actions'

const socket = io(process.env.REACT_APP_BASE_URL)
const socketClient = feathers()

socketClient.configure(socketio(socket))
socketClient.configure(auth({
  storage: window.localStorage,
  storageKey: AUTHENTICATION_KEY
}))

if (window.localStorage.getItem(AUTHENTICATION_KEY)) {
  const result = await socketClient.reAuthenticate(true)
  store.dispatch(AuthActions.reAuthenticated(result))
}

socketClient.hooks({
  error(context) {
    console.error(`Error in '${context.path}' service method '${context.method}'`, context.error.stack)
  }
})

export {socketClient}
