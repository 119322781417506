import { Box, Button, Grid, Modal, TextField, Typography, MenuItem } from "@mui/material"
import cross from 'images/crosss.svg';
import { DataGrid } from "@mui/x-data-grid";
const Role = [
  {
    value: 'Menu Items',
    label: 'Menu Items',
  },
  {
    value: 'Role',
    label: 'Role',
  },
];
const petOwner = [
  {
    value: 'Pet Owner',
    label: 'Rose',
  },
  {
    value: 'Pet Owner2',
    label: 'Johnson',
  },
];
const Status = [
  {
    value: 'Status',
    label: 'Enable',
  },
  {
    value: 'status2',
    label: 'Disable',
  },
];

const categorycolumns = [
  { field: 'reporttype', headerName: 'Report Type', width: 250},
  { field: 'commonname', headerName: 'Common Name', width: 270},
  { field: 'category', headerName: 'Category', width: 270 },
  { field: 'sortorder', headerName: 'Sort Order', width: 240 },
  ];

const categoryrows = [
  {
    reporttype: 'Post',
    category: 'Hate',
    category: 'Hate',
    sortorder: '1',
  },
];  

const editAdd = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
 
  width: 1200,
  
  '@media (max-width:767px)': {
    width: '95%',
  },
};
export const ModerationModal = ({open, handleModal}) => {
  return (
  <Modal open={open} onClose={handleModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
    <Box  sx={ editAdd }>
      <Box py={2} px={2}>  
        <Box textAlign="right" width="100%" >
          <Button onClick={handleModal} style={{ position: 'absolute', right: '-9px', top: '-3px'}}><img src={cross} /></Button>
        </Box> 
        <Box>
          <Typography variant="h5" component="h5" color="transparent.contrastText" mb={0.5} sx={{ fontSize: '20px', fontWeight: '600'}}>Add Moderation Category</Typography>
          <Typography variant="p" component="p" color="transparent.contrastText" sx={{ fontSize:'12px' }}>The page instruction goes here.  Actions are automatic actions that the system executes  based on parameters given on flagged content</Typography>
        </Box>
        <Grid container display="flex" justifyContent="space-between" alignItems="center" my={1}>
          <Grid item md={9} xs={12} sm={12}>
            <Grid container display="flex" justifyContent="space-between" my={3}>
              <Grid item md={3} xs={12} sm={12} sx={{pr:{md:2, sm:0, xs: 0}}}>
                <TextField id="role" select label="Report Type" defaultValue="Role" fullWidth >
                  {Role.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                      {option.label}
                      </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={3} xs={12} sm={12} sx={{pr:{md:2, sm:0, xs: 0}}}>
                <TextField id="role" select label="Common Name" defaultValue="Role" fullWidth >
                  {Role.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                      {option.label}
                      </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={3} xs={12} sm={12} sx={{pr:{md:2, sm:0, xs: 0}}}>
                <TextField id="role" select label="Category" defaultValue="Role" fullWidth >
                  {Role.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                      {option.label}
                      </MenuItem>
                  ))}
                </TextField>
              </Grid>
              
              <Grid item md={3} xs={12} sm={12}>
                <TextField id="role" select label="Sort Order" defaultValue="Role" fullWidth >
                  {Role.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                      {option.label}
                      </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2} xs={12} sm={12}>
            <Button  variant="contained" color='primary' size="large" fullWidth >
              Add Category
            </Button>
          </Grid>
        </Grid>                 
        <DataGrid columns={categorycolumns} rows={categoryrows} getRowId={(row) => row. reporttype} />
      </Box>
    </Box>
  </Modal>  
  )
}