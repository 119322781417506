import {useState} from 'react'
import {AUTHENTICATION_KEY} from 'client/common'
import axios from 'axios'

export const useUploadCSV = () => {
  const [loading, setLoading] = useState(false)

  const headers = {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
    Authorization: window.localStorage.getItem(AUTHENTICATION_KEY)
  }

  const uploadCSV = (file, service = null) => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      const formData = new FormData()
      formData.append('files', file)

      if (service) formData.append('service', service)

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/import`,
          formData,
          {headers}
        )
        .then(result => resolve(result.data))
        .catch(e => {
          reject(e)
        })
        .then(() => setLoading(false))
    })
  }

  return {loading, uploadCSV}
}
