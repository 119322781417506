import {Avatar} from '@mui/material'
import {MediaCarousel} from './MediaCarousel'
import {Description} from './Description'
import ShieldIcon from '@mui/icons-material/Shield'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {ImagePreview} from 'components/ImagePreview'

export const PostContent = props => {
  const petSrc = 'https://s3-media0.fl.yelpcdn.com/bphoto/m_ev79oAYkIFLlTh9wQdWg/300s.jpg'
  const avtarSrc = 'https://variety.com/wp-content/uploads/2021/04/Avatar.jpg'
  const {content} = props

  const [pet, setPet] = useState(null)
  const [postedBy, setPostedBy] = useState(null)
  const [postContent, setPostContent] = useState(null)
  useEffect(() => {
    if (content) {
      setPet(content?.pet)
      setPostedBy(content?.user)
      setPostContent(content)
    }
  }, [content])
  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <div className="flex  items-center">
          {postedBy?.profile ? (
            <ImagePreview media={postedBy?.profile} className="w-10 h-10 rounded-full" />
          ) : (
            <Avatar></Avatar>
          )}
          <div>
            {(postedBy?.firstName || postedBy?.lastName) && (
              <p className="mx-2">{`${postedBy?.firstName} ${postedBy?.lastName}`}</p>
            )}
            {postedBy?.username && <p className="text-xs text-slate-600 mx-2">{`@${postedBy?.username}`}</p>}
          </div>
        </div>
        <div>{postContent?.private && <ShieldIcon color="primary" />}</div>
      </div>
      <div className="my-2">
        <Description description={postContent?.body} className="text-sm" />
      </div>

      {postContent?.media && <MediaCarousel media={postContent?.media} />}

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {pet?.profile ? (
            <ImagePreview media={pet?.profile} className="w-10 h-10 rounded-full" />
          ) : (
            <Avatar></Avatar>
          )}
          {pet && <p className="mx-2">{pet?.name}</p>}
        </div>
        <div>
          {postContent?.updatedAt && (
            <p className="text-xs text-slate-600">
              Last Update: {moment(postContent?.updatedAt).format('DD MMM YYYY')}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
