import React, { useContext, useEffect, useState } from 'react'
import {Button, Typography} from '@mui/material'
import { OTPInput } from './OTPInput'
import { restClient } from 'client/rest.client'
import { signInWithEmailAndPassword } from "firebase/auth";
import {auth} from '../../firebase/auth'
import { SnackbarContext } from 'components/snackbar/SnackbarContext'
import { withFirebaseAuth } from './components/withFirebaseAuth'

const WRONG_OTP_ERROR = 'auth/wrong-password'

export const VerifyOTP =  withFirebaseAuth((props) => {
  const {onSuccessfulAuth, setOtpSent, email} = props
  const [timer, setTimer] = useState(0)
  const [otp, setOTP] = useState(null)
  const { setSeverity, showToast } = useContext(SnackbarContext)
  const [loading, setLoading] = useState(false)
  const [clearOTP, setClearOTP] = useState(false)

  const resendOTP = async () => {
    await restClient.service('authentication/email').create({email})
    setTimer(15)
    const interVal = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1)
    }, 1000)

    setTimeout(() => {
      clearInterval(interVal)
    }, 15000)
  }

  const handleFormSubmit = async () => {
    if (otp.length) {
      setLoading(true)
      signInWithEmailAndPassword(auth, email, otp)
        .then((result) => {
          if (result) {
            setLoading(false)
            onSuccessfulAuth()
          }
        })
        .catch((error) => {
          setLoading(false)
          if (error.code === WRONG_OTP_ERROR) {
            setSeverity('error')
            showToast('OTP is wrong')
            setClearOTP(true)
          }
        });
    } else {
      setSeverity('error')
      showToast('Please enter correct OTP')
    }
  };

  useEffect(() => {
    if (!email && setOtpSent) setOtpSent(false)
  }, [email])

  return (
      <div>
        <Typography variant="h6" component="h6" pt={{xs: 3, sm: 3, md: 2}} pb={3} mx="auto">
          {email}
        </Typography>
        <OTPInput
          className="mb-3"
          setClearOTP={setClearOTP}
          clearOTP={clearOTP}
          onChange={code => setOTP(code)}
        />

        <Button id="sign-in-button" variant="contained" color='primary' fullWidth size="large" onClick={handleFormSubmit} disabled={loading}>
          Verify OTP
        </Button>
        <div className="w-full flex justify-center mt-3">
          {timer > 0
            ? <span>Resend OTP in {timer} seconds</span>
            : <Button variant="text" color="primary" onClick={resendOTP}>Resend OTP</Button>
          }
        </div>
        <div className="mx-auto cursor-pointer text-blue-400" onClick={() => setOtpSent(false)}>Change Email</div>
      </div>
  )
})
