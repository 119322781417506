import React, { useState } from 'react'
import MuiAppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Button, Grid, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import {  Menu } from '@mui/material'
import menuicon from 'images/newimages/MenuFilled.svg';
import { useNavigate } from 'react-router-dom';
import {useDispatch} from 'react-redux'
import {AuthActions} from 'store/modules/authentication/actions'
import LogoutIcon from '@mui/icons-material/Logout';

const Header = (props) => {
    // const [open,setOpen] = useState(true)
  const { handleDrawerOpen, open, heading } = props
  const [anchorEl, setAnchorEl] = useState(null);
  const drawerWidth = 240;
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const AppBar = styled(MuiAppBar, {
      shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
  }));

  const openMenu = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
      setAnchorEl(null);
  };

  const logout = () => {
    // closeMenu()
    dispatch(AuthActions.resetAuth())
    localStorage.removeItem('access_token')
    navigate('/authentication/login', {replace: true})
  }

  return (
    <div>
      <AppBar position="fixed" open={open} color='header'>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'block' }) }}
          >
            <img src={menuicon} />
          </IconButton>
          <Grid container  direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" noWrap component="h6" color="dark.contrastText">
              {heading}
            </Typography>
            <IconButton onClick={logout}>
              <LogoutIcon style={{color: 'white'}}/>
            </IconButton>
            {/* <Box>
              <Box
                aria-controls="dropdown-menu"
                aria-haspopup="true"
                onClick={openMenu}
                variant="contained"
              >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="p" noWrap component="p" color="dark.contrastText" mr={2}>User Menu </Typography>
                  <Stack direction="row" spacing={2}>
                    <Avatar  src="{avatar2}" />
                  </Stack>
                </Box>
              </Box>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={closeMenu}
              >
                <MenuItem onClick={closeMenu}>Profile</MenuItem>
                <MenuItem onClick={closeMenu}>My account</MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </Box> */}
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Header;
