import {CloseSharp} from '@mui/icons-material'
import {Box, Divider, Drawer, Grid, IconButton} from '@mui/material'
import {storeSocketClient} from 'modules/store/client/socket.client'
import moment from 'moment'
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import {PoductImagesGrid} from './PoductImagesGrid'

const LabelField = props => {
  const {heading, value} = props

  return value ? (
    <Box my={1}>
      <p className="text-start text-xs text-slate-600">{heading}</p>
      <p className="text-sm">{value}</p>
    </Box>
  ) : (
    ''
  )
}
export const ProductDetailModal = forwardRef((props, ref) => {
  const [id, setId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [product, setProduct] = useState({})
  const [drawerProps, setDrawerProps] = useState({})

  useImperativeHandle(
    ref,
    () => ({
      show(id, drawerProps) {
        setId(id)
        setOpen(true)
        setDrawerProps(drawerProps)
      }
    }),
    []
  )

  useEffect(() => {
    if (!id) {
      return
    }

    setLoading(true)

    storeSocketClient
      .service('products')
      .get(id, {
        query: {
          $populate: [
            'images',
            'vendor',
            'brand',
            'taxClass',
            'categories',
            'crossSaleProducts',
            'upSaleProducts'
          ]
        }
      })
      .then(data => {
        setProduct(data)
        setLoading(false)
      })
  }, [id])

  return (
    <Drawer
      open={open}
      {...drawerProps}
      PaperProps={{style: {width: 'auto'}}}
      ModalProps={{
        onClose: () => setOpen(false)
      }}
    >
      <Box className="p-4">
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          {product?.name}
          <IconButton onClick={() => setOpen(false)}>
            <CloseSharp />
          </IconButton>
        </Box>
        <Divider className="!mb-4" />
        <div className="flex flex-col md:flex-row">
          <div className="w-[20rem] text-sm">
            <Grid container columnSpacing={1}>
              <Grid container item>
                <Grid item xs={6}>
                  <LabelField heading="Brand" value={product?.brand?.name} />
                </Grid>
                <Grid item xs={6}>
                  <LabelField
                    heading="Vendor"
                    value={`${product?.vendor?.firstName} ${product?.vendor?.lastName}`}
                  />
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={6}>
                  <LabelField heading="Price" value={`${product?.price}`} />
                </Grid>
                <Grid item xs={6}>
                  <LabelField heading="Quantity" value={product?.quantity} />
                </Grid>
              </Grid>

              <Grid container item>
                <Grid item xs={6}>
                  <LabelField heading="SKU" value={product?.sku} />
                </Grid>
                <Grid item xs={6}>
                  <LabelField
                    heading={product?.taxClass?.name}
                    value={product?.taxClass?.percentage ? `${product?.taxClass?.percentage}%` : ''}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
          {(product?.specialPrice ||
            product?.sellingPrice ||
            product?.specialPriceStart ||
            product?.specialPriceStart) && (
            <div className="md:ml-8 w-[20rem] text-sm	">
              <p className="text-base font-bold mb-1 mt-3 md:mt-0">Special Offer</p>

              <Grid container columnSpacing={1}>
                <Grid container item>
                  <Grid item xs={6}>
                    <LabelField
                      heading="Special Price"
                      value={product?.specialPrice ? `$ ${product?.specialPrice}` : ''}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LabelField
                      heading="Selling Price"
                      value={product?.sellingPrice ? `$ ${product?.sellingPrice}` : ''}
                    />
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item xs={6}>
                    <LabelField
                      heading="Offer starts from"
                      value={
                        product?.specialPriceStart
                          ? moment.utc(product?.specialPriceStart).format('DD MMM YYYY')
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LabelField
                      heading="Offer ends at"
                      value={
                        product?.specialPriceEnd
                          ? moment.utc(product?.specialPriceEnd).format('DD MMM YYYY')
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
        <div>
          { !!product?.options?.length &&
            <Box mt={3} display="flex" flexDirection="column">
              <p className="text-base font-bold mb-2">Options</p>
              {product?.options?.map((item, index) => (
                <div key={index} className="flex flex-col">
                  <div className="text-sm">{item?.name}</div>
                  <div className="flex my-2">
                    {item?.values?.map((val, index) => (
                      <div key={index} className="border border-gray-300 rounded-l mr-2 p-2">
                        <p className="text-sm">
                          {val?.name} (${val?.additionalPrice})
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </Box>
          }

          {!!product?.categories?.length &&
            <Box mt={3} display="flex" flexDirection="column">
              <p className="text-base font-bold">Categories</p>
              <div className="flex mt-2">
                {product?.categories?.map((item, index) => (
                  <div key={index} className="border border-gray-300 rounded-l mr-2 p-2 text-sm">
                    <p>{item?.name}</p>
                  </div>
                ))}
              </div>
            </Box>
          }
        </div>
        {product?.images?.length > 0 && (
          <div className="flex my-8">
            <PoductImagesGrid images={product?.images} />
          </div>
        )}
        { product?.description &&
            <Box mt={3} display="flex" flexDirection="column" className="w-[20rem]">
              <p className="text-base font-bold">Description</p>
              <p className="text-sm">{product?.description}</p>
            </Box>
        }

        { product?.nutritionFacts &&
            <Box mt={3} display="flex" flexDirection="column" className="w-[20rem]">
              <p className="text-base font-bold">Nutrition Facts</p>
              <p className="text-sm">{product?.nutritionFacts}</p>
            </Box>
        }

        { product?.feedingInstructions &&
            <Box mt={3} display="flex" flexDirection="column" className="w-[20rem]">
              <p className="text-base font-bold">Feeding Instructions</p>
              <p className="text-sm">{product?.feedingInstructions}</p>
            </Box>
        }
      </Box>
    </Drawer>
  )
})
