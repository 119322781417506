import {Close} from '@mui/icons-material'
import {Divider, Drawer, IconButton, Typography} from '@mui/material'
import React from 'react'

export const RightDrawerLayout = props => {
  const {open, onClose, className = '', title = ''} = props
  return (
    <React.Fragment>
      <Drawer anchor="right" open={open} onClose={onClose} className={className}>
        <div className="flex w-full h-full lg:w-[42rem] flex-col p-8">
          <div className="flex justify-between">
            <Typography
              variant="h5"
              component="h5"
              color="transparent.contrastText"
              sx={{fontSize: '24px', fontWeight: '600'}}
            >
              {title}
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </div>
          <Divider className="!mt-4" />
          <div className="h-full">{props.children}</div>
        </div>
      </Drawer>
    </React.Fragment>
  )
}
