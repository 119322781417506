import React, { useMemo, useRef } from 'react'
import Box from '@mui/material/Box'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip
} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import {Insights} from './Insights'
import {reduce} from 'lodash'
import { currency } from 'utils/Constants'
import { OrderDetailModal } from './model/OrderDetailModal'
const columns = [
  {id: 'name', label: 'Customer'},
  {id: 'products', label: 'Summary'},
  {id: 'value', label: 'Valued'},
  {id: 'status', label: 'Status'},
]

const OrderRow = ({order, onClick}) => {
  const {products} = order

  const price = useMemo(() => {
    if (!products.length) return 0

    return reduce(products, (total, item) => {
      let additionalPrice = 0
      let taxAmount = 0
      const basePrice = item.price

      if (item?.options?.length) {
        additionalPrice = reduce(item?.options, (total, _item) => {
          const _additionalPrice = _item?.value?.isBaseOption ? 0 : _item?.value?.additionalPrice
          return total + _additionalPrice
        }, 0)
      }

      let subTotal = (basePrice + additionalPrice) * item?.quantity

      const tax = item?.product?.taxClass
      if (tax?.percentage) {
        taxAmount = subTotal / 100 * tax?.percentage
      }

      return total + subTotal + taxAmount
    }, 0)

  }, [products])
  return (
    <TableRow onClick={() => onClick(order?._id)} sx={{cursor: 'pointer'}}>
      <TableCell>
        {order?.guest ?
          `${order.guest?.firstName} ${order.guest?.lastName}` :
          `${order.user?.firstName} ${order.user?.lastName}`
        }
      </TableCell>
      <TableCell>{order?.products?.length} Products</TableCell>
      <TableCell>{currency(price)}</TableCell>
      <TableCell>
        <Chip
          label="Pending"
          variant="outlined"
          color="warning"
          size="small"
        />
      </TableCell>
    </TableRow>
  )
}
export const Orders = props => {
  const {list, loading, Loader, Paginator} = props
  const detailModalRef = useRef(null)

  const onClick = id => {
    detailModalRef.current.show(id, {
      anchor: 'right'
    })
  }
  return (
    <Box mt={3}>
      <Insights />
      <OrderDetailModal ref={detailModalRef} />
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.length ? (
                list.map((row, index) => (
                  <OrderRow key={index} order={row} onClick={onClick}/>
                ))
              ) : loading ? (
                <TableCell colSpan={columns.length} align="center">
                  <Loader />
                </TableCell>
              ) : (
                <TableCell colSpan={columns.length} align="center">
                  <Typography>No Order found</Typography>
                </TableCell>
              )}
            </TableBody>
          </Table>
          <Paginator />
        </TableContainer>
      </Paper>
    </Box>
  )
}
