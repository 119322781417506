import Typography from '@mui/material/Typography';

export const CustomTypography = (props) => {
 return props?.value ? (
  <div className="flex">
    <Typography variant="body2" color="textSecondary" textAlign="left">
      {props?.label}:
    </Typography>
    <Typography variant="body2" fontWeight="bold" sx={{ mx: '4px' }} textAlign="left">
      {props?.value}
    </Typography>
  </div>
 ) : <></>
}
