import {AppLayout} from 'modules/layout/AppLayout'
import {useEffect, useState} from 'react'
import {AddEditShelterModal} from './modals/AddEditShelterModal'
import {DataTable} from './components/DataTable'
import {DeleteModal} from './modals/DeleteModal'
import {BrowseLayout} from 'components/layouts/BrowseLayout'
import {connectService} from 'components/service/connectService'

export const Shelters = connectService(
  props => {
    const [open, setOpen] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [refreshTable, setRefreshTable] = useState(false)

    const handleModal = (data = null) => {
      if (data) setRefreshTable(true)
      setSelectedItem(null)
      setOpen(!open)
    }

    const handleOnEdit = item => {
      setSelectedItem(item)
      setOpen(!open)
    }

    const handleDeleteModal = (data = null) => {
      if (data) setRefreshTable(true)
      setSelectedItem(null)
      setOpenDeleteModal(!openDeleteModal)
    }

    const handleOnDelete = item => {
      setSelectedItem(item)
      setOpenDeleteModal(!openDeleteModal)
    }

    useEffect(() => {
      if (!open) {
        setSelectedItem(null)
      }
    }, [open])

    return (
      <AppLayout heading="Pet Shelters">
        <BrowseLayout title="Shelters" onAdd={() => setOpen(!open)} {...props}>
          <DataTable
            onEdit={handleOnEdit}
            onDelete={handleOnDelete}
            isRefreshTable={refreshTable}
            setRefreshTable={setRefreshTable}
            {...props}
          />
        </BrowseLayout>
        {open && (
          <AddEditShelterModal open={open} setOpen={setOpen} handleModal={handleModal} id={selectedItem} />
        )}
        {openDeleteModal && (
          <DeleteModal
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            handleModal={handleDeleteModal}
            id={selectedItem}
          />
        )}
      </AppLayout>
    )
  },
  'shelters',
  {query: {$populate: 'logo'}}
)
