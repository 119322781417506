import React, {useEffect} from 'react'
import {ConnectedComponent} from 'components/service/ConnectedComponent'
import {Products} from './products/Products'
import {storeSocketClient} from './client/socket.client'
import {useSearchParams} from 'react-router-dom'

export const ProductDashboard = props => {
  const {setNavigationTitle} = props
  const query = useSearchParams()

  useEffect(() => {
    console.log(query)
    setNavigationTitle('Products')
  }, [])

  return (
    <ConnectedComponent
      Component={Products}
      service="products"
      client={storeSocketClient}
      config={{
        query: {
          $populate: ['brand', 'taxClass', 'vendor']
        }
      }}
    />
  )
}
