import {TextField, Typography} from '@mui/material'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'

export const SpecialPrice = props => {
  const {formik} = props
  return (
    <div>
      <div className="flex items-center">
        <Typography className="!text-lg">Special Price</Typography>
      </div>

      <div className="mt-2">
        <div className="flex">
          <TextField
            className="!w-1/2 !mr-2 "
            id="standard-basic"
            label="Special Price"
            variant="outlined"
            value={formik.values.specialPrice || ''}
            {...formik.getFieldProps('specialPrice')}
          />
          <TextField
            className="!w-1/2 !ml-2 "
            id="standard-basic"
            label="Selling Price"
            variant="outlined"
            value={formik.values.sellingPrice || ''}
            {...formik.getFieldProps('sellingPrice')}
          />
        </div>
        <div className="flex mt-4">
          <div className="w-1/2 mr-2">
            <LocalizationProvider dateAdapter={AdapterMoment} className="!w-full">
              <DemoContainer components={['DatePicker']} className="!w-full">
                <DatePicker
                  label="Special Price Start"
                  value={moment(formik.values.specialPriceStart)}
                  onChange={date => formik.setFieldValue('specialPriceStart', date)}
                  renderInput={params => <TextField {...params} variant="outlined" className="!w-full" />}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div className="w-1/2 ml-2">
            <LocalizationProvider dateAdapter={AdapterMoment} className="!w-full">
              <DemoContainer components={['DatePicker']} className="!w-full">
                <DatePicker
                  label="Special Price End"
                  value={moment(formik.values.specialPriceEnd)}
                  onChange={date => formik.setFieldValue('specialPriceEnd', date)}
                  renderInput={params => <TextField {...params} variant="outlined" className="!w-full" />}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </div>
  )
}
