import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import {ImagePreview} from 'components/ImagePreview'
import {storeSocketClient} from 'modules/store/client/socket.client'
import {useEffect, useState} from 'react'

export const PoductImagesGrid = props => {
  const {images = []} = props
  const [itemData, setItemData] = useState([])
  useEffect(() => {
    if (images?.length > 0) {
      setItemData(images)
    }
  }, [images])
  return (
    <ImageList sx={{max: 350, maxHeight: 500}} cols={3} rowHeight={164}>
      {itemData.map((item, index) => (
        <ImageListItem key={index}>
          <ImagePreview
            client={storeSocketClient}
            service="media"
            media={item}
            className="w-28 h-28 object-cover rounded-xl"
          />
        </ImageListItem>
      ))}
    </ImageList>
  )
}
