import React, {useEffect, useState} from 'react'
import {Button, Typography} from '@mui/material'
import PetsIcon from '@mui/icons-material/Pets'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import LandscapeIcon from '@mui/icons-material/Landscape'
import InfoIcon from '@mui/icons-material/Info'
import './lost-pet-profile.css'
import {useLocation, useNavigate} from 'react-router-dom'
import {RewardStamp} from './RewardStamp'
import {PetProfileImage} from './PetProfileImage'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShimmeringButton from 'components/ShimmeringButton'

const Heading = props => {
  return <p className="text-start text-xs text-slate-600">{props?.title}</p>
}
export const PetBasicDetail = props => {
  const {data, showMore, setShowMore} = props
  const [pet, setPet] = useState(null)
  const [petProfileUrl, setPetProfileUrl] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const code = searchParams.get('code')

  useEffect(() => {
    if (data?.pet) {
      setPet(data?.pet)
    }

    setProfileUrl()
  }, [data])

  const setProfileUrl = () => {
    const url = data?.report?.media?.length && data?.report?.media[0]?.url
    ? data?.report?.media[0].url
    : pet?.profile?.url
      ? pet?.profile?.url
      : null

    setPetProfileUrl(url)
  }

  const handleReportAction = () => {
    navigate(`/scan-report/create/${code}`)
  }

  return (
    <div className="flex flex-col lg:flex-row justify-between">
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-auto flex justify-center">
          <PetProfileImage src={petProfileUrl} />
          {data?.report?.rewardAmount && (
            <RewardStamp amount={data?.report?.rewardAmount} isDesktop={false} />
          )}
        </div>
        <div className="flex items-start flex-col mt-4 lg:mt-0 lg:ml-2">
          {data?.pet?.name && <Typography variant="h5">{pet?.name}</Typography>}

          {((pet?.type?.name || pet?.subtype?.name) && showMore) && (
            <div className="my-3">
              <Heading title="Type" />
              <div className="flex items-center justify-center lg:mt-0">
                <PetsIcon style={{fontSize: '1.10rem'}} color="primary" className="mr-2" /> {pet?.type?.name}
                {pet?.type?.name && pet?.subtype?.name && <span className="bullet mx-2">●</span>}
                {pet?.subtype?.name}
              </div>
            </div>
          )}

          { showMore &&
            <div className="flex item-start  my-3 w-full">
              {data?.pet?.size && (
                <div className="w-1/2 md:w-auto">
                  <Heading title="Weight" />
                  <div className="flex items-center lg:justify-center  lg:mt-0">
                    <FitnessCenterIcon style={{fontSize: '1.10rem'}} color="primary" className="mr-2" />
                    {data?.pet?.size} lbs
                  </div>
                </div>
              )}

              {data?.pet?.age && (
                <div className="w-1/2 lg:ml-8 md:w-auto ">
                  <Heading title="Age" />
                  <div className="flex items-center lg:justify-center lg:mt-0">
                    <LandscapeIcon style={{fontSize: '1.10rem'}} color="primary" className="mr-2" />{' '}
                    {data?.pet?.age} Year
                  </div>
                </div>
              )}
            </div>
          }

          {data?.report?.description && (
            <div className="my-2">
              {/* <Heading title="About" /> */}
              <Typography align="left">
                {/* <InfoIcon style={{fontSize: '1.10rem'}} color="primary" className="mr-2" /> */}
                {data?.report?.description}
              </Typography>
            </div>
          )}

          <div className='!mt-4 w-full flex flex-col md:flex-row'>
            <div className="!w-full md:!w-auto">
              <ShimmeringButton
                variant="contained"
                className="!font-bold !w-full"
                onClick={handleReportAction}
              >
                Seen or Found?
              </ShimmeringButton>
            </div>
            <div className="!w-full mt-4 md:mt-0 md:ml-2 md:!w-auto">
              <Button
                variant='outlined'
                className="!font-bold "
                onClick={() => setShowMore(!showMore)}
                fullWidth
              >
                More Detail {showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        {data?.report?.rewardAmount && <RewardStamp amount={data?.report?.rewardAmount} isDesktop={true} />}
      </div>
    </div>
  )
}
