import React, {useContext, useRef} from 'react'
import Box from '@mui/material/Box'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  Button,
  Chip,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import Add from '@mui/icons-material/Add'
import {Delete, Edit} from '@mui/icons-material'
import {AddEditModal} from './modals/AddEditModal'
import {DeleteModal} from './modals/DeleteModal'
import {storeSocketClient} from '../client/socket.client'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {ImagePreview} from 'components/ImagePreview'
import placeholder from 'images/avatar_placeholder.svg'
import { isEmpty } from 'lodash'

const columns = [
  {id: 'avatar', label: ''},
  {id: 'name', label: 'Name'},
  {id: 'slug', label: 'Slug'},
  {id: 'vendor', label: 'Vendor'},
  {id: 'status', label: 'Status'},
  {id: 'approved', label: ''},
  {id: 'actions', label: ''}
]

export const Browse = props => {
  const {list, loading, Loader, Paginator, handleSearch, refreshTable, additionalFilters, setAdditionalFilters} = props
  const editModalRef = useRef(null)
  const deleteModalRef = useRef(null)
  const {setSeverity, showToast} = useContext(SnackbarContext)

  const showModal = (event, id = null) => {
    if (event) event.stopPropagation()
    editModalRef.current.show(id)
  }

  const showDeleteModal = (event, id = null) => {
    if (event) event.stopPropagation()
    deleteModalRef.current.show(id)
  }

  const handleRequest = async (event, property, id, value) => {
    event.stopPropagation()
    try {
      const result = await storeSocketClient.service('categories').patch(id, {
        [property]: value
      })
      if (result) {
        refreshTable()
        setSeverity('success')
        const message = value
          ? property === 'isGlobal'
            ? 'Category enabled globally'
            : 'Category is now approved'
          : property === 'isGlobal'
          ? 'Category disabled globally'
          : 'Category approval revoked'
        showToast(message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onAddEdit = () => refreshTable()

  const onDelete = () => refreshTable()

  const applyFilter = (property, value) => {
    const filters = {...additionalFilters}
    if (typeof value === 'string' && isEmpty(value)) {
      console.log('pending called')
      delete filters[property]
      setAdditionalFilters({...filters})
      return
    }
    setAdditionalFilters({...filters, [property] : value})
  }
  return (
    <Box mt={3}>
      <AddEditModal ref={editModalRef} onAddEdit={onAddEdit} />
      <DeleteModal ref={deleteModalRef} onDelete={onDelete} />
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <div className="p-4 flex justify-between">
          <div className="flex">
            <TextField
              label="Name"
              type="text"
              size="small"
              InputLabelProps={{
                shrink: true
              }}
              onChange={e => {
                if (e.target.value.length > 2 || e.target.value === '')
                  handleSearch('name', e.target.value)
              }}
            />
            <FormControl sx={{ mx: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small-label">Approval</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Approval"
                onChange={(e) => applyFilter('approved', e.target.value)}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                <MenuItem value={true}>Approved</MenuItem>
                <MenuItem value={false}>Pending</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small-label">Global</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Global"
                onChange={(e) => applyFilter('isGlobal', e.target.value)}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ mx:1, minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small-label">Status</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Status"
                onChange={(e) => applyFilter('isActive', e.target.value)}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>

          <Tooltip title="Create User">
            <IconButton color="primary" onClick={e => showModal(e)}>
              <Add />
            </IconButton>
          </Tooltip>
        </div>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {columns.map(column => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.length ? (
                list.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {row?.icon ? (
                        <ImagePreview
                          media={row?.icon}
                          service="media"
                          client={storeSocketClient}
                          className="w-10 h-10  rounded-full"
                          fallbackSrc={placeholder}
                        />
                      ) : (
                        <Avatar src={placeholder}></Avatar>
                      )}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row?.slug}</TableCell>
                    <TableCell>
                      {row.vendor?.firstName} {row.vendor?.lastName}
                    </TableCell>
                    <TableCell>
                      {row?.isActive ? (
                        <Chip label="Active" color="success" variant="outlined" />
                      ) : (
                        <Chip label="Inactive" color="error" variant="outlined" />
                      )}
                    </TableCell>

                    <TableCell align="center" style={{width: '12rem'}}>
                      <Box display="flex" flexDirection="column">
                        {!row?.isGlobal && (
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={e => handleRequest(e, 'approved', row?._id, !row?.approved)}
                          >
                            {row?.approved ? 'Revoke approval' : 'Approve'}
                            {console.log(row?.approved)}
                          </Button>
                        )}

                        <Button
                          variant="outlined"
                          className="!mt-2"
                          size="small"
                          onClick={e => handleRequest(e, 'isGlobal', row?._id, !row?.isGlobal)}
                        >
                          {row?.isGlobal ? 'Disable' : 'Enable'}
                        </Button>
                      </Box>
                    </TableCell>

                    <TableCell align="center">
                      <IconButton size="small" onClick={e => showModal(e, row?._id)}>
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton size="small" onClick={e => showDeleteModal(e, row?._id)}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Typography>No categories found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Paginator />
        </TableContainer>
      </Paper>
    </Box>
  )
}
