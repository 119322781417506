import React from 'react'
import { Grid } from '@mui/material'
import { FeaturedPlayList, AccessTime, ThumbDown, Star, } from '@mui/icons-material'
import { Card } from '../Dashboard';

export const Insights = (props) => {
  return <Grid container alignItems='center'>
    <Card Icon={FeaturedPlayList} title="Listed Brands" count="2,390" />
    <Card Icon={AccessTime} title="Awaiting Brand Approval" count="90" />
    <Card Icon={ThumbDown} title="Unpublished Brands" count="7909" />
    <Card Icon={Star} title="Top Brands" count="12" />
  </Grid>
}
