import {RightDrawerLayout} from 'components/layouts/RightDrawerLayout'
import {Story} from '../../components/Story'
import {Divider} from '@mui/material'

export const DetailView = props => {
  const {open, onClose, data} = props
  return (
    <RightDrawerLayout open={open} onClose={onClose} title="Highlights">
      {data?.content?.stories?.map(story => (
        <Story content={story} />
      ))}
    </RightDrawerLayout>
  )
}
