import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'

export const PetSize = props => {
  return (
    <div className="flex items-center">
      <FitnessCenterIcon style={{color: '#ED1690', fontSize: '15px', marginRight: '8px'}} />
      <span className="text-sm">{props.data} lbs</span>
    </div>
  )
}
