import {AppLayout} from 'modules/layout/AppLayout'
import {useState} from 'react'
import {BrowseLayout} from 'components/layouts/BrowseLayout'
import {ProvisionModal} from './modals/ProvisionModal'
import {useParams} from 'react-router-dom'
import {ConnectedComponent} from 'components/service/ConnectedComponent'
import {PetCodesTable} from './components/PetCodesTable'
import {Typography} from '@mui/material'
import WestIcon from '@mui/icons-material/West'
import {useNavigate} from 'react-router-dom'
import {CodeRevokeModel} from './modals/CodeRevokeModel'

export const PetCodes = props => {
  const {shelter} = useParams()
  const [open, setOpen] = useState(false)
  const [showRevokeModal, setRevokeModal] = useState(false)
  const [search, setSearch] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isRefresh, setRefresh] = useState(false)
  const [id, setId] = useState(null)
  const navigate = useNavigate()

  const handleModal = data => {
    if (data) setRefresh(true)
    setOpen(false)
  }

  const handleBackButton = () => {
    navigate(-1)
  }

  const openRevokeModal = id => {
    console.log(id)
    setId(id)
    setRevokeModal(true)
  }

  const handleRevokeModal = data => {
    if (data) setRefresh(true)
    setId(null)
    setRevokeModal(!showRevokeModal)
  }

  return (
    <AppLayout heading="Pet Identification">
      <div className="flex text-left items-center cursor-pointer" onClick={handleBackButton}>
        <WestIcon />
        <Typography align="left" marginLeft={1}>
          Back to Shelters
        </Typography>
      </div>
      <BrowseLayout
        title="Pet QR Allocation"
        searchLabel="Search by code"
        onAdd={() => setOpen(!open)}
        search={search}
        setSearch={setSearch}
        loading={loading}
      >
        <ConnectedComponent
          Component={PetCodesTable}
          service="pet-codes"
          config={{
            query: {
              $populate: 'activatedFor',
              owner: shelter
            }
          }}
          search={search}
          setDataLoading={setLoading}
          isRefresh={isRefresh}
          setRefresh={setRefresh}
          handleRevokeModal={openRevokeModal}
        />
      </BrowseLayout>
      {open && <ProvisionModal open={open} setOpen={setOpen} handleModal={handleModal} owner={shelter} />}
      {showRevokeModal && <CodeRevokeModel open={showRevokeModal} handleModal={handleRevokeModal} id={id} />}
    </AppLayout>
  )
}
