import { Box, Button, Typography } from "@mui/material"
import { AppLayout } from "modules/layout/AppLayout"
import DataTable from "react-data-table-component"
import { Link } from "react-router-dom"
import edit from 'images/newimages/edit-rectangle.svg';
import deleteicon from 'images/newimages/trash.svg'
import { useEffect, useState } from "react";
import { CreateEditModal } from "./modals/CreateEditModal";

const data = [
  {
    id: 1,
    serve: 'Cups',
    edit:  edit,
    delete: deleteicon,
  },
  {
    id: 2,
    serve: 'Handfuls',
    edit:  edit,
    delete: deleteicon,
  },
  {
    id: 3,
    serve: 'Teaspoons',
    edit:  edit,
    delete: deleteicon,
  },
  {
    id: 4,
    serve: 'Cans',
    edit:  edit,
    delete: deleteicon,
  },
  {
    id: 5,
    serve: 'Grams',
    edit:  edit,
    delete: deleteicon,
  },
  {
    id: 6,
    serve: 'Pouches',
    edit:  edit,
    delete: deleteicon,
  },
]

export const ServingSize = () => {

  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const handleModal = () => {
    setOpen(!open)
  }

  const handleEditModal = (item) => {
    setSelectedItem(item)
    setOpen(!open)
  }

  useEffect(() => {
    if (!open) {
      setSelectedItem(null)
    }
  }, [open])
  
  const columns = [
    {
      name: <Typography variable="h6"  sx={{ fontSize: '15px', fontWeight: '600',}}>Serving Size</Typography>,
      selector: row =><Typography variable="h6" color="phylumTable.main" sx={{fontSize: '12px'}}>{row.serve}</Typography>,
    },
    {
      name: <Typography variable="h6"  sx={{ fontSize: '15px', fontWeight: '600',}}>Edit</Typography>,
      selector: row =><button onClick={() => handleEditModal(row)}><img src={row.edit} alt="Image 1" style={{ width: '17px', marginRight: '10px' }} /></button>,
      width: '60px',
    },
    {
      name: <Typography variable="h6"  sx={{ fontSize: '14px', fontWeight: '600',}}>Delete</Typography>,
      selector: row =><Link to="/" sx={{cursor:'pointer'}}><img src={row.delete} alt="Image 2" style={{ width: '15px' }} /></Link>,
      width: '90px',
    },
  ];
  return (
    <AppLayout heading='Serving Size'>
      <Box py={2.5}>
        <Box mb={3} textAlign="right">
          <Button variant="contained" color='primary' size="large" onClick={ handleModal }>Add Serving</Button>
        </Box>
        <Box sx={{boxShadow:'0px 4px 15px 0px rgba(0, 0, 0, 0.10)', borderRadius:'10px', overflow: 'auto'}}>  
          <DataTable columns={columns} data={data} striped />
        </Box>
      </Box>
      <CreateEditModal open={open} handleModal={handleModal} item={selectedItem}/>
    </AppLayout>
  )
}