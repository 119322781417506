import React from 'react'
import Box from '@mui/material/Box'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import moment from 'moment'
import {startCase} from 'lodash'
import { useNavigate } from "react-router-dom"

const columns = [
  {id: 'applicationType', label: 'Type'},
  {id: 'name', label: 'name'},
  {id: 'applicationStatus', label: 'Status'},
  {id: 'createdAt', label: 'Created On'},
]

export const TableView = props => {
  const {list, loading, Loader, Paginator} = props
  const navigate = useNavigate()

  return (
    <Box mt={3}>
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.length ? (
                list.map((row, index) => (
                  <TableRow key={index} onClick={() => navigate(`/store/enroll/${row?._id}`)} sx={{cursor: 'pointer'}}>
                    <TableCell>
                      {row?.data?.type ? startCase(row?.data?.type) : startCase(row?.data?.enrollData?.type) }
                    </TableCell>
                    <TableCell>{row?.data?.enrollData?.firstName} {row?.data?.enrollData?.lastName}</TableCell>
                    <TableCell>{startCase(row?.status)}</TableCell>
                    <TableCell>
                      {moment(row?.createdAt).format('DD, MMM YYYY')}
                    </TableCell>
                  </TableRow>
                ))
              ) : loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Typography>No enroll application for review</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Paginator />
        </TableContainer>
      </Paper>
    </Box>
  )
}
