import React, {useEffect} from 'react'
import {ConnectedComponent} from 'components/service/ConnectedComponent'
import { socketClient } from 'client/socket.client'
import { TableView } from './components/TableView'

export const Brows = props => {
  const {setNavigationTitle} = props

  useEffect(() => {
    setNavigationTitle('Enroll Application')
  }, [])

  return (
    <ConnectedComponent
      Component={TableView}
      service="enroll"
      client={socketClient}
      config={{
        query: {
          status: 'waitingForReview'
        }
      }}
    />
  )
}
