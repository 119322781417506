import {ConnectedComponent} from 'components/service/ConnectedComponent'
import {useEffect} from 'react'
import {Browse} from './Browse'
import {storeSocketClient} from '../client/socket.client'

export const Banners = props => {
  const {setNavigationTitle} = props
  useEffect(() => {
    setNavigationTitle('Banners')
  }, [])

  return (
    <ConnectedComponent
      Component={Browse}
      service="banner"
      client={storeSocketClient}
      config={{
        query: {
          $populate: ['banner']
        }
      }}
    />
  )
}
