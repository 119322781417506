import React, {useContext} from 'react'
import Box from '@mui/material/Box'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Divider,
  Button
} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {socketClient} from 'client/socket.client'

const columns = [
  {id: 'name', label: 'Name'},
  {id: 'status', label: 'Status'},
  {id: 'approvedBy', label: 'Approved By'},
  {id: 'approved', label: ''}
]

export const Browse = props => {
  const {list, loading, Loader, Paginator, handleSearch, refreshTable} = props
  const {setSeverity, showToast} = useContext(SnackbarContext)

  const handleRequest = async (event, _id, value) => {
    event.stopPropagation()
    try {
      const result = await socketClient.service('vendor-requests').patch(_id, {
        isApproved: value,
        isPending: false
      })
      if (result) {
        refreshTable()
        setSeverity('success')
        showToast(`${value ? 'Vendor request is approved' : 'Vendor request approval revoked'}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Box mt={3}>
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <div className="p-4 flex justify-between">
          <TextField
            label="Name"
            type="text"
            size="small"
            InputLabelProps={{
              shrink: true
            }}
            onChange={e => {
              handleSearch('name', e.target.value)
            }}
          />
        </div>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.length ? (
                list.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {row?.user?.firstName} {row?.user?.lastName}
                    </TableCell>
                    <TableCell>{row?.isPending ? 'Pending' : 'Approved'}</TableCell>
                    <TableCell>
                      {row?.approvedBy?.firstName} {row?.approvedBy?.lastName}
                    </TableCell>
                    <TableCell align="right">
                      {
                        row.isPending ? (
                          <>
                            <Button sx={{ mr: 1 }} variant="contained" onClick={e => handleRequest(e, row?._id, true)}>
                              Approve
                            </Button>
                            <Button variant="outlined" onClick={e => handleRequest(e, row?._id, false)}>
                              Reject
                            </Button>
                          </>
                        ) : (
                          <Button variant="outlined" onClick={e => handleRequest(e, row?._id, !row?.isApproved)}>
                            {row?.isApproved ? 'Revoke approval' : 'Approve'}
                          </Button>
                        )
                      }

                    </TableCell>
                  </TableRow>
                ))
              ) : loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Typography>No Vender Request Available</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Paginator />
        </TableContainer>
      </Paper>
    </Box>
  )
}
