export const useGeoCurrentLocation = () => {
  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            resolve([position.coords.longitude, position.coords.latitude])
          },
          error => {
            console.error('Error getting location:', error)
            reject(
              error?.code === 1
                ? 'Please allow location permission to detect current location.'
                : 'Failed to find current location'
            )
          }
        )
      } else {
        reject('Geolocation is not supported by this browser.')
      }
    })
  }

  return {getCurrentLocation}
}
