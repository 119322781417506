import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Card, Button, CardContent, Dialog, DialogContent, DialogTitle, Box } from '@mui/material'
import Typography from '@mui/material/Typography';
import { CREATE_FUNDING_SOURCE, GET_ACCESS_TOKEN, GET_DWOLLA_ID, GET_LIST_ACCOUNT, GET_PROCESSOR_TOKEN, GET_TOKEN_PLAID, IPAY_NET_API_KEY, IPAY_NET_BASE_URL } from 'utils/Constants';
import axios from 'axios';
import { usePlaidLink } from 'react-plaid-link';
import { green } from '@mui/material/colors';
import { ArrowForwardIos, Circle, Wallet } from '@mui/icons-material';
import {useSelector} from 'react-redux'
import { SnackbarContext } from 'components/snackbar/SnackbarContext';
import { SuccessModel } from './models/SuccessModel';
import appLogo from 'images/app_logo.png'
import { LoadingDialog } from 'components/LodingDialog';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { pink } from '@mui/material/colors';

export const AddFundingSource = () => {
  const [plaidToken, setPlaidToken] = useState(null)
  const [isOpen, setOpen] = useState(false)
  const [listAccessToken, setListAccessToken] = useState([]);
  const [accessToken, setAccessToken] = useState(null)
  const [clientId, setClientId] = useState(null); //
  const [account, setAccount] = useState(null)
  const {setSeverity, showToast} = useContext(SnackbarContext)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const user = useSelector(state => state?.authentication?.user)

  const headers = {
    Accept: '*/*',
    'Content-Type': 'application/json',
    'x-api-key': IPAY_NET_API_KEY
  }

  useEffect(() => {
    const clientIdNew = new Date().getTime().toString();
    setClientId(clientIdNew)
    axios.post(`${IPAY_NET_BASE_URL}/${GET_TOKEN_PLAID}`, { accessToken: '', clientId:clientIdNew},  {
      headers
    })
    .then((result) => {
      setPlaidToken(result.data.link_token)
    })
    .catch(console.log)
  }, [])


  const handleAccount = async (accountDetail) => {
    if (accountDetail && accessToken) {
      setLoading(true)
      try {
        const bodyParams = {
          accessToken: accessToken,
          accountId: accountDetail?.account_id,
          processor: 'dwolla',
        }
        const result = await axios.post(`${IPAY_NET_BASE_URL}/${GET_PROCESSOR_TOKEN}`, bodyParams, {headers})
        if (result) {
          await createFundingSource(result?.data?.processor_token, accountDetail)
        }
      } catch (e) {
        console.log('Failed::get-processor-token')
        console.log(e)
        setLoading(false)
      }
    }
  }

  const createFundingSource = async (processor_token, accountDetail) => {
    const dwollaResult = await axios.post(`${IPAY_NET_BASE_URL}/${GET_DWOLLA_ID}/${user?._id}`, {}, {headers})

    if (dwollaResult) {
      const payload = {
        customerId: dwollaResult.data,
        plaidToken: processor_token,
        name:  account ? account.name : accountDetail.name,
        accountId: account ? account.account_id : accountDetail.account_id,
        accessToken: accessToken,
        microDeposit: false,
        clientId,
        webhook: 'https://internal.petflix.io/enroll-webhook?provider=dwolla&fundingSource=true'
      }
      console.log(payload)
      const result = await axios.post(`${IPAY_NET_BASE_URL}/${CREATE_FUNDING_SOURCE}`, payload, {headers})

      setLoading(false)
      if (result.status !== 200) {
        setSeverity('error')
        showToast('Oops, failed to create funding source')
        return
      }

      setOpen(false)
      setOpenSuccess(true)

      // socketClient.io.emit('addFundingSource', 'enroll', payload, {}, (error, data) => {
      //   setLoading(false)
      //   if (error) {
      //     setSeverity('error')
      //     showToast(error?.message ? error?.message : 'Oops, failed to create funding source')
      //     console.log(error)
      //     return
      //   }
      //   setOpenSuccess(true)
      //   localStorage.removeItem('auth')
      //   dispatch(AuthActions.resetAuth())
      //   console.log(data)
      // })

    }
  }

  const getAccountList = async (publicToken) => {
    setLoading(true)
    if (publicToken) {
      axios.post(`${IPAY_NET_BASE_URL}/${GET_ACCESS_TOKEN}`, {publicToken}, {
        headers
      })
      .then((result) => {
        console.log('public-token', result)
        setAccessToken(result?.data?.access_token)
        axios.post(`${IPAY_NET_BASE_URL}/${GET_LIST_ACCOUNT}`, {accessToken: result?.data?.access_token}, {
          headers
        })
        .then(result => {
          if (result) {
            setListAccessToken(result?.data?.accounts)
            setLoading(false)
            setOpen(true)
          }
        })
      })
      .catch(e => {
        console.log(e)
        setLoading(false)
      })

    }
  }

  const onSuccess = useCallback((publicToken, metadata) => {
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    getAccountList(publicToken);
  }, []);

  const onEvent = useCallback((eventName, metadata) => {
    console.log('onEvent', eventName, metadata);
  }, []);
  const onExit = useCallback((error, metadata) => {
    console.log('onExit', error, metadata);
  }, []);

  const config = {
    token: plaidToken,
    onSuccess,
    onEvent,
    onExit,
  };

  const {
    open,
    ready,
    // error,
    // exit
  } = usePlaidLink(config);


  return (
    <div className='w-full h-screen flex flex-col items-center'>
      {loading && <LoadingDialog />}
      <Dialog
        open={isOpen}
        fullWidth={true}
        scroll={'paper'}
      >
        <DialogTitle>Verify and Select Account to save as Funding Source.</DialogTitle>
        <DialogContent>
          {listAccessToken.map((item, index) => {
            return (
              <div key={index} className='my-4'>
                <div
                  onClick={() => {
                    setAccount(item)
                    handleAccount(item);
                  }}
                  className='border rounded-lg  shadow p-4 flex justify-between cursor-pointer'
                >
                  <div>
                    <Typography
                      variant='subtitle1'
                      color='inherit'
                    >
                      <div className='flex items-center'>
                        <Wallet sx={{ fontSize: 64 }} />
                        <div>
                          <div className='font-bold ml-2 flex items-center'>{item.name}</div>
                          <Circle
                            className='ml-2'
                            sx={{ fontSize: 15, color: green[500] }}
                          ></Circle>
                          <span className='ml-1 font-bold'>Connected</span>
                        </div>
                      </div>
                    </Typography>
                    <div className='flex-1 px-2 my-1'>Number Account: {item.mask}</div>
                  </div>
                  <div className='flex items-center'>
                    <ArrowForwardIos sx={{ fontSize: 64 }} />
                  </div>
                </div>
              </div>
            );
          })}
        </DialogContent>
      </Dialog>
      <img src={appLogo} alt="Noah'sArk" style={{marginTop: '4rem', marginBottom: '2rem' }}/>
      <Card
        sx={{
          px: '32px',
          py:'8px',
          textAlign: 'center',
        }}
      >
        <CardContent>
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Button
              disabled={!ready}
              color="primary"
              size='large'
              onClick={open}
              sx={{textTransform : 'none', fontSize: 24}}
            >
              <AccountBalanceIcon sx={{ fontSize: 32, color: pink[500], mr: '8px' }}/>
              Link Bank Account
            </Button>
          </Box>
        </CardContent>
      </Card>
      <SuccessModel open={openSuccess} setOpen={setOpenSuccess}/>
    </div>
  );
};
