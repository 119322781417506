import ImageIcon from '@mui/icons-material/Image'

export const PlaceHolderImage = props => {
  const {containerHeight = '', containerWidth = '', iconSize = ''} = props
  return (
    <div
      style={{
        width: containerWidth ? containerWidth : '9rem',
        height: containerHeight ? containerHeight : '9rem'
      }}
      className="rounded-md border border-dashed flex flex-col justify-center items-center"
    >
      <ImageIcon className="!text-slate-200" style={{fontSize: iconSize ? iconSize : '4.5rem'}} />
      <p className="text-slate-200">No Image</p>
    </div>
  )
}
