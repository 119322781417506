import React, { useContext, useEffect } from 'react'
import { auth } from '../../firebase/auth'

import {
  Grid,
  Container,
  CircularProgress,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { withFirebaseAuth } from './components/withFirebaseAuth'
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'
import { SnackbarContext } from 'components/snackbar/SnackbarContext'

export const VerifyEmail = withFirebaseAuth((props) => {
  const { email } = useParams()
  const { onSuccessfulAuth } = props
  const navigate = useNavigate()
  const { setSeverity, showToast } = useContext(SnackbarContext)

  useEffect(() => {
    if(isSignInWithEmailLink(auth, window.location.href)) {
      signInWithEmailLink(auth, email, window.location.href).then(() => {
        onSuccessfulAuth()
      }).catch((e) => {
        setSeverity('error')
        showToast('Could not verify the login, the link may be expired or wrong. Please try again!')

        setTimeout(() => {
          navigate('/authentication/login', {replace: true})
        }, 3000)
      })
    }
  }, [email, navigate, onSuccessfulAuth])

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '100vh'
      }}
    >
      <Container maxWidth="lg" className="text-center">
        <CircularProgress />
      </Container>
    </Grid>
  )
})
