import React, { useContext, useEffect, useRef, useState } from 'react';
import {Button, CircularProgress} from '@mui/material';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {useParams} from 'react-router-dom'
import { socketClient } from 'client/socket.client';
import { BusinessDetail } from './components/BusinessDetail';
import { PersonalTypeDetail } from './components/PersonalTypeDetail';
import RejectionModal from './modal/RejectionModal';
import { useNavigate } from "react-router-dom";
import { PreviewImageDialog } from 'modules/pet-found/public-report/PreviewImageDialog';
import {SnackbarContext} from 'components/snackbar/SnackbarContext'

const EnrollDetailView = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {id} = useParams()
  const [data, setData] = useState(null)
  const [imageUrls, setImageUrls] = useState([])
  const [enrollResponse, setEnrollResponse] = useState()
  const rejectionModalRef = useRef();
  const navigate = useNavigate()
  const [previewUrl, setPreviewUrl] = useState(null)
  const dialogRef = useRef(null)
  const {setSeverity, showToast} = useContext(SnackbarContext)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props?.setNavigationTitle) {
      props.setNavigationTitle('Application Details')
    }
  }, [])

  useEffect(() => {
    if (id) {
      socketClient.service('enroll')
        .get(id)
        .then(result => {
          console.log(result)
          if (result) {
            setEnrollResponse(result)
          }

          if (result?.data?.enrollData) {
            setData(result?.data?.enrollData)
          }
        })
        .catch(console.log)
    }
  }, [id])

  useEffect(() => {
    if (data && data?.userIdImgs?.length) {
      setImageUrls(data?.userIdImgs)
    }
  }, [data])

  const handleReject = (reason) => {
    if (enrollResponse && reason) {
      setLoading(true)
      socketClient.io.emit('reject', 'enroll', {
        _id: enrollResponse._id,
        reviewNote: reason
      }, {}, (error, data) => {
        setLoading(false)
        if (error) {
          console.error(error)
          setSeverity('error')
          showToast('Oops, failed to send reject request')
          return
        }
        setSeverity('success')
        showToast('Application rejected successfully')
        navigate(-1)
      })
    }
  };

  const handleApprove = () => {
    setLoading(true)
    if (enrollResponse) {
      socketClient.io.emit('approve', 'enroll', {
        _id: enrollResponse._id,
      }, {}, (error, data) => {
        setLoading(false)
        if (error) {
          console.error(error)
          setSeverity('error')
          showToast(error?.message ? error.message : 'Oops, Failed to approve application')
          return
        }
        setSeverity('success')
        showToast('Application approved successfully')
        navigate(-1)
      })
    }
  }

  const showPreview = (src) => {
    if (src) {
      setPreviewUrl(src)
      dialogRef.current.openDialog()
    }
  }

  useEffect(() => {
    if (!dialogRef?.current?.open) {
      setPreviewUrl(null)
    }
  }, [dialogRef])

  return (
    <div className='w-full'>
      <RejectionModal ref={rejectionModalRef} onReject={handleReject} />

      <div className='flex justify-end'>
        <Button
          variant="contained"
          color="error"
          size="large"
          type="submit"
          sx={{mx: '16px'}}
          onClick={() => rejectionModalRef.current.open()}
        >
          Reject
        </Button>
        <Button variant="contained" color="success" size="large" onClick={handleApprove} >
          Approve{' '} {loading && <CircularProgress style={{width: '18px', height: '18px', marginLeft: '8px'}} />}
        </Button>
      </div>
      <div className="flex flex-col md:flex-row">
        {data?.type === "business" && <BusinessDetail data={data} />}
        {data?.type === "personal" && <PersonalTypeDetail data={data} />}


        <Paper elevation={3} style={{ padding: 20, margin: '10px auto', width: isMobile ? '100%' : '30%', height:'100%', position: 'sticky', top: 0 }}>
          <Typography variant="h5" gutterBottom>
            Documents
          </Typography>
          {
            imageUrls?.length &&
              <div style={{ width: '100%' }} onClick={() => showPreview(imageUrls[0])}>
                <img src={imageUrls[0]} alt="Back Id Proof" height="100%" width="100%" className="my-2" />
              </div>
          }
          {
            imageUrls?.length === 2 &&
              <div style={{ width: '100%' }} onClick={() => showPreview(imageUrls[1])}>
                <img src={imageUrls[1]} alt="Back Id Proof" height="100%" width="100%" className="my-2" />
              </div>
          }

          {imageUrls?.length === 0 &&
            <div className='w-full my-8'>
              <span>No Document uploaded</span>
            </div>
          }

        </Paper>
      </div>
      <PreviewImageDialog src={previewUrl} ref={dialogRef} />
    </div>
  );
};

export default EnrollDetailView;
