import {TextField} from '@mui/material'
import {styled} from '@mui/material/styles'

export const NumberTextField = styled(TextField)({
  '& input[type=number]': {
    MozAppearance: 'textfield',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0
    }
  }
})
