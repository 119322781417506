import {Box, Button, Checkbox, FormControlLabel, Modal, TextField} from '@mui/material'
import {connectService} from 'components/service/connectService'
import {useEffect, useMemo, useState} from 'react'
import {ModalLayout} from 'components/layouts/ModalLayout'
import * as yup from 'yup'
import {useFormik} from 'formik'
import {isEmpty, keys, pick} from 'lodash'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {socketClient} from 'client/socket.client'
const validationSchema = yup.object({
  count: yup.number().required('Code count required')
})

const initialValues = {
  count: ''
}
export const ProvisionModal = props => {
  const {open, handleModal, id, setOpen} = props

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      const payload = {owner: props.owner, ...values}
      socketClient.io.emit('reserve', 'pet-codes', payload, {}, (error, data) => {
        if (error) {
          console.error(error)
          return
        }
        handleModal(data)
      })
    }
  })

  const onClose = () => {
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      onClose={handleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout title={`${id ? 'Edit' : 'Add'} Shelter`} onClose={onClose}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <TextField
              id="standard-basic"
              label="Number of codes"
              variant="outlined"
              type="number"
              {...formik.getFieldProps('count')}
              error={formik.touched.count && Boolean(formik.errors.count)}
              helperText={formik.touched.count && formik.errors.count}
              fullWidth
            />
          </div>
          <Box marginTop={2}>
            <ErrorOutlineIcon fontSize="small" color="warning" />{' '}
            <span>
              Given number of unique pet identification codes will be reserved for the shelter. These code can
              later be claimed.
            </span>
          </Box>
          <Box marginTop={3}>
            <Button variant="contained" color="primary" fullWidth size="large" type="submit">
              Allocate Codes
            </Button>
          </Box>
        </form>
      </ModalLayout>
    </Modal>
  )
}
