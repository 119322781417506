import React, {useState} from 'react'
import {Tab, Tabs, Grid, Box} from '@mui/material'
import {Pets} from './tabs/pets'
import {ConnectedComponent} from 'components/service/ConnectedComponent'
import { UserHeaderInfo } from './UserHeaderInfo'
import { EditDetail } from './EditDetail'
// import { UserHeaderInfo } from 'modules/dashboard/users/UserHeaderInfo'
// import { EditDetail } from 'modules/dashboard/users/EditDetail'

const tabs = ['Pets']

export const DetailView = (props) => {

  const [activeTab, setActiveTab] = useState(0)

  return (
    <Box height="100vh" sx={{background: '#fff', overflowY: 'scroll', textAlign: 'start', margin: '-24px'}}>
      <UserHeaderInfo {...props} />
      <Grid container>
        <ConnectedComponent Component={EditDetail} service="users" id={props?.data?._id} />
        <Grid item md={8} xs={12} py={3} px={3}>
          <Box fullWidth>
            <Tabs
              value={activeTab}
              onChange={(e, value) => setActiveTab(value)}
              variant="scrollable"
              scrollButtons="auto"
              className="!mb-4"
            >
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab} sx={{fontWeight: 'bold'}} />
              ))}
            </Tabs>
            {(activeTab === 0 && props?.data?._id) && (
              <ConnectedComponent
                Component={Pets}
                service="pets"
                config={{query: {user: props?.data?._id, $populate: ['type', 'subtype', 'profile']}}}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
