import PropTypes from 'prop-types'

/**
 * A Component Reward Stamp
 *
 * @component
 * @example
 *
 * <RewardStamp amount={230} isDesktop={true}/>
 *
 * @param {string} amount - Amount to show on Rewardstamp. It is required prop.
 * @param {boolean} isDesktop - To support responsive ui. It is required prop.
 */

export const RewardStamp = props => {
  const {amount, isDesktop = true} = props
  return (
    <div className="inline-block">
      <div className={`w-32 h-32 ${isDesktop ? 'reward-desk' : 'reward-mobile'}`}>
        <span className="overlay-text text-sm ">${amount} REWARD</span>
      </div>
    </div>
  )
}

RewardStamp.prototype = {
  amount: PropTypes.string.isRequired,
  isDesktop: PropTypes.bool.isRequired
}
