import { ConnectedComponent } from "components/service/ConnectedComponent"
import { AppLayout } from "modules/layout/AppLayout"
import { DetailView } from "./components/DetailView"
import { useParams } from 'react-router-dom'

export const UserDetail = () => {
  const {id} = useParams()

  return (
  <AppLayout heading="User Detail">
    <ConnectedComponent
      Component={DetailView}
      id={id}
      service="users"
      config={{
        query: {
          $populate: 'profile'
        }
      }}
    />
  </AppLayout>
  )
}
