import moment from "moment"

export const mask = inputString => {
  // Convert the input string to an array of characters
  const characters = inputString.split('')

  // Calculate the number of characters to replace (e.g., 30% of the characters)
  const numToReplace = Math.floor(characters.length * 0.3) // Adjust the percentage as needed

  // Create an array to store the positions of characters to replace
  const positionsToReplace = []

  // Generate random positions to replace
  while (positionsToReplace.length < numToReplace) {
    const randomPosition = Math.floor(Math.random() * characters.length)
    if (!positionsToReplace.includes(randomPosition)) {
      positionsToReplace.push(randomPosition)
    }
  }

  // Replace characters at the selected positions with asterisks
  positionsToReplace.forEach(position => {
    characters[position] = '*'
  })

  // Join the characters back into a string
  const secretString = characters.join('')

  return secretString
}

export const maskEmail = inputString => {
  if (!inputString) {
    return inputString
  }
  // Split the input string to an array of parts
  const parts = inputString.split('@')

  // Mask the user detail
  parts[0] = mask(parts[0])

  return parts.join('@')
}

export const isDifferentDay = (previous, current) => {
  return !moment(previous).isSame(moment(current), 'day')
}
