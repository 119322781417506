import React from 'react';
import {Grid, Divider} from '@mui/material';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { GridItemTypography } from './GridItemTypography';

export const PersonalTypeDetail = (props) => {
  const {data} = props
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div style={{ padding: 10, margin: '0 10px', width: isMobile ? '100%' : '70%' }}>
      <Typography variant="h5" textAlign="left" sx={{ mb: '8px' }} gutterBottom>
        Basic Detail
      </Typography>
      <Divider sx={{ my: '1rem' }}/>
      <Grid container spacing={1} direction={isMobile ? 'column' : 'row'}>
        <GridItemTypography label="First Name" value={data?.firstName} />
        <GridItemTypography label="Last Name" value={data?.lastName} />
        <GridItemTypography label="Date of Birth" value={data?.dateOfBirth} />
        <GridItemTypography label="Email" value={data?.email} />
        <GridItemTypography label="SSN" value={data?.ssn} />
        <GridItemTypography label="Address1" value={data?.address1} />
        <GridItemTypography label="City" value={data?.city} />
        <GridItemTypography label="State" value={data?.state} />
        <GridItemTypography label="Country" value={data?.country} />
        <GridItemTypography label="Postal Code" value={data?.postalCode} />
      </Grid>
    </div>
  )
}
