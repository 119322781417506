export const types = [
  'Dry',
  'Wet',
  'Other'
]

export const servingSize = [
  'Cups',
  'Handfuls',
  'Teaspoons',
  'Cans',
  'Grams',
  'Pouches'
]

export const mealTime = [
  'Morning',
  'Afternoon',
  'Evening',
  'Other'
]