import { feathers } from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import auth from '@feathersjs/authentication-client'
import { AUTHENTICATION_KEY } from './common'

const socket = io(process.env.REACT_APP_STORE_MODULE_BASE_URL)
const storeSocketClient = feathers()

storeSocketClient.configure(socketio(socket))
storeSocketClient.configure(auth({ storage: window.localStorage, storageKey: AUTHENTICATION_KEY }))

const accessToken = window.localStorage.getItem(AUTHENTICATION_KEY)
if (accessToken) {
  storeSocketClient.authenticate({
    strategy: 'jwt',
    accessToken,
  }).then(response => {
    console.log('Socket client authenticated:', response)
    // You can now use the authenticated socket client
  })
  .catch(error => {
    console.error('Socket client authentication error:', error)
  })
}

export { storeSocketClient }
