import React, {useEffect} from 'react'
import {ConnectedComponent} from 'components/service/ConnectedComponent'
import { Brows } from './Brows'
import { storeSocketClient } from '../client/socket.client'

export const ReviewDashBoard = props => {
  const {setNavigationTitle} = props

  useEffect(() => {
    setNavigationTitle('Review')
  }, [])

  return (
    <ConnectedComponent
      Component={Brows}
      service="review"
      client={storeSocketClient}
      config={{
        query: {
          $populate: ['user', 'product']
        }
      }}
    />
  )
}
