import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material"
import cross from 'images/crosss.svg';

const editAdd = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
 
  width: 600,
  
  '@media (max-width:767px)': {
    width: '95%',
  },
};

export const CreateEditModal = ({ item, open, handleModal}) => {

  return (
    <Modal open={open} onClose={handleModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
      <Box  sx={ editAdd }>
        <Typography textAlign="center" variant="h5" component="h5" pb={0.5} pt={1} color="transparent.contrastText" sx={{ fontSize: '20px', fontWeight: '600'}}> {item ? 'Edit' : 'Add'} Meal Detail</Typography> 
        <Box sx={{ p: { lg: 8, md: 7, sm: 4, xs: 4 } }}>   
          <Box textAlign="right" width="100%" >
            <Button onClick={handleModal} style={{ position: 'absolute', right: '-9px', top: '-3px'}}><img src={cross} /></Button>
          </Box>
          <Box>
            <TextField id="standard-basic" value={item?.time ? item.time : ""} label="Meal Time" placeholder="Enter Meal Time" variant="standard" fullWidth/>
            <Grid container justifyContent="space-between" mt={3}>
              <Grid item md={5} xs={12} sm={12}>
                <TextField id="standard-basic" label="Quantity" placeholder="Enter Meal Quantity" variant="standard" fullWidth/>
              </Grid>     
            </Grid> 
            
            <Box textAlign="center" my={5}>
              <Button variant="contained" color='primary' size="large">{item ? 'Save' : 'Submit'}</Button>   
            </Box>
          </Box>
        </Box>
      </Box>
  </Modal>
  )
}