import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { GridItemTypography } from './GridItemTypography';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { useEffect, useRef, useState } from 'react';
import { PreviewImageDialog } from 'modules/pet-found/public-report/PreviewImageDialog';

export const OwnerDetailCard = (props) => {
  const theme = useTheme();
  const dialogRef = useRef(null)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {owner} = props
  const [src, setSrc] = useState(null)

  const showPreview = (src) => {
    if (src) {
      setSrc(src)
      dialogRef.current.openDialog()
    }
  }

  useEffect(() => {
    if (!dialogRef?.current?.open) {
      setSrc(null)
    }
  }, [dialogRef])

  return (
    <Paper elevation={3} style={{ padding: 15, marginBottom: 15, width: '100%' }}>
      <Grid container spacing={1} direction={isMobile ? 'column' : 'row'}>
        <GridItemTypography label="First Name" value={owner?.firstName} />
        <GridItemTypography label="Last Name" value={owner?.lastName} />
        <GridItemTypography label="Date of Birth" value={owner?.dateOfBirth} />
        <GridItemTypography label="Email" value={owner?.email} />
        <GridItemTypography label="Email" value={owner?.ssn} />
        <GridItemTypography label="Title" value={owner?.title} />
        <GridItemTypography label="Address1" value={owner?.address1} />
        <GridItemTypography label="Address2" value={owner?.address2} />
        <GridItemTypography label="City" value={owner?.city} />
        <GridItemTypography label="State" value={owner?.state} />
        <GridItemTypography label="Postal Code" value={owner?.postalCode} />
        <GridItemTypography label="Ownership Percentage" value={owner?.ownershipPercent} />
        <GridItemTypography label="Document Type" value={owner?.typeImage} />
        {owner?.userIdImgs?.length &&
          <Grid item sx={12} md={6}>
            {owner?.userIdImgs.length === 2 ?
              <div className="flex">
                <InsertPhotoIcon sx={{ fontSize: 40 }} style={{cursor: 'pointer'}} onClick={() => showPreview(owner?.userIdImgs[0])}/>
                <InsertPhotoIcon sx={{ fontSize: 40 }} style={{margin: 'auto 16px', cursor: 'pointer'}} onClick={() => showPreview(owner?.userIdImgs[1])}/>
              </div>  :
              <div className="flex"><InsertPhotoIcon sx={{ fontSize: 40 }} onClick={() => showPreview(owner?.userIdImgs[0])} /></div>
            }
          </Grid>
        }
      </Grid>
      <PreviewImageDialog src={src} ref={dialogRef} />
    </Paper>
  )
}
