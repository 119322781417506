import {useEffect, useState} from 'react'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import './map.css'
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded'
import {renderToString} from 'react-dom/server'
import PropTypes from 'prop-types'
import {Button} from '@mui/material'

/**
 * PetLostTimeLine
 *
 * @param {double} lat - latitude
 * @param {double} lng - longitude
 * @param {array} scans - Array of pet scan object
 */

export const MapLocationFinder = props => {
  const {lat = 0, lng = 0, onLocationChange = () => {}, width = '100%', height = '100%'} = props
  const [pinState, setPinState] = useState({lat: 0, lng: 0})
  const [reCenter, setReCenter] = useState(false)

  useEffect(() => {
    setPinState({lat, lng})
  }, [lat, lng])

  useEffect(() => {
    if ((pinState.lat !== 0 && pinState.lng !== 0) || reCenter) {
      setReCenter(false)
      const map = L.map('map', {
        center: [pinState.lat, pinState.lng],
        zoom: 14,
        zoomControl: true,
        layers: [
          L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            detectRetina: true,
            maxZoom: 20,
            maxNativeZoom: 17
          })
        ]
      })

      const icon = L.divIcon({
        className: 'custom-icon',
        html: renderToString(<FmdGoodRoundedIcon style={{color: '#ED1690'}} />),
        iconSize: [32, 32]
      })

      const marker = new L.marker([pinState.lat, pinState.lng], {icon, draggable: 'true'}).addTo(map)

      marker.on('dragend', function (event) {
        var position = marker.getLatLng()
        marker.setLatLng(position, {
          draggable: 'true'
        })
        onLocationChange([position.lng, position.lat])
      })

      map.on('click', function (e) {
        var lat = e.latlng.lat
        var lng = e.latlng.lng
        marker.setLatLng([lat, lng], {
          draggable: 'true'
        })
        onLocationChange([lng, lat])
      })

      map.addLayer(marker)
      return () => {
        map.remove()
      }
    }
  }, [pinState, reCenter])

  return (
    <div style={{width: width, height: height, position: 'relative'}}>
      <div id="map" style={{width: width, height: height, borderRadius: '10px'}} />
      <Button
        className="!font-bold !absolute !bottom-2.5 !right-2.5 !z-[1000]"
        variant="contained"
        color="primary"
        onClick={() => setReCenter(true)}
      >
        Re-Center
      </Button>
    </div>
  )
}

MapLocationFinder.prototype = {
  lat: PropTypes.number,
  lng: PropTypes.number
}
