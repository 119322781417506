import React, {useState} from 'react'
import {Card, Typography, IconButton, Tooltip, Collapse} from '@mui/material'
import Timeline from '@mui/lab/Timeline'
import TimelineItem, {timelineItemClasses} from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import moment from 'moment'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded'
import PetsRoundedIcon from '@mui/icons-material/PetsRounded'
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded'

const ScanItem = props => {
  const {data, setPetLocationData, item, index} = props
  const [expanded, setExpanded] = useState(false)

  return (
    <TimelineItem key={index} className="!my-2">
      <TimelineSeparator>
        <TimelineDot
          color="primary"
          sx={{
            width: 24,
            height: 24,
            padding: 0,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <PetsRoundedIcon fontSize="24px" style={{color: 'white'}} />
        </TimelineDot>
        {(index !== data?.report?.scans?.length - 1 || data?.report?.claimed) && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent className="">
        <Card variant="outlined" className="!p-3">
          <div className="flex justify-between">
            <div onClick={() => setExpanded(!expanded)} className="cursor-pointer">
              {item?.by?.username && <p className="text-xs font-bold">@{item?.by?.username}</p>}
              {/* {(item?.by?.firstName || item?.by?.lastName) &&
                (<p className="text-xs">
                  {`${item?.by?.firstName.slice(0,(item?.by?.firstName.length / 2))}**** `}
                </p>)
              } */}

              {item?.time && (
                <p className="text-xs mt-1">{moment.utc(item?.time).format('MMMM Do YYYY, LT')}</p>
              )}
              {item?.note && <p className="text-xs lg:max-w-[28rem] mt-2">{item?.note}</p>}
            </div>
            <IconButton
              style={{width: '24px', height: '24px'}}
              onClick={() => setPetLocationData(item.location)}
            >
              {index === data?.report?.scans?.length - 1 ? (
                <PlaceRoundedIcon fontSize="18px" color="primary" />
              ) : (
                <PlaceRoundedIcon fontSize="18px" />
              )}
            </IconButton>
          </div>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {item?.media?.url && <img src={item?.media?.url} className="w-40 mt-2" />}
          </Collapse>
        </Card>
      </TimelineContent>
    </TimelineItem>
  )
}

export const TagScanHistory = props => {
  const {data} = props

  return (
    <div>
      <Typography className="!mb-2 !font-bold">Tracking Updates: Sightings & Scans</Typography>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0
          }
        }}
      >
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot
              color="primary"
              sx={{width: 24, height: 24, padding: 0, alignItems: 'center', justifyContent: 'center'}}
            >
              <AssignmentRoundedIcon fontSize="24px" style={{color: 'white'}} />
            </TimelineDot>

            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Card variant="outlined" className="!p-3">
              <p className="text-xs font-bold	">Reported at</p>
              <p className="text-xs">
                {data?.report?.createdAt
                  ? moment.utc(data?.report?.createdAt).format('MMMM Do YYYY, LT')
                  : ''}
              </p>
            </Card>
          </TimelineContent>
        </TimelineItem>
        {data?.report?.scans?.length > 0 &&
          data?.report?.scans?.map((item, index) => (
            <ScanItem key={index} item={item} index={index} {...props} />
          ))}

        {data?.report?.claimed && data?.report?.claimedBy && (
          <TimelineItem>
            <TimelineSeparator className="!mt-2">
              <CheckCircleIcon color="success" />
            </TimelineSeparator>
            <TimelineContent>
              <Tooltip>
                <Card variant="outlined" className="!p-3">
                  <p className="text-xs font-bold	">Claimed By</p>
                  <p className="text-xs mt-1 ">
                    {`${data?.report?.claimedBy?.firstName} ${data?.report?.claimedBy?.lastName}` || ''}
                  </p>
                </Card>
              </Tooltip>
            </TimelineContent>
          </TimelineItem>
        )}
      </Timeline>
    </div>
  )
}
