import {Box, Button, Checkbox, FormControlLabel, Modal, TextField} from '@mui/material'
import {connectService} from 'components/service/connectService'
import {useEffect, useMemo, useState} from 'react'
import {AutoCompleteSearch} from '../components/AutoCompleteSearch'
import {ModalLayout} from 'components/layouts/ModalLayout'
import * as yup from 'yup'
import {useFormik} from 'formik'
import {isEmpty, keys, pick} from 'lodash'
import {NumberTextField} from 'components/NumberTextField'
import {IconUploader} from 'components/IconUploader'

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  sortOrder: yup.number().positive('Sort Order must be positive').required('Sort Order is required')
})

const initialValues = {
  name: '',
  type: '',
  active: true,
  sortOrder: ''
}
export const AddEditModal = connectService(
  props => {
    const {open, handleModal, id, data, setData, setOpen, loading, Loader, patch, create} = props
    const [icon, setIcon] = useState(null)

    const [iconError, showIconError] = useState(false)
    const [petTypeError, showPetTypeError] = useState(false)

    const initialData = useMemo(() => {
      if (data?.icon) setIcon(data.icon)
      return pick(data, keys(initialValues))
    }, [data])

    const formik = useFormik({
      initialValues: id
        ? {...initialData, active: data.hasOwnProperty('active') ? data.active : true}
        : initialValues,
      enableReinitialize: true,
      validationSchema,
      onSubmit: values => {
        if (isEmpty(values.type)) {
          showPetTypeError(true)
          return
        }

        if (!icon) {
          showIconError(true)
          return
        }
        const payload = {...values, icon: icon._id}
        id ? patch(id, payload) : create(payload)
      }
    })

    const onTypeChange = id => {
      showPetTypeError(false)
      formik.setFieldValue('type', id)
    }

    const onClose = () => {
      formik.resetForm()
      setData({})
      setOpen(false)
      setIcon(null)
    }

    useEffect(() => {
      if (!id) {
        setData({}) // create old persisted data
      }
    }, [id])

    useEffect(() => {
      if (data?.icon) {
        setIcon(data?.icon)
      }
    }, [data])

    useEffect(() => {
      showIconError(false)
    }, [icon])

    return (
      <Modal
        open={open}
        onClose={() => {
          formik.resetForm()
          handleModal()
          setIcon(null)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalLayout
          title={`${id ? 'Edit' : 'Add'} Pet Treat Type`}
          onClose={onClose}
          LoaderComponent={Loader}
          loading={loading}
        >
          <form onSubmit={formik.handleSubmit}>
            <TextField
              id="standard-basic"
              label="Name"
              variant="outlined"
              {...formik.getFieldProps('name')}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
            />
            <div className="flex mt-4">
              <AutoCompleteSearch
                id={data?.type}
                className="!w-1/2"
                onChange={onTypeChange}
                error={petTypeError}
                helperText="Please Select type"
              />
              <NumberTextField
                type="number"
                label="Sort Order"
                variant="outlined"
                {...formik.getFieldProps('sortOrder')}
                error={formik.touched.sortOrder && Boolean(formik.errors.sortOrder)}
                helperText={formik.touched.sortOrder && formik.errors.sortOrder}
                className={`w-1/2 !ml-2 `}
                onInput={event => {
                  event.target.value = event.target.value.replace(/^-/, '')
                }}
              />
            </div>
            <div className="my-4">
              <div className="flex justify-between items-center">
                <IconUploader
                  title="Upload Image"
                  icon={icon}
                  setIcon={setIcon}
                  pathKey="assets/treat-types"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.active}
                      onChange={e => formik.setFieldValue('active', e.target.checked)}
                    />
                  }
                  label="Active"
                />
              </div>
              {iconError && <p style={{color: 'red', marginLeft: '2PX'}}>Please upload image*</p>}
            </div>
            <Box>
              <Button variant="contained" color="primary" fullWidth size="large" type="submit">
                {id ? 'Edit' : 'Submit'}
              </Button>
            </Box>
          </form>
        </ModalLayout>
      </Modal>
    )
  },
  'treat-types',
  {query: {$populate: 'icon'}}
)
