import {Box, Button, Typography} from '@mui/material'
import { currency } from 'utils/Constants'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const TransactionSuccess = (props) => {
  const {amount, payee, onClose} = props
  console.log({amount, payee, onClose})
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height:'350px'}}>
      <Box sx={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent:'center', alignItems: 'center'}} >
        <CheckCircleIcon color="success" sx={{ fontSize: 64 }}/>
        <Typography variant="h6" fontWeight="bold">You sent {currency(amount)}</Typography>
        <Typography variant="h6" fontWeight="bold">To {payee?.user?.firstName} {payee?.user?.lastName}</Typography>
      </Box>
      <Button
        color="primary"
        variant="contained"
        onClick={onClose}
        mx={4}
        sx={{
          borderRadius:'20px'
        }}
      >
        Done
      </Button>
    </Box>
  )
}

export default TransactionSuccess
