import {Delete, Edit} from '@mui/icons-material'
import {
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Typography,
  Avatar,
  IconButton,
  Divider
} from '@mui/material'
import moment from 'moment'
import {useNavigate} from 'react-router-dom'
import {StyledTable, StyledTableRow, StyledTableHeadCell, StyledTableCell} from 'components/StyledComponent'
import {ImagePreview} from 'components/ImagePreview'
import placeholder from 'images/avatar_placeholder.svg'
import { FilterBar } from './FilterBar'

const columns = [
  {id: 'name', label: 'Name'},
  {id: 'birthdate', label: 'Birth Date'},
  {id: 'age', label: 'Age (years)'},
  {id: 'type', label: 'Type'},
  {id: 'subtype', label: 'Sub Type'},
  {id: 'size', label: 'Size'},
  {id: 'obituary', label: 'Obituary'},
  {id: 'status', label: 'Status'}
]

export const DataTable = props => {
  const {list, loading, Loader, Paginator} = props
  const navigate = useNavigate()
  return (
    <div>
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <FilterBar {...props}/>
        <Divider />
        <TableContainer>
          <StyledTable stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <StyledTableHeadCell></StyledTableHeadCell>
                {columns.map(column => (
                  <StyledTableHeadCell key={column.id}>{column.label}</StyledTableHeadCell>
                ))}
                <StyledTableHeadCell></StyledTableHeadCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {list.length > 0 ? (
                list.map((row, index) => (
                  <StyledTableRow key={index} odd={index % 2 !== 0 ? 1 : 0}>
                    <StyledTableCell>
                      {row?.profile ? (
                        <ImagePreview
                          media={row?.profile}
                          className="w-10 h-10  rounded-full"
                          fallbackSrc={placeholder}
                        />
                      ) : (
                        <Avatar src={placeholder}></Avatar>
                      )}
                    </StyledTableCell>
                    <StyledTableCell data-label="Name">{row?.name ? row.name : '-'}</StyledTableCell>
                    <StyledTableCell data-label="Birth Date">
                      {row?.birthdate ? moment(row.birthdate).format('DD MMM YYYY') : '-'}
                    </StyledTableCell>
                    <StyledTableCell data-label="Age">{row?.age ? row.age : '-'}</StyledTableCell>
                    <StyledTableCell data-label="Type">
                      {row?.type?.name ? row.type?.name : '-'}
                    </StyledTableCell>
                    <StyledTableCell data-label="Sub Type">
                      {row?.subtype?.name ? row.subtype?.name : '-'}
                    </StyledTableCell>
                    <StyledTableCell data-label="Size">{row?.size ? row.size : '-'}</StyledTableCell>
                    <StyledTableCell data-label="Obituary">
                      {row?.obituary ? row.obituary : '-'}
                    </StyledTableCell>
                    <StyledTableCell data-label="Status">{row?.status ? row.status : '-'}</StyledTableCell>
                    <StyledTableCell size="small">
                      <IconButton
                        onClick={() => {
                          navigate(`/pets/${row._id}`)
                        }}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton onClick={() => {}}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : loading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={9} align="center">
                    <Loader />
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={9} align="center">
                    <Typography>No Data</Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <Paginator />
      </Paper>
    </div>
  )
}
