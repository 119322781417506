import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography
} from '@mui/material'
import Add from '@mui/icons-material/Add'
import Search from '@mui/icons-material/Search'
import {Close} from '@mui/icons-material'
import { CsvDropDown } from 'components/CsvDropDown'

export const BrowseLayout = props => {
  const {
    title, 
    onAdd = () => {}, 
    allowToCreate = true, 
    setSearch, 
    loading, 
    search, 
    searchLabel = 'Search by name', 
    showSearchField = true,
    showCsvOption = false,
    service,
  } = props

  return (
    <Box py={2.5}>
      <div className="mb-3 flex flex-col md:flex-row md:justify-between md:items-center">
        <Typography
          variant="h5"
          component="h5"
          color="transparent.contrastText"
          textAlign="left"
          sx={{fontSize: '24px', fontWeight: '600'}}
        >
          {title}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {showSearchField &&
            <FormControl sx={{my: 1, width: '25ch'}} variant="outlined">
              <OutlinedInput
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : search ? (
                      <IconButton onClick={() => setSearch(null)}>
                        <Close sx={{fontSize: 20}} />
                      </IconButton>
                    ) : (
                      <Search />
                    )}
                  </InputAdornment>
                }
                placeholder={searchLabel}
                onChange={e => setSearch(e.target.value)}
                value={search ? search : ''}
              />
            </FormControl>
          }
          {allowToCreate &&
            <IconButton aria-label="plus" onClick={onAdd} color="primary">
              <Add />
            </IconButton>
          }

          {(showCsvOption  && service) && <CsvDropDown service={service} />}
        </Box>
      </div>
      <Box sx={{boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)', borderRadius: '10px'}}>
        {props.children}
      </Box>
    </Box>
  )
}
