import {connectService} from 'components/service/connectService'
import React, {useEffect, useState} from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Avatar,
  Chip,
  IconButton
} from '@mui/material'
import {Delete, Edit} from '@mui/icons-material'
import {StyledTable, StyledTableRow, StyledTableHeadCell, StyledTableCell} from 'components/StyledComponent'
import placeholder from 'images/avatar_placeholder.svg'
import {ImagePreview} from 'components/ImagePreview'

export const DataTable = props => {
  const {list, loading, Loader, Paginator, onEdit, onDelete, isRefreshTable, setRefreshTable, refreshTable} =
    props

  useEffect(() => {
    if (isRefreshTable) {
      refreshTable()
      setRefreshTable(false)
    }
  }, [isRefreshTable])

  return (
    <div>
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <TableContainer>
          <StyledTable stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <StyledTableHeadCell></StyledTableHeadCell>
                <StyledTableHeadCell>Name</StyledTableHeadCell>
                <StyledTableHeadCell>Type</StyledTableHeadCell>
                <StyledTableHeadCell>Sort Order</StyledTableHeadCell>
                <StyledTableHeadCell>Active</StyledTableHeadCell>
                <StyledTableHeadCell></StyledTableHeadCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {list.length > 0 ? (
                list.map((row, index) => (
                  <StyledTableRow key={index} odd={index % 2 !== 0 ? 1 : 0}>
                    <StyledTableCell>
                      {console.log(row)}
                      {row?.icon ? (
                        <ImagePreview
                          media={row?.icon}
                          className="w-10 h-10  rounded-full"
                          fallbackSrc={placeholder}
                        />
                      ) : (
                        <Avatar src={placeholder}></Avatar>
                      )}
                    </StyledTableCell>
                    <StyledTableCell data-label="Name">{row?.name ? row.name : '-'}</StyledTableCell>
                    <StyledTableCell data-label="Type">
                      {row?.type?.name ? row.type?.name : '-'}
                    </StyledTableCell>
                    <StyledTableCell data-label="Sort Order">
                      {row?.sortOrder ? row.sortOrder : '-'}
                    </StyledTableCell>
                    <StyledTableCell data-label="Active">
                      {row?.active ? (
                        <Chip label="Yes" size="small" color="success" variant="outlined" />
                      ) : (
                        <Chip label="No" size="small" color="error" variant="outlined" />
                      )}
                    </StyledTableCell>
                    <StyledTableCell size="small">
                      <IconButton onClick={() => onEdit(row._id)}>
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton onClick={() => onDelete(row._id)}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : loading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={5} align="center">
                    <Loader />
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={5} align="center">
                    <Typography>No Data</Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <Paginator />
      </Paper>
    </div>
  )
}
