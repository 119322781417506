import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {Modal, TextField, Button, Box, Typography} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  border: '2px solid #FFF',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
};

const RejectionModal = forwardRef(({ onReject }, ref) => {
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState('');


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReject = () => {
    onReject(reason);
    handleClose();
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Typography variant="h6" textAlign="center" sx={{mb: '1rem'}}>Reason for Rejection</Typography>
        <TextField
          label="Enter reason"
          variant="outlined"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          multiline={true}
          rows={3}
        />
        <div className="flex justify-end mt-4">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            sx={{mx: '1rem'}}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleReject}
          >
            Reject
          </Button>
        </div>
      </Box>
    </Modal>
  );
});

export default RejectionModal;
