import {Paper, TableBody, TableContainer, TableHead, Typography} from '@mui/material'
import {StyledTable, StyledTableCell, StyledTableHeadCell, StyledTableRow} from 'components/StyledComponent'

export const DataTable = props => {
  const {handleRowClick, list, loading, Loader, Paginator} = props

  return (
    <div>
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <TableContainer>
          <StyledTable>
            <TableHead>
              <StyledTableRow>
                <StyledTableHeadCell className="!lg:w-4/5">Description</StyledTableHeadCell>
                <StyledTableHeadCell className="!lg:w-1/5">Reported By</StyledTableHeadCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {list?.length > 0 ? (
                list?.map((row, index) => (
                  <StyledTableRow
                    key={index}
                    odd={index % 2 !== 0 ? 1 : 0}
                    onClick={() => handleRowClick(row)}
                    hover
                  >
                    <StyledTableCell data-label="Description" className="!lg:w-4/5">
                      {row.report ? row.report : '-'}
                    </StyledTableCell>
                    <StyledTableCell
                      data-label="Name"
                      className="!lg:w-1/5"
                    >{`${row?.user?.firstName} ${row?.user?.lastName}`}</StyledTableCell>
                  </StyledTableRow>
                ))
              ) : loading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={2} align="center">
                    <Loader />
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={2} align="center">
                    <Typography>No Data</Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <Paginator />
      </Paper>
    </div>
  )
}
