import { AppLayout } from 'modules/layout/AppLayout'
import { DataTable } from './components/DataTable'
import { ConnectedComponent } from 'components/service/ConnectedComponent'

export const PetList = props => {
  return (
    <AppLayout heading="Pet List">
      <ConnectedComponent
        Component={DataTable} service="pets"
        config={
          {query: {$populate: ['type', 'subtype', 'profile']}}
        }
      />
    </AppLayout>
  )
}
