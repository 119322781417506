import {Box, IconButton, Typography} from '@mui/material'
import Close from '@mui/icons-material/Close'
import { useContext } from 'react';
import { SnackbarContext } from 'components/snackbar/SnackbarContext';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const editAdd = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,

  width: 600,

  '@media (max-width:767px)': {
    width: '95%'
  }
}



export const ModalLayout = props => {
  const {title, loading = false, onClose = () => {}, id, showCopyIdButton = false} = props
  const {setSeverity, showToast} = useContext(SnackbarContext)

  const copyToClipboard = (id) => {
    navigator.clipboard.writeText(id)
      .then(() => {
        // Optionally, you can provide feedback to the user here
        setSeverity('success')
        showToast('ID copied to clipboard!');
      })
      .catch((error) => {
        console.error('Error copying ID to clipboard:', error);
        // Optionally, you can provide feedback to the user here
        setSeverity('error')
        showToast('Failed to copying ID to clipboard!');
      });
  };

  return (
    <Box sx={editAdd}>
      <Box sx={{p: {lg: 5, md: 5, sm: 4, xs: 4}}}>
        <Box display="flex" alignItems="center" justifyContent="space-between" pb={2.5}>
          <Box width="100%" display="flex">
            <Typography
              variant="h5"
              component="h5"
              color="transparent.contrastText"
              sx={{fontSize: '24px', fontWeight: '600'}}
            >
              {title} 
            </Typography>
            {showCopyIdButton && 
              <IconButton 
                size='small' 
                onClick={() => copyToClipboard(id)}
                sx={{marginLeft: '8px', textTransform: 'none'}}
                color="primary"
              >
                <ContentCopyIcon />
              </IconButton>
            }
          </Box>
          <Box textAlign="right">
            <IconButton onClick={onClose}>
              <Close onClick={onClose} />
            </IconButton>
          </Box>
        </Box>
        {loading && props?.LoaderComponent ? <props.LoaderComponent /> : props.children}
      </Box>
    </Box>
  )
}
