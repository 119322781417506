import {Divider, Drawer, IconButton, Typography} from '@mui/material'
import {connectService} from 'components/service/connectService'
import React, {useEffect, useState} from 'react'

import {PetLostTimeLine} from './PetLostTimeLine'
import {Close} from '@mui/icons-material'
import {LostPetMap} from './LostPetMap'
import PropTypes from 'prop-types'

import {BasicDetails} from './BasicDetails'
import { MediaCarousel } from 'modules/moderation-report/dashboard/components/MediaCarousel'

/**
 * ViewDetailModal
 *
 * @param {object} data - It is object of lost pet detail.
 * @param {function} handleClose
 * @param {boolean} open
 */

export const ViewDetailModal = connectService(
  props => {
    const {open, data, handleClose} = props
    const [[lng, lat], setPetLocationData] = useState([0, 0])

    useEffect(() => {
      if (data?.scans?.length > 0 && data?.scans[0]?.location?.length > 0) {
        setPetLocationData(data?.scans[data?.scans?.length - 1]?.location)
      }
    }, [data])

    return (
      <React.Fragment>
        <Drawer anchor="right" open={open} onClose={handleClose}>
          <div className="flex  flex-col p-8">
            <div className="w-full flex justify-between">
              <Typography
                variant="h5"
                component="h5"
                color="transparent.contrastText"
                sx={{fontSize: '24px', fontWeight: '600'}}
              >
                Lost Pet Detail
              </Typography>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </div>
            <Divider className="!mt-4 w-full" />
            <div className="flex flex-col lg:flex-row w-full mt-8">
              {data?.scans?.length > 0 && (
                <div className="min-w-[20rem] min-h-[20rem] md:min-w-[36rem] md:h-[36rem] xl:min-w-[36rem] xl:h-[36rem]">
                  <LostPetMap lat={lat} lng={lng} scans={data?.scans} />
                </div>
              )}
              <div className="w-full flex flex-col lg:flex-row lg:ml-4 mt-4 lg:mt-0">
                <BasicDetails data={data} />
                <div className="mt-4 lg:mt-0 lg:ml-2">
                  {!!data?.media?.length && <MediaCarousel media={data?.media} />}
                  <PetLostTimeLine data={data} setPetLocationData={setPetLocationData} />
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    )
  },
  'pet-finder',
  {
    query: {
      $populate: [
        'user',
        'media',
        // 'scans.by',
        'claimedBy',
        {
          path: 'pet',
          populate: ['profile', 'type']
        }, {
          path: 'scans',
          populate: ['by', 'media']
        }
      ]
    }
  }
)




ViewDetailModal.prototype = {
  data: PropTypes.object,
  handleClose: PropTypes.func,
  open: PropTypes.bool
}
