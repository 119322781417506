export const actions = {
  PET_FOUND: 'PET_FOUND',
  RESET_PET_FOUND: 'RESET_PET_FOUND',

  resetPetFound() {
    return {
      type: actions.RESET_PET_FOUND
    }
  },

  setPetFoundReport(data) {
    return {
      type: actions.PET_FOUND,
      data
    }
  }
}

export const PetFoundActions = actions
