import {Card, Divider, Tab, Tabs} from '@mui/material'
import {AppLayout} from 'modules/layout/AppLayout'
import {useState} from 'react'
import {PostList} from './tabs/posts'
import {CommentList} from './tabs/comments'
import {ShortList} from './tabs/shorts'
import {StoryList} from './tabs/stories'
import {HighLightList} from './tabs/highlights'
import {TributesList} from './tabs/tributes'
import {UserList} from './tabs/users'
const tabLabels = [
  'posts',
  'comments',
  'shorts',
  'highlights',
  'tributes',
  'stories',
  'messages',
  'pets',
  'users'
]

export const Dashboard = props => {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <AppLayout heading="Moderation Report">
        <Card className="w-screen md:w-full">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable tabs"
          >
            {tabLabels.map((label, index) => (
              <Tab key={index} label={label} />
            ))}
          </Tabs>
          <Divider />
          {value === 0 && <PostList />}
          {value === 1 && <CommentList />}
          {value === 2 && <ShortList />}
          {value === 3 && <HighLightList />}
          {/* {value === 3 && <StoryList />} */}
          {value === 4 && <TributesList />}
          {value === 5 && <h1 className="my-4">Comming Soon</h1>}
          {value === 6 && <h1 className="my-4">Comming Soon</h1>}
          {value === 7 && <h1 className="my-4">Comming Soon</h1>}
          {value === 8 && <h1 className="my-4">Comming Soon</h1>}
        </Card>
      </AppLayout>
    </div>
  )
}
