import {Add, Delete} from '@mui/icons-material'
import {Checkbox, FormControlLabel, IconButton, TextField} from '@mui/material'
import {OptionChoices} from './OptionChoices'

const choiceValues = {
  name: '',
  additionalPrice: 0,
  isBaseOption: false,
  isActive: false
}

export const OptionItem = props => {
  const {options, setOptions, optionIndex, item} = props

  const handleAddChoice = () => {
    const newOptions = [...options]
    newOptions[optionIndex]['values'] = [...newOptions[optionIndex]['values'], choiceValues]
    setOptions(newOptions)
  }

  const onDelete = () => {
    const newOptions = [...options]
    newOptions.splice(optionIndex, 1)
    setOptions(newOptions)
  }

  const handleChange = (property, value) => {
    const option = options[optionIndex]
    const newOptions = [...options]
    newOptions.splice(optionIndex, 1, {...option, [property]: value})
    setOptions(newOptions)
  }

  return (
    <div className="flex flex-col border rounded-lg border-gray-200 px-4 pt-4 my-4">
      <div className="flex">
        <TextField
          value={item?.name}
          className="!mr-2"
          label="Enter option name"
          variant="outlined"
          size="small"
          onChange={e => handleChange('name', e.target.value)}
          fullWidth
        />
        <IconButton onClick={onDelete}>
          <Delete />
        </IconButton>
      </div>

      <div className="flex justify-between my-4">
        <FormControlLabel
          control={
            <Checkbox
              checked={item?.isRequired || false}
              onChange={e => handleChange('isRequired', e.target.checked)}
            />
          }
          label="Required"
        />

        <IconButton onClick={handleAddChoice} color="primary">
          <Add />
        </IconButton>
      </div>
      {item?.values?.map((choice, index) => (
        <OptionChoices key={index} choiceIndex={index} choice={choice} {...props} />
      ))}
    </div>
  )
}
