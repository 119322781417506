import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField
} from '@mui/material'
import {connectService} from 'components/service/connectService'
import {useEffect, useState} from 'react'
import {ModalLayout} from 'components/layouts/ModalLayout'
import * as yup from 'yup'
import {useFormik} from 'formik'
import {NumberTextField} from 'components/NumberTextField'
import {IconUploader} from 'components/IconUploader'

const validationSchema = yup.object({
  title: yup.string().required('Title is required'),
  subTitle: yup.string().required('Sub-Title is required'),
  platform: yup.string().required('Please select platform')
})
const initialValues = {
  title: '',
  subTitle: '',
  platform: '',
  active: true,
  media: '',
  sortOrder: ''
}
export const AddEditModal = connectService(
  props => {
    const {open, handleModal, id, data, setData, setOpen, loading, Loader, patch, create} = props
    const [icon, setIcon] = useState(null)

    const [media, setMedia] = useState(null)
    const [iconError, showIconError] = useState(false)

    const formik = useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema,
      onSubmit: values => {
        if (!media) {
          showIconError(true)
          return
        }
        const payload = {...values, media: media._id}
        id ? patch(id, payload) : create(payload)
      }
    })

    const onClose = () => {
      setOpen(false)
    }

    useEffect(() => {
      if (!open) {
        formik.resetForm()
        setData({})
      }
    }, [open])

    useEffect(() => {
      if (!id) {
        setData({}) // create old persisted data
      }
    }, [id])

    useEffect(() => {
      if (data?._id) {
        formik.setFieldValue('title', data?.title)
        formik.setFieldValue('subTitle', data?.subTitle)
        formik.setFieldValue('active', data?.active)
        formik.setFieldValue('platform', data?.platform)
        formik.setFieldValue('sortOrder', data?.sortOrder)
        if (data?.media) {
          setIcon(data?.media)
          setMedia(data?.media)
        }
      }
    }, [data])

    useEffect(() => {
      if (media) {
        showIconError(false)
      }
    }, [media])

    return (
      <Modal
        open={open}
        onClose={() => {
          formik.resetForm()
          setData({})
          handleModal()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalLayout
          title={`${id ? 'Edit' : 'Add'} splash screen detail`}
          onClose={onClose}
          LoaderComponent={Loader}
          loading={loading}
        >
          <form onSubmit={formik.handleSubmit}>
            <TextField
              id="standard-basic"
              label="Title"
              variant="outlined"
              {...formik.getFieldProps('title')}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              fullWidth
            />

            <TextField
              id="standard-basic"
              className="!mt-3"
              label="Sub-Title"
              variant="outlined"
              {...formik.getFieldProps('subTitle')}
              error={formik.touched.subTitle && Boolean(formik.errors.subTitle)}
              helperText={formik.touched.subTitle && formik.errors.subTitle}
              fullWidth
            />
            <div className="flex mt-3">
              <FormControl
                className="!w-1/2"
                error={formik.touched.platform && Boolean(formik.errors.platform)}
              >
                <InputLabel>Plateform</InputLabel>
                <Select name="platform" label="Plateform" {...formik.getFieldProps('platform')}>
                  <MenuItem disabled value="">
                    Select a platform
                  </MenuItem>
                  <MenuItem value="web">Web</MenuItem>
                  <MenuItem value="mobile">Mobile</MenuItem>
                </Select>
                {formik.touched.platform && formik.errors.platform ? (
                  <FormHelperText>{formik.errors.platform}</FormHelperText>
                ) : null}
              </FormControl>

              <NumberTextField
                type="number"
                label="Sort Order"
                variant="outlined"
                {...formik.getFieldProps('sortOrder')}
                error={formik.touched.sortOrder && Boolean(formik.errors.sortOrder)}
                helperText={formik.touched.sortOrder && formik.errors.sortOrder}
                className={`w-1/2 !ml-2 `}
                onInput={event => {
                  event.target.value = event.target.value.replace(/^-/, '')
                }}
              />
            </div>
            <div className="my-4">
              <div className="flex justify-between items-center">
                <IconUploader title="Upload Image" icon={icon} setIcon={setIcon} setMedia={setMedia} />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.active}
                      onChange={e => formik.setFieldValue('active', e.target.checked)}
                    />
                  }
                  label="Active"
                />
              </div>
              {iconError && <p style={{color: 'red', marginLeft: '2PX'}}>Please upload image*</p>}
            </div>
            <Box>
              <Button variant="contained" color="primary" fullWidth size="large" type="submit">
                {id ? 'Edit' : 'Submit'}
              </Button>
            </Box>
          </form>
        </ModalLayout>
      </Modal>
    )
  },
  'intro-slides',
  {query: {$populate: 'media'}}
)
