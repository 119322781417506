import {RightDrawerLayout} from 'components/layouts/RightDrawerLayout'
import {Post} from '../../components/Post'

export const DetailView = props => {
  const {open, handleResult, onClose, data} = props
  const handleAction = flagged => {}

  return (
    <RightDrawerLayout open={open} onClose={onClose} title="Post">
      <Post content={data?.content} handleAction={handleAction} />
    </RightDrawerLayout>
  )
}
