import {actions} from './actions'
const initialState = {
  petReport: null
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.PET_FOUND:
      return {
        ...state,
        petReport: action.data
      }

    case actions.RESET_PET_FOUND:
      return initialState
    default:
      return state
  }
}

export default reducer
