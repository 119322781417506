import { AppDownloadButton } from "./AppDownloadButton"
import playStore from 'images/playstore.svg'
import appStore from 'images/appstore.svg'

export const DownloadAppFloatingSection = ({className= ''}) => {
  return (
    <div className={`w-full fixed bottom-0 flex mb-4 z-[1001] ${className}`}>
      <div>
        {/* <div className="text-xl mb-2">Download the app now</div> */}
        <div className="flex justify-center">
          <AppDownloadButton
            icon={playStore}
            description="Download on the"
            platform="AppStore"
            link="https://www.apple.com/in/app-store"
          />
          <AppDownloadButton
            icon={appStore}
            description="GET IT ON"
            platform="Google play"
            link="https://play.google.com/store/games?hl=en-IN"
          />
        </div>
      </div>
    </div>
  )
}
