import {Route, Routes} from 'react-router-dom'
import {CreateScanReport} from './CreateScanReport'
import {SuccessReport} from './SuccessReport'
export const ScanReport = () => {
  return (
    <Routes>
      <Route path="/create/:code" element={<CreateScanReport />} />
      <Route path="/success" element={<SuccessReport />} />
    </Routes>
  )
}
