import {
  FormControl,
  OutlinedInput,
  Grid
} from '@mui/material'
import { ConnectedComponent } from 'components/service/ConnectedComponent'
import { AutoCompleteSearch } from './AutoCompleteSearch'

export const FilterBar = (props) => {
  const {searchLabel = 'Search by name', handleSearch} = props

  return (
    <div className='w-full flex px-2'>
      <Grid container alignItems="center" spacing="10px">
        <Grid item xs={12} lg={3} md={4} gap="10px">
          <FormControl sx={{my: 1, width: '100%'}} variant="outlined">
            <OutlinedInput
              placeholder={searchLabel}
              onChange={e => handleSearch('name',e.target.value)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={3} md={4} gap="10px">
          <ConnectedComponent
            Component={AutoCompleteSearch}
            service="pet-types"
            label="Search By Type"
            className="!w-full"
            onChange={value => handleSearch('type', value?._id, false)}
          />
        </Grid>
        <Grid item xs={12} lg={3} md={4} gap="10px">
          <ConnectedComponent
            Component={AutoCompleteSearch}
            service="pet-subtypes"
            label="Search By Sub-Type"
            className="!w-full"
            onChange={value => handleSearch('subtype', value?._id, false)}
          />
        </Grid>
      </Grid>
    </div>
  )
}
