import {Button} from '@mui/material'
import {GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { auth } from '../../firebase/auth'
import { withFirebaseAuth } from './components/withFirebaseAuth'
import gmail from 'images/gamil.svg'

export const GoogleSignInButton = withFirebaseAuth((props) => {
  const { onSuccessfulAuth, title, ...otherProps } = props

  const onAuth = () => {
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider).then((result) => {
      onSuccessfulAuth()
    }).catch(console.error)
  }

  return (
    <Button onClick={onAuth} {...otherProps}>
      <img src={gmail} alt="Google Icon" />
      <span style={{marginLeft: '10px'}}>{title}</span>
    </Button>
  )
})
