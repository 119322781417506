import {DataTable} from '../../components/DataTable'
import {useState} from 'react'
import {DetailView} from './DetailView'
import {ConnectedComponent} from 'components/service/ConnectedComponent'

export const TributesList = props => {
  const [open, setOpen] = useState(false)
  const handleRowClick = value => {
    setOpen(!open)
  }
  return (
    <div>
      <ConnectedComponent
        Component={DataTable}
        service="reports"
        handleRowClick={handleRowClick}
        config={{query: {$populate: 'user', contentType: 'tributes'}}}
      />
      <DetailView open={open} onClose={() => setOpen(false)} />
    </div>
  )
}
