import PropTypes from 'prop-types'
import Card from '@mui/material/Card';
import { useEffect, useState } from 'react';

/**
 * Questionnaire
 *
 * @param {object} data - It is object of pet-finder detail. Which contain detail of questionnaire
 */

export const Questionnaire = props => {
  const {data} = props
  const [commandResponsive, setCommandResponsive] = useState(null)
  const [nameResponsive, setNameResponsive] = useState(null)
  const [favoriteTreat, setFavoriteTreat] = useState(null)

  useEffect(() => {
    if (data?.questionnaire?.length) {
      const responsiveToCommands = data.questionnaire.find(item => item.question === 'responsiveToCommands')
      if (responsiveToCommands) {
        responsiveToCommands['question'] = 'Is Responsive To Commands?'
        if (responsiveToCommands.answer === 'yes') {
          const commandForResponse = data.questionnaire.find(item => item.question === 'commandForResponse')
          responsiveToCommands['command'] = commandForResponse.answer
        }
        setCommandResponsive(responsiveToCommands)
      }
      const responsiveToName = data.questionnaire.find(item => item.question === 'responsiveToName')
      if (responsiveToName) {
        responsiveToName['question'] = 'Is Responsive To Name?'
        if (responsiveToName.answer === 'yes') {
          const nameToResponse = data.questionnaire.find(item => item.question === 'nameToResponse')
          responsiveToName['command'] = nameToResponse.answer
        }
        setNameResponsive(responsiveToName)
      }
      const favoriteTreatObj = data.questionnaire.find(item => item.question === 'favoriteTreat')
      if (favoriteTreatObj) {
        favoriteTreatObj['question'] = 'Favorite Treat(Food)'
        setFavoriteTreat(favoriteTreatObj)
      }
    }
  }, [data])

  return (
    <div className="md:max-w-[16rem] mt-8 mx-2 ">
      <p>
        <span className="font-bold">Pet Behaviors & Warnings as Described by Owner </span>
      </p>

      {commandResponsive &&
        <Card variant="outlined" className="!p-3 !my-2">
          <p className="text-sm">{commandResponsive?.question}</p>
          <p className="text-sm">
            <span className='font-bold'>{commandResponsive?.command ? `${commandResponsive?.answer} by, `: commandResponsive.answer}</span> {commandResponsive?.command ? `${commandResponsive.command}` : ''}
          </p>
        </Card>
      }

      {nameResponsive &&
        <Card variant="outlined" className="!p-3 !my-2">
          <p className="text-sm">{nameResponsive?.question}</p>
          <p className="text-sm">
            <span className='font-bold'>{nameResponsive?.command ? `${nameResponsive.answer} by, `: nameResponsive.answer}</span> {nameResponsive?.command ? `${nameResponsive.command}` : ''}
          </p>
        </Card>
      }

      {favoriteTreat &&
        <Card variant="outlined" className="!p-3 !my-2">
          <p className="text-sm">{favoriteTreat?.question}</p>
          <p className="text-sm">
            <span className='font-bold'>{favoriteTreat.answer}</span>
          </p>
        </Card>
      }
    </div>
  )
}

Questionnaire.prototype = {
  data: PropTypes.object
}
