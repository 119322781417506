import {
  OutlinedInput,
  InputLabel,
  InputAdornment,
  CircularProgress,
  FormControl,
  FormHelperText
} from '@mui/material'
import { find, throttle } from 'lodash';
import { useEffect, useState } from 'react'
import { storeSocketClient } from '../client/socket.client';

export const URLTextField = ({data, name, label, id}) => {
  const [value, setValue] = useState('')
  const [item, setItem] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/

  useEffect(() => {
    if (data?.length) {
      const item = find(data, i => i.name === name)
      if (item) {
        setItem(item)
        setValue(item.value)
      }
    }
  }, [data])

  const handleSuccess = (result) => {
    setItem(result)
    setLoading(false)
  }

  const handleError = (e) => {
    setLoading(false)
  }

  const callApi = (newValue) => {
    if (loading) {
      return
    }

    setLoading(true)
    const payload = {name, value: newValue}
    const service = storeSocketClient.service('settings')

    item
      ? service.patch(item._id, payload).then(handleSuccess).catch(handleError)
      : service.create(payload).then(handleSuccess).catch(handleError)

  }
  const throttledUpdateApi = throttle(callApi, 5000);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);

    setError(urlRegex.test(newValue) || !newValue ? false : true)

    if (!error) throttledUpdateApi(newValue);
  };

  return <FormControl className='!my-3' size="small" variant="outlined" fullWidth>
    <InputLabel htmlFor="description">{label}</InputLabel>
    <OutlinedInput
      className="w-full"
      id={id}
      name={name}
      label={label}
      value={value}
      onChange={handleChange}
      error={error}
      endAdornment={
        <InputAdornment position="end">
          {loading &&
            <CircularProgress color="primary" size={20} />
          }
        </InputAdornment>
      }
      fullWidth
    />
    {error  && <FormHelperText id="component-error-text" style={{color: '#d32f2f'}}>Invalid url</FormHelperText>}
  </FormControl>
}
