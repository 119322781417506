import {Box, Button, Typography} from '@mui/material'
import {AppLayout} from 'modules/layout/AppLayout'
import {useEffect, useState} from 'react'
// import DataTable from "react-data-table-component"
import {AddEditModal} from './modals/AddEditModal'
import tableicon from 'images/newimages/tableicon.svg'
import {Link} from 'react-router-dom'
import {DataTable} from './components/DataTable'
import {DeleteModal} from './modals/DeleteModal'
import {BrowseLayout} from 'components/layouts/BrowseLayout'
import {connectService} from 'components/service/connectService'

export const IntroSlides = connectService(
  props => {
    const [open, setOpen] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [refreshTable, setRefreshTable] = useState(false)

    const handleModal = (data = null) => {
      if (data) setRefreshTable(true)
      setSelectedItem(null)
      setOpen(!open)
    }

    const handleOnEdit = item => {
      setSelectedItem(item)
      setOpen(!open)
    }

    const handleDeleteModal = (data = null) => {
      if (data) setRefreshTable(true)
      setSelectedItem(null)
      setOpenDeleteModal(!openDeleteModal)
    }

    const handleOnDelete = item => {
      setSelectedItem(item)
      setOpenDeleteModal(!openDeleteModal)
    }

    useEffect(() => {
      if (!open) {
        setSelectedItem(null)
      }
    }, [open])

    return (
      <AppLayout heading="Intro Slides Configuration">
        <BrowseLayout title="Intro Slides" onAdd={() => setOpen(!open)} {...props}>
          <DataTable
            onEdit={handleOnEdit}
            onDelete={handleOnDelete}
            isRefreshTable={refreshTable}
            setRefreshTable={setRefreshTable}
            {...props}
          />
        </BrowseLayout>
        {open && <AddEditModal open={open} setOpen={setOpen} handleModal={handleModal} id={selectedItem} />}
        {openDeleteModal && (
          <DeleteModal
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            handleModal={handleDeleteModal}
            id={selectedItem}
          />
        )}
      </AppLayout>
    )
  },
  'intro-slides',
  {
    searchProperty: 'title',
    query: {$populate: 'media'}
  }
)
