import React, { useEffect } from 'react'
import { ConnectedComponent } from 'components/service/ConnectedComponent'
import { storeSocketClient } from './client/socket.client'
import { Shops } from './shops/Shops'

export const ShopDashBoard = props => {
  const { setNavigationTitle } = props
  useEffect(() => {
    setNavigationTitle('Shops')
  }, [])

  return (
    <ConnectedComponent
      Component={Shops}
      service="store"
      client={storeSocketClient}
      config={{
        query: {
          $populate: ['vendor', 'icon']
        }
      }}
    />
  )
}
