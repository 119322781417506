import {RightDrawerLayout} from 'components/layouts/RightDrawerLayout'
import {PostComment} from '../../components/PostComment'
import {Button} from '@mui/material'
import TourIcon from '@mui/icons-material/Tour'

export const DetailView = props => {
  const {open, onClose, data} = props
  return (
    <RightDrawerLayout open={open} onClose={onClose} title="Comments">
      <div className="flex flex-col justify-between lg:h-full">
        <PostComment data={data} className="my-8 rounded-xl p-4" style={{border: '1px solid #E0E0E0'}} />
        <div className="w-full mt-4 flex flex-col">
          <div>
            <Button variant="contained">Looks Safe</Button>
            <Button variant="outlined" className="!mx-4">
              <TourIcon />
            </Button>
          </div>
        </div>
      </div>
    </RightDrawerLayout>
  )
}
