import React, {useEffect, useRef, useState} from 'react'
import {join, map, range} from 'lodash'
import {NumberTextField} from 'components/NumberTextField'

export const OTPInput = props => {
  const {onChange = () => {}, className = '', length = 6, clearOTP, setClearOTP} = props
  const [otp, setOtp] = useState(Array(length).fill(''))
  const inputRefs = useRef([])

  useEffect(() => {
    if (clearOTP) {
      setOtp(Array(length).fill(''))
      setClearOTP(false)
    }
  }, [clearOTP])

  const handleInputChange = (value, index) => {
    if (!isNaN(value)) {
      if (value.length <= 1) {
        setOtp([...otp.map((d, idx) => (idx === index ? value : d))])
      }

      if (index < length - 1 && value !== '') {
        inputRefs.current[index + 1].focus()
      }
    }
  }

  const handleInputKeyDown = (event, index) => {
    if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
      setOtp([...otp.map((d, idx) => (idx === index - 1 ? '' : d))])
      inputRefs.current[index - 1].focus()
    }
  }

  const handleInputClick = index => {
    inputRefs.current[index].focus()
  }

  useEffect(() => {
    onChange(join(otp, ''))
  }, [otp])

  return (
    <div>
      <div className={`w-full flex ${className}`}>
        {map(range(6), index => (
          <NumberTextField
            key={`otp-input-${index}`}
            inputRef={el => (inputRefs.current[index] = el)}
            className="flex-1"
            style={{margin: '0 5px', textAlign: 'center'}}
            type="number"
            value={otp[index] || ''}
            placeholder="0"
            inputProps={{
              maxLength: 1,
              style: {textAlign: 'center'},
              inputMode: 'numeric'
            }}
            onChange={e => handleInputChange(e.target.value, index)}
            onKeyDown={e => handleInputKeyDown(e, index)}
            onClick={() => handleInputClick(index)}
          />
        ))}
      </div>
    </div>
  )
}
