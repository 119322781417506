import { Box, Card, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useEffect } from "react"
import { types, servingSize, mealTime } from "../Constants"
import { AutoCompleteSearch } from "./AutoCompleteSearch"
import { ConnectedComponent } from "components/service/ConnectedComponent"
import { NumberTextField } from "components/NumberTextField"
import { Add } from "@mui/icons-material"
import DeleteIcon from '@mui/icons-material/Delete';

const foodObj = {
  type: '',
  note: '',
  brand: '',
  food: '',
  servingSize: [],
  servingQuantity: 0,
  mealTime: [],
  typeError: false,
  brandError: false,
  foodError: false,
}

const DietItem = (props) => {
  const {item, index, handleChange, handleRemove} = props

  return (
    <Card sx={{py: 1, my:1}} className="lg:-mr-64">
      <Box textAlign="right" width="100%" px={1} mb={1}>
        <DeleteIcon 
          sx={{cursor: 'pointer'}}
          onClick={() => handleRemove(index)}
        />
      </Box>
      <div className='flex flex-col mb-2 px-4'>
        <div className='flex flex-col md:flex-row'>
          <div className='w-full md:w-1/2'>
            <FormControl size="small" error={item?.typeError} fullWidth>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={item?.type || ''}
                label="Type"
                onChange={(e) => {
                  handleChange(index, 'type', e.target.value )
                  handleChange(index, 'typeError', false)
                }}
              >
                {
                  types.map((item, index) => (
                    <MenuItem value={item} key={index}>{item}</MenuItem>
                  ))
                }
              </Select>
              {item?.typeError && <FormHelperText error={true}>Please select type</FormHelperText>}
            </FormControl>
          </div>

          <ConnectedComponent
            label="Food"
            Component={AutoCompleteSearch}
            service="pet-food"
            className="w-full md:w-1/2 !my-4 md:!my-0 md:ml-4"
            size="small"
            id={item?.food || ''}
            onChange={value => {
              handleChange(index, 'food', value ? value?._id : null )
              handleChange(index, 'foodError', false)
            }}
            helperText={item?.foodError && "Please select food"}
            error={item?.foodError}
          />

          <ConnectedComponent
            label="Brand"
            Component={AutoCompleteSearch}
            service="food-brands"
            size="small"
            id={item?.brand || ''}
            className="w-full md:w-1/2 md:ml-4"
            onChange={value => {
              handleChange(index, 'brand', value ? value?._id : null )
              handleChange(index, 'brandError', false)
            }}
            helperText={item?.brandError && "Please select brand"}
            error={item?.brandError}
          />

         <NumberTextField
            type="number"
            label="Serving Quantity"
            variant="outlined"
            className={`w-full md:w-1/2 md:!ml-4 !my-4 md:!my-0`}
            value={item?.servingQuantity || ''}
            onInput={event => {
              event.target.value = event.target.value.replace(/^-/, '')
            }}
            inputMode="numeric"
            onChange={(e) => handleChange(index, 'servingQuantity', e.target.value ? Number(e.target.value) : 0)}
            size="small"
          />

        </div>
        <div className='flex flex-col md:flex-row md:justify-between md:mt-4'>
          <div className='w-full md:w-1/2 md:mr-2'>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">Serving Size</InputLabel>
              <Select
                value={item?.servingSize || []}
                label="Serving Size"
                onChange={(e) => handleChange(index, 'servingSize', e.target.value )}
                multiple
              >{servingSize.map((item, index) => (
                  <MenuItem value={item} key={index}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className='w-full md:w-1/2 md:ml-2 mt-4 md:mt-0'>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">Meal Time</InputLabel>
              <Select
                value={item?.mealTime || ''}
                label="Meal Time"
                onChange={(e) => handleChange(index, 'mealTime', e.target.value)}
                multiple
              >{mealTime.map((item, index) => (
                  <MenuItem value={item} key={index}>{item}</MenuItem>
                ))
              }</Select>
            </FormControl>
          </div>
        </div>
        <TextField 
          placeholder="Note" 
          value={item?.note || ''}
          onChange={(e) => handleChange(index, 'note', e.target.value)}
          label="Note" 
          variant="outlined" 
          className="!mt-4" 
          size="small"/>
      </div>
    </Card>
  )
}
const PetDietFieldsCard = (props) => {
  const {dietFood, onChange} = props

  const addFood = () => {
    onChange([...dietFood, foodObj])
  }

  const handleChange = (_index, property, value) => {
    const _food = dietFood.map((item, index) => {
      if (index !== _index) return item
      item[property] = value
      return item
    })
    onChange([..._food])
  }

  const handleRemove = (_index) => {
    const _food = [...dietFood]
    _food.splice(_index, 1)
    onChange([..._food])
  }

  return (
    <>
      <Box display="flex" alignItems="center" >
        <Typography variant="h5" fontSize="18px">Food</Typography> 
        <IconButton onClick={addFood}>
          <Add color="primary" />
        </IconButton>
      </Box>
      {dietFood.map((item, index) => (
        <DietItem 
          key={index} 
          item={item} 
          index={index} 
          handleChange={handleChange} 
          handleRemove={() => handleRemove(index)} 
        />
      ))}
    </>
  )
}

export default PetDietFieldsCard