import React from 'react'
import { motion } from 'framer-motion';
import scaner from 'images/newimages/ss.png';
import iconname from 'images/name-icon.svg';
import { Grid, Box, Typography, Button, TextField, MenuItem, Modal, Stack, Avatar, Divider, FormControl, InputLabel, Select } from '@mui/material';
import avatar2 from 'images/avtarimg.png';
import cross from 'images/crosss.svg';
import location from 'images/newimages/location.svg';

export const AddDietModal = ({open, handleModal, age, handleChange}) => {
  return (
    <Modal
      open={open}
      onClose={handleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <motion.div
        initial={{ translateX: "100%" }}
        animate={{ translateX: 0 }}
        exit={{ translateX: "100%" }}
        transition={{ duration: 0.3 }}
      >
        <Box
          height="100vh"
          maxWidth="lg"
          sx={{
            marginLeft: "auto",
            background: "#fff",
            overflowY: "scroll",
          }}
        >
          <Box textAlign="right" width="100%">
            <Button
              onClick={handleModal}
              sx={{ position: "absolute", right: "0" }}
            >
              <img src={cross} />
            </Button>
          </Box>
          <Grid
            container
            columnGap={2}
            p={3}
            pt={5}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ boxShadow: 2 }}
          >
            <Grid item md={4} sm={12} xs={12}>
              <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
              >
                <Grid item md={3}>
                  <Stack direction="row" spacing={2}>
                      <Avatar
                          alt="Remy Sharp"
                          src={avatar2}
                          sx={{ width: 100, height: 100 }}
                          variant="rounded"
                      />
                  </Stack>
                </Grid>
                <Grid item md={9} pl={3}>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Pet Name:{" "}
                    <Typography
                      component="span"
                      sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "primary.main",
                      }}
                    >
                      Fluffy956
                    </Typography>
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Age:{" "}
                    <Typography
                      component="span"
                      sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "primary.main",
                      }}
                    >
                      2 ys 3 m
                    </Typography>
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Size:{" "}
                    <Typography
                      component="span"
                      sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "primary.main",
                      }}
                    >
                      Medium High
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
              xs={12}
              textAlign="left"
              sx={{ pt: { xs: 2, sm: 2, md: 0 } }}
            >
              <Grid container>
                <Box>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: "15px", fontWeight: "600" }}
                  >
                    Status:{" "}
                    <Typography
                        component="span"
                        sx={{
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "primary.main",
                        }}
                    >
                      Open to Breed
                    </Typography>
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: "15px", fontWeight: "600" }}
                  >
                    Tag #:{" "}
                    <Typography
                      component="span"
                      sx={{
                          fontSize: "15px",
                          fontWeight: "600",
                          color: "primary.main",
                      }}
                    >
                      4523
                    </Typography>
                  </Typography>
                </Box>
                <Box ml={1}>
                  <img src={scaner} />
                </Box>
              </Grid>
            </Grid>
            <Grid
                item
                md={3}
                sm={12}
                xs={12}
                sx={{
                    textAlign: { xs: "left", sm: "left", md: "right" },
                    pr: { xs: 0, sm: 0, md: 5 },
                    pt: { xs: 1, sm: 1, md: 0 },
                }}
            >
                <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: "15px", fontWeight: "600" }}
                >
                    Mariana Janeson
                </Typography>
                <Box
                    display="flex"
                    sx={{
                        justifyContent: {
                            xs: "flex-start",
                            sm: "flex-start",
                            md: "flex-end",
                        },
                    }}
                >
                    <Typography
                        variant="h6"
                        component="h6"
                        mr={1}
                        sx={{
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "primary.main",
                        }}
                    >
                        Pet Owner, Verified
                    </Typography>
                    <img src={iconname} />
                </Box>
                <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "primary.main",
                    }}
                >
                    PRIVACY ENABLED
                </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={5} xs={12} p={3}>
              <Typography
                variant="h5"
                component="h5"
                mb={2}
                sx={{ fontWeight: "600" }}
              >
                Pet Profile
              </Typography>
              <Box
                borderRadius="7px"
                border="1px solid"
                borderColor="border.main"
                bgcolor="offWhite.main"
                height="100%"
              >
                <Box p={2.5}>
                  <Typography
                    variant="p"
                    component="p"
                    textAlign="center"
                    sx={{ fontSize: "12px" }}
                  >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nulla eleifend, tellus vel vestibulum varius, dolor
                      ligula euismod erat, nec tincidunt nibh orci sit amet
                      dui. In libero mauris, ullamcorper nec sem
                  </Typography>
                  <Box my={3} textAlign="center">
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                      >
                          Promote
                      </Button>
                  </Box>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item md={6} textAlign="center">
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{ fontWeight: "600" }}
                      >
                          Followers
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{ fontWeight: "600" }}
                      >
                          12K
                      </Typography>
                    </Grid>
                    <Grid item md={6} textAlign="center">
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{ fontWeight: "600" }}
                      >
                          Followers
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{ fontWeight: "600" }}
                      >
                          12K
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                    bgcolor="primary.main"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                >
                    <Typography
                        variant="h6"
                        component="h6"
                        color="transparent.main"
                        sx={{ fontSize: "16px", fontWeight: "600" }}
                    >
                        Badges Earned - 100
                    </Typography>
                    <Typography
                        variant="h6"
                        component="h6"
                        color="transparent.main"
                        sx={{ fontSize: "16px", fontWeight: "600" }}
                    >
                        View All
                    </Typography>
                </Box>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    p={1.5}
                >
                    <Grid item md={6}>
                        <Typography
                            variant="h6"
                            component="h6"
                            sx={{ fontSize: "16px", fontWeight: "600" }}
                        >
                            Pet Birth Date
                        </Typography>
                        <Typography
                            variant="h6"
                            component="h6"
                            color="grey2.main"
                            sx={{ fontSize: "12px" }}
                        >
                            March 16th 2023
                        </Typography>
                    </Grid>
                    <Grid item md={6}>
                        <Box display="flex" alignItems="center">
                            <Typography
                                variant="h6"
                                component="h6"
                                sx={{ fontSize: "16px", fontWeight: "600" }}
                            >
                                Pet Location
                            </Typography>
                            <Box display="flex" alignItems="center" ml={1}>
                                <img src={location} />
                                <Typography
                                    variant="h6"
                                    component="h6"
                                    color="green.main"
                                    ml={0.5}
                                    sx={{ fontSize: "12px" }}
                                >
                                    10 Miles
                                </Typography>
                            </Box>
                        </Box>
                        <Typography
                            variant="h6"
                            component="h6"
                            color="grey2.main"
                            sx={{ fontSize: "12px" }}
                        >
                            Chicago, IL United State
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ width: "100%" }}>
                    <Divider />
                </Box>
                <Box p={2} bgcolor="transparent.main">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            Pet interests
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            label="Pet interests"
                            onChange={handleChange}
                        >
                            <MenuItem value={10}>Pet interests</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
              </Box>
            </Grid>
            <Grid
                item
                md={7}
                xs={12}
                pb={3}
                pt={4}
                px={3}
                sx={{ mt: { md: 0, xs: 5, sm: 5 } }}
                bgcolor="modalBg.main"
            >
                <Box mb={2} fullWidth>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={3}
                        pb={2}
                        sx={{
                            borderBottom: "2px solid",
                            borderColor: "border.main",
                        }}
                    >
                        <Typography
                            variant="h6"
                            component="h6"
                            sx={{ fontWeight: "500" }}
                        >
                            {" "}
                            Add Diet
                        </Typography>
                    </Box>
                    <Box>
                        <Box mb={2}>
                            <TextField
                                label="Enter Pet Food"
                                placeholder="Enter Pet Food"
                                fullWidth
                            />
                        </Box>
                        <Box mb={2}>
                            <TextField
                                label="Enter Brand"
                                placeholder="Enter Brand"
                                fullWidth
                            />
                        </Box>
                        <Box mb={2}>
                            <TextField
                                label="Enter Brand Product"
                                placeholder="Enter Brand Product"
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                    >
                        <Typography
                            variant="h6"
                            component="h6"
                            sx={{ fontWeight: "500" }}
                        >
                            {" "}
                            Add detail for food
                        </Typography>
                    </Box>
                    <Box mb={2}>
                        <TextField
                            label="Enter Food Type"
                            placeholder="Enter Food Type"
                            fullWidth
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            label="Enter Food Type"
                            placeholder="Enter Food Type"
                            fullWidth
                        />
                    </Box>
                </Box>
                <Button variant="contained" color="primary" size="large" sx={{ padding: '10px 40px' }}>
                    Add Diet
                </Button>
            </Grid>
          </Grid>
        </Box>
      </motion.div>
    </Modal>
  )
}