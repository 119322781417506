import {InputAdornment, TextField} from '@mui/material'
import PetsIcon from '@mui/icons-material/Pets'
import PersonIcon from '@mui/icons-material/Person'
import InfoIcon from '@mui/icons-material/Info'
import RedeemIcon from '@mui/icons-material/Redeem'
import moment from 'moment'
import {Questionnaire} from './Questionnaire'
import { ImagePreview } from 'components/ImagePreview'
import HyperLink from '@mui/material/Link';
import { Link } from "react-router-dom";

export const BasicDetails = props => {
  const {data} = props

  const getPetProfile = (report) => {
    if (report?.media?.length) {
      return report.media[0]
    }

    if (report?.pet?.profile) {
      return report?.pet?.profile
    }

    return null
  }

  return (
    <div className="flex flex-col">
      {getPetProfile(data) &&
        <ImagePreview media={getPetProfile(data)} className="w-[20ch] h-[20ch] object-cover rounded-xl mb-4 mx-2" />
      }
      { data?.finder &&
        <div className="mx-2  flex text-md">
          <HyperLink href={data?.finder} color="primary" target="_blank">Lost Pet Report</HyperLink>
        </div>
      }
      { data?.user &&
        <div className="mx-2 my-2 flex text-md underline" style={{color: '#ED1690'}}>
          <Link to={`/user/${data?.user?._id}`} target="_blank">Owner Profile</Link>
        </div>
      }
      <TextField
        label="Pet Name"
        sx={{m: 1, width: '25ch'}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PetsIcon fontSize="14px" />
            </InputAdornment>
          )
        }}
        value={data?.pet?.name || ''}
        disabled
        fullWidth
      />

      <TextField
        label="Owner"
        className="!mt-4"
        sx={{m: 1, width: '25ch'}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PersonIcon fontSize="14px" />
            </InputAdornment>
          )
        }}
        value={`${data?.user?.firstName ? data.user.firstName : ''} ${data?.user?.lastName ? data.user.lastName : ''}` || ''}
        disabled
        fullWidth
      />

      <TextField
        label="Co-Owner"
        className="!mt-4"
        sx={{m: 1, width: '25ch'}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PersonIcon fontSize="14px" />
            </InputAdornment>
          )
        }}
        value={`${data?.coOwner?.firstName ? data.coOwner.firstName : ''} ${data?.coOwner?.lastName ? data.coOwner.lastName : ''}` || ''}
        disabled
        fullWidth
      />

      <TextField
        label="Description"
        className="!mt-4"
        sx={{m: 1, width: '25ch'}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <InfoIcon fontSize="14px" />
            </InputAdornment>
          )
        }}
        multiline
        value={data?.description || ''}
        disabled
        fullWidth
      />


        <TextField
          label="Reward Amount"
          className="!mt-4"
          sx={{m: 1, width: '25ch'}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <RedeemIcon fontSize="14px" />
              </InputAdornment>
            )
          }}
          value={`$${data?.rewardAmount ? Number(data?.rewardAmount).toFixed(2) : '0'}` || ''}
          disabled
          fullWidth
        />


      {(data?.claimedBy?.firstName || data?.claimedBy?.lastName) &&
        <TextField
          label="Claimed By"
          className="!mt-4"
          sx={{m: 1, width: '25ch'}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PersonIcon fontSize="14px" />
              </InputAdornment>
            )
          }}
          value={`${data?.claimedBy?.firstName} ${data?.claimedBy?.lastName}` || ''}
          disabled
          fullWidth
        />
      }

      {data?.questionnaire && data?.questionnaire?.length > 0 && <Questionnaire data={data} />}

      {data?.updatedAt && (
        <div className="mt-4 ml-2 w-full flex flex-col justify-center items-start">
          <span className="font-bold text-base">Last Update Activity</span>
          <p className="text-sm text-slate-600">{moment.utc(data.updatedAt).format('MMMM Do YYYY, LT')}</p>
        </div>
      )}
    </div>
  )
}
