import * as React from 'react'
import {useState} from 'react'
import {styled, useTheme} from '@mui/material/styles'
import {ThemeProvider} from '@mui/material/styles'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Header from 'components/header'
import Sidebar from 'components/sidebar'

import {DashboardContextProvider} from 'modules/dashboard/DashboardContextProvider'
import {SearchBarProvider} from 'components/search-bar/SearchBarProvider'

const drawerWidth = 240

const Main = styled('main', {shouldForwardProp: prop => prop !== 'open'})(({theme, open}) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  })
}))

const Spacer = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

export const AppLayout = props => {
  const theme = useTheme()
  const [open, setOpen] = useState(true)
  const handleDrawerOpen = () => {
    setOpen(!open)
  }

  return (
    <DashboardContextProvider>
      <ThemeProvider theme={theme}>
        <Box sx={{display: 'flex'}}>
          <CssBaseline />

          <Header handleDrawerOpen={handleDrawerOpen} open={open} heading={props?.heading} />

          <Sidebar open={open} />

          <Main open={open} sx={{backgroundColor: '#f7f7f7', height: '100vh'}}>
            <Spacer />
            {props.children}
          </Main>
        </Box>
      </ThemeProvider>
    </DashboardContextProvider>
  )
}
