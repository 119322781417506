import {Close} from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  TextField,
  Typography
} from '@mui/material'
import {NumberTextField} from 'components/NumberTextField'
import {useFormik} from 'formik'
import {isEmpty, keys, pick} from 'lodash'
import {storeSocketClient} from 'modules/store/client/socket.client'
import {UserAutoCompleteSearch} from 'modules/store/products/components/UserAutoCompleteSearch'
import {forwardRef, useContext, useEffect, useImperativeHandle, useState} from 'react'
import * as yup from 'yup'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {MediaUploader} from '../component/MediaUploader'
import {StoreMediaUploader} from 'components/StoreMediaUploader'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,

  width: 600,

  '@media (max-width:767px)': {
    width: '95%'
  }
}

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  vendor: yup.string().required('Please select a vendor'),
  supportContact: yup.string().email('Please enter valid email')
})

const initialValues = {
  name: '',
  address: '',
  phone: '',
  supportContact: '',
  // icon: '',
  vendor: ''
}

export const AddEditModal = forwardRef((props, ref) => {
  const {onAddEdit} = props
  const [open, setOpen] = useState(false)
  const {setSeverity, showToast} = useContext(SnackbarContext)
  const [media, setMedia] = useState(null)
  const [id, setId] = useState(null)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [vendorError, setVendorError] = useState(false)
  const [approve, setApprove] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setData(null)
    setId(null)
    setVendorError(false)
    setOpen(false)
    setMedia(null)
    formik.resetForm({values: initialValues})
  }
  const service = storeSocketClient.service('store')
  useImperativeHandle(
    ref,
    () => ({
      show(id = null) {
        setId(id)
        handleOpen()
      }
    }),
    []
  )

  useEffect(() => {
    if (id) {
      setLoading(true)
      service
        .get(id, {query: {$populate: ['icon']}})
        .then(result => {
          setData(result)
          formik.resetForm({values: {...pick(result, keys(initialValues))}})
          setLoading(false)
          setMedia(result.icon)
        })
        .catch(error => {
          console.log(error)
          setLoading(false)
        })
    }
  }, [id])

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async values => {
      if (isEmpty(values?.vendor)) {
        setVendorError(true)
        return
      }

      if (media) {
        values['icon'] = media?._id
      }

      id
        ? service
            .patch(id, {...values, approved: approve})
            .then(result => {
              handleClose()
              onAddEdit()
            })
            .catch(console.error)
        : service
            .create(values)
            .then(result => {
              handleClose()
              onAddEdit()
            })
            .catch(e => {
              setSeverity('error')
              showToast(e.message)
            })
    }
  })

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box sx={{p: {lg: 5, md: 5, sm: 4, xs: 4}}}>
            <Box display="flex" alignItems="center" justifyContent="space-between" pb={2.5}>
              <Typography
                variant="h5"
                component="h5"
                color="transparent.contrastText"
                sx={{fontSize: '24px', fontWeight: '600'}}
                style={{width: '100%'}}
              >
                {id ? 'Edit Shop Detail' : 'Create Shop Detail'}
              </Typography>
              <Box textAlign="right" width="100%">
                <IconButton onClick={handleClose}>
                  <Close onClick={handleClose} />
                </IconButton>
              </Box>
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex my-2 ">
                <TextField
                  id="standard-basic"
                  label="Name"
                  variant="outlined"
                  {...formik.getFieldProps('name')}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  className="w-1/2 !mr-1"
                />
                <div className="w-1/2 flex !ml-1">
                  <UserAutoCompleteSearch
                    id={data?.vendor}
                    service="user"
                    label="Search vendor"
                    client={storeSocketClient}
                    onChange={id => {
                      formik.setFieldValue('vendor', id)
                      if (id) {
                        setVendorError(false)
                      }
                    }}
                    error={vendorError}
                    helperText="Please Select a vendor"
                  />
                </div>
              </div>

              <div className="flex mt-4 mb-2">
                <NumberTextField
                  id="standard-basic"
                  label="Phone"
                  type="number"
                  variant="outlined"
                  value={formik.values.phone}
                  onChange={e => formik.setFieldValue('phone', e.target.value.toString())}
                  className="w-1/2 !mr-1"
                />
                <TextField
                  id="standard-basic"
                  label="Support Contact"
                  variant="outlined"
                  {...formik.getFieldProps('supportContact')}
                  className="w-1/2 !ml-1"
                  error={formik.touched.supportContact && Boolean(formik.errors.supportContact)}
                  helperText={formik.touched.supportContact && formik.errors.supportContact}
                />
              </div>

              <TextField
                id="standard-basic"
                label="Address"
                variant="outlined"
                {...formik.getFieldProps('address')}
                className="!my-2"
                fullWidth
              />
              <div className="my-4">
                <div className="flex justify-between items-center">
                  <StoreMediaUploader title="Upload Image" media={media} setMedia={setMedia} />

                  {id && (
                    <FormControlLabel
                      control={<Checkbox checked={approve} onChange={e => setApprove(e.target.checked)} />}
                      label="Approved"
                    />
                  )}
                </div>
              </div>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  disabled={loading}
                >
                  {id ? 'Edit' : 'Submit'}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  )
})
