import * as React from 'react'
import {alpha, styled} from '@mui/material/styles'
import TreeView from '@mui/lab/TreeView'
import TreeItem, {treeItemClasses} from '@mui/lab/TreeItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
const StyledTreeItem = styled(props => <TreeItem {...props} />)(({theme}) => ({
  fontSize: '12px',
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3
    }
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
  }
}))

const Label = props => {
  return <span style={{fontSize: '0.875rem'}}>{props.children}</span>
}
export const SimpleComment = props => {
  const {className = '', style = {}, content} = props
  return (
    <div className={className} style={style}>
      <p className="font-bold my-2">Comments</p>
      <TreeView
        aria-label="customized"
        defaultExpanded={['1']}
        defaultCollapseIcon={<KeyboardArrowUpIcon />}
        defaultExpandIcon={<KeyboardArrowDownIcon />}
        // defaultEndIcon={<HorizontalRuleIcon />}
        sx={{flexGrow: 1, maxWidth: '100%', overflowY: 'auto', backgroundColor: 'white'}}
      >
        <StyledTreeItem nodeId="1" label={<Label>{content?.comment}</Label>}></StyledTreeItem>
      </TreeView>
    </div>
  )
}
