import {Routes, Route} from 'react-router-dom'
import {PetTypes} from './pet-types'
import {PetList} from './pet-list'
import {PetInterests} from './admin-pet-interest'
import {PetsDashboard} from './dashboard'
import {PetSubTypes} from './pet-subtypes'
import {PetActivities} from './pet-activites'
import {PetSensitivities} from './pet-sensitivities'
import {WellnessTypes} from './wellness-types'
import {VaccineTypes} from './vaccine-types'
import {EditPetDetail} from './pet-list/components/EditPetDetail'
import {CreatePetProfile} from './pet-list/components/CreatePetProfile'

export const Pets = () => {
  return (
    <Routes>
      <Route path="/:id" element={<EditPetDetail />} />
      <Route path="/create/:code" element={<CreatePetProfile />} />
      <Route path="/types" element={<PetTypes />} />
      <Route path="/sub-types" element={<PetSubTypes />} />
      <Route path="/list" element={<PetList />} />
      <Route path="/interests" element={<PetInterests />} />
      <Route path="/dashboard" element={<PetsDashboard />} />
      <Route path="/activities" element={<PetActivities />} />
      <Route path="/sensitivities" element={<PetSensitivities />} />
      <Route path="/wellness-types" element={<WellnessTypes />} />
      <Route path="/vaccine-types" element={<VaccineTypes />} />
    </Routes>
  )
}
