import {useState} from 'react'
import {AUTHENTICATION_KEY} from 'client/common'
import {AUTHENTICATION_KEY as STORE_AUTH_KEY} from 'modules/store/client/common'
import axios from 'axios'

export const useMediaUpload = () => {
  const [loading, setLoading] = useState(false)

  const headers = {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
    Authorization: window.localStorage.getItem(AUTHENTICATION_KEY)
  }

  const storeHeaders = {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
    Authorization: window.localStorage.getItem(STORE_AUTH_KEY)
  }

  const uploadMedia = (file, path = null, isStore = false) => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      const formData = new FormData()
      formData.append('files', file)

      if (path) formData.append('path', path)

      axios
        .post(
          `${isStore ? process.env.REACT_APP_STORE_MODULE_BASE_URL : process.env.REACT_APP_BASE_URL}/media`,
          formData,
          {headers}
        )
        .then(result => resolve(result.data))
        .catch(e => {
          reject(e)
        })
        .then(() => setLoading(false))
    })
  }

  const uploadStoreMedia = file => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      const formData = new FormData()
      formData.append('files', file)

      axios
        .post(`${process.env.REACT_APP_STORE_MODULE_BASE_URL}/media`, formData, {headers: storeHeaders})
        .then(result => resolve(result.data))
        .catch(e => {
          reject(e)
        })
        .then(() => setLoading(false))
    })
  }

  return {loading, uploadMedia, uploadStoreMedia}
}
