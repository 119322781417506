import {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {socketClient as feathers} from 'client/socket.client'
import {CircularProgress, TablePagination} from '@mui/material'
import {isEmpty, isObject, omit} from 'lodash'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import PropTypes from 'prop-types'

/**
 * A Connected Component with service
 *
 * @component
 * @example
 * const MyComponent = (props) => {
 *  return <p>My component</p>
 * }
 * <ConnectedComponent Component={MyComponent} service="service-name" id="object-id" config={query: {...options}}/>
 *
 * @param {elementType} Component - The component props and it is required.
 * @param {string} service - The service is name of service to make api call and it is required.
 * @param {string} id - The id prop is optional. It is used to fetch perticular object only by id.
 * @param {object} config - The config prop is optional. It query param or options for service call.
 */

const configPrototype = {
  query: {}
}
export const Loader = () => {
  return <CircularProgress />
}

export const ConnectedComponent = props => {
  const {id, service: connectToService, client = false, config = configPrototype, defaultPerPage} = props
  const [searchParams, setSearchParams] = useState({})
  const {setSeverity, showToast} = useContext(SnackbarContext)
  const [additionalFilters, setAdditionalFilters] = useState({})

  const clearSearch = useCallback(() => {
    setSearchParams({})
  }, [])

  const [page, setPage] = useState(1)
  const [skip, setSkip] = useState(0)
  const [total, setTotal] = useState(0)

  const [data, setData] = useState(null)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const rowsPerPageOptions = [5, 10, 15, 20, 25, 50, 100]
  const [limit, setLimit] = useState(defaultPerPage ? defaultPerPage : rowsPerPageOptions[1])

  const service = useMemo(() => {
    return client ? client.service(connectToService) : feathers.service(connectToService)
  }, [client, connectToService])

  const handleSearch = (property, value, isRegex = true) => {
    if (!isObject(property) && isEmpty(value)) {
      const newSearchParams = omit(searchParams, property)
      setSearchParams(newSearchParams)
      return
    }

    if (isRegex) {
      // if (isObject(property)) {
      //   const search = []
      //   forEach(property, (value, key) => {
      //     search.push({[key]: {$regex: value, $options: 'i'}})
      //   })
      //   setSearchParams({...searchParams, $or: search})
      //   return
      // }
      const search = {
        [property]: {
          $regex: value,
          $options: 'i'
        }
      }
      setSearchParams({...searchParams, ...search})
      return
    }
    setSearchParams({...searchParams, [property]: value})
  }

  const find = async query => {
    console.log('Trying to load products')
    try {
      setLoading(true)

      let _query = {
        ...query,
        ...config.query,
        ...searchParams,
        ...additionalFilters
      }

      const result = await service.find({
        query: _query
      })

      setList(result.data)
      setTotal(result.total)
      setLoading(false)
    } catch (error) {
      handleToast(error, 'error')
      setLoading(false)
    }
  }

  const handleToast = (e, status) => {
    setSeverity(status)
    showToast(e?.message ? e.message : 'Something Went wrong')
  }

  const get = async id => {
    try {
      setLoading(true)

      const result = config.query ? await service.get(id, {query: {...config.query}}) : await service.get(id)
      setData(result)
      setLoading(false)
    } catch (error) {
      setData(null)
      handleToast(error, 'error')
      setLoading(false)
    }
  }

  const create = async (data, validator) => {
    try {
      setLoading(true)
      const result = await service.create(data)
      handleToast({message: 'Data created successfully'}, 'success')
      setData({})
      setLoading(false)
      return result
    } catch (error) {
      handleToast(error, 'error')
      setLoading(false)
    }
  }

  const patch = async (id, data, validator) => {
    try {
      setLoading(true)
      const result = await service.patch(id, data)
      handleToast({message: 'Data updated successfully'}, 'success')
      setData({})
      setLoading(false)
      return result
    } catch (error) {
      handleToast(error, 'error')
      setLoading(false)
    }
  }

  const remove = async id => {
    try {
      setLoading(true)
      const result = await service.remove(id)
      handleToast({message: 'Data removed successfully'}, 'success')
      setData({})
      setLoading(false)
      return result
    } catch (error) {
      handleToast(error, 'error')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      get(id)
    } else {
      setData({})
      const query = {
        $limit: limit,
        $skip: skip
      }

      find(query)
    }
  }, [id, limit, skip, searchParams, additionalFilters])



  useEffect(() => {
    page === 0 ? setSkip(0) : setSkip((page - 1) * limit)
  }, [limit, page])

  const onSave = () => {
    if (id) {
      const {_id, __v, ...payload} = data
      patch(id, payload)
    } else {
      create(data)
    }
  }

  const refreshTable = () => {
    const query = {
      $limit: limit,
      $skip: skip
    }
    find(query)
  }

  const Paginator = additionalProps => {
    /*
     * Note: page minus and plus by one added to support TablePagination zero-indexing
     */
    return (
      <TablePagination
        component="div"
        count={total}
        page={page - 1}
        onPageChange={(event, newPage) => setPage(newPage + 1)}
        rowsPerPage={limit}
        rowsPerPageOptions={rowsPerPageOptions}
        onRowsPerPageChange={event => {
          setLimit(parseInt(event.target.value, 10))
          setPage(1)
        }}
        {...additionalProps}
      />
    )
  }

  return (
    <props.Component
      data={data}
      setData={setData}
      list={list}
      setList={setList}
      loading={loading}
      Loader={Loader}
      Paginator={Paginator}
      find={find}
      get={get}
      create={create}
      patch={patch}
      remove={remove}
      onSave={onSave}
      refreshTable={refreshTable}
      search={searchParams}
      clearSearch={clearSearch}
      handleSearch={handleSearch}
      additionalFilters={additionalFilters}
      setAdditionalFilters={setAdditionalFilters}
      {...props}
    />
  )
}

ConnectedComponent.prototype = {
  Component: PropTypes.elementType.isRequired,
  service: PropTypes.string.isRequired,
  config: PropTypes.object,
  id: PropTypes.string
}
