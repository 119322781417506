import {RightDrawerLayout} from 'components/layouts/RightDrawerLayout'
import {Tribute} from '../../components/Tribute'

export const DetailView = props => {
  const {open, onClose} = props
  return (
    <RightDrawerLayout open={open} onClose={onClose} title="Tribute">
      <Tribute />
    </RightDrawerLayout>
  )
}
