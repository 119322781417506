import React from 'react'
import { Grid } from '@mui/material'
import { LocalMall, Group, ShoppingBasket, GppGood, } from '@mui/icons-material'
import { Card } from '../Dashboard';

export const Insights = (props) => {
  return <Grid container alignItems='center'>
    <Card Icon={LocalMall} title="Products in Inventory" count="2,390" />
    <Card Icon={Group} title="Overall Product Variants" count="90" />
    <Card Icon={GppGood} title="Awaiting Products for Approval" count="7909" />
    <Card Icon={ShoppingBasket} title="Being Sold on Store" count="12" />
  </Grid>
}
