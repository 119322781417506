import * as React from 'react'
import {SimpleComment} from './SimpleComment'
import {PostContent} from './PostContent'

export const PostComment = props => {
  const {className = '', style = {}, data} = props
  return (
    <div className={className} style={style}>
      <PostContent content={data?.content?.content} />
      <SimpleComment className="mt-4" content={data?.content} />
    </div>
  )
}
