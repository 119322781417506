import {Button} from '@mui/material'
import TourIcon from '@mui/icons-material/Tour'
import {PostContent} from './PostContent'
export const Story = props => {
  const {content} = props
  console.log(content)
  return (
    <div className="m-4  flex flex-col ">
      {content?.posts?.map(post => (
        <div>
          <div className="rounded-xl p-4" style={{border: '1px solid #E0E0E0'}}>
            <PostContent content={post} />
          </div>
          <div className="w-full my-4 flex flex-col">
            <div>
              <Button variant="contained">Looks Safe</Button>
              <Button variant="outlined" className="!mx-4">
                <TourIcon />
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
