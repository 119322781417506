import {PetBasicDetail} from './PetBasicDetail'
import {PetBehaviourDetail} from './PetBehaviourDetail'
import {PetScanTimeLine} from './PetScanTimeLine'
import './lost-pet-profile.css'
import {PrivacyPolicy} from '../component/PrivacyPolicy'
import { useState } from 'react'
import { DownloadAppFloatingSection } from 'components/DownloadAppFloatingSection'
export const LostPetPublicProfile = props => {
  const {data} = props
  const [showMore, setShowMore] = useState(false)

  return (
    <div className="w-full">
      <div className="m-auto w-full md:w-9/12 xl:w-10/12 2xl:w-7/12 px-4 pt-1 lg:px-16 mb-32 ">
        <div
          style={{
            border: '1px solid #E0E0E0',
            borderRadius: '8px'
          }}
          className="flex flex-col"
        >
          <div className="p-4 lg:p-8">
            <PetBasicDetail data={data} showMore={showMore} setShowMore={setShowMore} />
            <PetBehaviourDetail data={data} showMore={showMore}/>
            {showMore && <PetScanTimeLine data={data} />}
          </div>
        </div>
        <PrivacyPolicy />
      </div>
      <DownloadAppFloatingSection className='md:justify-center  ml-6 md:mr-0'/>
    </div>
  )
}
