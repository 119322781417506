import React, {useEffect, useState} from 'react'
import Carousel from 'react-material-ui-carousel'
import {ImagePreview} from 'components/ImagePreview'
export const MediaCarousel = props => {
  const {media} = props
  const [items, setItems] = useState([])

  useEffect(() => {
    if (media && Array.isArray(media) && media?.length) {
      const data = media.filter(i => !i.path.includes('mp4'))
      setItems(data)
    }
  }, [media])

  return (
    <Carousel>
      {items?.map((item, i) => (
        <Item className="!w-full" key={i} media={item} />
      ))}
    </Carousel>
  )
}

function Item(props) {
  return <ImagePreview media={props.media} className="w-full h-72 object-cover rounded-xl" />
}
