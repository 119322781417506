import {Button, TextField} from '@mui/material'
import {socketClient} from 'client/socket.client'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {useSelector} from 'react-redux'
import {useNavigate, useLocation} from 'react-router-dom'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {useContext, useEffect, useMemo} from 'react'
import store from 'store/store'
import {AuthActions} from 'store/modules/authentication/actions'
import { isString } from 'lodash'
import appLogo from 'images/app_logo.png'

const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid Email').required('Email is required')
})

export const SetupBasicDetail = props => {
  const user = useSelector(state => state.authentication.user)
  const navigate = useNavigate()
  const {setSeverity, showToast} = useContext(SnackbarContext)
  const location = useLocation()
  const scannedCodePath = ['pet-found?code', 'pets/create', 'scan-report/create']
  const isScannedPath = path => scannedCodePath.some(ele => path.includes(ele))

  const isCodeScanned = useMemo(() => {
    return location?.state?.from && isString(location?.state?.from) && isScannedPath(location?.state?.from)
      ? true
      : false
  }, [location])

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: ''
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      socketClient
        .service('users')
        .patch(user?._id, {...values})
        .then(result => {
          console.log(result)
          setSeverity('success')
          showToast('Basic detail updated successfully')

          store.dispatch(AuthActions.reAuthenticated({user: result}))

          if (isCodeScanned) {
            navigate(location?.state?.from, {replace: true})
            return
          }

          if (!user?.roles.includes('admin')) {
            store.dispatch(AuthActions.resetAuth())
          }

          navigate('/', {replace: true})
        })
        .catch(e => {
          setSeverity('error')
          showToast('Request fail to save data')
          localStorage.removeItem('auth')
          store.dispatch(AuthActions.resetAuth())
          navigate('/', {replace: true})
        })
    }
  })

  useEffect(() => {
    if (user) {
      formik.setValues({firstName: user?.firstName, lastName: user?.lastName, email: user?.email})
    }
  }, [user])

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center p-8">
      <img src={appLogo} alt="Noah'sArk"/>
      <div className="lg:w-2/5 flex flex-col border border-gray-400		rounded-2xl	p-8">
        <form onSubmit={formik.handleSubmit}>
          {/* <div className="w-full text-left mb-4 ">
            <p className="text-xl	">Basic Details</p>
          </div> */}
          <TextField
            id="standard-basic"
            label="First Name"
            variant="outlined"
            {...formik.getFieldProps('firstName')}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            fullWidth
          />

          <TextField
            id="standard-basic"
            label="Last Name"
            variant="outlined"
            className="!my-4"
            {...formik.getFieldProps('lastName')}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            fullWidth
          />

          <TextField
            id="standard-basic"
            label="Email"
            variant="outlined"
            className="!my-4"
            {...formik.getFieldProps('email')}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            fullWidth
          />

          <Button variant="contained" color="primary" fullWidth size="large" type="submit">
            Save
          </Button>
        </form>
      </div>
    </div>
  )
}
