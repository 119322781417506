import React, {useContext, useRef} from 'react'
import Box from '@mui/material/Box'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  Chip,
  Avatar
} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import Add from '@mui/icons-material/Add'
import {Delete, Edit} from '@mui/icons-material'
import {AddEditModal} from './modals/AddEditModal'
import {DeleteModal} from './modals/DeleteModal'
import {storeSocketClient} from '../client/socket.client'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {ImagePreview} from 'components/ImagePreview'
import placeholder from 'images/avatar_placeholder.svg'

const columns = [
  {id: 'order', label: 'Order'},
  {id: 'title', label: 'Title'},
  {id: 'status', label: 'Status'},
]

export const Browse = props => {
  const {list, loading, Loader, Paginator, handleSearch, refreshTable} = props
  const editModalRef = useRef(null)
  const deleteModalRef = useRef(null)
  const {setSeverity, showToast} = useContext(SnackbarContext)

  const showModal = (event, id = null) => {
    if (event) event.stopPropagation()
    editModalRef.current.show(id)
  }

  const showDeleteModal = (event, id = null) => {
    if (event) event.stopPropagation()
    deleteModalRef.current.show(id)
  }

  const onAddEdit = () => refreshTable()

  const onDelete = () => refreshTable()
  return (
    <Box mt={3}>
      <AddEditModal ref={editModalRef} onAddEdit={onAddEdit} />
      <DeleteModal ref={deleteModalRef} onDelete={onDelete} />
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <div className="p-4 flex justify-end">
          <Tooltip title="Create Banner">
            <IconButton color="primary" onClick={e => showModal(e)}>
              <Add />
            </IconButton>
          </Tooltip>
        </div>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {columns.map(column => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.length ? (
                list.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {row?.banner ? (
                        <ImagePreview
                          media={row?.banner}
                          service="media"
                          client={storeSocketClient}
                          className="w-10 h-10  rounded-full"
                          fallbackSrc={placeholder}
                        />
                      ) : (
                        <Avatar src={placeholder}></Avatar>
                      )}
                    </TableCell>
                    <TableCell>{row.order}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>
                      {row?.isActive ? (
                        <Chip label="Active" color="success" variant="outlined" />
                      ) : (
                        <Chip label="Inactive" color="error" variant="outlined" />
                      )}
                    </TableCell>

                    <TableCell align="center">
                      <IconButton size="small" onClick={e => showModal(e, row?._id)}>
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton size="small" onClick={e => showDeleteModal(e, row?._id)}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : loading ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography>No Banners found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Paginator />
        </TableContainer>
      </Paper>
    </Box>
  )
}
