import React from 'react';
import { Typography, Container, Card } from '@mui/material';
import appLogo from 'images/app_logo.png'

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="lg"  sx={{textAlign: 'left', p: '1rem'}}>
      <Card sx={{p: '1rem 2rem'}}>
        <img src={appLogo} alt="Logo" style={{margin: '0 auto'}} />
        <Typography variant="h5" textAlign="center" fontWeight="bold" gutterBottom>
          Noah's Ark Pet Owners and Pets Privacy Policy
        </Typography>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Introduction
        </Typography>
        <Typography  className="!text-gray-600 !text-base" paragraph>
          At Noah's Ark, we understand the importance of protecting the privacy of pet owners and their pets. This Privacy Policy outlines the information that we collect, how we use it, and the measures we take to ensure that your information is protected.
        </Typography>
        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Information Collected
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          We may collect the following types of information from pet owners and their pets:
          <ul className="list-disc ml-8">
            <li>Personal Information: This includes information that can identify you, such as your name, email address, and location.</li>
            <li>Pet Information: This includes information about your pet, such as its name, breed, age, and photo.</li>
            <li>Diet Information: This includes information about your pet's dietary preferences and restrictions.</li>
            <li>Vaccination Information: This includes information about your pet's vaccines and their status.</li>
            <li>Illness Information: This includes information about your pet's health and any illnesses they may have.</li>
            <li>Care Information: This includes information about your pet's care preferences and routines.</li>
            <li>Treat Information: This includes information about your pet's favorite treats.</li>
            <li>Interest Information: This includes information about your pet's interests and activities.</li>
            <li>Breeding Availability Information: This includes information about whether your pet is available for breeding and what conditions apply.</li>
            <li>Activity Information: This includes information about your pet's daily activities and routines.</li>
            <li>Location Information: This includes information about your pet's location, including GPS coordinates and location history.</li>
            <li>Matching Information: This includes information used to match pet owners and their pets based on compatibility.</li>
            <li>Profile Information: This includes information you provide when creating a profile on Noah's Ark, such as your pet's name, breed, and photo.</li>
          </ul>
        </Typography>

        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Use of Information
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          We may use the information we collect for various purposes, including:
          <ul className="list-disc ml-8">
            <li>To provide, maintain, and improve Noah's Ark.</li>
            <li>To match pet owners and their pets based on compatibility.</li>
            <li>To personalize your experience on Noah's Ark and facilitate interactions between pet owners and their pets.</li>
            <li>To send you notifications and updates.</li>
            <li>For commercial purposes, such as marketing and advertising.</li>
            <li>To comply with legal obligations.</li>
            <li>To enforce this Privacy Policy and Noah's Ark's Terms of Service.</li>
          </ul>
        </Typography>

        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Disclosure of Information
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          We may disclose your information to third parties in the following circumstance:
          <ul className="list-disc ml-8">
            <li>To third-party service providers, such as hosting providers and payment processors, who may process your information on our behalf.</li>
            <li>To comply with legal obligations, such as a subpoena or court order.</li>
            <li>To protect the rights, property, or safety of Noah's Ark, its users, or the public.</li>
            <li>In connection with a merger, acquisition, or sale of Noah's Ark or its assets.</li>
            <li>With your consent.</li>
          </ul>
        </Typography>
        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Security of Information
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          We take reasonable measures to protect the security of your information, including using encryption technologies and implementing security controls. However, no method of transmitting or storing data is completely secure, and we cannot guarantee the security of your information.
        </Typography>
        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Location Sharing
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          By using Noah's Ark, you consent to the sharing of your location information with other users. You may control the sharing of your location information by adjusting the privacy settings in your device or within Noah's Ark.
        </Typography>
        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Changes to This Privacy Policy
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          We may update this Privacy Policy from time to time. The most current version of the Privacy Policy will be available on Noah's Ark's website. By continuing to use Noah's Ark after any changes have been made, you consent to the updated Privacy Policy.
        </Typography>
        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Contact Us
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          If you have any questions or concerns about this Privacy Policy, please contact us at <span className="font-bold text-black">support@petflix.io</span> or <span className="font-bold text-black">privacypolicy@petflix.io</span>. You may also reach us
        </Typography>
      </Card>
    </Container>
  );
};

export default PrivacyPolicy;
