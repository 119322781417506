import { Divider } from '@mui/material';
import AppLogo from 'images/app_logo_white.svg'
import QRCode from "react-qr-code";

export const LostPetPoster = ({data}) => {
  const primaryColor = '#ED1690'
  return (
    <div
      className="w-64 md:w-64 lg:w-72 rounded-xl"
      style={{
        backgroundColor: 'rgb(249 243 246)',
      }}
    >
      <div style={{backgroundColor: primaryColor}} className='p-2 text-white rounded-t-xl'>
        <p className='text-2xl font-bold'>
          MISSING PET
        </p>
        <p className='text-sm font-bold'>A CRY FOR HELP FOR OUR FURRY FRIEND</p>
      </div>
      <div className="flex">
        {data?.profile?.url &&
          <img
            src={data?.profile?.url}
            alt="Pet Profile"
            className="object-cover m-2 rounded"
            width="50%"
          />
        }
        <div className="text-left w-full">
          <p className="text-lg font-bold">{data.name}</p>
          <p className="text-base font-bold" style={{color: primaryColor}}>Type: <span className="font-normal text-black">{data?.type?.name}</span></p>
          <p className="text-base font-bold" style={{color: primaryColor}}>Bread: <span className="font-normal text-black">{data?.subtype?.name}</span></p>
          <Divider
            className="!mt-2 !mb-1 !mr-1"
            style={{
              background: 'rgb(237, 22, 144)',
              height: '0.2rem'
            }}
          />
          <p className="text-[12px]">{data?.description}</p>

          <div className='mt-4 mb-2 mr-2 bg-black pt-4 text-center'>
            <p className='text-white text-[14px] font-bold'>REWARD</p>
          </div>
        </div>
      </div>
      <div style={{backgroundColor: primaryColor}} className='p-2 flex justify-between text-white rounded-b-xl'>
        <img src={AppLogo} alt="Logo" width="64px" />

        <p className='text-[12px] mx-2 font-bold'>Download Noah's Ark app to verify and connect with the owner more efficiently</p>
        <QRCode value={data?.finder} size={124} style={{maxHeight: 64}}/>
      </div>
    </div>
  )
}
