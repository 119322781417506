import {Close} from '@mui/icons-material'
import {Box, Button, IconButton, Modal} from '@mui/material'

const layout = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,

  width: 600,

  '@media (max-width:767px)': {
    width: '95%'
  }
}

export const ReportActionModal = props => {
  const {open, onClose, handleConfirm} = props

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={layout}>
        <Box sx={{p: {lg: 5, md: 5, sm: 4, xs: 4}}}>
          <Box display="flex" alignItems="center" pb={2.5}>
            <p className=" w-full text-lg	md:text-2xl font-bold flex-auto">Pet Sighting Already Reported</p>
            <Box textAlign="right">
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <p>Do you wish to submit another report?</p>
            <div className="flex justify-end mt-4">
              <Button className="!mt-4 !mx-2" variant="outlined" color="primary" onClick={onClose}>
                Cancle
              </Button>

              <Button className="!mt-4" variant="contained" color="primary" onClick={handleConfirm}>
                Confirm
              </Button>
            </div>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
