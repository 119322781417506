import React, {useContext, useRef} from 'react'
import Box from '@mui/material/Box'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Rating,
} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import {storeSocketClient} from '../client/socket.client'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import { ProductDetailModal } from '../products/components/ProductDetailModal'

const columns = [
  {id: 'product', label: 'Product'},
  {id: 'comment', label: 'Comment'},
  {id: 'rating', label: 'Rating'},
  {id: 'commentedBy', label: 'Commented By'},
  {id: 'actions', label: ''}
]

export const Brows = props => {
  const {list, loading, Loader, Paginator, refreshTable} = props
  const detailModalRef = useRef(null)

  const {setSeverity, showToast} = useContext(SnackbarContext)

  const handleRequest = async (event, productId, value) => {
    event.stopPropagation()
    try {
      const result = await storeSocketClient.service('review').patch(productId, {
        approved: value
      })
      if (result) {
        refreshTable()
        setSeverity('success')
        showToast(`${value ? 'Product review is approved' : 'Product review approval revoked'}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onClick = id => {
    detailModalRef.current.show(id, {
      anchor: 'right'
    })
  }

  return (
    <Box mt={3}>
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <ProductDetailModal ref={detailModalRef} />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.length ? (
                list.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <span
                        style={{
                          color:'blue',
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        }}
                        onClick={() => onClick(row?.product?._id)}
                      >
                        {row.product.name}
                      </span>
                    </TableCell>
                    <TableCell>{row.review}</TableCell>
                    <TableCell>
                      <Rating name="read-only" value={row?.rating} readOnly />
                    </TableCell>
                    <TableCell>
                      {row?.user?.firstName} {row?.user?.lastName}
                    </TableCell>
                    <TableCell align="center" style={{width: '12rem'}}>
                      <Button variant="outlined" onClick={e => handleRequest(e, row?._id, !row?.approved)}>
                        {row?.approved ? 'Revoke approval' : 'Approve'}
                        {console.log(row?.approved)}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Typography>No Review</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Paginator />
        </TableContainer>
      </Paper>
    </Box>
  )
}
