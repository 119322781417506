import React from 'react'
import Modal from '@mui/material/Modal';
import { motion } from 'framer-motion';
import Box from '@mui/material/Box';
import { Button, TextField, MenuItem } from '@mui/material';
import cross from '../../../../src/images/crosss.svg';
import Typography from '@mui/material/Typography';
import pencil from '../../../../src/images/newimages/pencil.svg';
import { Grid, ListSubheader } from '@mui/material';
import Link from '@mui/material/Link';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import modallogo from '../../../../src/images/Noah.logo.png'

const RecieptModal = (props) => {
    const {openReceptModal, handleReceptModal} = props ;
  return ( 
    <div>
       <Modal open={openReceptModal} onClose={handleReceptModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <motion.div
                initial={{ opacity: 0, y: '-50%', x: '-50%' }}
                animate={{ opacity: 1, y: '-50%', x: '-50%' }}
                exit={{ opacity: 0, y: '-50%', x: '-50%' }}
                transition={{ duration: 0.3 }}
                style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', borderRadius: '20px' }}>
                <Box maxWidth="md" p={8}>
                  <Box textAlign="right" width="100%" >
                    <Button onClick={handleReceptModal} style={{ position: 'absolute', right: '-9px', top: '-3px' }}><img src={cross} /></Button>
                  </Box>
                  <Box fullWidth textAlign="center">
                    <Typography variant="h5" component="h5" mb={2} sx={{ fontWeight: '600' }}>GIVE $20, GET $20</Typography>
                    <Typography variant="h6" component="h6" >Tell your friend to enter your code at the checkout:</Typography>
                  </Box>
                  <Box bgcolor="lightPurple.main" fullWidth textAlign="center" border='2px dotted' borderColor="purple.main" p={1.5} my={2} sx={{ borderRadius: '6px' }}>
                    <Typography variant="p" component="p" color="purple.main" sx={{ fontSize: '12px' }}>Mariana Janie</Typography>
                  </Box>
                  <Box fullWidth textAlign="center">
                    <Button variant="contained" color="transparent" size="large" sx={{ fontSize: '14px', fontWeight: '600', border: '1px solid #C8C8C8', borderRadius: '20px', boxShadow: 'unset' }}>
                      <img src={pencil} style={{ marginRight: '4px', width: '14px' }} />Edit Recipient Name
                    </Button>
                  </Box>
                  <Box fullWidth textAlign="center" m={4}>
                    <Button variant="contained" color='primary' fullWidth size="large">
                      Share by Email
                    </Button>
                  </Box>
                  <Box>
                    <Typography variant="h6" component="h6" fullWidth textAlign="center" sx={{ fontWeight: '600' }} mb={1}>Share Via</Typography>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <Link color="dark.main"><FacebookIcon fontSize="large" /></Link>
                      <Box mx={2}><Link color="dark.main"><WhatsAppIcon fontSize="large" /></Link></Box>
                      <Link color="dark.main"><TwitterIcon fontSize="large" /></Link>
                    </Grid>
                  </Box>
                  <Box fullWidth textAlign="center" mt={5}>
                    <img src={modallogo} style={{ margin: 'auto' }} />
                    <Typography variant="h6" component="h6" pt={1}>Or copy your personal link</Typography>
                  </Box>

                  <Box fullWidth width="100%" mt={4}>
                    <TextField id="standard-basic" label="Mariana Janie" variant="standard" width="100%" fullWidth />
                  </Box>
                </Box>
              </motion.div>
            </Modal>
    </div>
  )
}

export default RecieptModal
