import React from 'react'
import { motion } from 'framer-motion';
import { Button, Box, TextField, Modal, Grid, Stack, Avatar, Typography, MenuItem} from '@mui/material';
import cross from 'images/crosss.svg';
import scaner from 'images/newimages/ss.png';
import iconname from 'images/name-icon.svg';
import avatar2 from 'images/avtarimg.png';
import uploadimg from 'images/newimages/uploading.png'

export const VaccineModal = ({open, handleModal}) => {
  return (
    <Modal
      open={open}
      onClose={handleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <motion.div
        initial={{ translateX: "100%" }}
        animate={{ translateX: 0 }}
        exit={{ translateX: "100%" }}
        transition={{ duration: 0.3 }}
      >
        <Box
          height="100vh"
          maxWidth="lg"
          bgcolor="modalBg.main"
          sx={{ marginLeft: "auto", overflowY: "scroll" }}
        >
          <Box textAlign="right" width="100%">
            <Button
              onClick={handleModal}
              sx={{ position: "absolute", right: "0" }}
            >
              <img src={cross} />
            </Button>
          </Box>
          <Grid
            container
            columnGap={2}
            p={3}
            pt={5}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ boxShadow: 2 }}
            bgcolor="dark.contrastText"
          >
            <Grid item md={4} sm={12} xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item md={3}>
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      alt="Remy Sharp"
                      src={avatar2}
                      sx={{ width: 100, height: 100 }}
                      variant="rounded"
                    />
                  </Stack>
                </Grid>
                <Grid item md={9} pl={3}>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Pet Name:{" "}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "primary.main",
                      }}
                    >
                      Fluffy956
                    </Typography>
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Age:{" "}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "primary.main",
                      }}
                    >
                      2 ys 3 m
                    </Typography>
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Size:{" "}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "primary.main",
                      }}
                    >
                      Medium High
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
              xs={12}
              textAlign="left"
              sx={{ pt: { xs: 2, sm: 2, md: 0 } }}
            >
              <Grid container>
                <Box>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: "15px", fontWeight: "600" }}
                  >
                    Status:{" "}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "primary.main",
                      }}
                    >
                      Open to Breed
                    </Typography>
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: "15px", fontWeight: "600" }}
                  >
                    Tag #:{" "}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "primary.main",
                      }}
                    >
                      4523
                    </Typography>
                  </Typography>
                </Box>
                <Box ml={1}>
                  <img src={scaner} />
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
              xs={12}
              sx={{
                textAlign: { xs: "left", sm: "left", md: "right" },
                pr: { xs: 0, sm: 0, md: 5 },
                pt: { xs: 1, sm: 1, md: 0 },
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                sx={{ fontSize: "15px", fontWeight: "600" }}
              >
                Mariana Janeson
              </Typography>
              <Box
                display="flex"
                sx={{
                  justifyContent: {
                    xs: "flex-start",
                    sm: "flex-start",
                    md: "flex-end",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  component="h6"
                  mr={1}
                  sx={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "primary.main",
                  }}
                >
                  Pet Owner, Verified
                </Typography>
                <img src={iconname} />
              </Box>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontSize: "15px",
                  fontWeight: "600",
                  color: "primary.main",
                }}
              >
                PRIVACY ENABLED
              </Typography>
            </Grid>
          </Grid>

          <Box
            item
            pb={3}
            pt={4}
            px={3}
            sx={{ mt: { md: 0, xs: 2, sm: 2 } }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={3}
              pb={2}
              sx={{ borderBottom: "2px solid", borderColor: "border.main" }}
            >
              <Typography
                variant="h6"
                component="h6"
                sx={{ fontWeight: "500" }}
              >
                {" "}
                Add Vaccine
              </Typography>
            </Box>
            <Grid container>
              <Grid
                md={8}
                xs={12}
                sm={12}
                sx={{ pr: { md: 3, sm: 0, xs: 0 } }}
              >
                <Box mb={2} fullWidth>
                  <Box>
                    <Box mb={2}>
                      <TextField
                        label="Enter Vaccine Type"
                        placeholder="Enter Vaccine Type"
                        fullWidth
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        label="Enter Date Applied "
                        placeholder="Enter Date Applied "
                        fullWidth
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        label="Enter Next Vaccination "
                        placeholder="Enter Next Vaccination "
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Box>
                <Button variant="contained" color="primary" size="large" sx={{padding: '10px 40px'}}>
                  Add Vaccine
                </Button>
              </Grid>
              <Grid
                md={4}
                xs={12}
                sm={12}
                sx={{ mt: { md: 0, sm: 2, xs: 2 } }}
              >
                <Box
                  bgcolor="dark.contrastText"
                  px={2}
                  pt={2.5}
                  sx={{ borderRadius: "10px" }}
                >
                  <img src={uploadimg} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </motion.div>
    </Modal>
  )
}