import {Routes, Route} from 'react-router-dom'
import {Browse} from './Browse'

export const Invitations = () => {
  return (
    <Routes>
      <Route path="/" element={<Browse />} />
    </Routes>
  )
}
