import React from 'react'
import { motion } from 'framer-motion';
import { Button, Box, TextField, Modal, Grid, Stack, Avatar, Typography, MenuItem} from '@mui/material';
import cross from 'images/crosss.svg';
import scaner from 'images/newimages/ss.png';
import iconname from 'images/name-icon.svg';
import googleimg from 'images/newimages/googleimg.png';
import petimage from 'images/newimages/petimg.png';

const Role = [{ 
  value: 'Menu Items',
  label: 'Menu Items',
},{
  value: 'Role',
  label: 'Role',
}];
export const MonitoringModal = ({open, handleModal}) => {
  return (
    <Modal
        open={open}
        onClose={handleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
      <motion.div
        initial={{ translateX: "100%" }}
        animate={{ translateX: 0 }}
        exit={{ translateX: "100%" }}
        transition={{ duration: 0.3 }}
      >
        <Box
          height="100vh"
          maxWidth="lg"
          sx={{
              marginLeft: "auto",
              background: "#fff",
              overflowY: "scroll",
          }}
        >
          <Box textAlign="right" width="100%">
              <Button
                  onClick={handleModal}
                  sx={{ position: "absolute", right: "0" }}
              >
                  <img src={cross} />
              </Button>
          </Box>
          <Grid
              container
              columnGap={2}
              p={3}
              pt={5}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ boxShadow: 2 }}
          >
              <Grid item md={4} sm={12} xs={12}>
                  <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                  >
                      <Grid item md={3}>
                          <Stack direction="row" spacing={2}>
                              <Avatar
                                  alt="Remy Sharp"
                                  src={petimage}
                                  sx={{ width: 100, height: 100 }}
                                  variant="rounded"
                              />
                          </Stack>
                      </Grid>
                      <Grid item md={9} pl={3}>
                          <Typography
                              variant="h6"
                              component="h6"
                              sx={{ fontSize: "16px", fontWeight: "600" }}
                          >
                              Pet Name :{" "}
                              <Typography
                                  component="span"
                                  sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "primary.main",
                                  }}
                              >
                                  Fluffy956
                              </Typography>
                          </Typography>
                          <Typography
                              variant="h6"
                              component="h6"
                              sx={{ fontSize: "16px", fontWeight: "600" }}
                          >
                              Age:{" "}
                              <Typography
                                  component="span"
                                  sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "primary.main",
                                  }}
                              >
                                  2 ys 3 m
                              </Typography>
                          </Typography>
                          <Typography
                              variant="h6"
                              component="h6"
                              sx={{ fontSize: "16px", fontWeight: "600" }}
                          >
                              Size:{" "}
                              <Typography
                                  component="span"
                                  sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "primary.main",
                                  }}
                              >
                                  Medium High
                              </Typography>
                          </Typography>
                      </Grid>
                  </Grid>
              </Grid>
              <Grid
                  item
                  md={3}
                  sm={12}
                  xs={12}
                  textAlign="left"
                  sx={{ pt: { xs: 2, sm: 2, md: 0 } }}
              >
                  <Grid container>
                      <Box>
                          <Typography variant="h6" component="h6" sx={{ fontSize: "15px", fontWeight: "600" }}>
                              Status:{" "}
                              <Typography component="span" sx={{
                                      fontSize: "15px",
                                      fontWeight: "600",
                                      color: "primary.main",
                                  }}
                              >
                                  Open to Breed
                              </Typography>
                          </Typography>
                          <Typography
                              variant="h6"
                              component="h6"
                              sx={{ fontSize: "15px", fontWeight: "600" }}
                          >
                              Tag #:{" "}
                              <Typography
                                  component="span"
                                  sx={{
                                      fontSize: "15px",
                                      fontWeight: "600",
                                      color: "primary.main",
                                  }}
                              >
                                  4523
                              </Typography>
                          </Typography>
                      </Box>
                      <Box ml={1}>
                          <img src={scaner} />
                      </Box>
                  </Grid>
              </Grid>
              <Grid
                  item
                  md={3}
                  sm={12}
                  xs={12}
                  sx={{
                      textAlign: { xs: "left", sm: "left", md: "right" },
                      pr: { xs: 0, sm: 0, md: 5 },
                      pt: { xs: 1, sm: 1, md: 0 },
                  }}
              >
                  <Typography
                      variant="h6"
                      component="h6"
                      sx={{ fontSize: "15px", fontWeight: "600" }}
                  >
                      Mariana Janeson
                  </Typography>
                  <Box
                      display="flex"
                      sx={{
                          justifyContent: {
                              xs: "flex-start",
                              sm: "flex-start",
                              md: "flex-end",
                          },
                      }}
                  >
                      <Typography
                          variant="h6"
                          component="h6"
                          mr={1}
                          sx={{
                              fontSize: "15px",
                              fontWeight: "600",
                              color: "primary.main",
                          }}
                      >
                          Pet Owner, Verified
                      </Typography>
                      <img src={iconname} />
                  </Box>
                  <Typography
                      variant="h6"
                      component="h6"
                      sx={{
                          fontSize: "15px",
                          fontWeight: "600",
                          color: "primary.main",
                      }}
                  >
                      PRIVACY ENABLED
                  </Typography>
              </Grid>
          </Grid>
          <Grid container bgcolor="modalBg.main">
            <Grid item md={7} xs={12} p={3} sx={{ pb:{md: 3, sm:0, xs:0}}} >
              <Grid container >
                <Grid md={3} sm={4} xs={12} sx={{pb:{md: 0, xs:2, sm: 2 }}}>
                  <Grid container>
                      <Box mr={1}>
                          <img src={scaner} />
                      </Box>
                      <Box>
                          <Typography variant="h6" component="h6" sx={{ fontSize: "15px", fontWeight: "600" }}>
                          Tag #:{" "}
                              <Typography component="span" sx={{ fontSize: "15px", fontWeight: "600", color: "primary.main",}}
                              >
                                  4532
                              </Typography>
                          </Typography>
                          <Typography component="span" sx={{ fontSize: "12px", fontWeight: "600", color: "primary.main",}}
                              >Re-Order</Typography>
                      </Box>
                  </Grid>
                </Grid>
                <Grid md={8} sm={8} xs={12}>
                  <TextField id="outlined-basic" fullWidth label="Lost Pet Reward Link" variant="outlined" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5} xs={12} pb={3} pt={4} px={3} >
              <Grid container >
                <Grid md={4} sm={6} xs={12} sx={{pb:{md: 0, xs:2, sm: 2 }}}>
                  <Box>
                    <Typography variant="h6" component="h6" sx={{ fontSize: "15px", fontWeight: "600" }}>
                      Reward:{" "} <Typography component="span" sx={{ fontSize: "15px", fontWeight: "600", color: "primary.main",}}> $100.00 </Typography>
                    </Typography>
                    <Typography component="span" sx={{ fontSize: "12px", fontWeight: "600", color: "primary.main",}}>
                      Add Funds
                    </Typography>
                  </Box>
                </Grid>
                <Grid md={5} sm={6} xs={12}>
                  <TextField id="role" select label="Tag Status" defaultValue="Role" fullWidth>
                    {Role.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                      {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box p={3}>
              <img src={ googleimg } style={{ width:'100%', }}/>
          </Box>
        </Box>
      </motion.div>
    </Modal>
  )
}