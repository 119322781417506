import React, {useEffect, useState} from 'react'
import {Typography} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import EmailIcon from '@mui/icons-material/Email'
import {LostPetMap} from 'modules/lost-pet/modals/LostPetMap'
import {TagScanHistory} from './TagScanHistory'
import {isEmpty} from 'lodash'
import useWindowSize from 'hooks/useWindowSize'

export const PetScanTimeLine = props => {
  const {data} = props
  const [[lng, lat], setPetLocationData] = useState([0, 0])
  const [posterURL, setPosterURL] = useState(null)
  const screenWidth = useWindowSize();
  const isMobile = screenWidth < 770

  useEffect(() => {
    if (data?.report?.poster?.url) setPosterURL(data?.report?.poster?.url)

    if (data?.report?.scans?.length > 0 && data?.report?.scans[0]?.location?.length > 0) {
      console.log(data?.report?.scans[data?.report?.scans?.length - 1]?.location)
      setPetLocationData(data?.report?.scans[data?.report?.scans?.length - 1]?.location)
    }
  }, [data])

  const protectedString = value => {
    return !isEmpty(value) ? value.slice(0, Math.floor(value.length / 2)) + '****' : ''
  }

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-start ">
        <TagScanHistory data={data} setPetLocationData={setPetLocationData} />
      </div>
      <div className="w-full lg:w-1/2 flex flex-col items-start  mt-12 lg:mt-0 ">
        {(!isMobile && posterURL) &&
          <div className="w-full mb-8 lg:mt-0 flex justify-center">
            <img src={posterURL} alt="lost pet poster" className="w-72 object-cover rounded-xl" />
          </div>
        }
        <Typography className="!mb-2 !font-bold">Contact detail</Typography>
        <div className="flex flex-col mb-4 items-start px-2 lg:px-0">
          <Typography className="!my-1 flex items-center" component="div">
            <PersonIcon color="primary" className="mr-2" />
            {`${protectedString(data?.pet?.user?.firstName)}  ${protectedString(data?.pet?.user?.lastName)} `}
          </Typography>
          {data?.pet?.user?.phone && (
            <Typography className="!my-2 ">
              <SmartphoneIcon color="primary" className="mr-2" />
              {`${data?.pet?.user?.phone?.slice(0, 6)}****`}
            </Typography>
          )}
          {data?.pet?.user?.email && (
            <Typography className="!my-2 ">
              <EmailIcon color="primary" className="mr-2" />
              {`${protectedString(data?.pet?.user?.email?.split('@')[0])}@***** `}
            </Typography>
          )}
        </div>
        {lat !== 0 && lng !== 0 && (
          <LostPetMap lat={lat} lng={lng} height="400px" scans={data?.report?.scans} />
        )}
      </div>
    </div>
  )
}
