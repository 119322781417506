import { useEffect, useState } from "react"
import { FooterDescriptionField } from "./FooterDescriptionField"
import { storeSocketClient } from "../client/socket.client"
import { URLTextField } from "./URLTextField"
import { PhoneTextField } from "./PhoneTextField"
import { EmailTextField } from "./EmailTextField"
import { AddressField } from "./AddressField"


const socialMedia = [
  {name: 'YOU_TUBE', label: 'You Tube', id:"you_tube"},
  {name: 'TUMBLR', label: 'Tumblr', id:"Tumblr"},
  {name: 'INSTAGRAM', label: 'Instagram', id:"instagram"},
  {name: 'TIK_TOK', label: 'TikTok', id:"tik_tok"},
  {name: 'FACEBOOK', label: 'FaceBook', id:"face_book"},
  {name: 'GOOGLE', label: 'Google', id:"google"},
  {name: 'TWITTER', label: 'Twitter', id:"twitter"},
]
export const Settings = props => {
  const {setNavigationTitle} = props
  const [data, setData] = useState([])

  const callAPI = async () => {
    try {
      const result = await storeSocketClient.service("settings").find()
      console.log(result)
      setData(result.data)
    } catch(e) {
      console.log(e)
    }

  }
  useEffect(() => {
    setNavigationTitle('Settings')
    callAPI()
  }, [])

  return (
    <div className=" w-full md:w-2/3 flex flex-col">
      <div className="w-full flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 md:mr-2">
          <PhoneTextField data={data}/>
        </div>
        <div className="w-full md:w-1/2 md:ml-2">
          <EmailTextField data={data}/>
        </div>
      </div>
      <FooterDescriptionField data={data} />
      <AddressField data={data} />
      <h1 className="text-start mt-4 mb-2">
        Manage Social Media Link
      </h1>
      {
        socialMedia.map((item, index) =>
          <URLTextField
            key={index}
            id={item.id}
            name={item.name}
            label={item.label}
            data={data}
          />
        )
      }
    </div>
  )
}
