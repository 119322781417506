import { Box, Button, Grid, MenuItem, Tab, Tabs, Typography, TextField } from "@mui/material";
import { AppLayout } from "modules/layout/AppLayout";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import check from 'images/newimages/check.svg';
import tableedit from 'images/newimages/edit-rectangle.svg';
import deleteicon from 'images/newimages/delete.svg';
import users from 'images/users.svg';
import playFill from 'images/play_fill.svg'
import petFinder from 'images/petFinder.svg'
import lostPets from 'images/lostPets.svg'
import flag from 'images/flagged.svg'
import { ModerationModal } from "./modals/ModerationModal";

const inorganiccolumns = [
  {
      name: 'Order' ,
      selector: row => <Typography variable="h6" color="phylumTable.main" sx={{fontSize: '12px'}}>{row.order}</Typography>,
  },
  {
      name: 'Rule Name',
      selector: row => <Typography variable="h6" color="phylumTable.main" sx={{fontSize: '12px'}}>{row.name}</Typography>,
  },
  {
      name: 'Action',
      selector: row =><img src={row.action} alt="Image"  />,
  },
  {
      name: 'Edit',
      selector: row =><img src={row.edit} alt="Image"  />,
  },
  {
      name: 'Delete',
      selector: row =><img src={row.delete} alt="Image"  />,
  },
  
];
const inorganicdata = [
  {
      order: '1' ,
      name: 'Send post to manual review if review threshold match',
      action: check,
      edit: tableedit,
      delete: deleteicon,
  },
  {
      order: '2' ,
      name: 'Send post to manual review if review threshold match',
      action: check,
      edit: tableedit,
      delete: deleteicon,
  },
  {
      order: '3' ,
      name: 'Send post to manual review if review threshold match',
      action: check,
      edit: tableedit,
      delete: deleteicon,
  },
]

const postcolumns = [
  {
      name: 'Order' ,
      selector: row => <Typography variable="h6" color="phylumTable.main" sx={{fontSize: '12px'}}>{row.order}</Typography>,
  },
  {
      name: 'Action Name',
      selector: row => <Typography variable="h6" color="phylumTable.main" sx={{fontSize: '12px'}}>{row.name}</Typography>,
  },
  {
      name: 'Show on Dashboard',
      selector: row =><img src={row.showdashboard} alt="Image"  />,
  },
  {
      name: 'Endpoint URL',
      selector: row => <Typography variable="h6" color="phylumTable.main" textAlign="center" sx={{fontSize: '12px'}}>{row.url}</Typography>,
  },
  {
      name: 'Edit',
      selector: row =><img src={row.edit} alt="Image"  />,
  },
  {
      name: 'Delete',
      selector: row =><img src={row.delete} alt="Image"  />,
  },
  
];
const postdata = [
  {
      order: '1' ,
      name: 'Delete Post',
      showdashboard: check,
      url: 'https://example.com/apli/v1/action/delete',
      edit: tableedit,
      delete: deleteicon,
  },
  {
      order: '2' ,
      name: 'Delete Post',
      showdashboard: check,
      url: 'https://example.com/apli/v1/action/delete',
      edit: tableedit,
      delete: deleteicon,
  },
]

const actioncolumns = [
  {
      name: 'Action Name' ,
      selector: row => <Typography variable="h6" color="phylumTable.main" sx={{fontSize: '12px'}}>{row.name}</Typography>,
  },
  {
      name: 'Type',
      selector: row => <Typography variable="h6" color="phylumTable.main" sx={{fontSize: '12px'}}>{row.type}</Typography>,
  },
  {
      name: 'Source',
      selector: row =><Typography variable="h6" color="phylumTable.main" sx={{fontSize: '12px'}}>{row.source}</Typography>,
  },
  {
      name: 'Timestamp',
      selector: row => <Typography variable="h6" color="phylumTable.main" textAlign="center" sx={{fontSize: '12px'}}>{row.timestamp}</Typography>,
  },
  {
      name: 'Affected User ID',
      selector: row =><Typography variable="h6" color="phylumTable.main" textAlign="center" sx={{fontSize: '12px'}}>{row.userid}</Typography>,
  },
  {
      name: 'Affected Post ID',
      selector: row =><Typography variable="h6" color="phylumTable.main" textAlign="center" sx={{fontSize: '12px'}}>{row.postid}</Typography>,
  },
  {
      name: 'Status',
      selector: row =><Typography variable="h6" color="phylumTable.main" textAlign="center" sx={{fontSize: '12px'}}>{row.status}</Typography>,
  },
];
const actiondata = [
  {
      name: 'Publish Post' ,
      type: 'Post',
      source: '40836',
      timestamp: '4/5/2023, 03:01 PM',
      userid: '4565465486',
      postid: '4565465486',
      status: 'deleteicon',
  },
]
const Role = [
  {
    value: 'Menu Items',
    label: 'Menu Items',
  },
  {
    value: 'Role',
    label: 'Role',
  },
];
const petOwner = [
  {
    value: 'Pet Owner',
    label: 'Rose',
  },
  {
    value: 'Pet Owner2',
    label: 'Johnson',
  },
];
const Status = [
  {
    value: 'Status',
    label: 'Enable',
  },
  {
    value: 'status2',
    label: 'Disable',
  },
];
export const ModerationDashboard = () => {

  const [activeTab, setActiveTab] = useState(0);
  const [activeTab2, setActiveTab2] = useState(0);
  const [openModerationModal, setOpenModerationModal] = useState(false);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleTab2Change = (event, newValue) => {
    setActiveTab2(newValue);
  };

  const handleModerationModal = () => {
    setOpenModerationModal(!openModerationModal);
  };

  return (
    <AppLayout heading='Moderation Dashboard / Settings'>
      <Grid container gap='10px' alignItems='center'>
        <Grid item boxShadow='custom' bgcolor='white' xs={12} lg={2} md={3} sx={{ display: 'block',  borderRadius:'10px', p:3, border:'1px solid #DDDDDD' }}>
          <Grid container direction="row" justifyContent="center" alignItems="center" >
              <img src={users} alt='img'/>
          </Grid>
          <Typography fontSize='12px' my='4px'>App Users</Typography>
          <Typography variant='h4' fontSize='26px' sx={{fontWeight:'bold', color:'#09244B'}}>2,765</Typography>
          <Typography sx={{color:'#2AC670', alignItems:'center', display:'flex', justifyContent:'center', gap:'2px'}}><img src={playFill} alt='play' />  %10 </Typography>
        </Grid>
        <Grid item boxShadow='custom'  bgcolor='white' xs={12} lg={2} md={3} sx={{ display: 'block', borderRadius:'10px', border:'1px solid #DDDDDD', p:3 }}>
          <Grid container direction="row" justifyContent="center" alignItems="center" >
            <img src={petFinder} alt='img'/>
          </Grid>

          <Typography fontSize='12px' my='4px'>Pet Finders</Typography>
          <Typography variant='h4' fontSize='26px' sx={{fontWeight:'bold', color:'#09244B'}}>253</Typography>
          <Typography sx={{color:'#2AC670', alignItems:'center', display:'flex', justifyContent:'center', gap:'2px'}}>
            <img src={playFill} alt='pet' /> %10
          </Typography>
        </Grid>

        <Grid item  boxShadow='custom'  bgcolor='white' xs={12} lg={2} md={3} sx={{ display: 'block', borderRadius:'10px', p:3, border:'1px solid #DDDDDD'}}>
          <Grid container direction="row" justifyContent="center" alignItems="center" >
              <img src={lostPets} alt='img'/>
          </Grid>
          
          <Typography fontSize='12px' my='4px'>Lost Pets</Typography>
          <Typography variant='h4' fontSize='26px' sx={{fontWeight:'bold', color:'#09244B'}}>896</Typography>
          <Typography sx={{color:'#2AC670', alignItems:'center', display:'flex', justifyContent:'center', gap:'2px'}}>
            <img src={playFill} alt='pet' /> %10
          </Typography>
        </Grid>

        <Grid item  boxShadow='custom'  bgcolor='white' xs={12} lg={2} md={3} sx={{ display: 'block', borderRadius:'10px', p:3, border:'1px solid #DDDDDD' }}>
          <Grid container direction="row" justifyContent="center" alignItems="center" >
              <img src={flag} alt='img'/>
          </Grid>
          
          <Typography fontSize='12px' my='4px'>Flagged Users</Typography>
          <Typography variant='h4' fontSize='26px' sx={{fontWeight:'bold', color:'#09244B'}}>896</Typography>
          <Typography sx={{color:'#2AC670', alignItems:'center', display:'flex', justifyContent:'center', gap:'2px'}}>
            <img src={playFill} alt='pet' /> %10
          </Typography> 
        </Grid>
        <Grid item  boxShadow='custom'  bgcolor='white' xs={12} lg={2} md={3} sx={{ display: 'block', borderRadius:'10px', p:3, border:'1px solid #DDDDDD'}}>
          <Grid container direction="row" justifyContent="center" alignItems="center" >
              <img src={lostPets} alt='img'/>
          </Grid>
          
          <Typography fontSize='12px' my='4px'>Adoptions</Typography>
          <Typography variant='h4' fontSize='26px' sx={{fontWeight:'bold', color:'#09244B'}}>896</Typography>
          <Typography sx={{color:'#2AC670', alignItems:'center', display:'flex', justifyContent:'center', gap:'2px'}}>
            <img src={playFill} alt='pet' /> %10
          </Typography>
        </Grid>
        <Typography>
          <Button onClick={ handleModerationModal } variant="contained" color='primary' uppercase sx={{height:'42px', display: 'block', width: '200px', mb:2}} size='large'>Categories</Button>
          <Button variant="contained" color='primary' uppercase sx={{height:'42px', width: '200px'}} size='large'>Actions</Button>
        </Typography>
      </Grid>

      <Box component="form" p="6px" mt={2} borderRadius="10px" boxShadow="custom" sx={{ textAlign: "center", width: "100%", backgroundColor: "#fff",
        "& .MuiTextField-root": { m: 1, width: "95%", textAlign: "left" },}} noValidate autoComplete="off">
        <Grid container display="flex" justifyContent="space-between" alignItems="center">
          <Grid lg={8} md={8} xs={12} sm={12} sx={{ pr:{ lg:3.5, md:0, sm:0, xs: 0 }, md:{lg: 0, md: 2.5, xs: 2.5, sm: 2.5 } }}>
            <Tabs value={activeTab} onChange={handleTabChange}  variant="scrollable"  scrollButtons="auto">
              <Tab label="Actions" sx={{ fontWeight: 'bold' }}/>
              <Tab label="Categories" sx={{ fontWeight: 'bold' }}/>
            </Tabs>
          </Grid>
          <Grid lg={4} md={4} xs={12} sm={12} sx={{ pl:{ lg:3.5, md:0, sm:0, xs: 0 } }}>
            <Box py={2.5} sx={{ textAlign: {lg: 'right', md: 'right', sm: 'left', xs: 'left'} }}>
              <Button  variant="contained" color='primary' size="large" sx={{ width: '150px' }}>
                Add Action
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Typography component="h5" variable="h5" textAlign="left"  color="grey3.main" sx={{ fontSize: '14px' }}>The page instruction goes here.  Actions are automatic actions that the system executes  based on parameters given on flagged content</Typography> 
      {activeTab === 0 && 
        <Box> 
          <Box component="form" p={1} mt={2} borderRadius="10px" boxShadow="custom" sx={{ textAlign: "center", width: "100%", backgroundColor: "#fff",
          "& .MuiTextField-root": { m: 1, width: "95%", textAlign: "left" },}} noValidate autoComplete="off">
            <Grid container display="flex" justifyContent="space-between" alignItems="center">
              <Grid lg={12} md={12} xs={12} sm={12} sx={{ pr:{ lg:3.5, md:0, sm:0, xs: 0 }, md:{lg: 0, md: 2.5, xs: 2.5, sm: 2.5 } }}>
                <Tabs value={activeTab2} onChange={handleTab2Change}  variant="scrollable"  scrollButtons="auto">
                  <Tab label="User" sx={{ fontWeight: 'bold' }}/>
                  <Tab label="Post" sx={{ fontWeight: 'bold' }}/>
                  <Tab label="Actions Log" sx={{ fontWeight: 'bold' }}/>
                </Tabs>
              </Grid>
            </Grid>
          </Box>
                
          {activeTab2 === 0 && 
            <Box> 
              <Box component="form" p="6px" mt="8px" borderRadius="10px" boxShadow="custom" sx={{ textAlign: "center", width: "100%", backgroundColor: "#fff" }} noValidate autoComplete="off">
                <Grid container display="flex" justifyContent="space-between" alignItems="center">
                  <Grid lg={8} md={8} xs={12} sm={12} sx={{ pr:{ lg:3.5, md:0, sm:0, xs: 0 }, md:{lg: 0,  xs: 2.5, sm: 2.5 } }}>
                    <Grid container>
                      <Grid item xs={12} lg={3} md={4} pr={1} sx={{ mb:{md: 0, sm: 3, xs: 3}, px:{md:0, xs: 1.5, sm: 1.5} }}>
                          <TextField label="Search" fullWidth variant="standard"/>
                      </Grid>
                      <Grid item xs={12} lg={3} md={4} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 3, xs: 3} }}>
                        <TextField id="role" select label="Failed" defaultValue="Role" fullWidth >
                          {Role.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} lg={3} md={4} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 3, xs: 3} }}>
                        <TextField id="owner" select label="Unread" defaultValue="Pet Owner" fullWidth>
                          {petOwner.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                                textAlign="left"
                                width="auto"
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} lg={3} md={4} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 2, xs: 3} }}>
                        <TextField id="status" select label="Actions" defaultValue="Status" fullWidth>
                          {Status.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                                style={{ textAlign: "left", width: "auto" }}
                              >
                                {option.label}
                              </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid lg={4} md={4} xs={12} sm={12} sx={{ pl:{ lg:3.5, md:0, sm:0, xs: 0 }, px:{md:0, xs: 2, sm: 2} }}>
                    <Box py={2.5} sx={{ textAlign: {lg: 'right', md: 'right', sm: 'left', xs: 'left'} }}>
                      <Button   variant="contained" color='failed' size="large" sx={{ width: '220px', fontWeight:'600' }}>
                          Mark Failed as Read
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box> 
              <Box mt={2} sx={{ boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)', borderRadius: '10px'}}>
                  <DataTable columns={inorganiccolumns} data={inorganicdata} striped />
              </Box>  
            </Box>
          }

          {activeTab2 === 1 && 
            <Box> 
              <Box component="form" p="6px" mt="8px" borderRadius="10px" boxShadow="custom" sx={{ textAlign: "center", width: "100%", backgroundColor: "#fff" }} noValidate autoComplete="off">
                <Grid container display="flex" justifyContent="space-between" alignItems="center">
                  <Grid lg={8} md={8} xs={12} sm={12} sx={{ pr:{ lg:3.5, md:0, sm:0, xs: 0 }, md:{lg: 0,  xs: 2.5, sm: 2.5 } }}>
                    <Grid container>
                      <Grid item xs={12} lg={3} md={4} pr={1} sx={{ mb:{md: 0, sm: 3, xs: 3}, px:{md:0, xs: 1.5, sm: 1.5} }}>
                        <TextField label="Search" fullWidth variant="standard"/>
                      </Grid>
                      <Grid item xs={12} lg={3} md={4} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 3, xs: 3} }}>
                        <TextField id="role" select label="Failed" defaultValue="Role" fullWidth >
                          {Role.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} lg={3} md={4} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 3, xs: 3} }}>
                        <TextField id="owner" select label="Unread" defaultValue="Pet Owner" fullWidth>
                          {petOwner.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                                textAlign="left"
                                width="auto"
                              >
                                {option.label}
                              </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} lg={3} md={4} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 2, xs: 3} }}>
                        <TextField id="status" select label="Actions" defaultValue="Status" fullWidth>
                          {Status.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              style={{ textAlign: "left", width: "auto" }}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid lg={4} md={4} xs={12} sm={12} sx={{ pl:{ lg:3.5, md:0, sm:0, xs: 0 }, px:{md:0, xs: 2, sm: 2} }}>
                    <Box py={2.5} sx={{ textAlign: {lg: 'right', md: 'right', sm: 'left', xs: 'left'} }}>
                      <Button   variant="contained" color='failed' size="large" sx={{ width: '220px', fontWeight:'600' }}>
                        Mark Failed as Read
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box> 
              <Box mt={2} sx={{ boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)', borderRadius: '10px'}}>
                  <DataTable columns={postcolumns} data={postdata} striped />
              </Box>  
            </Box>
          }
          {activeTab2 === 2 && 
            <Box> 
              <Box component="form" p="6px" mt="8px" borderRadius="10px" boxShadow="custom" sx={{ textAlign: "center", width: "100%", backgroundColor: "#fff" }} noValidate autoComplete="off">
                <Grid container display="flex" justifyContent="space-between" alignItems="center">
                    <Grid lg={8} md={8} xs={12} sm={12} sx={{ pr:{ lg:3.5, md:0, sm:0, xs: 0 }, md:{lg: 0,  xs: 2.5, sm: 2.5 } }}>
                        <Grid container>
                            <Grid item xs={12} lg={3} md={4} pr={1} sx={{ mb:{md: 0, sm: 3, xs: 3}, px:{md:0, xs: 1.5, sm: 1.5} }}>
                                <TextField label="Search" fullWidth variant="standard"/>
                            </Grid>
                            <Grid item xs={12} lg={3} md={4} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 3, xs: 3} }}>
                                <TextField id="role" select label="Failed" defaultValue="Role" fullWidth >
                                    {Role.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} lg={3} md={4} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 3, xs: 3} }}>
                                <TextField id="owner" select label="Unread" defaultValue="Pet Owner" fullWidth>
                                    {petOwner.map((option) => (
                                        <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        textAlign="left"
                                        width="auto"
                                        >
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} lg={3} md={4} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 2, xs: 3} }}>
                                <TextField id="status" select label="Actions" defaultValue="Status" fullWidth>
                                    {Status.map((option) => (
                                        <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        style={{ textAlign: "left", width: "auto" }}
                                        >
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid lg={4} md={4} xs={12} sm={12} sx={{ pl:{ lg:3.5, md:0, sm:0, xs: 0 }, px:{md:0, xs: 2, sm: 2} }}>
                        <Box py={2.5} sx={{ textAlign: {lg: 'right', md: 'right', sm: 'left', xs: 'left'} }}>
                            <Button   variant="contained" color='failed' size="large" sx={{ width: '220px', fontWeight:'600' }}>
                                Mark Failed as Read
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
              </Box> 
              <Box mt={2} sx={{ boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)', borderRadius: '10px'}}>
                  <DataTable columns={actioncolumns} data={actiondata} striped />
              </Box>
            </Box>
          }
        </Box>
      }

      {activeTab === 1 && 
          <Box>
            <Box>    
              <Box display="flex" justifyContent="start" alignItems="center">                 
                <Typography  display="flex" component="h5" variable="h5" textAlign="left" py={2.5} mr={1} sx={{fontSize:'20px', fontWeight:'600'}}>Canines 
                </Typography>   
                <Link href="#" ><img src={tableedit} /></Link>
              </Box> 
              <Box sx={{ boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)', borderRadius: '10px'}}>
                  <DataTable columns={inorganiccolumns} data={inorganicdata} striped />
              </Box>                 
            </Box>     
            <Box>    
              <Box display="flex" justifyContent="start" alignItems="center">                 
                <Typography  display="flex" component="h5" variable="h5" textAlign="left" py={2.5} mr={1} sx={{fontSize:'20px', fontWeight:'600'}}>Equidae
                </Typography>   
                <Link href="#"><img src={tableedit} /></Link>
              </Box> 
              <Box sx={{ boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)', borderRadius: '10px'}}>
                <DataTable columns={inorganiccolumns} data={inorganicdata} striped />
              </Box>                 
            </Box> 
            <Box>    
              <Box display="flex" justifyContent="start" alignItems="center">                 
                <Typography  display="flex" component="h5" variable="h5" textAlign="left" py={2.5} mr={1} sx={{fontSize:'20px', fontWeight:'600'}}>Felines
                </Typography>   
                <Link href="#"><img src={tableedit} /></Link>
              </Box> 
              <Box sx={{ boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)', borderRadius: '10px'}}>
                <DataTable columns={inorganiccolumns} data={inorganicdata} striped />
              </Box>                 
            </Box>   
          </Box>
      }
      <ModerationModal open={openModerationModal} handleModal={handleModerationModal}/>
    </AppLayout>
  )
}