import {
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Typography,
  Chip,
  Button,
  Modal,
  Box
} from '@mui/material'
import {StyledTable, StyledTableRow, StyledTableHeadCell, StyledTableCell} from 'components/StyledComponent'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import {QRCodeSVG} from 'qrcode.react'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  width: 200
}

export const PetCodesTable = props => {
  const {
    list,
    loading,
    Loader,
    Paginator,
    setDataLoading,
    refreshTable,
    isRefresh,
    setRefresh,
    search,
    handleSearch,
    handleRevokeModal
  } = props

  const [open, setOpen] = useState(false)
  const [qrKey, setQrKey] = useState(null)

  const openModal = link => {
    setQrKey(link)
    setOpen(true)
  }

  const closeModal = () => {
    setQrKey(null)
    setOpen(false)
  }

  useEffect(() => {
    if (isRefresh) {
      refreshTable()
      setRefresh(false)
    }
  }, [isRefresh])

  useEffect(() => {
    setDataLoading(loading)
  }, [loading])

  useEffect(() => {
    handleSearch('code', search)
  }, [search])

  return (
    <div>
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <TableContainer>
          <StyledTable>
            <TableHead>
              <StyledTableRow>
                <StyledTableHeadCell>Code</StyledTableHeadCell>
                <StyledTableHeadCell>Status</StyledTableHeadCell>
                <StyledTableHeadCell>Pet</StyledTableHeadCell>
                <StyledTableHeadCell>Activated Time</StyledTableHeadCell>
                <StyledTableHeadCell></StyledTableHeadCell>
                <StyledTableHeadCell></StyledTableHeadCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {list.length ? (
                list.map((row, index) => (
                  <StyledTableRow key={index} odd={index % 2 !== 0 ? 1 : 0}>
                    <StyledTableCell data-label="Code">{row.code ? row.code : '-'}</StyledTableCell>
                    <StyledTableCell data-label="status">
                      {row.activated ? (
                        <Chip label="Claimed" color="success" size="small" variant="outlined" />
                      ) : (
                        <Chip label="Unclaimed" color="error" size="small" variant="outlined" />
                      )}
                    </StyledTableCell>
                    <StyledTableCell data-label="Pet Name">
                      {row?.activatedFor?.name ? (
                        <Link
                          to={`/pets/${row?.activatedFor?._id}`}
                          className="underline underline-offset-4 text-blue-700	"
                        >
                          {row?.activatedFor?.name}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </StyledTableCell>
                    <StyledTableCell data-label="Activated Time">
                      {row?.activatedAt ? moment(row?.activatedAt).format('DD MMM YYYY') : 'Not Claimed'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {!row?.activated && (
                        <Button
                          onClick={e => {
                            e.stopPropagation()
                            handleRevokeModal(row?._id)
                          }}
                        >
                          Revoke
                        </Button>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <QrCode2Icon onClick={() => openModal(row?.link)} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : loading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={4} align="center">
                    <Loader />
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={4} align="center">
                    <Typography>No Data </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </StyledTable>
          <Paginator />
        </TableContainer>
      </Paper>
      {open && qrKey && (
        <Modal open={open} onClose={closeModal}>
          <Box sx={style} display="flex" justifyContent="center">
            {qrKey && <QRCodeSVG value={qrKey} />}
          </Box>
        </Modal>
      )}
    </div>
  )
}
