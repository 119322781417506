import { Routes, Route } from 'react-router-dom'
import { FoodsType } from './food-type'
import { Meal } from './meal'
import { ServingSize } from './serving'
import { Treats } from './treat-types'
import { Flavours } from './flavours'
import { PetFood } from './food'
import { FoodBrands } from './food-brands'
 
export const PetDiet = () => {
  return (
    <Routes>
      <Route path="/food-type" element={<FoodsType />} />
      <Route path="/meal" element={<Meal />} />
      <Route path="/serving" element={<ServingSize />} />
      <Route path="/treats" element={<Treats />} />
      <Route path="/flavours" element={<Flavours />} />
      <Route path="/food" element={<PetFood />} />
      <Route path="/food-brands" element={<FoodBrands />} />
    </Routes>
  )
}
