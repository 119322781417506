import {
  OutlinedInput,
  InputLabel,
  InputAdornment,
  CircularProgress,
  FormControl,
  FormHelperText
} from '@mui/material'
import { find, throttle } from 'lodash';
import { useEffect, useState } from 'react'
import { storeSocketClient } from '../client/socket.client';


export const PhoneTextField = ({data}) => {
  const [value, setValue] = useState('')
  const [item, setItem] = useState(null)
  const [loading, setLoading] = useState(false)
  const KEY = 'CONTACT_PHONE'
  const [error, setError] = useState(false)
  const phoneRegex = /^\+[0-9]+$/

  useEffect(() => {
    if (data?.length) {
      const description = find(data, i => i.name === KEY)
      console.log(description)
      if (description) {
        setItem(description)
        setValue(description.value)
      }
    }
  }, [data])

  const handleSuccess = (result) => {
    console.log(result)
    setItem(result)
    setLoading(false)
  }

  const handleError = (e) => {
    console.log(e)
    setLoading(false)
  }

  const callApi = (newValue) => {
    if (loading) {
      return
    }

    setLoading(true)
    const payload = {name: KEY, value: newValue}
    const service = storeSocketClient.service('settings')

    if (item) {
      service
        .patch(item._id, payload)
        .then(result => handleSuccess(result))
        .catch(e => handleError(e))
    } else {
      service
        .create(payload)
        .then(result => handleSuccess(result))
        .catch(e => handleError(e))
    }

  }
  const throttledUpdateApi = throttle(callApi, 5000);

  const handleChange = (event) => {
    const newValue = event.target.value.replace(/[^+\d]|(?!^)\+/g, '');
    setValue(newValue);

    if ((phoneRegex.test(newValue) && newValue.length > 8) || !newValue) {
      setError(false)
    } else {
      setError(true)
    }

    if (!error) throttledUpdateApi(newValue);
  };

  return <FormControl className='!my-2 w-full' size='small' variant="outlined">
    <InputLabel htmlFor="description">Contact Number</InputLabel>
    <OutlinedInput
      className="w-full"
      id={KEY}
      name={KEY}
      label="Contact number"
      value={value}
      onChange={handleChange}
      error={error}
      endAdornment={
        <InputAdornment position="end">
          {loading &&
            <CircularProgress color="primary" size={20} />
          }
        </InputAdornment>
      }
      fullWidth
    />
    {error  && <FormHelperText id="component-error-text" style={{color: '#d32f2f'}}>Invalid Phone number. Phone number must be start with country code eg. +1 or +91</FormHelperText>}
  </FormControl>
}
