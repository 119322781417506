import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { CustomTypography } from './CustomTypography';

export const GridItemTypography = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return props?.value ? (
    <Grid item xs={isMobile ? 12 : 6}>
      <CustomTypography {...props} />
    </Grid>
  ) : <></>
}
