import {Box, Button, Checkbox, FormControlLabel, Modal, TextField} from '@mui/material'
import {connectService} from 'components/service/connectService'
import {useEffect, useMemo, useState} from 'react'
import {ModalLayout} from 'components/layouts/ModalLayout'
import * as yup from 'yup'
import {useFormik} from 'formik'
import {isEmpty, keys, pick} from 'lodash'
import {NumberTextField} from 'components/NumberTextField'
import {IconUploader} from 'components/IconUploader'

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  sortOrder: yup.number().positive('Sort Order must be positive').required('Sort Order is required')
})

const initialValues = {
  name: '',
  active: true,
  sortOrder: ''
}

export const AddEditModal = connectService(
  props => {
    const {open, handleModal, id, data, setData, setOpen, loading, Loader, patch, create} = props
    const [icon, setIcon] = useState(null)

    const initialData = useMemo(() => {
      return pick(data, keys(initialValues))
    }, [data])

    const formik = useFormik({
      initialValues: id
        ? {...initialData, active: data.hasOwnProperty('active') ? data.active : true}
        : initialValues,
      enableReinitialize: true,
      validationSchema,
      onSubmit: values => {
        const payload = icon?._id ? {...values, icon: icon._id} : {...values}
        id ? patch(id, payload) : create(payload)

        formik.resetForm({values: initialValues})
      }
    })

    const onClose = () => {
      setData({})
      setOpen(false)
      setIcon(null)
    }

    useEffect(() => {
      console.log(id)
      if (!id) {
        setData({}) // create old persisted data
      }
    }, [id])

    useEffect(() => {
      if (data?.icon) {
        setIcon(data?.icon)
      }
    }, [data])

    return (
      <Modal
        open={open}
        onClose={() => {
          formik.resetForm()
          handleModal()
          setIcon(null)
        }}
      >
        <ModalLayout
          title={`${id ? 'Edit' : 'Add'} Flavour Type`}
          onClose={onClose}
          LoaderComponent={Loader}
          loading={loading}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className="flex mt-4">
              <TextField
                id="standard-basic"
                label="Name"
                variant="outlined"
                className="!w-1/2"
                {...formik.getFieldProps('name')}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <NumberTextField
                type="number"
                label="Sort Order"
                variant="outlined"
                {...formik.getFieldProps('sortOrder')}
                error={formik.touched.sortOrder && Boolean(formik.errors.sortOrder)}
                helperText={formik.touched.sortOrder && formik.errors.sortOrder}
                className={`w-1/2 !ml-2 `}
                onInput={event => {
                  event.target.value = event.target.value.replace(/^-/, '')
                }}
              />
            </div>
            <div className="my-4">
              <div className="flex justify-between items-center">
                <IconUploader title="Upload Image" icon={icon} setIcon={setIcon} />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.active}
                      onChange={e => formik.setFieldValue('active', e.target.checked)}
                    />
                  }
                  label="Active"
                />
              </div>
            </div>
            <Box>
              <Button variant="contained" color="primary" fullWidth size="large" type="submit">
                {id ? 'Edit' : 'Submit'}
              </Button>
            </Box>
          </form>
        </ModalLayout>
      </Modal>
    )
  },
  'flavour-types',
  {query: {$populate: ['icon']}}
)
