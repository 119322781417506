import {Box, Collapse, Divider, IconButton} from '@mui/material'
import {useState} from 'react'
import {AdditionalPetDetail} from './AdditionalPetDetail'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import {BirthDate} from './BirthDate'
import {PetSize} from './PetSize'
import {ConnectedComponent} from 'components/service/ConnectedComponent'
import ImageIcon from '@mui/icons-material/Image'
import {Edit} from '@mui/icons-material'
import {useNavigate} from 'react-router-dom'
import {PlaceHolderImage} from 'components/PlaceHolderImage'
import {ImagePreview} from 'components/ImagePreview'

export const PetListItem = props => {
  const {pet} = props
  const [expanded, setExpanded] = useState(false)
  const [media, setMedia] = useState(null)
  const navigate = useNavigate()

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  return (
    <Box
      p={3}
      mb={3}
      sx={{
        border: '1px solid #E0E0E0',
        borderRadius: '8px'
      }}
    >
      <div className="w-full">
        <div className="flex">
          {pet?.profile ? (
            <ImagePreview media={pet?.profile} className="w-36 h-36 object-cover rounded-xl" />
          ) : (
            <PlaceHolderImage />
          )}
          <div className="w-full flex flex-col ml-4">
            <div className="w-full flex justify-between">
              <p className="text-xl">{pet?.name}</p>
              <IconButton
                className="!rounded-full"
                color="primary"
                onClick={() => navigate(`/pets/${pet?._id}`)}
              >
                <Edit />
              </IconButton>
            </div>
            {(pet?.type?.name || pet?.subtype?.name) && (
              <p className="text-sm mt-1">
                {pet?.type?.name} {pet?.type?.name ? ` • ${pet?.subtype?.name}` : pet?.subtype?.name}
              </p>
            )}
            {(pet?.birthdate || pet?.size) && (
              <div className="flex text-sm mt-1">
                {pet?.birthdate && <BirthDate data={pet?.birthdate} />}
                {pet?.size && <PetSize data={pet?.size} />}
              </div>
            )}
            <p className="text-sm mt-2">{pet?.description}</p>
          </div>
        </div>

        {expanded && <Divider className="!my-8" />}
        <Collapse in={expanded} timeout="auto" className="!mb-4" unmountOnExit>
          <ConnectedComponent
            Component={AdditionalPetDetail}
            service="pets"
            id={pet?._id}
            config={{query: {$populate: ['wellness', 'vaccination', 'activities']}}}
          />
        </Collapse>
        <div className="w-full flex justify-end items-center">
          <IconButton
            onClick={handleExpandClick}
            className="cursor-pointer rounded-full "
            style={{backgroundColor: '#ED1690'}}
          >
            {expanded ? (
              <ExpandLessIcon style={{color: '#FFFFFF'}} />
            ) : (
              <ExpandMoreIcon style={{color: '#FFFFFF'}} />
            )}
          </IconButton>
        </div>
      </div>
    </Box>
  )
}
