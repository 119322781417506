import {Box} from '@mui/material'
import {PetListItem} from './PetListItem'

export const Pets = props => {
  const {id, list, Loader, loading} = props
  return (
    <Box alignContent="center">
      {loading ? (
        <Loader />
      ) : list?.length ? (
        list.map(pet => <PetListItem pet={pet} />)
      ) : (
        <div className="w-full flex justify-center">
          <p>No Pet Found</p>
        </div>
      )}
    </Box>
  )
}
