import {Delete} from '@mui/icons-material'
import {Box, Checkbox, FormControlLabel, IconButton, TextField, Typography} from '@mui/material'

export const OptionChoices = props => {
  const {options, setOptions, optionIndex, choiceIndex, choice} = props

  const onDelete = () => {
    const newOptions = [...options]
    newOptions[optionIndex]['values'].splice(choiceIndex, 1)
    console.log(choiceIndex)
    setOptions(newOptions)
  }

  const handleChange = (property, value) => {
    const option = options[optionIndex]
    const values = option?.values
    const choice = values[choiceIndex]
    values.splice(choiceIndex, 1, {...choice, [property]: value})

    const newOptions = [...options]
    newOptions.splice(optionIndex, 1, {...option, values})
    setOptions(newOptions)
  }

  return (
    <div className="flex flex-col border rounded-lg border-gray-100  my-4">
      <Box display="flex" justifyContent="end" sx={{width: '100%'}}>
        <IconButton onClick={onDelete}>
          <Delete />
        </IconButton>
      </Box>
      <Box px={2}>
        <div className="flex">
          <TextField
            className="!w-1/2 !mr-2"
            id="standard-basic"
            label="Enter choice name"
            variant="outlined"
            size="small"
            value={choice?.name || ''}
            onChange={e => handleChange('name', e.target.value)}
          />
          <TextField
            type="number"
            className="!w-1/2 !ml-2"
            id="standard-basic"
            label="Enter additional price"
            variant="outlined"
            size="small"
            value={choice?.additionalPrice}
            disabled={choice?.isBaseOption || false}
            onChange={e => handleChange('additionalPrice', e.target.value)}
          />
        </div>

        <div className="flex justify-between mt-2">
          <FormControlLabel
            control={
              <Checkbox
                checked={choice?.isBaseOption || false}
                onChange={e => {
                  handleChange('isBaseOption', e.target.checked)
                  if (e.target.checked) {
                    handleChange('additionalPrice', 0)
                  }
                }}
              />
            }
            label="I default choice?"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={choice?.isActive || false}
                onChange={e => {
                  handleChange('isActive', e.target.checked)
                }}
              />
            }
            label="Active"
          />
        </div>
      </Box>
    </div>
  )
}
