import React, {useState} from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import {Box, CircularProgress, IconButton, Tooltip, Typography} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import style from './css/image-uploader.css'
import Close from '@mui/icons-material/Close'
import {ImagePreview} from 'components/ImagePreview'
import {useMediaUpload} from 'hooks/useMediaUpload'
import {useContext} from 'react'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {storeSocketClient} from 'modules/store/client/socket.client'

export const StoreMediaUploader = props => {
  const {title, media, setMedia} = props
  const [selectedImages, setSelectedImages] = useState(null)
  const [open, setOpen] = useState(false)
  const {loading, uploadStoreMedia} = useMediaUpload()
  const {setSeverity, showToast} = useContext(SnackbarContext)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setSelectedImages([])
    setOpen(false)
  }

  const handleUploadImage = () => {
    uploadStoreMedia(selectedImages.file)
      .then(data => {
        if (setMedia) {
          setMedia(data[0])
        }
        handleClose()
      })
      .catch(e => {
        setSeverity('error')
        showToast('Failed to upload photo. Please try again.')
      })
  }

  const handleImageChange = event => {
    const files = event.target.files
    const reader = new FileReader()
    reader.onloadend = () => {
      setSelectedImages({file: files[0], base64: reader.result})
    }
    reader.readAsDataURL(files[0])
  }

  const handleRemoveImage = () => {
    setSelectedImages(null)
  }

  return (
    <div>
      <Tooltip title={title}>
        <IconButton onClick={handleOpen} color="primary">
          {media ? (
            <ImagePreview
              media={media}
              service="media"
              client={storeSocketClient}
              className="w-20 h-20 object-cover rounded-xl"
            />
          ) : (
            <AddPhotoAlternateIcon />
          )}
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between" pb={2.5}>
            <Typography
              variant="h5"
              component="h5"
              color="transparent.contrastText"
              sx={{fontSize: '24px', fontWeight: '600'}}
              style={{width: '100%'}}
            >
              {title}
            </Typography>
            <Box textAlign="right" width="100%">
              <IconButton onClick={handleClose}>
                <Close onClick={handleClose} />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className="upload-dialog-container">
          <div id="upload-area" className="icon-upload-area">
            <div className="drag-drop-label">Drag & Drop images here</div>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{width: '100%', height: '100%', opacity: 0}}
            />
          </div>
          <div
            style={{
              maxHeight: 'calc(100vh - 350px)', // Set the max height of the scrollable div
              overflowY: 'auto', // Enable vertical scrolling
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: '10px'
            }}
          >
            {selectedImages?.base64 && (
              <div style={{position: 'relative', padding: '8px'}}>
                <IconButton
                  style={{position: 'absolute', top: 2, right: 1, zIndex: 1, color: '#5A5A5A'}}
                  onClick={() => handleRemoveImage()}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
                <img src={selectedImages?.base64} alt={`Uploaded Preview`} className="preview" />
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUploadImage} color="primary" variant="contained" disabled={loading}>
            Upload{' '}
            {loading && <CircularProgress style={{width: '18px', height: '18px', marginLeft: '8px'}} />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
