import {Button} from '@mui/material'
import {OAuthProvider, signInWithPopup } from 'firebase/auth'
import { auth } from '../../firebase/auth'
import { withFirebaseAuth } from './components/withFirebaseAuth'
import apple from 'images/apple.svg'

export const AppleSignInButton = withFirebaseAuth((props) => {
  const { onSuccessfulAuth, title, ...otherProps } = props

  const onAuth = () => {
    const provider = new OAuthProvider()
    signInWithPopup(auth, provider).then((result) => {
      onSuccessfulAuth()
    }).catch(console.error)
  }

  return (
    <Button onClick={onAuth} {...otherProps}>
      <img src={apple} alt="Facebook Icon" />
      <span style={{marginLeft: '10px'}}>{title}</span>
    </Button>
  )
})
