import {Box, Button, Checkbox, FormControlLabel, Modal, TextField} from '@mui/material'
import {connectService} from 'components/service/connectService'
import {useEffect, useMemo, useState} from 'react'
import {ModalLayout} from 'components/layouts/ModalLayout'
import * as yup from 'yup'
import {useFormik} from 'formik'
import {keys, pick} from 'lodash'
import {NumberTextField} from 'components/NumberTextField'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {useContext} from 'react'
import {IconUploader} from 'components/IconUploader'

const validationSchema = yup.object({
  name: yup.string().required('Please enter name of shelter'),
  description: yup.string().required('Please enter description about shelter'),
  website: yup.string().url('Invalid URL').required('Please enter website'),
  phone: yup
    .string()
    .matches(/^\+\d{1,3}\d{1,14}$/, 'Invalid phone number format')
    .required('Please enter phone number'),
  TIN: yup.string(),
  place: yup.string().required('Please address of shelter'),
  sortOrder: yup.number().typeError('Sort order must be a number').required('Please enter sort order number')
})

const initialValues = {
  name: '',
  description: '',
  active: true,
  website: '',
  phone: '',
  TIN: '',
  place: '',
  sortOrder: '',
  canAcceptDonation: false,
  verified: false
}
export const AddEditShelterModal = connectService(
  props => {
    const {
      open,
      handleModal,
      id,
      data,
      setData,
      setOpen,
      loading,
      Loader,
      create,
      patch,
      removeBlankAttribute
    } = props
    const [logo, setLogo] = useState(null)
    const {setSeverity, showToast} = useContext(SnackbarContext)

    const initialData = useMemo(() => {
      return pick(data, keys(initialValues))
    }, [data])

    const handleToast = (status, message) => {
      setSeverity(status)
      showToast(message)
    }

    const handleSubmit = async values => {
      if (!logo) {
        handleToast('error', 'Please select logo')
        return
      }

      const {active, sortOrder, canAcceptDonation, verified} = values

      const payload = {
        active,
        sortOrder,
        canAcceptDonation,
        verified,
        ...removeBlankAttribute({...values, logo: logo?._id})
      }

      id ? patch(id, payload) : create(payload)
    }

    const formik = useFormik({
      initialValues: id
        ? {
            ...initialData,
            active: data.hasOwnProperty('active') ? data.active : true,
            verified: data.hasOwnProperty('verified') ? data.verified : true,
            canAcceptDonation: data.hasOwnProperty('canAcceptDonation') ? data.canAcceptDonation : false,
          }
        : initialValues,
      enableReinitialize: true,
      validationSchema,
      onSubmit: values => {
        handleSubmit(values)
      }
    })

    useEffect(() => {
      if (!id) {
        setData({})
      }
    }, [id])

    const onClose = () => {
      setData({})
      setOpen(false)
    }

    useEffect(() => {
      if (data) {
        if (data?.logo) setLogo(data?.logo)
      }
    }, [data])

    return (
      <Modal
        open={open}
        onClose={handleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalLayout
          title={`${id ? 'Edit' : 'Add'} Shelter`}
          onClose={onClose}
          LoaderComponent={Loader}
          loading={loading}
        >
          <form onSubmit={formik.handleSubmit}>
            <div>
              <TextField
                id="standard-basic"
                label="Name"
                variant="outlined"
                {...formik.getFieldProps('name')}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
              />
            </div>

            <div className="flex mt-4">
              <TextField
                id="standard-basic"
                label="Website"
                variant="outlined"
                className="!w-1/2"
                placeholder="e.g. https://www.test.com"
                {...formik.getFieldProps('website')}
                error={formik.touched.website && Boolean(formik.errors.website)}
                helperText={formik.touched.website && formik.errors.website}
              />

              <TextField
                id="standard-basic"
                label="phone"
                variant="outlined"
                className={`w-1/2 !ml-2 `}
                {...formik.getFieldProps('phone')}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </div>

            <div className="flex mt-4">
              <TextField
                id="standard-basic"
                label="TIN"
                variant="outlined"
                className="!w-1/2"
                {...formik.getFieldProps('TIN')}
                error={formik.touched.TIN && Boolean(formik.errors.TIN)}
                helperText={formik.touched.TIN && formik.errors.TIN}
              />
              <NumberTextField
                type="number"
                label="Sort Order"
                variant="outlined"
                {...formik.getFieldProps('sortOrder')}
                error={formik.touched.sortOrder && Boolean(formik.errors.sortOrder)}
                helperText={formik.touched.sortOrder && formik.errors.sortOrder}
                className={`w-1/2 !ml-2 `}
                onInput={event => {
                  event.target.value = event.target.value.replace(/^-/, '')
                }}
              />
            </div>

            <div className="flex mt-4">
              <TextField
                id="standard-basic"
                label="Address"
                variant="outlined"
                {...formik.getFieldProps('place')}
                error={formik.touched.place && Boolean(formik.errors.place)}
                helperText={formik.touched.place && formik.errors.place}
                fullWidth
              />
            </div>

            <div className="flex mt-4">
              <TextField
                id="standard-basic"
                label="Description"
                variant="outlined"
                multiline
                rows={3}
                {...formik.getFieldProps('description')}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                fullWidth
              />
            </div>

            <Box display="flex"  justifyContent="space-between">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.active}
                    onChange={e => formik.setFieldValue('active', e.target.checked)}
                  />
                }
                label="Active"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.canAcceptDonation}
                    onChange={e => {
                        formik.setFieldValue('canAcceptDonation', e.target.checked)
                    }}
                  />
                }
                label="Accept Donation"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.verified}
                    onChange={e => {
                        formik.setFieldValue('verified', e.target.checked)
                    }}
                  />
                }
                label="Verified"
              />
            </Box>

            <div className="my-4">
              <div className="flex justify-between items-center">
                <IconUploader title="Upload Image" icon={logo} setIcon={setLogo} />
              </div>
            </div>

            <Box>
              <Button variant="contained" color="primary" fullWidth size="large" type="submit">
                {id ? 'Edit' : 'Submit'}
              </Button>
            </Box>
          </form>
        </ModalLayout>
      </Modal>
    )
  },
  'shelters',
  {query: {$populate: ['logo']}}
)
