import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { LocalMall, Group, ShoppingBasket, GppGood, ArrowDropUp } from '@mui/icons-material'
import { storeSocketClient } from './client/socket.client'
import { map, random } from 'lodash';
import { LineChart } from './LineChart';

export const Card = (props) => {
  return <Grid item xs={12} sm={6} lg={3} md={3}>
    <Box bgcolor='white' sx={{ display: 'block', borderRadius: '10px', p: 3, m: 1, border: '1px solid #DDDDDD' }}>
      <Grid container direction="row" justifyContent="center" alignItems="center" >
        <props.Icon color="primary" />
      </Grid>

      <Typography fontSize='12px' my='4px'>{props.title}</Typography>
      <Typography variant='h4' fontSize='26px' sx={{ fontWeight: 'bold', color: '#09244B' }}>
        {props.count}
      </Typography>
      <Typography sx={{ color: '#2AC670', alignItems: 'center', display: 'flex', justifyContent: 'center', gap: '2px' }}>
        <ArrowDropUp />
        %10
      </Typography>
    </Box>
  </Grid>
}

export const Dashboard = (props) => {
  const { setNavigationTitle } = props
  const [dailySalesAnalytics, setDailySalesAnalytics] = useState([])
  const [dailyOrdersAnalytics, setDailyOrdersAnalytics] = useState([])

  useEffect(() => {
    setNavigationTitle('Dashboard')
    storeSocketClient.io.emit('report', 'analytics', {}, {}, (e, data) => {
      console.log(data, e)
      setDailySalesAnalytics([
        {
          id: 'Sales',
          color: '#ED1690',
          data: map(data.daily, d => ({
            x: d.day.toString(),
            y: random(100, 1999)
          }))
        }
      ])

      setDailyOrdersAnalytics([
        {
          id: 'Orders',
          color: '#ED1690',
          data: map(data.daily, d => ({
            x: d.day.toString(),
            y: random(1, 34)
          }))
        }
      ])
    })
  }, [])

  return (
    <div>
      <Grid container alignItems='center'>
        <Card Icon={LocalMall} title="Total Products" count="2,390" />
        <Card Icon={Group} title="Total Vendors" count="90" />
        <Card Icon={ShoppingBasket} title="Total Orders" count="7909" />
        <Card Icon={GppGood} title="Total Approval Requests" count="12" />
      </Grid>
      <Grid container alignItems='center'>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <Box bgcolor='white' sx={{
              borderRadius: '10px',
              m: 1,
              border: '1px solid #DDDDDD',
              height: '400px'
            }}
          >
            <LineChart
              data={dailySalesAnalytics}
              title="Sales (This month)"
              xLegend="Days"
              yLegend="Sales"
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} lg={6} md={6}>
          <Box bgcolor='white' sx={{
              borderRadius: '10px',
              m: 1,
              border: '1px solid #DDDDDD',
              height: '400px'
            }}
          >
            <LineChart
              data={dailyOrdersAnalytics}
              title="Orders (This month)"
              xLegend="Days"
              yLegend="Number of Orders"
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
