import {RightDrawerLayout} from 'components/layouts/RightDrawerLayout'
import {Post} from '../../components/Post'

export const DetailView = props => {
  const {open, onClose, data} = props
  return (
    <RightDrawerLayout open={open} onClose={onClose} title="Shorts">
      <Post content={data?.content?.posts[0]} />
    </RightDrawerLayout>
  )
}
