import {DataTable} from '../../components/DataTable'
import {useState} from 'react'
import {DetailView} from './DetailView'
import {ConnectedComponent} from 'components/service/ConnectedComponent'

export const HighLightList = props => {
  const [open, setOpen] = useState(false)
  const [highlight, setHighlight] = useState(null)
  const handleRowClick = value => {
    setHighlight(value)
    setOpen(!open)
  }
  return (
    <div>
      <ConnectedComponent
        Component={DataTable}
        service="reports"
        handleRowClick={handleRowClick}
        config={{query: {$populate: 'user', contentType: 'highlights'}}}
      />
      {open && (
        <ConnectedComponent
          Component={DetailView}
          id={highlight._id}
          service="reports"
          config={{
            query: {
              $populate: [
                {
                  path: 'content',
                  populate: [
                    {
                      path: 'stories',
                      populate: {
                        path: 'posts',
                        populate: [
                          {
                            path: 'pet',
                            populate: ['profile']
                          },
                          {
                            path: 'user',
                            populate: ['profile']
                          },
                          {path: 'media'}
                        ]
                      }
                    }
                  ]
                }
              ]
            }
          }}
          open={open}
          onClose={() => setOpen(false)}
        />
      )}
    </div>
  )
}
