import { Grid, Box, Typography, Card, Divider} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Header2 from "components/Header2";
import { useEffect, useState } from "react";
import {useSelector} from 'react-redux'
import axios from "axios";
import { GET_ACCOUNTS, GET_DWOLLA_ACCESS_TOKEN, GET_DWOLLA_ID, GET_PLAID_BALANCE, IPAY_NET_BASE_URL, currency, enrollHeader } from "utils/Constants";
import { LoadingDialog } from "components/LodingDialog";
import { pink } from '@mui/material/colors';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export const FundingSource = () => {

  const user = useSelector(state => state?.authentication?.user)
  const [fundingSources, setFundingSources] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        setLoading(true)
        const dwollaResult = await axios.post(`${IPAY_NET_BASE_URL}/${GET_DWOLLA_ID}/${user?._id}`, {}, {headers: enrollHeader})

        if (dwollaResult.status === 200 && dwollaResult?.data) {
          const accountsResult = await axios.post(`${IPAY_NET_BASE_URL}/${GET_ACCOUNTS}`, {dwollaId: dwollaResult?.data}, {headers: enrollHeader})

          if (accountsResult.status === 200 && accountsResult?.data?.length) {
            const accounts = accountsResult?.data.map((account) => account?.plaid_id)

            if (accounts?.length) {
              const accessToken = await axios.post(`${IPAY_NET_BASE_URL}/${GET_DWOLLA_ACCESS_TOKEN}`, {dwollaId: dwollaResult?.data}, {headers: enrollHeader})

              if (accessToken.status === 200 && accessToken?.data) {
                const accountBalances = await axios.post(`${IPAY_NET_BASE_URL}/${GET_PLAID_BALANCE}`, {accessToken : accessToken?.data, accounts}, {headers: enrollHeader})

                if (accountBalances?.status === 200 && accountBalances?.data) {
                  setFundingSources(accountBalances?.data)
                  setLoading(false)
                }
              }
            }
          }
        }
      } catch (e) {
        setLoading(false)
        console.log(e)
      }
    }

    fetchBalance()
  }, [user])

  return loading ? (<LoadingDialog />) : (
    <Box sx={{backgroundColor: 'rgb(247, 247, 247)', height: '100vh', display: 'flex', flexDirection: 'column'}}>
      <Header2 heading="Funding Source"/>
      <Box sx={{px: {lg: '4rem', xs: '1rem'}, mt: '5rem'}}>
        <div className='flex mb-4'>
          <AccountBalanceIcon sx={{ fontSize: 32, color: pink[500]  }}/>
          <Typography sx={{fontWeight: 'bold', fontSize: '24px', textAlign: 'left', mx:'8px'}}>Connected Funding Source</Typography>
        </div>
      </Box>
    </Box>
  )
}
