import {all, call, put, takeLatest} from 'redux-saga/effects'
import {actions} from './actions'
import {getErrorAction, getSuccessAction} from '../utility'
import {restClient} from 'client/rest.client'
import {socketClient} from 'client/socket.client'

export function* watchLogin() {
  yield takeLatest(actions.LOGIN, function* ({token, onSuccess, onError}) {
    try {
      const authResult = yield call(() =>
        restClient.authenticate({strategy: 'firebase', access_token: token})
      )
      yield call(() => socketClient.authenticate({strategy: 'jwt', accessToken: authResult.accessToken}))

      const {user} = authResult

      yield put({
        type: getSuccessAction(actions.LOGIN),
        user
      })
      onSuccess(user)
    } catch (e) {
      yield put({type: actions.RESET})
      yield put({
        type: getErrorAction(actions.LOGIN)
      })
      onError('Could not access account due to technical problem, Please report the issue.')
    }
  })
}

export const AuthenticationSaga = function* () {
  yield all([watchLogin()])
}
