import {ConnectedComponent} from 'components/service/ConnectedComponent'
import {useEffect} from 'react'
import {storeSocketClient} from '../client/socket.client'
import {ProductTable} from './component/ProductTable'
import {useParams} from 'react-router-dom'

export const BrandProducts = props => {
  const {id: brand} = useParams()
  const {setNavigationTitle} = props
  useEffect(() => {
    setNavigationTitle('Products')
  }, [])
  console.log('product-list')
  return (
    brand && (
      <ConnectedComponent
        Component={ProductTable}
        service="products"
        client={storeSocketClient}
        config={{
          query: {
            $populate: ['brand', 'taxClass', 'vendor'],
            brand
          }
        }}
      />
    )
  )
}
