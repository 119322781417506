import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme({
  palette: {
    primary: {
      main: '#ED1690',
    },
    purple: {
      main: '#9747FF',
    },
    transparent: {
      main: '#fff',
      contrastText: "#000"
    },
    lightPurple: {
      main: '#fbf8ff',
    },
    dark: {
      main: '#333333',
      contrastText: "#fff"
    },
    secondary: {
      main: '#007AFF',
     },
     grayColor: {
      main: '#FCFCFC',
    },
    lightPink: {
      main: '#FFF3F3',
    },
    allow: {
      main: ' #2E7D32',
      contrastText: "#fff"
    },
    deny: {
      main: ' #ED6C02',
      contrastText: "#fff"
    },
    header: {
      main: '#1C1C1C',
    },
    border: {
      main: '#DCDDE1',
    },
    offWhite: {
      main: '#FAFAFA',
    },
    green: {
      main: '#B6E300',
    },
    grey2: {
      main: '#505155',
    },
    grey3: {
      main: '#989898',
    },
    modalBg: {
      main: '#F5F6FA',
    },
    border: {
      main: '#C4C4C4',
    },
    border2: {
      main: '#DBDBDB',
    },
    petBandge: {
      main: '#D9D9D9;',
    },
    bandgeText: {
      main: '#929292;',
    },
    bandgeBg: {
      main: '#4083A7;',
    },
    pendingColor: {
      main: '#4083A7;',
    },
    checkbox: {
      main: '#2196F3;',
      contrastText: "#fff"
    },
    action: {
      main: '#fff;',
      contrast: '#00E332'
    },
    phylumTable: {
      main: '#727272;',
    },
    failed: {
      main: '#E0E0E0;',
    },
    iframeGray: {
      main: '#9F9F9F;',
    },
    iframeGray2: {
      main: '#60667C;',
    },
    navyBlue: {
      main: '#253253;',
    },
    peach: {
      main: '#EB6582;',
    },
  },
})

export const Theme = (props) => {
  return <ThemeProvider theme={theme}>
    {props.children}
  </ThemeProvider>
}
