import { Grid, Typography, Button } from "@mui/material"
import { AppLayout } from "modules/layout/AppLayout"
import users from 'images/users.svg';
import playFill from 'images/play_fill.svg'
import petFinder from 'images/petFinder.svg'
import uploadimg from 'images/newimages/uploading.png'
import lostPets from 'images/lostPets.svg'
import flag from 'images/flagged.svg'
import { useState } from "react";
import { AddDietModal } from "./modals/AddDietModal";
import { EnrollPetModal } from "./modals/EnrollPetModal";
import { WellnessModal } from "./modals/WellnessModal";
import { PetBadgesModal } from "./modals/PetBadgesModal";
import { MonitoringModal } from "./modals/MonitoringModal";
import { VaccineModal } from "./modals/VaccineModal";
export const PetsDashboard = () => {
  const [age, setAge] = useState('');
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [openEnrolModal, setOpenEnrolModal] = useState(false);
  const [openDietModal, setOpenDietModal] = useState(false);
  const [openWellnessModal, setOpenWellnessModal] = useState(false);
  const [openVaccineModal, setOpenVaccineModal] = useState(false);
  const [openBandageModal, setOpenBandageModal] = useState(false);
  const [openMonitaringModal, setOpenMonitaringModal] = useState(false);

  const handleEnrolModal = () => {
    setOpenEnrolModal(!openEnrolModal);
  };
  const handleDietModal = () => {
    setOpenDietModal(!openDietModal);
  };
  const handleWellnessModal = () => {
    setOpenWellnessModal(!openWellnessModal);
  };
  const handleVaccineModal = () => {
    setOpenVaccineModal(!openVaccineModal);
  };
  const handleBandageModal = () => {
    setOpenBandageModal(!openBandageModal);
  };
  const handleMonitaringModal = () => {
    setOpenMonitaringModal(!openMonitaringModal);
  };
  return (
    <AppLayout heading='Pets Dashboard'>
      <Grid container gap="10px" alignItems="center">
        <Grid
          item
          boxShadow="custom"
          bgcolor="white"
          xs={12}
          lg={2}
          md={3}
          sx={{
            display: "block",
            borderRadius: "10px",
            p: 3,
            border: "1px solid #DDDDDD",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <img src={users} alt="img" />
          </Grid>

          <Typography fontSize="12px" my="4px">
            App Users
          </Typography>
          <Typography
            variant="h4"
            fontSize="26px"
            sx={{ fontWeight: "bold", color: "#09244B" }}
          >
            2,765
          </Typography>
          <Typography
            sx={{
              color: "#2AC670",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img src={playFill} alt="play" />
            %10
          </Typography>
        </Grid>
        <Grid
          item
          boxShadow="custom"
          bgcolor="white"
          xs={12}
          lg={2}
          md={3}
          sx={{
            display: "block",
            borderRadius: "10px",
            border: "1px solid #DDDDDD",
            p: 3,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <img src={petFinder} alt="img" />
          </Grid>

          <Typography fontSize="12px" my="4px">
            Pet Finders
          </Typography>
          <Typography
            variant="h4"
            fontSize="26px"
            sx={{ fontWeight: "bold", color: "#09244B" }}
          >
            253
          </Typography>
          <Typography
            sx={{
              color: "#2AC670",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img src={playFill} alt="pet" />
            %10
          </Typography>
        </Grid>

        <Grid
          item
          boxShadow="custom"
          bgcolor="white"
          xs={12}
          lg={2}
          md={3}
          sx={{
            display: "block",
            borderRadius: "10px",
            p: 3,
            border: "1px solid #DDDDDD",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <img src={lostPets} alt="img" />
          </Grid>

          <Typography style={{cursor: 'pointer'}} onClick={handleMonitaringModal} fontSize="12px" my="4px">
            Lost Pets
          </Typography>
          <Typography
            variant="h4"
            fontSize="26px"
            sx={{ fontWeight: "bold", color: "#09244B" }}
          >
            896
          </Typography>
          <Typography
            sx={{
              color: "#2AC670",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img src={playFill} alt="pet" />
            %10
          </Typography>
        </Grid>
        <Grid
          item
          boxShadow="custom"
          bgcolor="white"
          xs={12}
          lg={2}
          md={3}
          sx={{
            display: "block",
            borderRadius: "10px",
            p: 3,
            border: "1px solid #DDDDDD",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <img src={flag} alt="img" />
          </Grid>

          <Typography style={{cursor: 'pointer'}} onClick={handleBandageModal} fontSize="12px" my="4px">
            Flagged Users
          </Typography>
          <Typography
            variant="h4"
            fontSize="26px"
            sx={{ fontWeight: "bold", color: "#09244B" }}
          >
            896
          </Typography>
          <Typography
            sx={{
              color: "#2AC670",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img src={playFill} alt="pet" />
            %10
          </Typography>
        </Grid>
        <Grid
          item
          boxShadow="custom"
          bgcolor="white"
          xs={12}
          lg={2}
          md={3}
          sx={{
            display: "block",
            borderRadius: "10px",
            p: 3,
            border: "1px solid #DDDDDD",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <img src={lostPets} alt="img" />
          </Grid>

          <Typography style={{cursor: 'pointer'}} onClick={handleVaccineModal} fontSize="12px" my="4px">
            Adoptions
          </Typography>
          <Typography
            variant="h4"
            fontSize="26px"
            sx={{ fontWeight: "bold", color: "#09244B" }}
          >
            896
          </Typography>
          <Typography
            sx={{
              color: "#2AC670",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img src={playFill} alt="pet" />
            %10
          </Typography>
        </Grid>
      </Grid>

      <AddDietModal open={openDietModal} handleModal={handleDietModal} age={age} handleChange={handleChange}/>
      <EnrollPetModal open={openEnrolModal} handleModal={handleEnrolModal} age={age} handleChange={handleChange}/>
      <WellnessModal open={openWellnessModal} handleModal={handleWellnessModal} />
      <PetBadgesModal open={openBandageModal} handleModal={handleBandageModal} />
      <MonitoringModal open={openMonitaringModal} handleModal={handleMonitaringModal} />
      <VaccineModal open={openVaccineModal} handleModal={handleVaccineModal} />
    </AppLayout>
  )
}
