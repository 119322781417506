import React, {useState} from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import {Box, CircularProgress, IconButton, Tooltip, Typography} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import style from './css/image-uploader.css'
import Close from '@mui/icons-material/Close'
import axios from 'axios'
import {AUTHENTICATION_KEY} from 'client/common'
export const ImageUploader = props => {
  const {isMultiple = false, title, selectedImages, setSelectedImages, media, setMedia} = props
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const headers = {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
    Authorization: window.localStorage.getItem(AUTHENTICATION_KEY)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setSelectedImages([])
    setOpen(false)
  }

  const handleUploadImage = () => {
    setLoading(true)
    const formData = new FormData()
    formData.append('files', selectedImages[0].file)
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/media`, formData, {headers})
      .then(result => {
        setMedia(result.data)
        setLoading(false)
        handleClose()
      })
      .catch(e => {
        console.log(e)
        setLoading(false)
        handleClose()
      })
  }

  const handleImageChange = event => {
    const files = event.target.files
    const reader = new FileReader()
    reader.onloadend = () => {
      setSelectedImages([{file: files[0], base64: reader.result}])
    }
    reader.readAsDataURL(files[0])
  }

  const handleRemoveImage = index => {
    const updatedImages = [...selectedImages]
    updatedImages.splice(index, 1)
    setSelectedImages(updatedImages)
  }

  return (
    <div>
      <Tooltip title={title}>
        <IconButton onClick={handleOpen} color="primary">
          <AddPhotoAlternateIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between" pb={2.5}>
            <Typography
              variant="h5"
              component="h5"
              color="transparent.contrastText"
              sx={{fontSize: '24px', fontWeight: '600'}}
              style={{width: '100%'}}
            >
              {title}
            </Typography>
            <Box textAlign="right" width="100%">
              <IconButton onClick={handleClose}>
                <Close onClick={handleClose} />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className="upload-dialog-container">
          <div id="upload-area" className="upload-area">
            <div className="drag-drop-label">Drag & Drop images here</div>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{width: '100%', height: '100%', opacity: 0}}
              multiple
            />
          </div>
          <div
            style={{
              maxHeight: 'calc(100vh - 350px)', // Set the max height of the scrollable div
              overflowY: 'auto', // Enable vertical scrolling
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: '10px'
            }}
          >
            {selectedImages.map((image, index) => (
              <div key={index} style={{position: 'relative', padding: '8px'}}>
                <IconButton
                  style={{position: 'absolute', top: 2, right: 1, zIndex: 1, color: '#5A5A5A'}}
                  onClick={() => handleRemoveImage(index)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
                <img src={image.base64} alt={`Uploaded Preview ${index + 1}`} className="preview" />
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUploadImage} color="primary" variant="contained" disabled={loading}>
            Upload{' '}
            {loading && <CircularProgress style={{width: '18px', height: '18px', marginLeft: '8px'}} />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
