import {Avatar} from '@mui/material'
import {Description} from './Description'
import SecurityIcon from '@mui/icons-material/Security'
import {Button} from '@mui/material'
import TourIcon from '@mui/icons-material/Tour'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'
import EventNoteIcon from '@mui/icons-material/EventNote'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'

export const Tribute = props => {
  const petSrc = 'https://s3-media0.fl.yelpcdn.com/bphoto/m_ev79oAYkIFLlTh9wQdWg/300s.jpg'
  const avtarSrc = 'https://variety.com/wp-content/uploads/2021/04/Avatar.jpg'
  return (
    <div className="m-4  flex flex-col ">
      <div className="rounded-xl p-4" style={{border: '1px solid #E0E0E0'}}>
        <div className="flex flex-col">
          <div className="flex justify-between">
            <div className="flex  items-center">
              <Avatar src={avtarSrc} />
              <div className="mx-2">
                <p className>Pavan Rajput</p>
                <p className="text-xs text-slate-600">@pavanrajput21</p>
              </div>
            </div>
            <div>
              <SecurityIcon style={{color: '#4caf50'}} />
            </div>
          </div>
          <div className="flex justify-center items-center my-4">
            <img
              src="https://s3-media0.fl.yelpcdn.com/bphoto/m_ev79oAYkIFLlTh9wQdWg/300s.jpg"
              alt="Pet media"
              className="w-full h-72	object-cover rounded-xl"
            />
          </div>

          <div>
            <div className="flex mb-1">
              <VolunteerActivismIcon className="!mr-2 !text-xl !text-gray-400" />
              <span className="text-base font-bold">Eulogy</span>
            </div>
            <Description
              description="In cherished memory of Papaaya, who brought us boundless joy and unconditional love. Forever in our hearts, [his/her] spirit lives on. 🌟🐶 #FurryFriend #PetLove #UnconditionalLove"
              className="text-sm"
            />
          </div>

          <div className="my-2">
            <div className="flex mb-1">
              <EventNoteIcon className="!mr-2 !text-xl !text-gray-400" />
              <span className="text-base font-bold">Obituary</span>
            </div>
            <Description
              description="Our beloved Golden Retriever, Papaaya, passed away December 31, 2019, in the comfort of our home wrapped in his favorite blanket, and surrounded by the entire family."
              className="text-sm"
            />
          </div>

          <div>
            <div className="flex mb-1">
              <CalendarMonthIcon className="!mr-2 !text-xl !text-gray-400" />
              <span className="text-base font-bold">Date of Death</span>
            </div>
            <span className="text-sm">23 August 2023</span>
          </div>

          <div className="flex items-center justify-between mt-4">
            <div className="flex items-center">
              <Avatar src={petSrc} />
              <p className="mx-2">Papaaya</p>
            </div>
            <div>
              <p className="text-xs text-slate-600">Last Update: 20 August 2023</p>
            </div>
          </div>
        </div>

        <div className="w-full mt-4 flex flex-col">
          <div>
            <Button variant="contained">Looks Safe</Button>
            <Button variant="outlined" className="!mx-4">
              <TourIcon />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
