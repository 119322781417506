import { useState } from "react"
import {Box, Button, Typography, Grid} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const initialInputWidth = 40

export const TransactionActionPanel = (props) => {
  const {amount, setAmount, onClose, onPay, onRequest} = props
  const [inputWidth, setInputWidth] = useState(initialInputWidth)

  const onAmountChange = (e) => {
    let value = e.target.value
    value = value.replace(/[^0-9.]/g, '');
    value = value.replace(/(\..*)\./g, '$1');
    setInputWidth(value.length === 0 ? initialInputWidth : initialInputWidth * e.target.value.length)
    setAmount(value)
  }
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
      <Box sx={{display: 'flex', justifyContent:'end', width: '100%'}} >
        <CloseIcon color="primary" onClick={onClose} />
      </Box>
      <Box height="410px" >
        <Box  sx={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', width: '100%', height: '100%'}}>
          <Box mb={2} sx={{display: 'flex', flex: '1', justifyContent:'center', alignItems:'center'}}>
            <Typography fontSize="64px">$</Typography>
            <input
              placeholder="0"
              value={amount}
              onChange={onAmountChange}
              style={{
                borderWidth: 0,
                padding: 0,
                paddingLeft: '4px',
                fontSize: '64px',
                width: `${inputWidth}px`,
                height: '72px'
              }}
              className="focus:outline-none"
              inputMode="numeric"
              autoFocus
            />
          </Box>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <Button
                variant="outlined"
                color="primary"
                onClick={onRequest}
                disabled={!amount}
                sx={{width: 120}}
              >
                Request
              </Button>
            </Grid>
            <Grid xs={6} item>
              <Button
                variant="contained"
                color="primary"
                onClick={onPay}
                disabled={!amount}
                sx={{width: 120}}
              >
                Pay
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
  </Box>
  )
}
