import {CloseSharp} from '@mui/icons-material'
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Chip,
  Typography,
} from '@mui/material'
import {storeSocketClient} from 'modules/store/client/socket.client'
import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react'
import { currency } from 'utils/Constants'
import {sumBy, reduce} from 'lodash'
import moment from 'moment'
import { ImagePreview } from 'components/ImagePreview'

export const OrderDetailModal = forwardRef((props, ref) => {
  const [id, setId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [order, setOrder] = useState({})
  const [products, setProducts] = useState([])
  const [drawerProps, setDrawerProps] = useState({})

  useImperativeHandle(
    ref,
    () => ({
      show(id, drawerProps) {
        setId(id)
        setOpen(true)
        setDrawerProps(drawerProps)
      }
    }),
    []
  )

  useEffect(() => {
    if (!id) {
      return
    }

    setLoading(true)

    storeSocketClient
      .service('orders')
      .get(id, {
        query: {
          $populate: ['user', 'guest', 'address', {
            path: 'products',
            populate: {
              path : 'product',
              populate: ['taxClass', 'images', 'vendor']
            }
          }]
        }
      })
      .then(data => {
        setOrder(data)
        setProducts(data?.products?.length ? data.products : [])
        setLoading(false)
      })
  }, [id])

  const getItemTotalPrice = (item) => {
    let productPrice = item.price * item.quantity
    if (item.options.length) {
      const additionalPrice = sumBy(item.options, 'value.additionalPrice')
      productPrice += additionalPrice
    }
    if (item?.product?.taxClass?.percentage) {
      const tax = productPrice * item?.product?.taxClass?.percentage / 100
      productPrice += tax
    }
    return productPrice
  }

  const getAddress = (address) => {
    if (address) {
      const {houseFlatNo, locality, street, city, state, zipCode, country } = address
      const flat = `${houseFlatNo},${locality ? ` ${locality}, `: ''} ${street ? ` ${street}, ` : ''}`
      return `${flat} ${city}, ${state}, ${country}-${zipCode}`
    }
    return ''
  }

  const subTotal = useMemo(() => {
    if (!products.length) return 0

    return reduce(products, (total, item) => {
      let additionalPrice = 0
      const basePrice = item.price

      if (item?.options?.length) {
        additionalPrice = reduce(item?.options, (total, _item) => {
          const _additionalPrice = _item?.value?.isBaseOption ? 0 : _item?.value?.additionalPrice
          return total + _additionalPrice
        }, 0)
      }

      let subTotal = (basePrice + additionalPrice) * item?.quantity
      return total + subTotal
    }, 0)

  }, [products])

  const taxAmount = useMemo(() => {
    if (!products.length) return 0

    return reduce(products, (total, item) => {
      let additionalPrice = 0
      let taxAmount = 0
      const basePrice = item.price

      if (item?.options?.length) {
        additionalPrice = reduce(item?.options, (total, _item) => {
          const _additionalPrice = _item?.value?.isBaseOption ? 0 : _item?.value?.additionalPrice
          return total + _additionalPrice
        }, 0)
      }

      let subTotal = (basePrice + additionalPrice) * item?.quantity

      const tax = item?.product?.taxClass
      if (tax?.percentage) {
        taxAmount = subTotal / 100 * tax?.percentage
      }

      return total + taxAmount
    }, 0)

  }, [products])

  const invoiceAmount = useMemo(() => {
    if (!products.length) return 0

    return reduce(products, (total, item) => {
      let additionalPrice = 0
      let taxAmount = 0
      const basePrice = item.price

      if (item?.options?.length) {
        additionalPrice = reduce(item?.options, (total, _item) => {
          const _additionalPrice = _item?.value?.isBaseOption ? 0 : _item?.value?.additionalPrice
          return total + _additionalPrice
        }, 0)
      }

      let subTotal = (basePrice + additionalPrice) * item?.quantity

      const tax = item?.product?.taxClass
      if (tax?.percentage) {
        taxAmount = subTotal / 100 * tax?.percentage
      }

      return total + subTotal + taxAmount
    }, 0)

  }, [products])

  return (
    <Drawer
      open={open}
      {...drawerProps}
      PaperProps={{style: {width: 'auto'}}}
      ModalProps={{
        onClose: () => {
          setOrder(null)
          setOpen(false)
        }
      }}
    >
      <Box className="p-4">
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          Order Detail
          <IconButton onClick={() => setOpen(false)}>
            <CloseSharp />
          </IconButton>
        </Box>
        <Divider my={2}/>
        <div className='flex flex-col md:flex-row'>
          <div className='w-[20rem]'>
            <Typography fontSize="18px" my={1}>
              {
                order?.guest
                  ? `${order?.guest.firstName} ${order?.guest.lastName}`
                  : `${order?.user?.firstName} ${order?.user?.lastName}`
              }
            </Typography>
            <Typography fontSize="14px"  mb={2}>
              <span className="!font-bold">Order Id</span> : <span>{order?._id}</span>
            </Typography>
            <Typography fontSize="14px"  mb={2}>
              <span className="!font-bold">Order placed</span> : <span>{moment(order?.createdAt).format('MMM Do YY')}</span>
            </Typography>
            <Typography fontSize="14px"  mb={2}>
              <span className="!font-bold">Status</span> : <Chip label="Pending" size="small" variant="outlined" color="warning"/>
            </Typography>
            <Typography fontSize="14px" >
              <span className="!font-bold">Delivery Address</span> :
            </Typography>
            <Typography fontSize="14px"  mb={2} pr={2}>
              <span>{getAddress(order?.guest ? order?.guest : order?.address)}</span>
            </Typography>
            {order?.billingAddress &&
              <>
                <Typography fontSize="14px" >
                  <span className="!font-bold">Billing Address</span> :
                </Typography>
                <Typography fontSize="14px"  mb={2} pr={2}>
                  <span>{getAddress(order?.billingAddress)}</span>
                </Typography>
              </>
            }
            {order?.note &&
              <>
                <Typography fontSize="14px" >
                  <span className="!font-bold">Additional Note</span> :
                </Typography>
                <Typography fontSize="14px"  mb={2} pr={2}>
                  <span>{order?.note}</span>
                </Typography>
              </>
            }
          </div>
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{mx: 2}}
            flexItem
          />
          <div className='w-[16rem]'>
            <div className="flex justify-between mt-4 mb-2">
              <Typography fontSize="16px" fontWeight="bold">
                Sub Total
              </Typography>
              <Typography fontSize="16px">
                {currency(subTotal)}
              </Typography>
            </div>
            <div className="flex justify-between">
              <Typography fontSize="16px" fontWeight="bold">
                Tax
              </Typography>
              <Typography fontSize="16px">
                {currency(taxAmount)}
              </Typography>
            </div>
            <Divider sx={{my: 2}}/>
            <div className="flex justify-between">
              <Typography fontSize="16px" fontWeight="bold">
                Total
              </Typography>
              <Typography fontSize="16px">
                {currency(invoiceAmount)}
              </Typography>
            </div>
          </div>
        </div>
        <Divider />
        <Box pb="0.5rem" mx={2}>
            {order?.products?.map((item, ind) => (
              <Box
                sx={{display: 'flex', flexDirection: 'column'}}
                key={ind}
              >
                <Box
                  py="0.5rem"
                  sx={{display: 'flex', flexWrap: 'wrap', alignItems:"center", width: '100%'}}
                >
                  <Box sx={{display: 'flex', flex: '2 2 260px', m:"6px"}}  >
                    <ImagePreview
                      client={storeSocketClient}
                      service="media"
                      media={item.product.images[0]}
                      className="w-28 h-28 object-cover rounded-xl"
                    />
                    <Box sx={{display: 'flex', flexDirection: 'column', width:"100%", px:1}}>
                      <div className="flex justify-between">
                        <Typography fontSize="20px" >{item?.product?.name}</Typography>
                        <Typography fontWeight={600} color="primary" mr="1rem" fontSize="1.2rem">
                          {currency(getItemTotalPrice(item))}
                        </Typography>
                      </div>
                      <div className="flex justify-between mt-1">
                        <div className="flex flex-col">
                          <Typography fontSize="12px" fontWeight="bold">Price & Quantity</Typography>
                          <Typography fontSize="12px" color="text.muted">
                            {currency(item?.price)} x {item?.quantity}
                          </Typography>
                        </div>
                        <div className="flex flex-col">
                          <Typography fontSize="12px" fontWeight="bold">Tax</Typography>
                          <Typography fontSize="12px" color="text.muted">
                          {item?.product?.taxClass ?
                            `${item?.product?.taxClass?.name}-${item?.product?.taxClass?.percentage}%` :
                            '-'
                          }
                          </Typography>
                        </div>
                        <div className="flex flex-col">
                          <Typography fontSize="12px" fontWeight="bold">Sold By</Typography>
                          <Typography fontSize="12px" color="text.muted">
                          {item?.product?.vendor ?
                            `${item?.product?.vendor?.firstName} ${item?.product?.vendor?.lastName}` :
                            '-'
                          }
                          </Typography>
                        </div>
                      </div>
                      {(item?.options && item?.options.length > 0) &&
                        <div className="flex flex-col mt-2">
                          <Typography fontSize="12px" fontWeight="bold">Selected Options</Typography>
                          <div className='flex flex-wrap'>
                            {
                              item?.options.map((i) => (
                                <Chip
                                  variant='outlined'
                                  size="small"
                                  color="info"
                                  label={`${i?.value?.name}-${currency(i?.value?.additionalPrice)}`}
                                />
                              ))
                            }
                          </div>
                        </div>
                      }
                    </Box>
                  </Box>
                </Box>
                <Divider className="!mb-4" />
              </Box>
            ))}
          </Box>
      </Box>
    </Drawer>
  )
})
