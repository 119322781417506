import {Box, Button, Checkbox, FormControlLabel, Modal, TextField} from '@mui/material'
import {connectService} from 'components/service/connectService'
import {Component, useEffect, useState} from 'react'
import {ModalLayout} from 'components/layouts/ModalLayout'
import {ImageUploader} from 'components/ImageUploader'
import {useFormik} from 'formik'
import {NumberTextField} from 'components/NumberTextField'
import PropTypes from 'prop-types'
/**
 * AddEditModal
 *
 * @param {string} id - pet-finder object id
 * @param {object} data - It is object of lost pet detail.
 * @param {function} setData - setter function
 * @param {function} handleModal
 * @param {boolean} open - boolean value to manage modal open/close state
 * @param {function} setOpen - setter function to set open (true, false)
 * @param {boolean} loading - boolean value represent Loader state
 * @param {Component} Loader - component to show loading
 * @param {function} patch - function to send patch request
 */

export const AddEditModal = connectService(props => {
  const {open, handleModal, id, data, setData, setOpen, loading, Loader, patch} = props
  const [icon, setIcon] = useState('/assets/icons/pet-types/insects.svg')
  const [selectedImages, setSelectedImages] = useState([])

  const [media, setMedia] = useState([])

  const formik = useFormik({
    initialValues: {
      description: id && data?.description ? data.description : '',
      rewardAmount: id && data?.rewardAmount ? data?.rewardAmount : 0.0,
      claimed: id && data?.claimed ? data?.claimed : false
    },
    enableReinitialize: true,
    onSubmit: values => {
      const payload = {...values}
      patch(id, payload)
    }
  })

  const onClose = () => {
    formik.resetForm()
    setData({})
    setOpen(false)
  }

  useEffect(() => {
    if (!id) {
      setData({}) // create old persisted data
    }
  }, [id])

  useEffect(() => {}, [media])

  return (
    <Modal
      open={open}
      onClose={() => {
        formik.resetForm()
        handleModal()
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout
        title={`${id ? 'Edit' : 'Add'} lost pet detail`}
        onClose={onClose}
        LoaderComponent={Loader}
        loading={loading}
      >
        <form onSubmit={formik.handleSubmit}>
          <TextField
            className="w-full "
            id="description"
            name="description"
            label="Description"
            variant="outlined"
            multiline
            rows={2}
            value={formik.values.description}
            onChange={formik.handleChange}
          />
          <NumberTextField
            id="outlined-number"
            type="number"
            className="!mt-4 w-full"
            name="rewardAmount"
            label="Reward Amount"
            variant="outlined"
            inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
            value={formik.values.rewardAmount}
            onChange={formik.handleChange}
            onInput={event => {
              event.target.value = event.target.value.replace(/^-/, '')
            }}
          />

          <div className="my-4">
            <div className="flex justify-between items-center">
              <ImageUploader
                title="Upload Image"
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
                setMedia={setMedia}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.claimed}
                    onChange={e => formik.setFieldValue('claimed', e.target.checked)}
                  />
                }
                label="Claimed"
              />
            </div>
          </div>
          <Box>
            <Button variant="contained" color="primary" fullWidth size="large" type="submit">
              {id ? 'Edit' : 'Submit'}
            </Button>
          </Box>
        </form>
      </ModalLayout>
    </Modal>
  )
}, 'pet-finder')

AddEditModal.prototype = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object,
  setData: PropTypes.func,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  handleModal: PropTypes.func,
  loading: PropTypes.loading,
  Loader: PropTypes.elementType,
  patch: PropTypes.func
}
