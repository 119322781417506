import {Typography, Button} from '@mui/material'
import PetsIcon from '@mui/icons-material/Pets'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import LandscapeIcon from '@mui/icons-material/Landscape'
import InfoIcon from '@mui/icons-material/Info'
import CakeIcon from '@mui/icons-material/Cake'
import {useMemo} from 'react'
import moment from 'moment'
import {useLocation, useNavigate} from 'react-router-dom'

export const BasicDetail = props => {
  const {data} = props
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const code = searchParams.get('code')

  const age = useMemo(() => {
    if (data?.birthdate) {
      const years = moment().diff(data?.birthdate, 'years')
      const months = moment().diff(data?.birthdate, 'months') % 12
      return `${years ? `${years} years` : ''}  ${months ? `${months} month` : ''}`
    }
    return null
  }, [data])
  return (
    <div className="flex items-start flex-col lg:ml-6  mx-2">
      {data?.name && <Typography variant="h5">{data?.name}</Typography>}

      {(data?.type?.name || data?.subtype?.name) && (
        <div className="flex items-center justify-center my-3 lg:mt-0">
          <PetsIcon style={{fontSize: '1.10rem'}} color="primary" className="mr-2" /> {data?.type?.name}
          {data?.type?.name && data?.subtype?.name && <span className="bullet mx-2">●</span>}
          {data?.subtype?.name}
        </div>
      )}

      <div className="flex flex-col item-start ">
        {data?.birthdate && (
          <div className="flex items-center">
            <CakeIcon style={{fontSize: '1.10rem'}} color="primary" className="mr-2" />
            {moment.utc(data?.birthdate).format('DD MMM YYYY')}
          </div>
        )}

        {age && (
          <div className="flex items-center mt-2">
            <LandscapeIcon style={{fontSize: '1.10rem'}} color="primary" className="mr-2" /> {age}
          </div>
        )}

        {data?.size && (
          <div className="flex items-center mt-2">
            <FitnessCenterIcon style={{fontSize: '1.10rem'}} color="primary" className="mr-2" />
            {data?.size} lbs
          </div>
        )}
      </div>

      {data?.description && (
        <Typography align="left" className="!mt-2">
          <InfoIcon style={{fontSize: '1.10rem'}} color="primary" className="mr-2" />
          {data?.description}
        </Typography>
      )}

      <Button
        variant="contained"
        className="!font-bold !w-full !my-2"
        // onClick={() => navigate(`/scan-report/create/${code}`)}
      >
        Seen or Found?
      </Button>
    </div>
  )
}
