import { Box,  Dialog,  DialogContent} from '@mui/material';
import { TransactionActionPanel } from './TransactionActionPanel';
import { useState } from 'react';
import { ContactSelectionPanel } from './ContactSelectionPanel';
import TransactionSuccess from './TransactionSuccess';

export const SendMoneyModel = (props) => {
  const {open, onClose} = props

  const [amount, setAmount] = useState('')
  const [step, setStep]  = useState(0)
  const [selected, setSelected] = useState(null)

  const handleClose = () => {
    setStep(0)
    setAmount('')
    setSelected(null)
    onClose()
  }


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          width: {xs: '100%', sm: '50%', md:'40%', lg:'25%'}
        }
      }}
    >
      <DialogContent>
        <Box>
          {step === 0 &&
            <TransactionActionPanel
              amount={amount}
              setAmount={setAmount}
              onClose={handleClose}
              onPay={() => setStep(1)}
              onRequest={() => setStep(1)}
            />
          }

          {step === 1 &&
            <ContactSelectionPanel
              amount={amount}
              onClose={handleClose}
              onPay={() => setStep(2)}
              selected={selected}
              setSelected={setSelected}
            />
          }

          {step === 2 &&
            <TransactionSuccess
              amount={amount}
              payee={selected}
              onClose={handleClose}
            />
          }
        </Box>
      </DialogContent>
    </Dialog>
  )
}
