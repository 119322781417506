import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import {ConnectedComponent} from 'components/service/ConnectedComponent'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {AutoCompleteSearch} from './AutoCompleteSearch'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {ImageUploader} from 'modules/pet-found/scan-report/component/ImageUploader'
import appLogo from 'images/app_logo.png'
import {useMediaUpload} from 'hooks/useMediaUpload'
import {socketClient} from 'client/socket.client'
import {AuthActions} from 'store/modules/authentication/actions'
import {useDispatch, useSelector} from 'react-redux'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {useContext} from 'react'
import {Navigate, useLocation, useNavigate, useParams} from 'react-router-dom'
import {MapLocationFinder} from 'modules/pet-found/scan-report/component/MapLocationFinder'
import {useGeoCurrentLocation} from 'hooks/useGeoCurrentLocation'

const validationSchema = Yup.object({
  name: Yup.string().required('Please enter pet name'),
  birthdate: Yup.date().required('Please select Birth Date'),
  size: Yup.number().required('Please enter pet weight'),
  type: Yup.string().required('Please select pet type'),
  subtype: Yup.string().required('Please select pet sub-type')
})

const statusOptions = ['Spayed', 'Neutered', 'Open to Mate', 'Open to Breed']

const initialValues = {
  name: '',
  birthdate: new Date(),
  type: '',
  subtype: '',
  status: '',
  size: '',
  description: '',
  privacy: false
}

export const CreatePetProfile = () => {
  const [selectedImages, setSelectedImages] = useState(null)
  const {loading: fileUploading, uploadMedia} = useMediaUpload()
  const [petLocation, setPetLocation] = useState(['-74.006', '40.7128'])
  const {getCurrentLocation} = useGeoCurrentLocation()
  const user = useSelector(state => state.authentication.user)
  const {setSeverity, showToast} = useContext(SnackbarContext)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const location = useLocation()
  const {code} = useParams()

  useEffect(() => {
    askForLocation()
  }, [])

  const handleToast = (message, status) => {
    setSeverity(status)
    showToast(message)
  }

  const askForLocation = () => {
    getCurrentLocation()
      .then(location => {
        console.log(location)
        setPetLocation(location)
      })
      .catch(e => {
        console.log(e)
        handleToast(e, 'error')
      })
  }

  const handleSubmit = async values => {
    let media = []
    if (selectedImages?.file) {
      media = await uploadMedia(selectedImages.file)
    }

    const {status, ...rest} = values
    const payload = {...rest, age: moment().diff(values?.birthdate, 'years')}

    if (media.length > 0) {
      payload['profile'] = media[0]?._id
    }

    if (status) {
      payload['status'] = status
    }

    if (code) {
      payload['code'] = code
    }

    payload['location'] = petLocation

    console.log(payload)
    socketClient
      .service('pets')
      .create({...payload})
      .then(result => {
        if (!user?.roles?.includes('admin')) {
          localStorage.removeItem('auth')
          dispatch(AuthActions.resetAuth())
        }
        setSeverity('success')
        showToast('Pet profile created successfully')
        navigate('/', {replace: true, state: {from: '/'}})
      })
      .catch(e => {
        setSeverity('error')
        showToast('Failed to create pet profile. Please try again')
      })
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      handleSubmit(values)
    }
  })

  window.addEventListener('popstate', function (event) {
    if (event.state) {
      if (user && !user?.roles?.includes('admin')) {
        dispatch(AuthActions.resetAuth())
      }
      navigate(-1)
    }
  })

  return !user ? (
    <Navigate to="/authentication/login" state={{from: location.pathname}} />
  ) : (
    <div className="p-4 md:p-4 w-full lg:w-3/4	xl:w-3/5 2xl:w-1/2 mx-auto">
      <div className="w-full flex justify-center mt-2 mb-4">
        <img src={appLogo} />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            border: '1px solid #E0E0E0',
            borderRadius: '8px'
          }}
        >
          <div className="flex flex-col-reverse md:flex-row p-2 md:px-8">
            <div className="w-full md:w-4/6 lg:w-3/5 flex">
              <div className="w-full px-2 md:px-0">
                <div className="flex flex-col md:flex-row w-full overflow-auto md:pt-4">
                  <div className="w-full md:w-1/2 md:mt-2 md:mr-4">
                    <TextField
                      id="name"
                      name="name"
                      label="Name"
                      variant="outlined"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      fullWidth
                    />
                  </div>

                  <div className="w-full md:w-1/2 mt-4 md:mt-0 ">
                    <LocalizationProvider dateAdapter={AdapterMoment} className="!w-full">
                      <DemoContainer components={['DatePicker']} className="!w-full">
                        <DatePicker
                          label="Birth Date"
                          value={moment(formik.values.birthdate)}
                          onChange={date => formik.setFieldValue('birthdate', date)}
                          renderInput={params => (
                            <TextField {...params} variant="outlined" className="!w-full" />
                          )}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row w-full my-4 justify-between">
                  <ConnectedComponent
                    Component={AutoCompleteSearch}
                    id={formik?.values?.type}
                    service="pet-types"
                    className="w-full md:w-1/2 md:mr-2"
                    onChange={value => formik.setFieldValue('type', value?._id)}
                    label="Search pet type"
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  />

                  <ConnectedComponent
                    Component={AutoCompleteSearch}
                    service="pet-subtypes"
                    id={formik?.values?.subtype}
                    className="w-full md:w-1/2 mt-4 md:mt-0 md:ml-1"
                    onChange={value => formik.setFieldValue('subtype', value?._id)}
                    label="Search pet sub-type"
                    error={formik.touched.subtype && Boolean(formik.errors.subtype)}
                    helperText={formik.touched.subtype && formik.errors.subtype}
                  />
                </div>

                <div className="flex flex-col md:flex-row w-full mb-4 justify-between">
                  <FormControl className="w-full md:w-1/2 !mr-2">
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={formik.values.status}
                      label="Status"
                      name="status"
                      onChange={formik.handleChange}
                      className="!text-left"
                    >
                      {statusOptions.map((status, index) => (
                        <MenuItem key={index} value={status} className="!text-left">
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="w-full md:w-1/2 mt-4 md:mt-0 md:ml-1">
                    <TextField
                      className="w-full"
                      id="size"
                      name="size"
                      label="Size(lbs)"
                      type="number"
                      variant="outlined"
                      value={formik.values.size}
                      onChange={formik.handleChange}
                      error={formik.touched.size && Boolean(formik.errors.size)}
                      helperText={formik.touched.size && formik.errors.size}
                    />
                  </div>
                </div>

                <div className="w-full mb-4">
                  <TextField
                    className="w-full "
                    id="description"
                    name="description"
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  />
                </div>

                <div className="mx-2 flex justify-between mb-2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="privacy"
                        name="privacy"
                        color="primary"
                        checked={formik.values.privacy}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Private"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-2/6	lg:w-2/5 md:flex md:justify-center py-4 md:ml-8">
              <div>
                <ImageUploader selectedImages={selectedImages} setSelectedImages={setSelectedImages} />
              </div>
            </div>
          </div>
          <div className="px-2 md:px-8">
            <div className="w-full mt-1">
              <MapLocationFinder
                lat={petLocation[1]}
                lng={petLocation[0]}
                height="400px"
                onLocationChange={location => setPetLocation(location)}
              />
            </div>
            <p className="text-start text-sm text-slate-600 mt-2">
              We've automatically detected your current location. If the location is incorrect, please ensure
              your device's location services are enabled. You can manually adjust and drop a pin to provide a
              different location if needed.
            </p>
          </div>
          <div className="flex justify-start m-2 md:m-8">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="mt-4"
              disabled={fileUploading}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}
