import {socketClient} from 'client/socket.client'
import {useState} from 'react'

export const useSearchBy = () => {
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [error, setError] = useState(null)

  const searchBy = (client = socketClient, service, property = null, value = null) => {
    setLoading(true)
    const query = {
      $limit: 10,
      $skip: 0
    }
    if (value?.length >= 3) {
      query[property] = {}
      query[property]['$regex'] = value
      query[property]['$options'] = 'i'
    }
    client
      .service(service)
      .find({query})
      .then(result => {
        setList(result.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        setError(error)
      })
  }

  const searchVendor = (client = socketClient, service, property = null, value = null) => {
    setLoading(true)
    const query = {
      $limit: 10,
      $skip: 0,
      roles: {
        $in: ['vendor']
      }
    }

    if (value?.length >= 3) {
      query[property] = {}
      query[property]['$regex'] = value
      query[property]['$options'] = 'i'
    }

    client
      .service(service)
      .find({
        query: {
          ...query
        }
      })
      .then(result => {
        setList(result.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        setError(error)
      })
  }

  return {searchBy, searchVendor, loading, list, error, setError}
}
