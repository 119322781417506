import {Button, Modal, Typography} from '@mui/material'
import {ModalLayout} from 'components/layouts/ModalLayout'
import {useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
export const NoPetFound = () => {
  const [open, setOpen] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const code = searchParams.get('code')

  const handleCreate = () => {
    navigate(`/pets/create/${code}`)
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
    navigate('/', {replace: true})
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalLayout title="Pet tag not claimed" onClose={handleClose}>
        <Typography>
          This pet tag has not been claimed yet. Please create a new pet profile to claim it.
        </Typography>
        <div className="flex justify-end mt-4">
          <Button variant="contained" className="!mt-4" onClick={handleCreate}>
            Create Pet Profile
          </Button>
        </div>
      </ModalLayout>
    </Modal>
  )
}
