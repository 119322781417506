import { getErrorAction, getSuccessAction } from '../utility'
import { actions } from './actions'
const initialState = {
  user: null
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN:
      return {
        ...state,
        loading: true
      }
    case getSuccessAction(actions.LOGIN):
      return {
        ...state,
        user: action.user,
        loading: false,
        error: false
      }
    case getErrorAction(actions.LOGIN):
      return {
        ...state,
        loading: false,
        error: true
      }
    case actions.RE_AUTHENTICATED:
      return {
        ...state,
        user: action.user
      }
    case actions.RESET:
      window.localStorage.removeItem('auth')
      return initialState


    case actions.SET_CREDENTIAL :
      return {
        ...state,
        credential: action.credential
      }

    case actions.REMOVE_CREDENTIAL:
      return {
        ...state,
        credential: null
      }

    default:
      return state
  }
}

export default reducer
