import {color} from 'framer-motion'
import {useState} from 'react'

export const Description = props => {
  const {className} = props
  const [expanded, setExpanded] = useState(false)

  const toggleExpansion = () => {
    setExpanded(!expanded)
  }

  return (
    <div>
      <div style={{maxHeight: expanded ? 'none' : '2.6em', overflow: 'hidden'}} className={className}>
        {props?.description}
      </div>
      {props?.description?.length > 2 * 50 && (
        <button
          onClick={toggleExpansion}
          style={{display: 'block', margin: '5px 0', color: '#1976d2'}}
          className={className}
        >
          {expanded ? 'Show less' : 'Show more'}
        </button>
      )}
    </div>
  )
}
