import {useEffect} from 'react'
import {TableBody, TableContainer, TableHead, Paper, Typography, IconButton} from '@mui/material'
import {Delete} from '@mui/icons-material'
import {StyledTable, StyledTableRow, StyledTableHeadCell, StyledTableCell} from 'components/StyledComponent'

export const DataTable = props => {
  const {list, loading, Loader, Paginator, onDelete} = props

  return (
    <div>
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <TableContainer>
          <StyledTable>
            <TableHead>
              <StyledTableRow>
                <StyledTableHeadCell>Name</StyledTableHeadCell>
                <StyledTableHeadCell>Email</StyledTableHeadCell>
                <StyledTableHeadCell>Phone</StyledTableHeadCell>
                <StyledTableHeadCell>Invited By</StyledTableHeadCell>
                <StyledTableHeadCell>Roles</StyledTableHeadCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {list.length ? (
                list.map((row, index) => (
                  <StyledTableRow key={index} odd={index % 2 !== 0 ? 1 : 0}>
                    <StyledTableCell data-label="Name">
                      {row.firstName} {row.lastName}
                    </StyledTableCell>
                    <StyledTableCell data-label="Email">{row?.email}</StyledTableCell>
                    <StyledTableCell data-label="Phone">{row?.phone}</StyledTableCell>
                    <StyledTableCell data-label="Invited By">
                      {row.invitedBy.firstName} {row.invitedBy.lastName}
                    </StyledTableCell>
                    <StyledTableCell data-label="Roles">{row?.roles.toString()}</StyledTableCell>
                    <StyledTableCell size="small">
                      <IconButton onClick={() => onDelete(row._id)}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : loading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={6} align="center">
                    <Loader />
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={6} align="center">
                    <Typography>No Data </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </StyledTable>
          <Paginator />
        </TableContainer>
      </Paper>
    </div>
  )
}
