import {DataTable} from '../../components/DataTable'
import {useState} from 'react'
import {DetailView} from './DetailView'
import {ConnectedComponent} from 'components/service/ConnectedComponent'

export const ShortList = props => {
  const [open, setOpen] = useState(false)
  const [short, setShort] = useState(null)
  const handleRowClick = value => {
    setShort(value)
    setOpen(!open)
  }
  return (
    <div>
      <ConnectedComponent
        Component={DataTable}
        service="reports"
        handleRowClick={handleRowClick}
        config={{query: {$populate: ['user', 'content'], contentType: 'shorts'}}}
      />

      {open && (
        <ConnectedComponent
          Component={DetailView}
          service="reports"
          id={short?._id}
          handleRowClick={handleRowClick}
          config={{
            query: {
              $populate: [
                {
                  path: 'content',
                  populate: [
                    {
                      path: 'posts',
                      populate: [
                        {
                          path: 'pet',
                          populate: ['profile']
                        },
                        {
                          path: 'user',
                          populate: ['profile']
                        },
                        {path: 'media'}
                      ]
                    },
                    'user'
                  ]
                }
              ],
              contentType: 'shorts'
            }
          }}
          open={open}
          onClose={() => setOpen(false)}
        />
      )}
    </div>
  )
}
