import moment from "moment"

export const Separator = (props) => {
  return <div className="separator">
    <div className="line"/>
    <div className="time">
      <div className="label">
        {moment(props.time).fromNow()}
      </div>
    </div>
    <div className="line"/>
  </div>
}
