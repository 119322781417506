import React, {useEffect, useState} from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import {useSearchBy} from './useSearchBy'
import {socketClient} from 'client/socket.client'
import {Search} from '@mui/icons-material'
export const AutoCompleteSearch = props => {
  const {
    id,
    label = 'Search',
    searchProperty = 'name',
    service,
    onChange,
    className = '!w-full',
    client = socketClient
  } = props
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [data, setData] = useState(null)
  const {searchBy, list, loading} = useSearchBy()

  const handleInputChange = event => {
    searchBy(client, service, searchProperty, event.target.value)
  }

  useEffect(() => {
    setOptions(list)
  }, [list])

  useEffect(() => {
    if (id) {
      client
        .service(service)
        .get(id)
        .then(result => {
          setData(result)
        })
        .catch(e => {
          console.log('error::', e)
        })

      searchBy(client, service)
    } else {
      searchBy(client, service)
    }
  }, [id])

  useEffect(() => {
    onChange(data?._id)
  }, [data])

  return (
    <Autocomplete
      id="autocomplete"
      className={className}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={option => `${option.name}`}
      options={options}
      loading={loading}
      value={data?._id ? data : null}
      onChange={(e, value) => setData(value)}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          onChange={handleInputChange}
          error={props.error}
          helperText={props.helperText}
        />
      )}
    />
  )
}
