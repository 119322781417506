import React from 'react';
import { Typography, Container, Card } from '@mui/material';
import appLogo from 'images/app_logo.png'

const TermsConditions = () => {
  return (
    <Container maxWidth="lg"  sx={{textAlign: 'left', p: '1rem'}}>
      <Card sx={{p: '1rem 2rem'}}>
        <img src={appLogo} alt="Logo" style={{margin: '0 auto'}} />
        <Typography variant="h5" textAlign="center" fontWeight="bold" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Introduction
        </Typography>
        <Typography  className="!text-gray-600 !text-base" paragraph>
        Welcome to Petflix.io, a platform dedicated to uniting pet owners with their lost pets,
        sharing pet memories through Tails and stories, and honoring pets through obituaries.
        By accessing or using Petflix.io ("App"), you agree to these Terms and Conditions
        ("Terms"). These Terms apply to all users of the App and outline the rules and
        regulations for using Petflix.io and its associated services
        </Typography>
        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Eligibility and Account Registration
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          The App welcomes users of all ages. However, engagement in financial transactions,
          specifically funding Lost Pet Connect Rewards through the E-Wallet service powered by
          our third-party partners Plaid and Dwolla, is exclusively available to users who are 18
          years of age or older. By registering an account, you commit to providing accurate,
          current, and complete information and to maintain the accuracy of this information.
          Users below the age of 18 may use the App but will not be eligible to the E-Wallet Lost
          Pet Connect Services.
        </Typography>

        <Typography variant="h5"  fontWeight="bold" gutterBottom>
          User Conduct and Responsibilities
        </Typography>
        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Content and Interaction
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          Petflix.io encourages a vibrant community where users can post, like, and engage with
          content respectfully. Users are expected to:
          <ul className="list-disc ml-8">
            <li>Adhere to community standards, avoiding abusive, offensive, or harassing content.</li>
            <li>Comply with our content guidelines, refraining from spam, solicitation, or posting inappropriate materials.</li>
            <li>Respect intellectual property rights and refrain from unauthorized use of copyrighted material.</li>
          </ul>
        </Typography>

        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Lost Pet Module
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          This service allows users to report lost pets and offer rewards for their recovery:
          <ul className="list-disc ml-8">
            <li>Rewards must be funded through the E-Wallet service facilitated by Plaid and
              Dwolla to be considered verified. Rewards not processed through this system will
              be designated as unverified.
            </li>
            <li>Detailed, accurate descriptions and information about lost pets are crucial to the recovery process.</li>
            <li>Payment Terms for Pet Owners: Fees may be charged for using the app and its
              services, such as booking pet services or using the e-commerce marketplace. Late fees
              and additional charges may apply. Authorizations to charge your payment method may
              be given. Taxes may also be applicable.
            </li>
          </ul>
        </Typography>
        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Reporting and Moderation
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          The App provides mechanisms for users to report inappropriate or abusive content,
          messages, posts, or behavior. We are committed to maintaining a safe, respectful
          online environment and will take appropriate action against violations of our policies.
        </Typography>
        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Privacy and Data Management
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          Your privacy is paramount to us. Petflix.io collects and uses personal and pet information as detailed in our Privacy Policy. This includes:
          <ul className="list-disc ml-8">
            <li>Utilizing location data to aid in the recovery of lost pets</li>
            <li>Managing pet health records and other relevant information with appropriate security measures.</li>
          </ul>
        </Typography>

        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Intellectual Property
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          All content on Petflix.io is protected by intellectual property laws. The App and its original content, features, and functionality are owned by Petflix.io and are protected by
          copyright, trademark, and other intellectual property or proprietary rights laws.
        </Typography>

        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Disclaimers and Limitations of Liability
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          Petflix.io is a platform designed to facilitate a community for pet lovers. While we strive
          to provide a valuable service, we do not guarantee the recovery of lost pets or the
          veracity of user-generated content. The App is provided on an "as is" and "as available"
          basis.
        </Typography>

        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Changes to Terms
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          Petflix.io reserves the right, at our discretion, to modify or replace these Terms at any
          time. We will provide notice of significant changes to the Terms by posting a notice on
          the App or by sending you an email. Your continued use of the App after such changes
          will constitute your acceptance of the new Terms.
        </Typography>

        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Governing Law and Dispute Resolution
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          These Terms shall be governed and construed in accordance with the laws of Texas,
          United States, without regard to its conflict of law provisions. Disputes arising from
          these Terms will be resolved through binding arbitration or another form of dispute
          resolution agreed upon by both parties
        </Typography>

        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Termination
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          We may terminate or suspend your account and bar access to the App immediately,
          without prior notice or liability, for any reason whatsoever, including, without limitation, a
          breach of the Terms. You can terminate your account by contacting support@petflix.io.
        </Typography>

        <Typography variant="h6"  fontWeight="bold" gutterBottom>
          Contact Us
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          For any questions or concerns about these Terms, please contact us at
          support@petflix.io. Our headquarters is located at 200 S 10th St, Suite 905, McAllen,
          Texas 78503.
        </Typography>

        <Typography fontWeight="bold" gutterBottom>
          Pet Owners and Pets terms of use for the "Noah's Ark" mobile application:
        </Typography>
        <Typography className="!text-gray-600 !text-base">
          <ol className="list-decimal ml-8">
            <li>Eligibility: To use the app, you must meet the criteria set out by the app.</li>
            <li>Payment Terms for Pet Owners: Fees may be charged for using the app and its services, such as booking pet services or using the e-commerce marketplace. Late fees and additional charges may apply. Authorizations to charge your payment method may be given. Taxes may also be applicable.</li>
            <li>Background Checks and Identity Verifications: Service providers listed on the app may undergo background checks and identity verifications, but the app does not screen or endorse them. It is up to the pet owners to exercise caution and use their own judgment when selecting and engaging with service providers.</li>
            <li>Pet Owner Obligations: Pet owners are solely responsible for evaluating service providers and making decisions that are in the best interests of themselves and their pets. This includes ensuring their pet's vaccinations are up to date and caring for their pet's needs, such as diet and activities.</li>
            <li>Phone, Text, and Mobile Communications: Use of the app may require the use of phone, text, and mobile communications. By using the app, you consent to receiving autodialed text messages.</li>
            <li>Booking and Appointments: The app offers pet services such as pet walking, pet care, online vet consultations, pet diet consultations, and pet training. Service fees may apply.</li>
            <li>E-Commerce Services: The app offers an e-commerce marketplace for pet owners to purchase pet-related products and services.</li>
            <li>Data Usage: The data collected through the app will be used for commercial purposes.</li>
            <li>Governing Law: Texas law and the Federal Arbitration Act will apply to any disputes, except where prohibited by law.</li>
            <li>Limitations on Liability: The app, "Noah's Ark," is not liable for any losses or damages incurred by pet owners or service providers. Pet owners and service providers are solely responsible for their interactions and dealings with each other.</li>
            <li>Indemnity by You: Pet owners agree to indemnify and hold the app harmless from any claims or damages incurred while using the app.</li>
            <li>Third-Party Applications: The app may use third-party applications, such as Google Maps, and usage of these applications is subject to their terms and conditions and privacy policies.</li>
            <li>Privacy: The app has a privacy policy in place to protect pet owners' personal information.</li>
          </ol>
        </Typography>

        <Typography fontWeight="bold" mt={2} gutterBottom>
          The terms of use for the "Noah's Ark" mobile application for pet owners and pets would include the following clauses:
        </Typography>
        <Typography className="!text-gray-600 !text-base">
          <ol className="list-decimal ml-8">
            <li>
              Acceptable Use of the Services: This would specify the acceptable use of the Noah's Ark app by pet owners and pets.
            </li>
            <li>
              Payment Terms: This would include the payment terms for pet owners and fees for service providers, including late fees and additional charges. It would also specify the authorization to charge, taxes and payment processing
            </li>
            <li>
              Background Checks and Identity Verifications: This would specify the background checks and identity verifications conducted on service providers
            </li>
            <li>
              Privacy: This would specify how the data collected by the app will be used, including commercial purposes
            </li>
            <li>
              E-Commerce Services: This would specify the e-commerce services provided by the app, such as the marketplace for pet owners and pets
            </li>
            <li>
              Eligibility: This would specify the eligibility criteria for using the Noah's Ark app.
            </li>
            <li>
              Phone, Text and Mobile Communications: This would specify the use of phone, text, and
              mobile communications for the app and would include consent to autodialed text
              messages and phone number changes
            </li>
            <li>
              Pet Care: This would include booking and appointment services for pet owners, such as pet walking, pet care, online vet consultations, pet diet consultations, and pet training.
            </li>
            <li>
              Pet Profile: This would include clauses for pet diets, pet vaccines, pet illness, pet care, pet treats, pet interests, pet breeding availability, pet activities, and pet locations
            </li>
            <li>
              Pet Matching: This would include the process of matching pet owners and pets based on their profiles.
            </li>
          </ol>
        </Typography>

        <Typography fontWeight="bold" mt={2} gutterBottom>
          Common Clauses:
        </Typography>
        <Typography className="!text-gray-600 !text-base">
          <ol className="list-decimal ml-8">
            <li>
              User agreement: All the links provided require the user to agree to the terms of service
              before using the platform.
            </li>
            <li>
              Age restriction: All the links require the user to be of a certain age to use the platform.
            </li>
            <li>
              User conduct: All the links mention that the user should abide by certain rules of conduct while using the platform.
            </li>
            <li>
              Intellectual property rights: All the links mention that the platform owns the intellectual property rights for all content on the platform.
            </li>
            <li>
              Limitation of liability: All the links mention that the platform is not liable for any damages or losses incurred by the user
            </li>
            <li>
              Termination of service: All the links mention that the platform has the right to terminate the user's account for any reason.
            </li>
            <li>
              Dispute resolution: All the links mention that any disputes will be resolved through arbitration.
            </li>
          </ol>
        </Typography>

        <Typography fontWeight="bold" mt={2} gutterBottom>
          Differences:
        </Typography>
        <Typography className="!text-gray-600 !text-base">
          <ol className="list-decimal ml-8">
            <li>
              <a class="text-blue-600 visited:text-purple-600" href="https://bluebuffalo.com/buddies/rewards/terms-and-conditions">Blue Buffalo</a>: This link is
              specific to a rewards program offered by Blue Buffalo. It mentions that the program is
              only available in the United States
            </li>
            <li>
              <a class="text-blue-600 visited:text-purple-600" href="https://www.rover.com/terms/tos/">Rover</a>: This link is for a pet care platform. It mentions
              that the platform provides services for pet owners and pet sitters
            </li>
            <li>
              <a class="text-blue-600 visited:text-purple-600" href="https://www.tiktok.com/legal/page/us/terms-of-service/en">TikTok</a>: This link is for a
              social media platform. It mentions that the user should not upload any content that is illegal or offensive
            </li>
            <li>
            <a class="text-blue-600 visited:text-purple-600" href="https://www.chewy.com/app/content/terms">Chewy</a>: This link is for an online pet supply store. It mentions that the platform provides delivery services for pet supplies.
            </li>
            <li>
              <a class="text-blue-600 visited:text-purple-600" href="https://policies.tinder.com/terms/us/en">Tinder</a>: This link is for a dating app. It mentions that the user should not upload any content that is illegal or offensive
            </li>
            <li>
              <a class="text-blue-600 visited:text-purple-600" href="https://safety.wagwalking.com/terms">Wag Walking</a>: This link is for a pet care platform. It mentions that the platform provides services for pet owners and pet sitters.
            </li>
            <li>
              <a class="text-blue-600 visited:text-purple-600" href="https://safety.wagwalking.com/terms-pet-caregiver-mobile">Wag Walking</a>: This link is specific to the mobile app for pet caregivers. It mentions that the pet caregiver should
              follow certain rules of conduct while providing pet care services
            </li>
            <li>
              <a class="text-blue-600 visited:text-purple-600" href="https://safety.wagwalking.com/terms-pet-parent-mobile">Wag Walking</a>: This link is
              specific to the mobile app for pet owners. It mentions that the pet owner should follow
              certain rules of conduct while using the platform.
            </li>
            <li>
              <a class="text-blue-600 visited:text-purple-600" href="https://safety.wagwalking.com/terms-wellness-membership-agreement">Wag Walking</a>: This link is for a wellness membership offered by Wag Walking. It mentions that the user
              should follow certain rules of conduct while using the membership
            </li>
          </ol>
        </Typography>

        <Typography className="!text-gray-600 !text-base" mt={2} paragraph>
          Common Clauses:
          <ul className="list-disc ml-8">
            <li>User Agreement</li>
            <li>Proprietary Rights and Limited License</li>
            <li>User Conduct and User Generated Content</li>
            <li>Representations and Warranties</li>
            <li>Disclaimer of Warranty</li>
            <li>Limitation of Liability</li>
            <li>Indemnification</li>
            <li>Dispute Resolution</li>
            <li>General Terms</li>
            <li>Termination</li>
          </ul>
        </Typography>

        <Typography className="!text-black-600 !text-base" paragraph>
          Link: <a class="text-blue-600 visited:text-purple-600" href="https://bluebuffalo.com/buddies/rewards/terms-and-conditions">https://bluebuffalo.com/buddies/rewards/terms-and-conditions</a>
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          Differences:
          <ul className="list-disc ml-8">
            <li>Includes a section on reward points and redemption</li>
            <li>Includes a section on the use of personal information</li>
            <li>No mention of dispute resolution methods.</li>
          </ul>
        </Typography>

        <Typography className="!text-black-600 !text-base" paragraph>
          Link: <a class="text-blue-600 visited:text-purple-600" href="https://www.rover.com/terms/tos/">https://www.rover.com/terms/tos/</a>
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          Differences:
          <ul className="list-disc ml-8">
            <li>Includes a section on payment and billing</li>
            <li>Includes a section on Rover's role as a platform for pet care services</li>
            <li>Includes a section on the review and removal of content by Rover.</li>
          </ul>
        </Typography>

        <Typography className="!text-black-600 !text-base" paragraph>
          Link: <a class="text-blue-600 visited:text-purple-600" href="https://www.tiktok.com/legal/page/us/terms-of-service/en">https://www.tiktok.com/legal/page/us/terms-of-service/en</a>
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          Differences:
          <ul className="list-disc ml-8">
            <li>Includes a section on privacy policies</li>
            <li>Includes a section on user privacy and security</li>
            <li>Includes a section on the use of user data.</li>
          </ul>
        </Typography>

        <Typography className="!text-black-600 !text-base" paragraph>
          Link: <a class="text-blue-600 visited:text-purple-600" href="https://www.chewy.com/app/content/terms">https://www.chewy.com/app/content/terms</a>
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          Differences:
          <ul className="list-disc ml-8">
            <li>Includes a section on product and service descriptions</li>
            <li>Includes a section on product orders and shipping</li>
            <li>Includes a section on returns and refunds.</li>
          </ul>
        </Typography>


        <Typography className="!text-black-600 !text-base" paragraph>
          Link: <a class="text-blue-600 visited:text-purple-600" href="https://policies.tinder.com/terms/us/en">https://policies.tinder.com/terms/us/en</a>
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          Differences:
          <ul className="list-disc ml-8">
            <li>Includes a section on the use of data for advertising purposes</li>
            <li>Includes a section on user privacy and security</li>
            <li>Includes a section on the use of personal data for marketing purposes.</li>
          </ul>
        </Typography>

        <Typography className="!text-black-600 !text-base" paragraph>
          Link: <a class="text-blue-600 visited:text-purple-600" href="https://safety.wagwalking.com/terms">https://safety.wagwalking.com/terms</a>
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          Differences:
          <ul className="list-disc ml-8">
            <li>Includes a section on the use of the Wag! Platform for pet care services</li>
            <li>Includes a section on the role of Wag! as a mediator for pet care services</li>
            <li>No mention of dispute resolution methods.</li>
          </ul>
        </Typography>

        <Typography className="!text-black-600 !text-base" paragraph>
          Link: <a class="text-blue-600 visited:text-purple-600" href="https://safety.wagwalking.com/terms-pet-caregiver-mobile">https://safety.wagwalking.com/terms-pet-caregiver-mobile</a>
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          Differences:
          <ul className="list-disc ml-8">
            <li>Includes a section on the terms and conditions of the pet caregiver mobile application</li>
            <li>No mention of dispute resolution methods.</li>
          </ul>
        </Typography>

        <Typography className="!text-black-600 !text-base" paragraph>
          Link: <a class="text-blue-600 visited:text-purple-600" href="https://safety.wagwalking.com/terms-pet-parent-mobile">https://safety.wagwalking.com/terms-pet-parent-mobile</a>
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          Differences:
          <ul className="list-disc ml-8">
            <li>Includes a section on the terms and conditions of the pet parent mobile application</li>
            <li>No mention of dispute resolution methods.</li>
          </ul>
        </Typography>


        <Typography className="!text-black-600 !text-base" paragraph>
          Link: <a class="text-blue-600 visited:text-purple-600" href="https://safety.wagwalking.com/terms-wellness-membership-agreement">https://safety.wagwalking.com/terms-wellness-membership-agreement</a>
        </Typography>
        <Typography className="!text-gray-600 !text-base" paragraph>
          Differences:
          <ul className="list-disc ml-8">
            <li>Includes a section on the terms and conditions of the wellness membership agreement</li>
            <li>No mention of dispute resolution methods.</li>
          </ul>
        </Typography>

      </Card>
    </Container>
  );
};

export default TermsConditions;
