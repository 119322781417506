import { useEffect, useState } from "react"
import { Box, Typography, Button,  Divider, CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { currency } from "utils/Constants"
import { filter } from "lodash"
import { ContactItem } from "./ContactItem";
import { useSelector } from "react-redux"
import { socketClient } from "client/socket.client";

export const ContactSelectionPanel = (props) => {
  const {amount, onClose, onPay, selected, setSelected} = props
  const user = useSelector((state) => state?.authentication?.user)

  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const [followers, setFollowers] = useState([])
  const [filteredUser, setFilteredUser] = useState([])

  useEffect(() => {
    if (!user) return
    setLoading(true)
    socketClient.service('following').find({
      query : {
        following: user?._id,
        followingType: 'users',
        approved: true,
        $populate: ['user']
      }
    })
    .then((result) => {
      setLoading(false)
      setFollowers(result.data)
      setFilteredUser(result.data)
    })
    .catch((e) => {
      setLoading(false)
      console.log(e)
    })
  }, [user])

  return (
    <Box flexDirection="column"  width="100%">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <CloseIcon color="primary" className="cursor-pointer" onClick={onClose} />
        <Typography as="h4">{currency(amount)}</Typography>
        <Button
          color="primary"
          variant="contained"
          disabled={!selected}
          style={{height : '28px', padding: '10px 14px'}}
          onClick={onPay}
        >
          Pay
        </Button>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center'}}>
        <Typography fontWeight="bold">To</Typography>
        <input
          value={name}
          onChange={(e) => {
            setName(e.target.value)
            if (e.target.value.length) {
              const filtered = filter(followers, (i) => i.user?.firstName.toLowerCase().includes(e.target.value.toLowerCase()))
              setFilteredUser(filtered)
            } else {
              console.log('no data')
              setFilteredUser(followers)
              setSelected(null)
            }
          }}
          disabled={loading}

          style={{
            borderWidth: 0,
            padding: 0,
            paddingLeft: '4px',
            width: '100%',
            height: '36px'
          }}
          className="focus:outline-none"
          fullWidth
          autoFocus
        />
      </Box>
      <Divider sx={{
        marginLeft: '-16px', marginRight: '-16px'
      }}/>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Typography fontWeight="bold">For</Typography>
        <input
          style={{
            borderWidth: 0,
            padding: 0,
            paddingLeft: '4px',
            width: '100%',
            height: '36px'
          }}
          className="focus:outline-none"
        />
      </Box>
      <Divider sx={{
        marginLeft: '-16px',
        marginRight: '-16px',
        marginBottom: '4px'
      }}/>
      <Typography
        my={1}
        style={{
          background: 'rgba(15,52,96,1)',
          color: 'white',
          marginLeft: '-16px',
          marginRight: '-16px',
          marginTop: 0,
          padding: '4px 16px'
        }}
      >
        Suggested
      </Typography>
      {loading ?
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '350px'}}>
          <CircularProgress />
        </Box> :
        followers?.length ?
          <Box sx={{display: 'flex', flexDirection: 'column', height: '350px', overflow: 'scroll',  marginLeft: '-16px', marginRight: '-16px'}} >
            {filteredUser.map((item, index) => (
              <ContactItem
                key={index}
                item={item}
                onClick={(item) => {
                  setSelected(item)
                  setName(item?.user ? `${item?.user?.firstName} ${item?.user?.lastName}`  : '')
                }}
                selected={selected}
              />
            ))}
          </Box> :
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '350px'}}>
            <Typography variant="h5" gutterBottom>
              No followers found
            </Typography>
          </Box>
      }
    </Box>
  )
}
