import {Box, Button, Modal} from '@mui/material'
import {ModalLayout} from 'components/layouts/ModalLayout'
import {socketClient} from 'client/socket.client'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {useContext} from 'react'

export const CodeRevokeModel = props => {
  const {open, handleModal, id} = props
  const {setSeverity, showToast} = useContext(SnackbarContext)

  const handleToast = (status, message) => {
    setSeverity(status)
    showToast(message)
  }
  const handleConfirm = () => {
    if (!id) {
      handleToast('error', 'Code not found. Please try again')
    }
    socketClient
      .service('pet-codes')
      .remove(id)
      .then(result => {
        handleModal(result)
        handleToast('success', 'Code revoked successfully')
      })
      .catch(error => {
        handleToast('error', 'Failed to revoke code')
      })
  }
  return (
    <Modal
      open={open}
      onClose={handleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout title="Are you sure ?" onClose={handleModal}>
        <Box>
          <div>
            <p>This will delete the item permanently. You can not undo this action. </p>
            <div className="flex justify-end mt-4">
              <Button className="!mt-4" variant="contained" color="primary" onClick={handleConfirm}>
                Confirm
              </Button>
            </div>
          </div>
        </Box>
      </ModalLayout>
    </Modal>
  )
}
