import {AppLayout} from 'modules/layout/AppLayout'
import {PetEditForm} from './PetEditForm'
import {useParams} from 'react-router-dom'
import {ConnectedComponent} from 'components/service/ConnectedComponent'

export const EditPetDetail = () => {
  const {id} = useParams()
  return (
    <AppLayout heading="Edit Pet">
      <ConnectedComponent
        Component={PetEditForm}
        service="pets"
        id={id}
        config={{
          query: {
            $populate: [
              'activities', 
              'wellness', 
              'vaccination.vaccine', 
              'profile', {
                path: 'diet',
                populate: ['favoriteFlavours', 'favoriteTreats', 'sensitivities']
              }
            ]
          }
        }}
      />
    </AppLayout>
  )
}
