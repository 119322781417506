import {Routes, Route} from 'react-router-dom'
import { Dashboard } from './Dashboard'
import { FundingSource } from './FundingSource'
import { AccountActivity } from './AccountActivity'

export const Wallet = (props) => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/funding-sources" element={<FundingSource />} />
      <Route path="/activity" element={<AccountActivity />} />
    </Routes>
  )
}
