import { Grid, Typography, Button, Box, Tabs, Tab, TextField, MenuItem } from "@mui/material";
import { AppLayout } from "modules/layout/AppLayout"
import viedoicon from 'images/newimages/viedoicon.svg';
import tableicon from 'images/newimages/tableicon.svg';
import playFill from 'images/play_fill.svg'
import petFinder from 'images/petFinder.svg'
import lostPets from 'images/lostPets.svg'
import flag from 'images/flagged.svg'
import { useState } from "react";
import { Link } from "react-router-dom";
import users from 'images/users.svg';
import { DataGrid } from "@mui/x-data-grid";
import { IframeModal } from "./modals/IframeModal";
import { UrlHistorModal } from "./modals/UrlHistoryModal";

const Role = [
  {
    value: 'Menu Items',
    label: 'Menu Items',
  },
  {
    value: 'Role',
    label: 'Role',
  },
];
const petOwner = [
  {
    value: 'Pet Owner',
    label: 'Rose',
  },
  {
    value: 'Pet Owner2',
    label: 'Johnson',
  },
];
const Status = [
  {
    value: 'Status',
    label: 'Enable',
  },
  {
    value: 'status2',
    label: 'Disable',
  },
];
const data = [
  {
    id: 1,
    details: '11/9/2023, 08:01 PM',
    postid: 'postID5ah84',
    content: 'this is a sample text post',
    category: 'Violence (V)',
    action: 'None',
  },
  {
    id: 2,
    details: '11/9/2023, 08:01 PM',
    postid: 'postID5ah84',
    content: <button>{viedoicon}</button>,
    action: 'None',
  },
];

const flaggedData = [
  {
    userimg: {tableicon},
    url: 'reddit/btccheap',
    lastpost: '15 min ago',
    spampost: '233',
    totaluser: '56',
    user: 'pater@paterson.com',
    pet: 'Snuggles',
    allow: 'Allow',
    deny: 'Deny',
  },
  {
    userimg: {tableicon},
    url: 'reddit/btccheap',
    lastpost: '15 min ago',
    spampost: '233',
    totaluser: '56',
    user: 'pater@paterson.com',
    pet: 'Snuggles',
    allow: 'Allow',
    deny: 'Deny',
  },
];


export const FlaggedReport = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [openIframeModal,setOpenIframeModal] = useState(false)
  const [openUrlModal,setOpenUrlModal] = useState(false)

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleOpenVideoModal = (e) => {
    e.stopPropagation();
    setOpenIframeModal(!openIframeModal)
  }

  const handleOpenUrlHistoryModal = (e) => {
    e.stopPropagation();
    setOpenUrlModal(!openUrlModal)
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'details', headerName: 'Details', width: 255 },
    { field: 'postid', headerName: 'Post ID', width: 155 },
    {
      field: 'content',
      headerName: 'Content',
      width: 327,
      renderCell: (params) => {
        const { value } = params;
        return typeof value === 'string' ? value : <button onClick={(e) => handleOpenVideoModal(e)}><img src={viedoicon} alt='icon'/></button>;
      },
    },
    {
      field: 'category',
      headerName: 'Top moderation Categories',
      width: 255,
    },
    {
      field: 'action',
      headerName: 'Last Action taken',
      width: 255,
    },
  ];


  const flaggedcolumns = [
    { field: 'userimg', headerName: '', width: 45, renderCell: (params) => {
      const { value } = params;
      return typeof value === 'string' ? value : <button><img src={tableicon} /></button>;
    },  
    },
    { field: 'url', headerName: 'URL Link', width: 206,
      renderCell: (params) => {
        return <Link onClick={(e) => handleOpenUrlHistoryModal(e)} underline="none" color="transparent.contrastText">{params.value}</Link>;
      },
    },
    { field: 'lastpost', headerName: 'Last Posted', width: 255 },
    { field: 'spampost', headerName: 'Total Spam Posts', width: 155 },
    {
      field: 'totaluser',
      headerName: 'Total Users',
      width: 117,
    },
    {
      field: 'user',
      headerName: 'User',
      width: 206,
    },
    {
      field: 'pet',
      headerName: 'Pet',
      width: 206,
    },
    {
      field: 'allow',
      headerName: 'Allow',
      width: 106,
      renderCell: (params) => {
        return <Button variant="contained" size="small" color="checkbox">{params.value}</Button>;
      },
    },
    {
      field: 'deny',
      headerName: 'Deny',
      width: 106,
      renderCell: (params) => {
        return <Button variant="contained" size="small" color="deny">{params.value}</Button>;
      },
    },
  ];



  return (
    <AppLayout heading='Moderation Dashboard'>
      <Grid container gap='10px' alignItems='center'>
        <Grid item boxShadow='custom' bgcolor='white' xs={12} lg={2} md={3} sx={{ display: 'block',  borderRadius:'10px', p:3, border:'1px solid #DDDDDD' }}>
          <Grid container direction="row" justifyContent="center" alignItems="center" >
            <img src={users} alt='img'/>
          </Grid>
          
          <Typography fontSize='12px' my='4px'>App Users</Typography>
          <Typography variant='h4' fontSize='26px' sx={{fontWeight:'bold', color:'#09244B'}}>2,765</Typography>
          <Typography sx={{color:'#2AC670', alignItems:'center', display:'flex', justifyContent:'center', gap:'2px'}}>
            <img src={playFill} alt='play' />
            %10
          </Typography>       
        </Grid>
        <Grid item boxShadow='custom'  bgcolor='white' xs={12} lg={2} md={3} sx={{ display: 'block', borderRadius:'10px', border:'1px solid #DDDDDD', p:3 }}>
          <Grid container direction="row" justifyContent="center" alignItems="center" >
            <img src={petFinder} alt='img'/>
          </Grid>
          
          <Typography fontSize='12px' my='4px'>Pet Finders</Typography>
          <Typography variant='h4' fontSize='26px' sx={{fontWeight:'bold', color:'#09244B'}}>253</Typography>
          <Typography sx={{color:'#2AC670', alignItems:'center', display:'flex', justifyContent:'center', gap:'2px'}}>
            <img src={playFill} alt='pet' />
            %10
          </Typography>
        </Grid>

        <Grid item  boxShadow='custom'  bgcolor='white' xs={12} lg={2} md={3} sx={{ display: 'block', borderRadius:'10px', p:3, border:'1px solid #DDDDDD'}}>
          <Grid container direction="row" justifyContent="center" alignItems="center" >
              <img src={lostPets} alt='img'/>
          </Grid>
          
          <Typography fontSize='12px' my='4px'>Lost Pets</Typography>
          <Typography variant='h4' fontSize='26px' sx={{fontWeight:'bold', color:'#09244B'}}>896</Typography>
          <Typography sx={{color:'#2AC670', alignItems:'center', display:'flex', justifyContent:'center', gap:'2px'}}>
            <img src={playFill} alt='pet' />
            %10
          </Typography>
        </Grid>
        <Grid item  boxShadow='custom'  bgcolor='white' xs={12} lg={2} md={3} sx={{ display: 'block', borderRadius:'10px', p:3, border:'1px solid #DDDDDD' }}>
          <Grid container direction="row" justifyContent="center" alignItems="center" >
            <img src={flag} alt='img'/>
          </Grid>
          
          <Typography fontSize='12px' my='4px'>Flagged Users</Typography>
          <Typography variant='h4' fontSize='26px' sx={{fontWeight:'bold', color:'#09244B'}}>896</Typography>
          <Typography sx={{color:'#2AC670', alignItems:'center', display:'flex', justifyContent:'center', gap:'2px'}}>
            <img src={playFill} alt='pet' />
            %10
          </Typography>
        </Grid>
        <Grid item  boxShadow='custom'  bgcolor='white' xs={12} lg={2} md={3} sx={{ display: 'block', borderRadius:'10px', p:3, border:'1px solid #DDDDDD'}}>
          <Grid container direction="row" justifyContent="center" alignItems="center" >
              <img src={lostPets} alt='img'/>
          </Grid>
          
          <Typography fontSize='12px' my='4px'>Adoptions</Typography>
          <Typography variant='h4' fontSize='26px' sx={{fontWeight:'bold', color:'#09244B'}}>896</Typography>
          <Typography sx={{color:'#2AC670', alignItems:'center', display:'flex', justifyContent:'center', gap:'2px'}}>
            <img src={playFill} alt='pet' />
            %10
          </Typography> 
        </Grid>
        <Typography>
          <Button variant="contained" color='primary' uppercase sx={{height:'42px', display: 'block', width: '200px', mb:2}} size='large'>Categories</Button>
          <Button variant="contained" color='primary' uppercase sx={{height:'42px', width: '200px'}} size='large'>Actions</Button>
        </Typography>
      </Grid>

      <Box component="form" p={2} mt={2} borderRadius="10px" boxShadow="custom" sx={{ textAlign: "center", width: "100%", backgroundColor: "#fff",
        "& .MuiTextField-root": { m: 1, width: "95%", textAlign: "left" },}} noValidate autoComplete="off">
          <Grid container display="flex" justifyContent="space-between" alignItems="center">
            <Grid lg={12} md={12} xs={12} sm={12} sx={{ pr:{ lg:3.5, md:0, sm:0, xs: 0 }, md:{lg: 0, md: 2.5, xs: 2.5, sm: 2.5 } }}>
              <Tabs value={activeTab} onChange={handleTabChange}  variant="scrollable"  scrollButtons="auto">
                <Tab label="Flagged Posts Feed" sx={{ fontWeight: 'bold' }}/>
                <Tab label="Flagged Comments" sx={{ fontWeight: 'bold' }}/>
              </Tabs>
            </Grid>
          </Grid>
        </Box>
        
        {activeTab === 0 && 
          <Box> 
            <Box component="form" p={2} mt={2} borderRadius="10px" boxShadow="custom" sx={{ textAlign: "center", width: "100%", backgroundColor: "#fff" }} noValidate autoComplete="off">
              <Grid container display="flex" justifyContent="space-between" alignItems="center">
                <Grid lg={12} md={12} xs={12} sm={12} sx={{ pr:{ lg:3.5, md:0, sm:0, xs: 0 }, md:{lg: 0,  xs: 2.5, sm: 2.5 } }}>
                  <Grid container>
                    <Grid item xs={12} lg={3} md={3} pr={1} textAlign="left" sx={{ mb:{md: 0, sm: 3, xs: 3}, px:{md:0, xs: 1.5, sm: 1.5} }}>
                    <TextField id="role" select label="Url Link" defaultValue="Role" fullWidth >
                      {Role.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    </Grid>
                    <Grid item xs={12} lg={2} md={2} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 3, xs: 3} }}>
                        <TextField id="role" select label="Date Range" defaultValue="Role" fullWidth >
                            {Role.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} lg={2} md={2} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 3, xs: 3} }}>
                        <TextField id="owner" select label="Status" defaultValue="Pet Owner" fullWidth>
                            {petOwner.map((option) => (
                                <MenuItem
                                key={option.value}
                                value={option.value}
                                textAlign="left"
                                width="auto"
                                >
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} lg={2} md={2} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 2, xs: 3} }}>
                        <TextField id="status" select label="User" defaultValue="Status" fullWidth>
                            {Status.map((option) => (
                                <MenuItem
                                key={option.value}
                                value={option.value}
                                style={{ textAlign: "left", width: "auto" }}
                                >
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} lg={2} md={2} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 2, xs: 3} }}>
                        <TextField id="status" select label="Pet" defaultValue="Status" fullWidth>
                            {Status.map((option) => (
                                <MenuItem
                                key={option.value}
                                value={option.value}
                                style={{ textAlign: "left", width: "auto" }}
                                >
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box mt={2} mb={2}>
              <Typography component="h5" variable="h5" color="transparent.contrastText" textAlign='left' mb={2} sx={{fontSize:'20px', fontWeight:'600'}}>Posts Review Feed</Typography>
              <Grid container>
                  <Grid item xs={12} lg={3} md={3} pr={1} textAlign="left" sx={{ mb:{md: 0, sm: 3, xs: 3}, px:{md:0, xs: 1.5, sm: 1.5} }}>
                      <TextField id="role" select label="Search" defaultValue="Role" fullWidth >
                              {Role.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                  </MenuItem>
                              ))}
                      </TextField>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 3, xs: 3} }}>
                      <TextField id="role" select label="All Time" defaultValue="Role" fullWidth >
                          {Role.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                              {option.label}
                              </MenuItem>
                          ))}
                      </TextField>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 3, xs: 3} }}>
                      <TextField id="owner" select label="Moderation Categories" defaultValue="Pet Owner" fullWidth>
                          {petOwner.map((option) => (
                              <MenuItem
                              key={option.value}
                              value={option.value}
                              textAlign="left"
                              width="auto"
                              >
                              {option.label}
                              </MenuItem>
                          ))}
                      </TextField>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 2, xs: 3} }}>
                      <TextField id="status" select label="Last Action" defaultValue="Status" fullWidth>
                          {Status.map((option) => (
                              <MenuItem
                              key={option.value}
                              value={option.value}
                              style={{ textAlign: "left", width: "auto" }}
                              >
                              {option.label}
                              </MenuItem>
                          ))}
                      </TextField>
                  </Grid>
              </Grid>      
            </Box>
            <Box sx={{ boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)', borderRadius: '10px',  overflowX: 'auto'  }}>
              <DataGrid
                rows={data}
                columns={columns}
                initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
              />
            </Box>
          </Box>
        }

        {activeTab === 1 && 
          <Box>
            <Box component="form" p={2} mt={2} borderRadius="10px" boxShadow="custom" sx={{ textAlign: "center", width: "100%", backgroundColor: "#fff" }} noValidate autoComplete="off">
              <Grid container display="flex" justifyContent="space-between" alignItems="center">
                <Grid lg={12} md={12} xs={12} sm={12} sx={{ pr:{ lg:3.5, md:0, sm:0, xs: 0 }, md:{lg: 0,  xs: 2.5, sm: 2.5 } }}>
                  <Grid container>
                    <Grid item xs={12} lg={3} md={3} pr={1} textAlign="left" sx={{ mb:{md: 0, sm: 3, xs: 3}, px:{md:0, xs: 1.5, sm: 1.5} }}>
                      <TextField id="role" select label="Url Link" defaultValue="Role" fullWidth >
                        {Role.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} lg={2} md={2} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 3, xs: 3} }}>
                      <TextField id="role" select label="Date Range" defaultValue="Role" fullWidth >
                        {Role.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} lg={2} md={2} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 3, xs: 3} }}>
                      <TextField id="owner" select label="Status" defaultValue="Pet Owner" fullWidth>
                        {petOwner.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            textAlign="left"
                            width="auto"
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} lg={2} md={2} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 2, xs: 3} }}>
                      <TextField id="status" select label="User" defaultValue="Status" fullWidth>
                        {Status.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              style={{ textAlign: "left", width: "auto" }}
                            >
                              {option.label}
                            </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                      <Grid item xs={12} lg={2} md={2} px={1} textAlign="left" sx={{ mb:{md: 0, sm: 2, xs: 3} }}>
                        <TextField id="status" select label="Pet" defaultValue="Status" fullWidth>
                          {Status.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                                style={{ textAlign: "left", width: "auto" }}
                              >
                                {option.label}
                              </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

              <Box mt={2} sx={{ boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)', borderRadius: '10px',  overflowX: 'auto'  }}>
                <DataGrid columns={flaggedcolumns} rows={flaggedData} getRowId={(row) => row.url} />
              </Box>
          </Box>
        }

        <IframeModal open={openIframeModal} handleModal={handleOpenVideoModal} />
        <UrlHistorModal open={openUrlModal} handleModal={handleOpenUrlHistoryModal} />
    </AppLayout>
  )
}