import { feathers } from '@feathersjs/feathers'
import rest from '@feathersjs/rest-client'
import axios from 'axios'
import auth from '@feathersjs/authentication-client'
import { AUTHENTICATION_KEY } from './common'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

const client = rest(process.env.REACT_APP_BASE_URL).axios(axiosInstance)

export const restClient = feathers()

restClient.configure(client)
restClient.configure(auth({
  storage: window.localStorage,
  storageKey: AUTHENTICATION_KEY
}))
