import {CloseSharp} from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import {useFormik} from 'formik'
import {isEmpty, keys, pick, pickBy} from 'lodash'
import {storeSocketClient} from 'modules/store/client/socket.client'
import {forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState} from 'react'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {AutoCompleteSearch} from './AutoCompleteSearch'
import {UserAutoCompleteSearch} from './UserAutoCompleteSearch'
import {AutoCompleteChipInput} from './AutoCompleteChipInput'
import {ProductOptions} from './ProductOptions'
import {SpecialPrice} from './SpecialPrice'

const initialValues = {
  name: '',
  vendor: '',
  images: '',
  brand: '',
  taxClass: '',
  price: '',
  specialPrice: '',
  specialPriceStart: new Date(),
  specialPriceEnd: new Date(),
  sellingPrice: '',
  categories: '',
  sku: '',
  quantity: '',
  manageInventory: false,
  isNewArrival: false,
  isActive: true,
  crossSaleProducts: '',
  upSaleProducts: '',
  options: '',
  approved: false,
  approvedBy: ''
}

export const ProductEditModal = forwardRef((props, ref) => {
  const {onEdit} = props
  const [id, setId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [drawerProps, setDrawerProps] = useState({})
  const {setSeverity, showToast} = useContext(SnackbarContext)
  const [product, setProduct] = useState(null)

  const removeBlankAttribute = obj => {
    return pickBy(obj, value => !isEmpty(value))
  }

  useImperativeHandle(
    ref,
    () => ({
      show(id, drawerProps) {
        console.log(id)
        setId(id)
        setOpen(true)
        setDrawerProps(drawerProps)
      }
    }),
    []
  )

  const handleSubmit = async values => {
    try {
      const result = await storeSocketClient.service('products').patch(id, {...removeBlankAttribute(values)})
      if (result) {
        setSeverity('success')
        showToast('Product updated successfully')
        onEdit()
        onClose()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: values => {
      console.log(values)
      handleSubmit(values)
    }
  })

  useEffect(() => {
    if (!id) {
      return
    }

    setLoading(true)

    storeSocketClient
      .service('products')
      .get(id)
      .then(data => {
        setProduct(data)
        formik.resetForm({
          values: {
            ...pick(data, keys(initialValues)),
            isActive: data?.isActive,
            manageInventory: data?.manageInventory,
            isNewArrival: data?.isNewArrival
          }
        })
        setLoading(false)
      })
  }, [id])

  const onClose = () => {
    setId(null)
    setProduct(null)
    formik.resetForm({values: {...initialValues}})
    setOpen(false)
  }
  return (
    <Drawer
      open={open}
      {...drawerProps}
      ModalProps={{
        onClose: onClose
      }}
    >
      <Box className="p-4 w-[72rem]">
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <h1>Edit Product</h1>
          <IconButton onClick={() => setOpen(false)}>
            <CloseSharp />
          </IconButton>
        </Box>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full">
            <div className="w-full flex">
              <div className="w-1/2">
                <div className="w-full flex my-4">
                  <TextField
                    className="!w-1/2 !mr-2 "
                    id="standard-basic"
                    label="Name"
                    variant="outlined"
                    {...formik.getFieldProps('name')}
                  />
                  <TextField
                    className="!w-1/2 !ml-2"
                    id="standard-basic"
                    label="SKU"
                    variant="outlined"
                    {...formik.getFieldProps('sku')}
                  />
                </div>
                <div className="w-full flex my-4">
                  <TextField
                    className="!w-1/2 !mr-2 "
                    id="standard-basic"
                    label="Quantity"
                    variant="outlined"
                    {...formik.getFieldProps('quantity')}
                  />
                  <TextField
                    className="!w-1/2 !ml-2 "
                    id="standard-basic"
                    label="Price"
                    variant="outlined"
                    {...formik.getFieldProps('price')}
                  />
                </div>

                <div className="w-full flex my-4 ">
                  <AutoCompleteSearch
                    id={product?.brand}
                    className="!w-1/2 mr-2"
                    service="brands"
                    label="Search branch"
                    client={storeSocketClient}
                    searchProperty="name"
                    onChange={id => formik.setFieldValue('brand', id)}
                  />
                  <AutoCompleteSearch
                    id={product?.taxClass}
                    className="!w-1/2 ml-2"
                    service="tax"
                    label="Search Tax Class"
                    client={storeSocketClient}
                    searchProperty="name"
                    onChange={id => formik.setFieldValue('taxClass', id)}
                  />
                </div>

                <div className="w-full flex my-4 ">
                  <UserAutoCompleteSearch
                    id={product?.vendor}
                    className="!w-1/2"
                    service="user"
                    label="Search vendor"
                    client={storeSocketClient}
                    onChange={id => formik.setFieldValue('vendor', id)}
                  />
                </div>
                <div className="w-full flex flex-col my-4 ">
                  <SpecialPrice formik={formik} />
                </div>
                <div className="w-full flex my-4 ">
                  <AutoCompleteChipInput
                    client={storeSocketClient}
                    service="categories"
                    label="Search category"
                    heading="Category"
                    items={product?.categories}
                    onChange={values => formik.setFieldValue('categories', values)}
                  />
                </div>

                <div className="w-full flex my-4 ">
                  <AutoCompleteChipInput
                    client={storeSocketClient}
                    service="products"
                    label="Search cross sale products"
                    heading="Cross sale products"
                    items={product?.crossSaleProducts}
                    onChange={values => formik.setFieldValue('crossSaleProducts', values)}
                  />
                </div>

                <div className="w-full flex my-4 ">
                  <AutoCompleteChipInput
                    client={storeSocketClient}
                    service="products"
                    label="Search up sale products"
                    heading="Up sale products"
                    items={product?.upSaleProducts}
                    onChange={values => formik.setFieldValue('upSaleProducts', values)}
                  />
                </div>

                <div className="flex flex-col">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isActive || false}
                        onChange={e => formik.setFieldValue('isActive', e.target.checked)}
                      />
                    }
                    label="Active"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.manageInventory || false}
                        onChange={e => formik.setFieldValue('manageInventory', e.target.checked)}
                      />
                    }
                    label="Manage Inventory"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isNewArrival || false}
                        onChange={e => formik.setFieldValue('isNewArrival', e.target.checked)}
                      />
                    }
                    label="New Arrival"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.approved || false}
                        onChange={e => formik.setFieldValue('approved', e.target.checked)}
                      />
                    }
                    label="Approved"
                  />
                </div>
              </div>
              <div className="w-1/2 ml-8">
                <div className="w-full flex flex-col ">
                  <ProductOptions formik={formik} data={product?.options} />
                </div>
              </div>
            </div>

            <Box>
              <Button variant="contained" color="primary" className="!w-1/4 !my-2" size="large" type="submit">
                {id ? 'Edit' : 'Submit'}
              </Button>
            </Box>
          </div>
        </form>
      </Box>
    </Drawer>
  )
})
