import {Box, Button, Checkbox, FormControlLabel, Modal, TextField} from '@mui/material'
import {connectService} from 'components/service/connectService'
import {useEffect, useMemo} from 'react'
import {ModalLayout} from 'components/layouts/ModalLayout'
import * as yup from 'yup'
import {useFormik} from 'formik'
import {keys, pick} from 'lodash'
import {NumberTextField} from 'components/NumberTextField'

const validationSchema = yup.object({
  name: yup.string().required('Name is required')
})

const initialValues = {
  name: '',
  group: '',
  active: true,
  sortOrder: ''
}
export const AddEditModal = connectService(props => {
  const {open, handleModal, id, data, setData, setOpen, loading, Loader, patch, create} = props

  const initialData = useMemo(() => {
    return pick(data, keys(initialValues))
  }, [data])

  const formik = useFormik({
    initialValues: id
      ? {...initialData, active: data.hasOwnProperty('active') ? data.active : true}
      : initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      console.log(values)
      id ? patch(id, {...values}) : create({...values})
    }
  })

  const onClose = () => {
    formik.resetForm()
    setData({})
    setOpen(false)
  }

  useEffect(() => {
    if (!id) {
      setData({}) // create old persisted data
    }
  }, [id])

  return (
    <Modal
      open={open}
      onClose={() => {
        formik.resetForm()
        handleModal()
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout
        title={`${id ? 'Edit' : 'Add'} pet food type`}
        onClose={onClose}
        LoaderComponent={Loader}
        loading={loading}
      >
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id="standard-basic"
            label="Name"
            variant="outlined"
            {...formik.getFieldProps('name')}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
          />
          <div className="flex mt-4">
            <TextField
              id="standard-basic"
              label="Group"
              variant="outlined"
              {...formik.getFieldProps('group')}
              className={`w-1/2 !mr-2 `}
            />
            <NumberTextField
              type="number"
              label="Sort Order"
              variant="outlined"
              {...formik.getFieldProps('sortOrder')}
              error={formik.touched.sortOrder && Boolean(formik.errors.sortOrder)}
              helperText={formik.touched.sortOrder && formik.errors.sortOrder}
              className={`w-1/2 !ml-2 `}
              onInput={event => {
                event.target.value = event.target.value.replace(/^-/, '')
              }}
            />
          </div>
          <div className="my-4">
            <div className="flex justify-between items-center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.active}
                    onChange={e => formik.setFieldValue('active', e.target.checked)}
                  />
                }
                label="Active"
              />
            </div>
          </div>
          <Box>
            <Button variant="contained" color="primary" fullWidth size="large" type="submit">
              {id ? 'Edit' : 'Submit'}
            </Button>
          </Box>
        </form>
      </ModalLayout>
    </Modal>
  )
}, 'food-types')
