import Dialog from '@mui/material/Dialog'
import React, {forwardRef, useImperativeHandle, useState} from 'react'
export const PreviewImageDialog = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false)

  const handleOpenDialog = () => {
    setOpen(true)
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  useImperativeHandle(ref, () => ({
    openDialog: handleOpenDialog,
    open: open
  }))

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <div className="relative">
        {/* <CloseIcon onClick={handleCloseDialog} className='!absolute !top-2 !right-2 !z-[1000]' style={{color: 'white'}}/> */}
        <img src={props?.src} alt="Preview" style={{width: '100%'}} />
      </div>
    </Dialog>
  )
})
