import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField,
  Typography,
  Divider
} from '@mui/material'
import TableCell from '@mui/material/TableCell'

const columns = [
  {id: 'name', label: 'Name'},
  {id: 'price', label: 'Price'},
  {id: 'quantity', label: 'In Stock'},
  {id: 'sku', label: 'SKU'},
  {id: 'isActive', label: 'Status'}
]
export const ProductTable = props => {
  const {list, loading, Loader, Paginator, handleSearch} = props

  return (
    <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
      <Grid className="!p-4" container alignItems="center" spacing="10px">
        <Grid item xs={12} lg={2} md={4} gap="10px">
          <TextField
            label="Name"
            type="text"
            size="small"
            InputLabelProps={{
              shrink: true
            }}
            onChange={e => {
              handleSearch('name', e.target.value)
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length ? (
              list.map((row, index) => (
                <TableRow key={index} onClick={() => {}} hover>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>$ {row.price}</TableCell>
                  <TableCell>{row.quantity}</TableCell>
                  <TableCell>{row.sku}</TableCell>
                  <TableCell>{row.isActive ? 'Published' : 'Unknown'}</TableCell>
                </TableRow>
              ))
            ) : loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Loader />
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography>No Products</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Paginator />
      </TableContainer>
    </Paper>
  )
}
