import {Add} from '@mui/icons-material'
import {IconButton, Typography} from '@mui/material'
import {OptionItem} from './OptionItem'
import {useEffect, useState} from 'react'

const optionValues = {
  name: '',
  isRequired: false,
  values: []
}

export const ProductOptions = props => {
  const {formik, data = []} = props
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (data?.length > 0) {
      setOptions(data)
    }
  }, [data])

  useEffect(() => {
    if (formik) {
      formik.setFieldValue('options', options)
    }
    console.log(options)
  }, [options])

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center">
        <Typography className="!text-lg">Options</Typography>
        <IconButton
          className="!rounded-full !mx-4"
          variant="contained"
          color="primary"
          onClick={() => {
            setOptions([...options, optionValues])
          }}
        >
          <Add />
        </IconButton>
      </div>
      {options?.map((item, index) => (
        <OptionItem
          key={index}
          formik={formik}
          item={item}
          options={options}
          setOptions={setOptions}
          optionIndex={index}
        />
      ))}
    </div>
  )
}
