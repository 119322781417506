import React, {useState} from 'react'
import {Dialog, DialogContent, CircularProgress, styled} from '@mui/material'

const TransparentBackgroundDialog = styled(Dialog)(({theme}) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent'
  },
  '& .MuiPaper-root': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden'
  }
}))

const BlackTransparentBackdrop = styled('div')({
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black with 50% opacity
  zIndex: 1200, // Ensure the backdrop is displayed above other content
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

const LoaderContainer = styled(DialogContent)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh'
})

export const LoadingDialog = () => {
  const [open, setOpen] = useState(true) // Set this to false when you want to close the modal

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      {open && (
        <BlackTransparentBackdrop>
          <TransparentBackgroundDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="loading-dialog-title"
            aria-describedby="loading-dialog-description"
            fullWidth
            maxWidth="xs"
          >
            <LoaderContainer>
              <CircularProgress color="primary" size={60} thickness={4} />
            </LoaderContainer>
          </TransparentBackgroundDialog>
        </BlackTransparentBackdrop>
      )}
    </div>
  )
}
