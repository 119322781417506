import { Box, Button, FormControlLabel, Grid, IconButton, Modal, TextField, Typography, Switch, MenuItem } from "@mui/material"
import cross from 'images/crosss.svg';
import { DataGrid } from "@mui/x-data-grid";
import tableicon from 'images/newimages/tableicon.svg';

const editAdd = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
 
  width: 1000,
  
  '@media (max-width:767px)': {
    width: '95%',
  },
};

const Role = [
  {
    value: 'Menu Items',
    label: 'Menu Items',
  },
  {
    value: 'Role',
    label: 'Role',
  },
];
const urlcolumns = [
  { field: 'userimg', headerName: <img src={tableicon} />, width: 75, 
    renderCell: (params) => {
      const { value } = params;
      return typeof value === 'string' ? value : <button><img src={tableicon} /></button>;
      },  
  },
  { field: 'user', headerName: 'User', width: 290},
  { field: 'posttime', headerName: 'Last Posted', width: 270 },
  { field: 'postcontent', headerName: 'Post Content', width: 155 },
]

const urlData = [
  {
    userimg: {tableicon},
    user: 'petOwnerBTC',
    posttime: '15 min ago',
    postcontent: 'Earn bitcoin today by completing paid surveys....',
  },
];
export const UrlHistorModal = ({open, handleModal}) => {
  return (
    <Modal open={open} onClose={handleModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
      <Box  sx={ editAdd }>
        <Box py={2} px={2}>  
          <Box textAlign="right" width="100%" >
            <Button onClick={handleModal} style={{ position: 'absolute', right: '-9px', top: '-3px'}}><img src={cross} /></Button>
          </Box> 
          <Box>
            <Typography variant="h5" component="h5" color="transparent.contrastText" mb={0.5} sx={{ fontSize: '20px', fontWeight: '600'}}>URL History</Typography>
            <Typography variant="p" component="p" color="transparent.contrastText" sx={{ fontSize:'12px' }}>The page instruction goes here.  Actions are automatic actions that the system executes  based on parameters given on flagged content</Typography>
          </Box>
          <Grid container my={4}>
            <Grid item md={3} xs={12} sm={12}>
              <TextField id="role" select label="Data Range" defaultValue="Role" fullWidth >
                {Role.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>         
          <DataGrid columns={urlcolumns} rows={urlData} getRowId={(row) => row.userimg} />
        </Box>
      </Box>
    </Modal>
  )
}