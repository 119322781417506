import React from 'react'
import { motion } from 'framer-motion';
import { Button, Box, TextField, Modal, Grid, Stack, Avatar, Typography} from '@mui/material';
import cross from 'images/crosss.svg';
import avatar2 from 'images/avtarimg.png';
import scaner from 'images/newimages/ss.png';
import iconname from 'images/name-icon.svg';
import circle from 'images/newimages/circle.png';

export const PetBadgesModal = ({open, handleModal}) => {
  return (
    <Modal
      open={open}
      onClose={handleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <motion.div
        initial={{ translateX: "100%" }}
        animate={{ translateX: 0 }}
        exit={{ translateX: "100%" }}
        transition={{ duration: 0.3 }}
      >
        <Box
          height="100vh"
          maxWidth="lg"
          bgcolor="modalBg.main"
          sx={{ marginLeft: "auto", overflowY: "scroll" }}
        >
          <Box textAlign="right" width="100%">
            <Button
              onClick={handleModal}
              sx={{ position: "absolute", right: "0" }}
            >
              <img src={cross} />
            </Button>
          </Box>
          <Grid
            container
            columnGap={2}
            p={3}
            pt={5}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ boxShadow: 2 }}
            bgcolor="dark.contrastText"
          >
            <Grid item md={4} sm={12} xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item md={3}>
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      alt="Remy Sharp"
                      src={avatar2}
                      sx={{ width: 100, height: 100 }}
                      variant="rounded"
                    />
                  </Stack>
                </Grid>
                <Grid item md={9} pl={3}>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Pet Name :{" "}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "primary.main",
                      }}
                    >
                      Fluffy956
                    </Typography>
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Age:{" "}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "primary.main",
                      }}
                    >
                      2 ys 3 m
                    </Typography>
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Size:{" "}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "primary.main",
                      }}
                    >
                      Medium High
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
              xs={12}
              textAlign="left"
              sx={{ pt: { xs: 2, sm: 2, md: 0 } }}
            >
              <Grid container>
                <Box>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: "15px", fontWeight: "600" }}
                  >
                    Status:{" "}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "primary.main",
                      }}
                    >
                      Open to Breed
                    </Typography>
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: "15px", fontWeight: "600" }}
                  >
                    Tag #:{" "}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "primary.main",
                      }}
                    >
                      4523
                    </Typography>
                  </Typography>
                </Box>
                <Box ml={1}>
                  <img src={scaner} />
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
              xs={12}
              sx={{
                textAlign: { xs: "left", sm: "left", md: "right" },
                pr: { xs: 0, sm: 0, md: 5 },
                pt: { xs: 1, sm: 1, md: 0 },
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                sx={{ fontSize: "15px", fontWeight: "600" }}
              >
                Mariana Janeson
              </Typography>
              <Box
                display="flex"
                sx={{
                  justifyContent: {
                    xs: "flex-start",
                    sm: "flex-start",
                    md: "flex-end",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  component="h6"
                  mr={1}
                  sx={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "primary.main",
                  }}
                >
                  Pet Owner, Verified
                </Typography>
                <img src={iconname} />
              </Box>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontSize: "15px",
                  fontWeight: "600",
                  color: "primary.main",
                }}
              >
                PRIVACY ENABLED
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={3}
            pt={5}
            py={3}
          >
            <Grid lg={2} md={4} sm={6} xs={12} textAlign="center" sx={{mb: {lg: 0, md: 2, xs: 2, sm: 2} }}>
              <Box
                bgcolor="petBandge.main"
                mb={1}
                sx={{
                  height: "95px",
                  width: "95px",
                  borderRadius: "50%",
                  margin: "0 auto",
                }}
              ></Box>
              <Typography
                variant="h6"
                component="h6"
                sx={{ fontSize: "12px" }}
              >
                Challenge Patches
              </Typography>
            </Grid>
            <Grid lg={2} md={4} sm={6} xs={12} textAlign="center" sx={{mb: {lg: 0, md: 2, xs: 2, sm: 2} }}>
              <Box
                bgcolor="petBandge.main"
                mb={1}
                sx={{
                  height: "95px",
                  width: "95px",
                  borderRadius: "50%",
                  margin: "0 auto",
                }}
              ></Box>
              <Typography
                variant="h6"
                component="h6"
                sx={{ fontSize: "12px" }}
              >
                Black Hole Badges
              </Typography>
            </Grid>
            <Grid lg={2} md={4} sm={6} xs={12} textAlign="center" sx={{mb: {lg: 0, md: 2, xs: 2, sm: 2} }}>
              <Box
                bgcolor="petBandge.main"
                mb={1}
                sx={{
                  height: "95px",
                  width: "95px",
                  borderRadius: "50%",
                  margin: "0 auto",
                }}
              ></Box>
              <Typography
                variant="h6"
                component="h6"
                sx={{ fontSize: "12px" }}
              >
                Sun Badges
              </Typography>
            </Grid>
            <Grid lg={2} md={4} sm={6} xs={12} textAlign="center" sx={{mb: {lg: 0, md: 2, xs: 2, sm: 2} }}>
              <Box
                bgcolor="petBandge.main"
                mb={1}
                sx={{
                  height: "95px",
                  width: "95px",
                  borderRadius: "50%",
                  margin: "0 auto",
                }}
              ></Box>
              <Typography
                variant="h6"
                component="h6"
                sx={{ fontSize: "12px" }}
              >
                Earth Badges
              </Typography>
            </Grid>
            <Grid lg={2} md={4} sm={6} xs={12} textAlign="center" sx={{mb: {lg: 0, md: 2, xs: 2, sm: 2} }}>
              <Box
                bgcolor="petBandge.main"
                mb={1}
                sx={{
                  height: "95px",
                  width: "95px",
                  borderRadius: "50%",
                  margin: "0 auto",
                }}
              ></Box>
              <Typography
                variant="h6"
                component="h6"
                sx={{ fontSize: "12px" }}
              >
                Moon Badges
              </Typography>
            </Grid>
            <Grid lg={2} md={4} sm={6} xs={12} textAlign="center" sx={{mb: {lg: 0, md: 2, xs: 2, sm: 2} }}>
              <Box
                bgcolor="petBandge.main"
                mb={1}
                sx={{
                  height: "95px",
                  width: "95px",
                  borderRadius: "50%",
                  margin: "0 auto",
                }}
              ></Box>
              <Typography
                variant="h6"
                component="h6"
                sx={{ fontSize: "12px" }}
              >
                Meteorite Badges
              </Typography>
            </Grid>
          </Grid>
          <Box px={3}>
            <Typography
              variant="h6"
              component="h6"
              textAlign="center"
              pt={2}
              sx={{
                borderTop: "2px solid",
                borderColor: "border.main",
                fontSize: "12px",
              }}
            >
              Moon Badges are uncommon and represent an investment in
              learning.
            </Typography>
          </Box>

          <Box
            item
            pb={3}
            pt={4}
            px={3}
            mt={3}
            sx={{ mt: { md: 0, xs: 2, sm: 2 } }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} pb={2} sx={{ borderBottom: "2px solid", borderColor: "border.main" }}
            >
              <Typography
                variant="h6"
                component="h6"
                sx={{ fontWeight: "500" }}
              >
                Possible Badges
              </Typography>
            </Box>
            <Grid container direction="row" justifyContent="space-between"  sx={{ mx:{ md:-1.5, sm:-1.5, xs:0 } }}>
              <Grid md={4} sm={6} xs={12} mb={3}  sx={{ px: { md: 1.5, sm: 1.5, xs: 0  } }}>
                <Box p={2} sx={{ border: "1px solid #DBDBDB", borderRadius: '6px', position: 'relative' }}>
                  <Grid container direction="row">
                    <Grid md={1}>
                      <img src={circle} />
                    </Grid>
                    <Grid md={8} pl={1}>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={0.5}
                        sx={{ fontSize: "12px" }}
                      >
                        Going Transonic{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        color="bandgeText.main"
                        sx={{ fontSize: "12px" }}
                      >
                        Quickly & correctly answer 10 skill problem in a row
                        (Time limit depends on skill difficulty)
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box bgcolor="bandgeBg.main"  sx={{height: '14px', width: '41px', borderRadius: '4px', position: 'absolute', top: '10px', right: '10px'}} textAlign="center">
                    <Typography variant="h6" component="h6" color="allow.contrastText" sx={{fontSize:'12px', lineHeight: '13px'}}>
                      500
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              
              <Grid md={4} sm={6} xs={12} mb={3}  sx={{ px: { md: 1.5, sm: 1.5, xs: 0  } }}>
                <Box p={2} sx={{ border: "1px solid #DBDBDB", borderRadius: '6px', position: 'relative' }}>
                  <Grid container direction="row">
                    <Grid md={1}>
                      <img src={circle} />
                    </Grid>
                    <Grid md={8} pl={1}>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={0.5}
                        sx={{ fontSize: "12px" }}
                      >
                        Going Transonic{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        color="bandgeText.main"
                        sx={{ fontSize: "12px" }}
                      >
                        Quickly & correctly answer 10 skill problem in a row
                        (Time limit depends on skill difficulty)
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box bgcolor="bandgeBg.main"  sx={{height: '14px', width: '41px', borderRadius: '4px', position: 'absolute', top: '10px', right: '10px'}} textAlign="center">
                    <Typography variant="h6" component="h6" color="allow.contrastText" sx={{fontSize:'12px', lineHeight: '13px'}}>
                      500
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={4} sm={6} xs={12} mb={3}  sx={{ px: { md: 1.5, sm: 1.5, xs: 0  } }}>
                <Box p={2} sx={{ border: "1px solid #DBDBDB", borderRadius: '6px', position: 'relative' }}>
                  <Grid container direction="row">
                    <Grid md={1}>
                      <img src={circle} />
                    </Grid>
                    <Grid md={8} pl={1}>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={0.5}
                        sx={{ fontSize: "12px" }}
                      >
                        Going Transonic{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        color="bandgeText.main"
                        sx={{ fontSize: "12px" }}
                      >
                        Quickly & correctly answer 10 skill problem in a row
                        (Time limit depends on skill difficulty)
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box bgcolor="bandgeBg.main"  sx={{height: '14px', width: '41px', borderRadius: '4px', position: 'absolute', top: '10px', right: '10px'}} textAlign="center">
                    <Typography variant="h6" component="h6" color="allow.contrastText" sx={{fontSize:'12px', lineHeight: '13px'}}>
                      500
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={4} sm={6} xs={12} mb={3} sx={{ px: { md: 1.5, sm: 1.5, xs: 0  } }}>
                <Box p={2} sx={{ border: "1px solid #DBDBDB", borderRadius: '6px', position: 'relative' }}>
                  <Grid container direction="row">
                    <Grid md={1}>
                      <img src={circle} />
                    </Grid>
                    <Grid md={8} pl={1}>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={0.5}
                        sx={{ fontSize: "12px" }}
                      >
                        Going Transonic{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        color="bandgeText.main"
                        sx={{ fontSize: "12px" }}
                      >
                        Quickly & correctly answer 10 skill problem in a row
                        (Time limit depends on skill difficulty)
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box bgcolor="bandgeBg.main"  sx={{height: '14px', width: '41px', borderRadius: '4px', position: 'absolute', top: '10px', right: '10px'}} textAlign="center">
                    <Typography variant="h6" component="h6" color="allow.contrastText" sx={{fontSize:'12px', lineHeight: '13px'}}>
                      500
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={4} sm={6} xs={12} mb={3}  sx={{ px: { md: 1.5, sm: 1.5, xs: 0  } }}>
                <Box p={2} sx={{ border: "1px solid #DBDBDB", borderRadius: '6px', position: 'relative' }}>
                  <Grid container direction="row">
                    <Grid md={1}>
                      <img src={circle} />
                    </Grid>
                    <Grid md={8} pl={1}>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={0.5}
                        sx={{ fontSize: "12px" }}
                      >
                        Going Transonic{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        color="bandgeText.main"
                        sx={{ fontSize: "12px" }}
                      >
                        Quickly & correctly answer 10 skill problem in a row
                        (Time limit depends on skill difficulty)
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box bgcolor="bandgeBg.main"  sx={{height: '14px', width: '41px', borderRadius: '4px', position: 'absolute', top: '10px', right: '10px'}} textAlign="center">
                    <Typography variant="h6" component="h6" color="allow.contrastText" sx={{fontSize:'12px', lineHeight: '13px'}}>
                      500
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={4} sm={6} xs={12} mb={3}  sx={{ px: { md: 1.5, sm: 1.5, xs: 0  } }}>
                <Box p={2} sx={{ border: "1px solid #DBDBDB", borderRadius: '6px', position: 'relative' }}>
                  <Grid container direction="row">
                    <Grid md={1}>
                      <img src={circle} />
                    </Grid>
                    <Grid md={8} pl={1}>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={0.5}
                        sx={{ fontSize: "12px" }}
                      >
                        Going Transonic{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        color="bandgeText.main"
                        sx={{ fontSize: "12px" }}
                      >
                        Quickly & correctly answer 10 skill problem in a row
                        (Time limit depends on skill difficulty)
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box bgcolor="bandgeBg.main"  sx={{height: '14px', width: '41px', borderRadius: '4px', position: 'absolute', top: '10px', right: '10px'}} textAlign="center">
                    <Typography variant="h6" component="h6" color="allow.contrastText" sx={{fontSize:'12px', lineHeight: '13px'}}>
                      500
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={4} sm={6} xs={12} mb={3} sx={{ px: { md: 1.5, sm: 1.5, xs: 0  } }}>
                <Box p={2} sx={{ border: "1px solid #DBDBDB", borderRadius: '6px', position: 'relative' }}>
                  <Grid container direction="row">
                    <Grid md={1}>
                      <img src={circle} />
                    </Grid>
                    <Grid md={8} pl={1}>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={0.5}
                        sx={{ fontSize: "12px" }}
                      >
                        Going Transonic{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        color="bandgeText.main"
                        sx={{ fontSize: "12px" }}
                      >
                        Quickly & correctly answer 10 skill problem in a row
                        (Time limit depends on skill difficulty)
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box bgcolor="bandgeBg.main"  sx={{height: '14px', width: '41px', borderRadius: '4px', position: 'absolute', top: '10px', right: '10px'}} textAlign="center">
                    <Typography variant="h6" component="h6" color="allow.contrastText" sx={{fontSize:'12px', lineHeight: '13px'}}>
                      500
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={4} sm={6} xs={12} mb={3}  sx={{ px: { md: 1.5, sm: 1.5, xs: 0  } }}>
                <Box p={2} sx={{ border: "1px solid #DBDBDB", borderRadius: '6px', position: 'relative' }}>
                  <Grid container direction="row">
                    <Grid md={1}>
                      <img src={circle} />
                    </Grid>
                    <Grid md={8} pl={1}>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={0.5}
                        sx={{ fontSize: "12px" }}
                      >
                        Going Transonic{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        color="bandgeText.main"
                        sx={{ fontSize: "12px" }}
                      >
                        Quickly & correctly answer 10 skill problem in a row
                        (Time limit depends on skill difficulty)
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box bgcolor="bandgeBg.main"  sx={{height: '14px', width: '41px', borderRadius: '4px', position: 'absolute', top: '10px', right: '10px'}} textAlign="center">
                    <Typography variant="h6" component="h6" color="allow.contrastText" sx={{fontSize:'12px', lineHeight: '13px'}}>
                      500
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={4} sm={6} xs={12} mb={3}  sx={{ px: { md: 1.5, sm: 1.5, xs: 0  } }}>
                <Box p={2} sx={{ border: "1px solid #DBDBDB", borderRadius: '6px', position: 'relative' }}>
                  <Grid container direction="row">
                    <Grid md={1}>
                      <img src={circle} />
                    </Grid>
                    <Grid md={8} pl={1}>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={0.5}
                        sx={{ fontSize: "12px" }}
                      >
                        Going Transonic{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        color="bandgeText.main"
                        sx={{ fontSize: "12px" }}
                      >
                        Quickly & correctly answer 10 skill problem in a row
                        (Time limit depends on skill difficulty)
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box bgcolor="bandgeBg.main"  sx={{height: '14px', width: '41px', borderRadius: '4px', position: 'absolute', top: '10px', right: '10px'}} textAlign="center">
                    <Typography variant="h6" component="h6" color="allow.contrastText" sx={{fontSize:'12px', lineHeight: '13px'}}>
                      500
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={4} sm={6} xs={12} mb={3}  sx={{ px: { md: 1.5, sm: 1.5, xs: 0  } }}>
                <Box p={2} sx={{ border: "1px solid #DBDBDB", borderRadius: '6px', position: 'relative' }}>
                  <Grid container direction="row">
                    <Grid md={1}>
                      <img src={circle} />
                    </Grid>
                    <Grid md={8} pl={1}>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={0.5}
                        sx={{ fontSize: "12px" }}
                      >
                        Going Transonic{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        color="bandgeText.main"
                        sx={{ fontSize: "12px" }}
                      >
                        Quickly & correctly answer 10 skill problem in a row
                        (Time limit depends on skill difficulty)
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box bgcolor="bandgeBg.main"  sx={{height: '14px', width: '41px', borderRadius: '4px', position: 'absolute', top: '10px', right: '10px'}} textAlign="center">
                    <Typography variant="h6" component="h6" color="allow.contrastText" sx={{fontSize:'12px', lineHeight: '13px'}}>
                      500
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={4} sm={6} xs={12} mb={3}  sx={{ px: { md: 1.5, sm: 1.5, xs: 0  } }}>
                <Box p={2} sx={{ border: "1px solid #DBDBDB", borderRadius: '6px', position: 'relative' }}>
                  <Grid container direction="row">
                    <Grid md={1}>
                      <img src={circle} />
                    </Grid>
                    <Grid md={8} pl={1}>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={0.5}
                        sx={{ fontSize: "12px" }}
                      >
                        Going Transonic{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        color="bandgeText.main"
                        sx={{ fontSize: "12px" }}
                      >
                        Quickly & correctly answer 10 skill problem in a row
                        (Time limit depends on skill difficulty)
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box bgcolor="bandgeBg.main"  sx={{height: '14px', width: '41px', borderRadius: '4px', position: 'absolute', top: '10px', right: '10px'}} textAlign="center">
                    <Typography variant="h6" component="h6" color="allow.contrastText" sx={{fontSize:'12px', lineHeight: '13px'}}>
                      500
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={4} sm={6} xs={12} mb={3}  sx={{ px: { md: 1.5, sm: 1.5, xs: 0  } }}>
                <Box p={2} sx={{ border: "1px solid #DBDBDB", borderRadius: '6px', position: 'relative' }}>
                  <Grid container direction="row">
                    <Grid md={1}>
                      <img src={circle} />
                    </Grid>
                    <Grid md={8} pl={1}>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={0.5}
                        sx={{ fontSize: "12px" }}
                      >
                        Going Transonic{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        color="bandgeText.main"
                        sx={{ fontSize: "12px" }}
                      >
                        Quickly & correctly answer 10 skill problem in a row
                        (Time limit depends on skill difficulty)
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box bgcolor="bandgeBg.main"  sx={{height: '14px', width: '41px', borderRadius: '4px', position: 'absolute', top: '10px', right: '10px'}} textAlign="center">
                    <Typography variant="h6" component="h6" color="allow.contrastText" sx={{fontSize:'12px', lineHeight: '13px'}}>
                      500
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={4} sm={6} xs={12} mb={3} sx={{ px: { md: 1.5, sm: 1.5, xs: 0  } }}>
                <Box p={2} sx={{ border: "1px solid #DBDBDB", borderRadius: '6px', position: 'relative' }}>
                  <Grid container direction="row">
                    <Grid md={1}>
                      <img src={circle} />
                    </Grid>
                    <Grid md={8} pl={1}>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={0.5}
                        sx={{ fontSize: "12px" }}
                      >
                        Going Transonic{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        color="bandgeText.main"
                        sx={{ fontSize: "12px" }}
                      >
                        Quickly & correctly answer 10 skill problem in a row
                        (Time limit depends on skill difficulty)
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box bgcolor="bandgeBg.main"  sx={{height: '14px', width: '41px', borderRadius: '4px', position: 'absolute', top: '10px', right: '10px'}} textAlign="center">
                    <Typography variant="h6" component="h6" color="allow.contrastText" sx={{fontSize:'12px', lineHeight: '13px'}}>
                      500
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={4} sm={6} xs={12} mb={3} sx={{ px: { md: 1.5, sm: 1.5, xs: 0  } }}>
                <Box p={2} sx={{ border: "1px solid #DBDBDB", borderRadius: '6px', position: 'relative' }}>
                  <Grid container direction="row">
                    <Grid md={1}>
                      <img src={circle} />
                    </Grid>
                    <Grid md={8} pl={1}>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={0.5}
                        sx={{ fontSize: "12px" }}
                      >
                        Going Transonic{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        color="bandgeText.main"
                        sx={{ fontSize: "12px" }}
                      >
                        Quickly & correctly answer 10 skill problem in a row
                        (Time limit depends on skill difficulty)
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box bgcolor="bandgeBg.main"  sx={{height: '14px', width: '41px', borderRadius: '4px', position: 'absolute', top: '10px', right: '10px'}} textAlign="center">
                    <Typography variant="h6" component="h6" color="allow.contrastText" sx={{fontSize:'12px', lineHeight: '13px'}}>
                      500
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={4} sm={6} xs={12} mb={3} sx={{ px: { md: 1.5, sm: 1.5, xs: 0  } }}>
                <Box p={2} sx={{ border: "1px solid #DBDBDB", borderRadius: '6px', position: 'relative' }}>
                  <Grid container direction="row">
                    <Grid md={1}>
                      <img src={circle} />
                    </Grid>
                    <Grid md={8} pl={1}>
                      <Typography
                        variant="h6"
                        component="h6"
                        mb={0.5}
                        sx={{ fontSize: "12px" }}
                      >
                        Going Transonic{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        color="bandgeText.main"
                        sx={{ fontSize: "12px" }}
                      >
                        Quickly & correctly answer 10 skill problem in a row
                        (Time limit depends on skill difficulty)
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box bgcolor="bandgeBg.main"  sx={{height: '14px', width: '41px', borderRadius: '4px', position: 'absolute', top: '10px', right: '10px'}} textAlign="center">
                    <Typography variant="h6" component="h6" color="allow.contrastText" sx={{fontSize:'12px', lineHeight: '13px'}}>
                      500
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </motion.div>
    </Modal>
  )
}