import {useState} from 'react'
import {Analytics} from './Analytics'
import UserTable from './UserTable'
import DetailModal from './users/DetailModal'
import ReceiptModel from './modals/ReceiptModal'
import {AppLayout} from 'modules/layout/AppLayout'
import {requireAuth} from 'modules/auth/components/requireAuth'
import {ConnectedComponent} from 'components/service/ConnectedComponent'
import {Chat} from '../messaging/Chat'

export const Dashboard = requireAuth(() => {
  const [selectedItem, setSelectedItem] = useState(null)

  const [age, setAge] = useState('')
  const handleChange = event => {
    setAge(event.target.value)
  }

  const [openDetailModal, setOpenDetailModal] = useState(false)
  const [openAddPetModal, setOpenAddPetModal] = useState(false)
  const [openEditPetModal, setOpenEditPetModal] = useState(false)
  const [openReceptModal, setOpenReceptModal] = useState(false)

  const handleDetailModal = () => {
    console.log(openDetailModal)
    setOpenDetailModal(!openDetailModal)
  }

  const handleAddPetModal = () => {
    setOpenAddPetModal(!openAddPetModal)
  }

  const handleEditPetModal = () => {
    setOpenEditPetModal(!openEditPetModal)
  }

  const handleReceptModal = () => {
    setOpenReceptModal(!openReceptModal)
  }

  const handleRowClick = id => {
    setSelectedItem(id)
    handleDetailModal()
  }

  const [showChat, setShowChat] = useState(false)

  window.enableBetaChat = (withUser) => {
    setShowChat(withUser)
  }

  return (
    <AppLayout heading="User Dashboard">
      <Analytics />

      <ConnectedComponent Component={UserTable} service="users" onRowClick={handleRowClick} />

      <ConnectedComponent
        Component={DetailModal}
        id={selectedItem}
        service="users"
        config={{
          query: {
            $populate: 'profile'
          }
        }}
        openDetailModal={openDetailModal}
        handleDetailModal={handleDetailModal}
        handleAddPetModal={handleAddPetModal}
        handleEditPetModal={handleEditPetModal}
        handleChange={handleChange}
        age={age}
        openEditPetModal={openEditPetModal}
      />

      <ReceiptModel openReceptModal={openReceptModal} handleReceptModal={handleReceptModal} />
      {
        showChat ? <Chat participant={showChat}/> : null
      }

    </AppLayout>
  )
})
