import {Close} from '@mui/icons-material'
import {Box, Button, IconButton, Modal, Typography} from '@mui/material'
import {storeSocketClient} from 'modules/store/client/socket.client'
import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,

  width: 600,

  '@media (max-width:767px)': {
    width: '95%'
  }
}

export const DeleteModal = forwardRef((props, ref) => {
  const {onDelete} = props
  const [open, setOpen] = useState(false)

  const [id, setId] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setId(null)
    setOpen(false)
  }
  const service = storeSocketClient.service('categories')
  useImperativeHandle(
    ref,
    () => ({
      show(id = null) {
        setId(id)
        handleOpen()
      }
    }),
    []
  )

  const deleteItem = () => {
    if (id) {
      setLoading(true)
      service
        .remove(id)
        .then(result => {
          handleClose()
          onDelete()
          setLoading(false)
        })
        .catch(console.error)
    }
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box sx={{p: {lg: 5, md: 5, sm: 4, xs: 4}}}>
            <Box display="flex" alignItems="center" justifyContent="space-between" pb={2.5}>
              <Typography
                variant="h5"
                component="h5"
                color="transparent.contrastText"
                sx={{fontSize: '24px', fontWeight: '600'}}
                style={{width: '100%'}}
              >
                Are you sure?
              </Typography>
              <Box textAlign="right" width="100%">
                <IconButton onClick={handleClose}>
                  <Close onClick={handleClose} />
                </IconButton>
              </Box>
            </Box>
            <Box>
              <div>
                <p>This will delete the item permanently. You can not undo this action. </p>
                <div className="flex justify-end mt-4">
                  <Button
                    className="!mt-4"
                    variant="contained"
                    color="primary"
                    onClick={deleteItem}
                    disabled={loading}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  )
})
