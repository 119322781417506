import {useEffect, useState} from 'react'
import {
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Typography,
  Avatar,
  Chip,
  IconButton,
} from '@mui/material'
import {Delete, Edit} from '@mui/icons-material'
import {StyledTable, StyledTableRow, StyledTableHeadCell, StyledTableCell} from 'components/StyledComponent'
import moment from 'moment'
import {ViewDetailModal} from '../modals/ViewDetailModal'
import placeholder from 'images/avatar_placeholder.svg'
import { ImagePreview } from 'components/ImagePreview'

export const DataTable = props => {
  const {
    list,
    loading,
    Loader,
    Paginator,
    onEdit,
    onDelete,
    isRefreshTable,
    setRefreshTable,
    refreshTable
  } = props

  const [openModal, setModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)

  useEffect(() => {
    if (isRefreshTable) {
      refreshTable()
      setRefreshTable(false)
    }
  }, [isRefreshTable])

  const getPetProfile = (report) => {
    if (report?.media?.length) {
      return report.media[0]
    }

    if (report?.pet?.profile) {
      return report?.pet?.profile
    }

    return null
  }

  return (
    <div>
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <TableContainer>
          <StyledTable stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <StyledTableHeadCell>
                </StyledTableHeadCell>
                <StyledTableHeadCell>Pet Name</StyledTableHeadCell>
                <StyledTableHeadCell>Owner Name</StyledTableHeadCell>
                <StyledTableHeadCell>Claimed</StyledTableHeadCell>
                <StyledTableHeadCell>Claimed By</StyledTableHeadCell>
                <StyledTableHeadCell>Reward</StyledTableHeadCell>
                <StyledTableHeadCell>Scans</StyledTableHeadCell>
                <StyledTableHeadCell>Reported at</StyledTableHeadCell>
                <StyledTableHeadCell></StyledTableHeadCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {list.length > 0 ? (
                list.map((row, index) => (
                  <StyledTableRow
                    onClick={() => {
                      setSelectedRow(row)
                      setModal(true)
                    }}
                    key={index}
                    odd={index % 2 !== 0 ? 1 : 0}
                  >
                    <StyledTableCell>
                      {getPetProfile(row) ? (
                        <ImagePreview
                          media={getPetProfile(row)}
                          className="w-10 h-10  rounded-full"
                          fallbackSrc={placeholder}
                        />
                      ) : (
                        <Avatar src={placeholder}></Avatar>
                      )}
                    </StyledTableCell>
                    <StyledTableCell data-label="Pet Name">
                      {row?.pet?.name ? row.pet?.name : '-'}
                    </StyledTableCell>
                    <StyledTableCell data-label="Owner Name">
                      {row?.user?.firstName || row?.user?.lastName ? `${row?.user?.firstName} ${row?.user?.lastName}` : '-'}
                    </StyledTableCell>
                    <StyledTableCell data-label="Claimed">
                      {row?.claimed ? (
                        <Chip label="Yes" size="small" color="success" variant="outlined" />
                      ) : (
                        <Chip label="No" size="small" color="error" variant="outlined" />
                      )}
                    </StyledTableCell>
                    <StyledTableCell data-label="Claimed By">
                      {row?.claimedBy?.firstName || row?.claimedBy?.lastName ? `${row?.claimedBy?.firstName} ${row?.claimedBy?.lastName}` : '-'}
                    </StyledTableCell>
                    <StyledTableCell data-label="Reward">
                      {row?.rewardAmount ? `$${Number(row.rewardAmount).toFixed(2)}` : '$0.00'}
                    </StyledTableCell>
                    <StyledTableCell data-label="Scans">{row?.scans?.length || '0'}</StyledTableCell>
                    <StyledTableCell data-label="Reported At">
                      {row?.createdAt ? moment(row.createdAt).format('DD MMM YYYY') : ''}
                    </StyledTableCell>
                    <StyledTableCell size="small">
                      <IconButton
                        onClick={e => {
                          e.stopPropagation()
                          onEdit(row._id)
                        }}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton
                        onClick={e => {
                          e.stopPropagation()
                          onDelete(row._id)
                        }}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : loading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={8} align="center">
                    <Loader />
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={8} align="center">
                    <Typography>No Data</Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <Paginator />
      </Paper>
      {openModal && (
        <ViewDetailModal id={selectedRow?._id} open={openModal} handleClose={() => setModal(false)} />
      )}
    </div>
  )
}
