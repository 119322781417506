import React, {useEffect, useState} from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import {findIndex, map, remove} from 'lodash'
import {Chip, IconButton, Stack, Tooltip, Typography} from '@mui/material'
import {Add, Close} from '@mui/icons-material'
import {socketClient} from 'client/socket.client'

export const AutoCompleteChipInput = props => {
  const {
    label = 'Search',
    searchProperty = 'name',
    service,
    items = [],
    heading,
    list,
    onChange,
    client = socketClient
  } = props
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])

  useEffect(() => {
    if (items?.length > 0) {
      client
        .service(service)
        .find({
          query: {
            _id: {
              $in: [...items]
            }
          }
        })
        .then(result => {
          setSelectedItems(result?.data)
        })
        .catch(console.error)
    }

    client
      .service(service)
      .find()
      .then(result => {
        setOptions(result?.data)
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
  }, [items])

  const handleInputChange = async event => {
    const searchTerm = event.target.value
    setLoading(true)
    const query = {
      $limit: 10,
      $skip: 0
    }

    if (searchTerm.length >= 3) {
      query[searchProperty] = {}
      query[searchProperty]['$regex'] = searchTerm
      query[searchProperty]['$options'] = 'i'
    }

    client
      .service(service)
      .find({query})
      .then(result => {
        setOptions(result?.data)
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (list && list.length > 0) {
      setOptions(list)
    }
  }, [list])

  const handleDelete = item => {
    let _items = [...selectedItems]
    const index = findIndex(_items, i => i._id === item?._id)
    if (index !== -1) _items.splice(index, 1)

    const ids = map(_items, i => i._id)
    setSelectedItems(_items)
    onChange(ids)
  }

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="flex items-center">
          <Typography className="!text-lg">{heading}</Typography>
          <Tooltip title={showInput ? 'Close' : `Add ${heading}`}>
            <IconButton
              className="!rounded-full !mx-4"
              variant="contained"
              color="primary"
              onClick={() => setShowInput(!showInput)}
            >
              {showInput ? <Close /> : <Add />}
            </IconButton>
          </Tooltip>
        </div>
        {showInput && (
          <Autocomplete
            id="autocomplete"
            className="!w-full !my-2"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionLabel={option => option.name || ''}
            options={options}
            loading={loading}
            onChange={(e, value) => {
              if (value) {
                onChange([...items, value?._id])
                setSelectedItems([...selectedItems, value])
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
                onChange={handleInputChange}
                error={props.error}
                helperText={props.helperText}
              />
            )}
          />
        )}

        <div className="flex mt-4 w-full" style={{display: 'flex', flexWrap: 'wrap', gap: '8px'}}>
          {selectedItems?.map((item, index) => (
            <Chip
              key={index}
              label={item?.name}
              variant="outlined"
              className="!mx-1"
              onDelete={() => handleDelete(item)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
