import React, {useEffect} from 'react'
import {ConnectedComponent} from 'components/service/ConnectedComponent'
import {Browse} from './vendor-requests/Browse'

export const VendorRequestsDashboard = props => {
  const {setNavigationTitle} = props
  useEffect(() => {
    setNavigationTitle('Vendor Requests')
  }, [])

  return (
    <ConnectedComponent
      Component={Browse}
      service="vendor-requests"
      config={{
        query: {
          $populate: ['user', 'approvedBy']
        }
      }}
    />
  )
}
