import {Box, Modal, Typography, IconButton, Button} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import Close from '@mui/icons-material/Close'
import playstore from 'images/playstore.svg'
import appstore from 'images/appstore.svg'
import { AppDownloadButton } from 'components/AppDownloadButton'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
  p: 2
}

export const SuccessModel = (props) => {
  const {open, setOpen} = props
  const navigate = useNavigate()

  const handleClose = () => {
    setOpen(false)
    navigate('/', {replace: true})
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div className="flex flex-col items-center ">
            <div className="flex items-center justify-center w-full">
              <Typography className="!ml-2 !text-base flex-1 !text-center !font-bold">Application Submitted</Typography>
              <Box textAlign="right">
                <IconButton onClick={handleClose}>
                  <Close onClick={handleClose} />
                </IconButton>
              </Box>
            </div>

            <p className="mt-4 text-start">
              You've successfully enrolled and your wallet is ready.
            </p>
            <Button sx={{my: '0.5rem'}} variant='outlined' color='primary' onClick={() => navigate('/wallet', {replace: true})}>Continue</Button>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={4}>
              <div className="text-md mb-2 mt-8">Download the app now</div>
              <div className="flex justify-center">
                <AppDownloadButton
                  icon={appstore}
                  description="Download on the"
                  platform="AppStore"
                  link="https://www.apple.com/in/app-store"
                />

                <AppDownloadButton
                  icon={playstore}
                  description="GET IT ON"
                  platform="Google play"
                  link="https://play.google.com/store/games?hl=en-IN"
                />
              </div>
            </Box>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
