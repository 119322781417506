import React, { useEffect } from 'react'
import { ConnectedComponent } from 'components/service/ConnectedComponent'
import { storeSocketClient } from './client/socket.client'
import { Orders } from './orders/Orders'

export const OrderDashboard = props => {
  const { setNavigationTitle } = props
  useEffect(() => {
    setNavigationTitle('Orders')
  }, [])
  return (
    <ConnectedComponent
      Component={Orders}
      service="orders"
      client={storeSocketClient}
      config={{
        query: {
          $populate: ['user', 'guest', {
            path: 'products',
            populate: {
              path : 'product',
              populate: 'taxClass'
            }
          }]
        }
      }}
    />
  )
}
