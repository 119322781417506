import axios from 'axios'
import {LostPetPublicProfile} from './public-report'
import {useEffect, useMemo, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {PetFoundActions} from 'store/modules/pet-found-report/actions'
import {useDispatch, useSelector} from 'react-redux'
import {PetPublicProfile} from './public-profile'
import {NoPetFound} from './component/NoPetFound'
import {LoadingDialog} from 'components/LodingDialog'
import { Chat } from 'modules/messaging/Chat'

export const PetFoundPage = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const code = searchParams.get('code')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.authentication)

  const shouldAllowMessaging = useMemo(() => {
    return !!user?._id && !!data?.pet?.user?._id
  }, [user, data])

  const getData = async () => {
    try {
      setLoading(true)
      const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/pet-found?code=${code}`)
      if (result?.data?.report && result?.data?.pet?.lostMode) {
        setData(result?.data)
        dispatch(PetFoundActions.setPetFoundReport(result?.data))
      } else {
        const _result = await axios.get(`${process.env.REACT_APP_BASE_URL}/pet-profile?code=${code}`)
        setData(_result?.data)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (code) {
      setLoading(true)
      getData()
    }
  }, [code])

  if (loading) {
    return <LoadingDialog />
  }

  if (!data) {
    return  <NoPetFound />
  }

  return (
    <>
      { data?.report && data?.pet?.lostMode ? <LostPetPublicProfile data={data} /> : <PetPublicProfile data={data} />}
      { shouldAllowMessaging ? <Chat participant={data.pet.user._id} /> : null }
    </>
  )
}
