import React, { createContext, useCallback, useState } from 'react';

export const DashboardContext = createContext({
  open: true,
  toggleDashboard: () => {}
});

export const DashboardContextProvider = ({ children }) =>{
  const [open, setOpen] = useState()

  const toggleDashboard = useCallback(() => {
      setOpen(!open)
  }, [])

  return <DashboardContext.Provider value={{ open, toggleDashboard }}>
      {children}
  </DashboardContext.Provider>
}
