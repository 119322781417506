import {Routes, Route} from 'react-router-dom'
import EnrollDetailView from './DetailView'
import { Brows } from './Brows'

export const Enroll = (props) => {
  return (
    <Routes>
      <Route path="/" element={<Brows setNavigationTitle={props.setNavigationTitle}/>} />
      <Route path="/:id" element={<EnrollDetailView setNavigationTitle={props.setNavigationTitle}/>} />
    </Routes>
  )
}
