import React, {useEffect} from 'react'
import {ConnectedComponent} from 'components/service/ConnectedComponent'
import {storeSocketClient} from './client/socket.client'
import {Brands} from './brands/Brands'

export const BrandDashBoard = props => {
  const {setNavigationTitle} = props
  useEffect(() => {
    setNavigationTitle('Brands')
  }, [])

  return (
    <ConnectedComponent
      Component={Brands}
      service="brands"
      client={storeSocketClient}
      config={{
        query: {
          $populate: ['vendor', 'icon']
        }
      }}
    />
  )
}
