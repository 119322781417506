import { Grid, Box, Avatar, Typography, Card, Button} from '@mui/material';
import placeholder from 'images/avatar_placeholder.svg'
import { pink } from '@mui/material/colors';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Header2 from "components/Header2";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import {useSelector} from 'react-redux'
import { reduce } from "lodash";
import axios from "axios";
import { GET_ACCOUNTS, GET_DWOLLA_ACCESS_TOKEN, GET_DWOLLA_ID, GET_PLAID_BALANCE, IPAY_NET_BASE_URL, currency, enrollHeader } from "utils/Constants";
import { LoadingDialog } from "components/LodingDialog";

const CustomTypography = (props) => {
  const {label, value} = props
  return (
    <Box flexDirection="column" p="0.5rem" flex="1">
      <Typography textAlign="left" className="!text-gray-500">
        {label}
      </Typography>
      <Typography textAlign="left">
        {value}
      </Typography>
    </Box>
  )
}


const data = [
  {
    firstName: 'Pavan',
    lastName: 'Thakur',
    dateTime: '18 Dec 2023 18:45:89',
    from: 'App Wallet',
    to: 'Pavan Rajput',
    status: 'Complete',
    amount: '$12,024.00',
    type: 'debit'
  },
  {
    firstName: 'Krishna',
    lastName: 'Vyas',
    dateTime: '30 Dec 2023 21:45:89',
    from: 'Krishna Vyas',
    to: 'App Wallet',
    status: 'Complete',
    amount: '$400.45',
    type: 'credit'
  },
  {
    firstName: 'Ram',
    lastName: 'Vansh',
    dateTime: '01 Jan 2023 02:45:89',
    from: 'Krishna Vyas',
    to: 'App Wallet',
    status: 'Complete',
    amount: '$123.00',
    type: 'credit'
  }
]

export const AccountActivity = () => {
  const user = useSelector(state => state?.authentication?.user)
  const [walletBalance, setWalletBalance] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        setLoading(true)
        const dwollaResult = await axios.post(`${IPAY_NET_BASE_URL}/${GET_DWOLLA_ID}/${user?._id}`, {}, {headers: enrollHeader})

        if (dwollaResult.status === 200 && dwollaResult?.data) {
          const accountsResult = await axios.post(`${IPAY_NET_BASE_URL}/${GET_ACCOUNTS}`, {dwollaId: dwollaResult?.data}, {headers: enrollHeader})

          if (accountsResult.status === 200 && accountsResult?.data?.length) {
            const accounts = accountsResult?.data.map((account) => account?.plaid_id)

            if (accounts?.length) {
              const accessToken = await axios.post(`${IPAY_NET_BASE_URL}/${GET_DWOLLA_ACCESS_TOKEN}`, {dwollaId: dwollaResult?.data}, {headers: enrollHeader})

              if (accessToken.status === 200 && accessToken?.data) {
                const accountBalances = await axios.post(`${IPAY_NET_BASE_URL}/${GET_PLAID_BALANCE}`, {accessToken : accessToken?.data, accounts}, {headers: enrollHeader})

                if (accountBalances?.status === 200 && accountBalances?.data) {
                  const balance = reduce(accountBalances?.data?.accounts, function(acc, account) {
                    return acc + account?.balances?.available
                  }, 0)
                  setWalletBalance(balance)
                  setLoading(false)
                }
              }
            }
          }
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.log(e)
      }
    }

    fetchBalance()
  }, [user])

  return loading ? (<LoadingDialog />) : (
    <Box sx={{backgroundColor: 'rgb(247, 247, 247)', height: '100vh'}}>
      <Header2 heading="My Wallet"/>

      <Box sx={{px: {lg: '4rem', xs: '1rem'}, mt: '5rem'}}>
        <div className='flex mb-4'>
          <AccountBalanceWalletIcon sx={{ fontSize: 32, color: pink[500] }}/>
          <Typography sx={{fontWeight: 'bold', fontSize: '24px', textAlign: 'left', mx:'8px'}}>Wallet</Typography>
        </div>
        <Card sx={{pb: {xs: '1rem', md: 0}}}>
          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <Box sx={{ display: 'flex', p: '16px', alignItems: 'center', height: '100%' }}>
                <Avatar src={placeholder} sx={{ width: 48, height: 48 }}></Avatar>
                <Box ml="12px" flex="1 1 0">
                  <Box sx={{display: 'flex', justifyContent:'space-between', alignItems: 'center', flexWrap: 'wrap', textAlign:'left'}}>
                    <div>
                      <Typography sx={{fontWeight: 'bold'}}>{user?.firstName ? user?.firstName : ''} {user?.lastName ? user?.lastName : ''}</Typography>
                      <Typography sx={{fontSize: '12px'}}>{user?.email ? user?.email : ''}</Typography>
                      <Typography sx={{fontSize: '12px'}}>{user?.phone ? user?.phone : ''}</Typography>
                      <Typography sx={{fontWeight: 'bold', color: pink[500] }}><span className="font-normal text-gray-400" >Available Balance:</span>{currency(walletBalance)}</Typography>
                    </div>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <div className="flex justify-evenly	items-center h-full w-full">
                <Button variant="contained" color="primary">Add Money</Button>
                <Button variant="contained" color="primary">Send</Button>
                <Button variant="contained" color="primary">Request</Button>
              </div>
            </Grid>
          </Grid>
        </Card>
        <Typography sx={{textAlign: 'left', mt: '2rem', mb: '0.5rem'}}>Operations</Typography>
          {data.map((item, index) => (
            <Accordion sx={{my: 2}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box sx={{display: 'flex', justifyContent:'space-between', alignItems:'center', textAlign: 'left', width: '100%'}}>
                  <Box sx={{display: 'flex'}}>
                    <Avatar src={placeholder} sx={{ width: 48, height: 48 }}></Avatar>
                    <Box mx={2}>
                      <Typography fontWeight="bold" my="0px">{item.firstName} {item.lastName}</Typography>
                      <Typography sx={{fontSize: '12px'}}>{item.dateTime}</Typography>
                    </Box>
                  </Box>
                  <Typography className='!mr-2' style={{color : item.type === 'credit' ? 'green' : 'red'}} >{item.type === 'credit' ? '+' : '-'}{item.amount}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  <CustomTypography label="From" value={item?.from}/>
                  <CustomTypography label="To" value={item?.to}/>
                  <CustomTypography label="Status" value={item?.status}/>
                  <CustomTypography label="Order Id" value={Date.now()} />
                  <CustomTypography label="Transaction Id" value={Math.random().toString(36).slice(2)} />
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
    </Box>
  )
}
