export const IPAY_NET_API_KEY = "$2b$12$SFHDbA6O6K08p1GVzuCoTOuMlGnB5DFnfgUimBdvjIueIwRqEt9cC"
export const IPAY_NET_BASE_URL = 'https://api-service.petflix.io/api'
export const GET_TOKEN_PLAID = 'plaid/get-token-plaid'
export const GET_PROCESSOR_TOKEN = 'plaid/get-processor-token'
export const GET_DWOLLA_ID = 'dwolla/get-dwolla-by-uuid'
export const CREATE_FUNDING_SOURCE = 'dwolla/create-funding-source'
export const GET_ACCESS_TOKEN = 'plaid/get-access-token'
export const GET_LIST_ACCOUNT = 'plaid/get-list-accounts'
export const GET_ACCOUNTS = 'dwolla/get-accounts'
export const GET_PLAID_BALANCE = 'plaid/get-balance'
export const GET_DWOLLA_ACCESS_TOKEN = 'dwolla/get-access-token'

export const enrollHeader = {
  Accept: '*/*',
  'x-api-key' : '$2b$12$SFHDbA6O6K08p1GVzuCoTOuMlGnB5DFnfgUimBdvjIueIwRqEt9cC',
  'Content-Type': 'application/json'
}


export function currency(price, fraction = 2) {
  // const { publicRuntimeConfig } = getConfig();
  // currency: publicRuntimeConfig.currency,

  const formatCurrency = new Intl.NumberFormat(undefined, {
    currency: "USD",
    style: "currency",
    maximumFractionDigits: fraction,
    minimumFractionDigits: fraction,
  });

  return formatCurrency.format(price);
}
