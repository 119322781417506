import {Route, Routes} from 'react-router-dom'
import {Shelters} from './Shelters'
import {PetCodes} from './PetCodes'

export const Shelter = () => {
  return (
    <Routes>
      <Route path="/" element={<Shelters />} />
      <Route path="/pet-codes/:shelter" element={<PetCodes />} />
    </Routes>
  )
}
