import React, {useState} from 'react'
import Box from '@mui/material/Box'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Divider,
  IconButton,
  Tooltip
} from '@mui/material'
import {join} from 'lodash'
import {AddEditModal} from './users/AddEditModal'
import {Delete, Edit} from '@mui/icons-material'
import {DeleteModal} from './users/DeleteModal'
import moment from 'moment/moment'
import {StyledTable, StyledTableRow, StyledTableHeadCell, StyledTableCell} from 'components/StyledComponent'
import {NumberTextField as PhoneNumberField} from 'components/NumberTextField'
import {useNavigate} from 'react-router-dom'

const columns = [
  {id: 'name', label: 'Name'},
  {id: 'email', label: 'Email / ID'},
  {id: 'phone', label: 'Mobile'},
  {id: 'lastActive', label: 'Last Active'},
  {id: 'roles', label: 'Role'},
  // {id: 'pets', label: 'Pets'},
  // {id: 'engagement', label: 'Engagement'},
  // {id: 'posts', label: 'Posts'},
  // {id: 'status', label: 'Status'},
  // {id: 'verified', label: 'Verified'}
]

const Role = [
  {
    value: '',
    label: 'All'
  },
  {
    value: 'user',
    label: 'User'
  },
  {
    value: 'admin',
    label: 'Admin'
  },
  {
    value: 'vendor',
    label: 'Vendor'
  },
  {
    value: 'finder',
    label: 'Finder'
  }
]
// const petOwner = [
//   {
//     value: 'Pet Owner',
//     label: 'Rose'
//   },
//   {
//     value: 'Pet Owner2',
//     label: 'Johnson'
//   }
// ]
// const Status = [
//   {
//     value: 'Status',
//     label: 'Enable'
//   },
//   {
//     value: 'status2',
//     label: 'Disable'
//   }
// ]

const UserTable = props => {
  const {
    list,
    loading,
    Loader,
    Paginator,
    handleSearch,
    create,
    patch,
    get,
    remove,
    refreshTable,
    onRowClick
  } = props
  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [item, setItem] = useState(null)
  const navigate = useNavigate()
  const onEdit = (event, item) => {
    event.stopPropagation()

    setSelectedItem(item._id)
    setItem(item)
    setOpen(true)
  }

  const onDelete = (event, id) => {
    event.stopPropagation()

    setSelectedItem(id)
    setOpenDeleteModal(true)
  }

  const handleDeleteModal = (result = null) => {
    if (result) refreshTable()
    setSelectedItem(null)
    setOpenDeleteModal(false)
  }

  const handleEditModal = (result = null) => {
    if (result) refreshTable()

    setItem(null)
    setSelectedItem(null)
    setOpen(false)
  }

  return (
    <Box mt={3} bgcolor="transparent.main">
      <Paper>
        <Grid className="!p-4" container alignItems="center" spacing="10px">
          <Grid item xs={12} lg={3} md={4} gap="10px">
            <TextField
              className="!w-full"
              label="Name"
              type="text"
              size="small"
              placeholder="Search by name"
              InputLabelProps={{
                shrink: true
              }}
              onChange={e => {
                handleSearch('firstName', e.target.value)
                // handleSearch({
                //   firstName: e.target.value,
                //   lastName: e.target.value
                // })
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <TextField
              className="!w-full"
              label="Email"
              type="text"
              size="small"
              placeholder="Search by email"
              InputLabelProps={{
                shrink: true
              }}
              onChange={e => handleSearch('email', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <PhoneNumberField
              className="w-full"
              label="Phone"
              type="number"
              size="small"
              placeholder="Search by phone"
              InputLabelProps={{
                shrink: true
              }}
              onChange={e => handleSearch('phone', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <FormControl sx={{width: '100%'}} size="small">
              <InputLabel id="demo-select-small-label">Role</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Role"
                onChange={e => handleSearch('roles', e.target.value, false)}
              >
                {Role.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} lg={2} md={3}>
            <FormControl sx={{width: '100%'}} size="small">
              <InputLabel id="demo-select-small-label">Pet Owner</InputLabel>
              <Select labelId="demo-select-small-label" id="demo-select-small" label="Pet Owner">
                {petOwner.map(option => (
                  <MenuItem key={option.value} value={option.value} textAlign="left" width="auto">
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={2} md={3}>
            <FormControl sx={{width: '100%'}} size="small">
              <InputLabel id="demo-select-small-label">Status</InputLabel>
              <Select labelId="demo-select-small-label" id="demo-select-small" label="Status">
                {Status.map(option => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    style={{textAlign: 'left', width: 'auto'}}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
        </Grid>
        <Divider />
        <Paper sx={{width: '100%', overflow: 'hidden'}}>
          <TableContainer sx={{maxHeight: 600}}>
            <StyledTable stickyHeader aria-label="sticky table">
              <TableHead>
                <StyledTableRow>
                  {columns.map(column => (
                    <StyledTableHeadCell key={column.id}>{column.label}</StyledTableHeadCell>
                  ))}
                  <StyledTableHeadCell>{''}</StyledTableHeadCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {list.length ? (
                  list.map((row, index) => (
                    <StyledTableRow
                      key={index}
                      odd={index % 2 !== 0 ? 1 : 0}
                      onClick={() => navigate(`/user/${row._id}`)}
                    >
                      <StyledTableCell data-label="Name">
                        <Tooltip title={row?.username} placement="top">
                          <span>
                            {row.firstName || row.lastName ? `${row.firstName} ${row.lastName}` : '-'}
                          </span>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell data-label="Email">{row.email ? row.email : '-'}</StyledTableCell>
                      <StyledTableCell data-label="Phone">{row.phone ? row.phone : '-'}</StyledTableCell>
                      <StyledTableCell data-label="Last Active">
                        {row?.isOnline ? 'now' : moment(row.updatedAt).fromNow()}
                      </StyledTableCell>
                      <StyledTableCell data-label="Roles">
                        {row?.roles ? join(row?.roles, ',') : '-'}
                      </StyledTableCell>
                      {/* <StyledTableCell data-label="Pets">None</StyledTableCell>
                      <StyledTableCell data-label="Engagement">23 ^</StyledTableCell>
                      <StyledTableCell data-label="Posts">5 + ^</StyledTableCell> */}
                      {/* <StyledTableCell data-label="Status">None</StyledTableCell>
                      <StyledTableCell data-label="Verified">None</StyledTableCell> */}
                      <StyledTableCell size="small">
                        <IconButton onClick={e => onEdit(e, row)}>
                          {/* onClick={() => onEdit(row._id)} */}
                          <Edit fontSize="small" />
                        </IconButton>
                        <IconButton onClick={e => onDelete(e, row._id)}>
                          <Delete fontSize="small" />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : loading ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} align="center">
                      <Loader />
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} align="center">
                      <Typography>No Data</Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </StyledTable>
          </TableContainer>
          <Paginator />
        </Paper>
      </Paper>
      {open && (
        <AddEditModal
          open={open}
          id={selectedItem}
          create={create}
          patch={patch}
          get={get}
          item={item}
          handleModal={handleEditModal}
        />
      )}
      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          handleModal={handleDeleteModal}
          id={selectedItem}
          remove={remove}
        />
      )}
    </Box>
  )
}

export default UserTable
