import { Box, Button, FormControlLabel, Grid, IconButton, Modal, TextField, Typography, Switch } from "@mui/material"
import cross from 'images/crosss.svg';
import uploadicon from 'images/newimages/uploadicon.svg'
import { Link } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import { pink } from "@mui/material/colors";
import copy from 'images/newimages/copy.svg';
import finishflag from 'images/newimages/Flag_finish.svg';
import download from 'images/newimages/download.png';

const editAdd = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
 
  width: 1000,
  
  '@media (max-width:767px)': {
    width: '95%',
  },
};

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[600],
    '&:hover': {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[600],
  },
}));
const label = { inputProps: { 'aria-label': 'Color switch demo' } };

export const IframeModal = ({open, handleModal}) => {
  return (
    <Modal open={open} onClose={handleModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
      <Box  sx={ editAdd }>
        <Box py={4} px={5}>                    
          <Box textAlign="right" width="100%" >
              <Button onClick={handleModal} style={{ position: 'absolute', right: '-9px', top: '-3px'}}><img src={cross} /></Button>
          </Box>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
              <Grid item md={4} sm={12} xs={12}>
                <Typography variant="h6" component="h6" color="iframeGray.main" display="flex" sx={{ fontSize: "16px", fontWeight: "500" }}>
                    Post ID: <Typography component="span" display="flex" sx={{fontSize: "16px", fontWeight: "600", color: "transparent.contrastText",}}>
                        75798598743755 <img src={ copy } />
                  </Typography>
                </Typography>
                <Typography variant="h6" component="h6" color="iframeGray.main" sx={{ fontSize: "16px", fontWeight: "500" }}>
                  Posted: <Typography component="span" sx={{fontSize: "16px", fontWeight: "600", color: "transparent.contrastText",}}>
                    15/6/2023, 11:18 AM
                  </Typography>
                </Typography>
              </Grid>
                
              <Grid item md={4} sm={12} xs={12}>
                <Typography variant="h6" component="h6" color="iframeGray.main" display="flex" sx={{ fontSize: "16px", fontWeight: "500" }}>
                  User: <Typography component="span" display="flex" sx={{fontSize: "16px", fontWeight: "600", color: "secondary.main",}}>
                  abc_12345671 <img src={ copy } />
                  </Typography>
                </Typography>
                <Typography variant="h6" component="h6" color="iframeGray.main" sx={{ fontSize: "16px", fontWeight: "500" }}>
                  Last Active: <Typography component="span" sx={{fontSize: "16px", fontWeight: "600", color: "transparent.contrastText",}}>
                  15/7/2023, 11:18 AM
                  </Typography>
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Box display="flex" sx={{ justifyContent: {md: "flex-end", sm: "flex-start", xs: "flex-start"} }}>
                  <Typography variant="h6" component="h6"  display="flex" mr={1} color="iframeGray.main"  sx={{ fontSize: "16px", fontWeight: "500" }}>Last Action: <Typography component="span" sx={{fontSize: "16px", fontWeight: "600", color: "transparent.contrastText",}}>  Delete Post 
                  </Typography>
                  </Typography>
                  <img src={ finishflag } />
                </Box>
              </Grid>
          </Grid>
          <Box mt={1.5} sx={{border: '1px solid #DCDDE1'}}>
            <iframe width="100%" height="400" src="https://www.youtube.com/embed/yAoLSRbwxL8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </Box>
          <Box display="flex" alignItems="center" m={1.5}>
            <img src={download} />
            <Link href="#" underline="none"><Typography variant="h6" component="h6" color="iframeGray2.main" ml={0.5} sx={{fontSize:'12px'}}>download JSON</Typography>
            </Link>
          </Box>
          <Box bgcolor="navyBlue.main">
            <Box display="flex" justifyContent="space-between" alignItems="center" pl={2.5} pr={1} py={0.7} sx={{borderBottom: '1px solid #fff'}}>
              <Typography variant="h6" component="h6" color="transparent.main" sx={{fontSize:'14px', fontWeight: '300'}}>Moderation Results</Typography>
              <Box display="flex" alignItems="center">
                <Typography variant="h6" component="h6" color="transparent.main" mr={1} sx={{fontSize:'14px', fontWeight: '300'}}>Switch to Model Class Scores</Typography>
                <FormControlLabel control={<PinkSwitch {...label} defaultChecked />}/>
              </Box>
            </Box>
            
            <Grid container display="flex" justifyContent="space-between" alignItems="center" px={2.5} py={2}>
              <Grid itme md={6}> 
                <Box display="flex" alignItems="center"> 
                  <Typography variant="h6" component="h6" color="transparent.main" sx={{fontSize:'14px', fontWeight: '300'}}>Flagged Categories : </Typography>
                  <Box display="flex" alignItems="center" ml={1}>
                    <Box bgcolor="peach.main" mr={0.5} sx={{ height: '11px', width: '11px' }}></Box>
                    <Typography variant="h6" component="h6" color="transparent.main" sx={{fontSize:'14px', fontWeight: '300'}}>Suggestive (visual)</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid itme md={6}>
                <Typography variant="h6" component="h6" color="transparent.main" sx={{fontSize:'14px', fontWeight: '300'}}>Go To First Occurence : 
                <Typography variant="span" component="span">0:00:00</Typography>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Modal>   
  )
}