import {Navigate, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'

export const requireAuth = Component => {
  return props => {
    const user = useSelector(state => state.authentication.user)
    const location = useLocation()

    return user && user?.roles?.includes('admin') ? (
      <Component {...props} />
    ) : (
      <Navigate to="/authentication/login" state={{from: location}} replace />
    )
  }
}
