import {useEffect, useState} from 'react'
import {Routes, Route} from 'react-router-dom'
import {storeRestClient} from './client/rest.client'
import {storeSocketClient} from './client/socket.client'
import {AppLayout} from 'modules/layout/AppLayout'
import {Dashboard} from './Dashboard'
import {CircularProgress} from '@mui/material'
import {ProductDashboard} from './ProductDashboard'
import {BrandDashBoard} from './BrandDashboard'
import {OrderDashboard} from './OrderDashboard'
import {Categories} from './category'
import {BrandProducts} from './brands/BrandProducts'
import {ShopDashBoard} from './ShopDashboard'
import {VendorRequestsDashboard} from './VendorRequestsDashboard'
import {Settings} from './settings'
import { Banners } from './banners'
import { ReviewDashBoard } from './product-review'
import { Enroll } from './enroll'

export const Store = () => {
  const [authenticating, setAuthenticating] = useState(true)
  const [storeUser, setStoreUser] = useState({})
  const [title, setTitle] = useState('')

  useEffect(() => {
    storeRestClient
      .authenticate({
        strategy: 'store',
        accessToken: window.localStorage.getItem('auth')
      })
      .then(async authentication => {
        await storeSocketClient.authenticate({
          strategy: 'store',
          accessToken: window.localStorage.getItem('auth')
        })
        setStoreUser(authentication.user)
        setAuthenticating(false)
      })
      .catch(e => {
        console.error(`Could not authenticate with the store module`, e)
      })
  }, [])

  if (authenticating) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  return (
    <AppLayout heading={title}>
      <Routes>
        <Route path="/dashboard" element={<Dashboard setNavigationTitle={setTitle} />} />
        <Route path="/products" element={<ProductDashboard setNavigationTitle={setTitle} />} />
        <Route path="/brands" element={<BrandDashBoard setNavigationTitle={setTitle} />} />
        <Route path="/orders" element={<OrderDashboard setNavigationTitle={setTitle} />} />
        <Route path="/categories" element={<Categories setNavigationTitle={setTitle} />} />
        <Route path="/brand-products/:id" element={<BrandProducts setNavigationTitle={setTitle} />} />
        <Route path="/shops" element={<ShopDashBoard setNavigationTitle={setTitle} />} />
        <Route path="/vendor-requests" element={<VendorRequestsDashboard setNavigationTitle={setTitle} />} />
        <Route path="/banners" element={<Banners setNavigationTitle={setTitle} />} />
        <Route path="/settings" element={<Settings setNavigationTitle={setTitle} />} />
        <Route path="/review" element={<ReviewDashBoard setNavigationTitle={setTitle} />} />
        <Route path="/enroll/*" element={<Enroll setNavigationTitle={setTitle} />} />
      </Routes>
    </AppLayout>
  )
}
