import { Box, Button, Checkbox, FormControlLabel, Modal, TextField } from '@mui/material'
import { connectService } from 'components/service/connectService'
import { useEffect, useMemo, useState } from 'react'
import { ModalLayout } from 'components/layouts/ModalLayout'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { keys, pick } from 'lodash'
import { IconUploader } from 'components/IconUploader'

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required')
})

const initialValues = {
  name: '',
  active: true,
  description: ''
}

export const AddEditModal = connectService(
  props => {
    const {open, handleModal, id, data, setData, setOpen, loading, Loader, patch, create} = props
    const [icon, setIcon] = useState(null)

    const initialData = useMemo(() => {
      return pick(data, keys(initialValues))
    }, [data])

    const formik = useFormik({
      initialValues: id
        ? {...initialData, active: data.hasOwnProperty('active') ? data.active : true}
        : initialValues,
      enableReinitialize: true,
      validationSchema,
      onSubmit: values => {
        const payload = icon?._id ? {...values, icon: icon._id} : {...values}
        id ? patch(id, payload) : create(payload)

        formik.resetForm({values: initialValues})
      }
    })

    const onClose = () => {
      setData({})
      setOpen(false)
      setIcon(null)
    }

    useEffect(() => {
      console.log(id)
      if (!id) {
        setData({}) // create old persisted data
      }
    }, [id])

    useEffect(() => {
      if (data?.icon) {
        setIcon(data?.icon)
      }
    }, [data])

    return (
      <Modal
        open={open}
        onClose={() => {
          formik.resetForm()
          handleModal()
          setIcon(null)
        }}
      >
        <ModalLayout
          title={`${id ? 'Edit' : 'Add'} Pet Food`}
          onClose={onClose}
          LoaderComponent={Loader}
          loading={loading}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col mt-4">
              <TextField
                id="standard-basic"
                label="Name"
                variant="outlined"
                {...formik.getFieldProps('name')}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
              />
              
              <TextField
                id="standard-basic"
                label="Description"
                variant="outlined"
                {...formik.getFieldProps('description')}
                sx={{mt: 2}}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                rows={3}
                multiline
                fullWidth
              />
             
            </div>
            <div className="my-4">
              <div className="flex justify-between items-center">
                <IconUploader title="Upload Image" icon={icon} setIcon={setIcon} />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.active}
                      onChange={e => formik.setFieldValue('active', e.target.checked)}
                    />
                  }
                  label="Active"
                />
              </div>
            </div>
            <Box>
              <Button variant="contained" color="primary" fullWidth size="large" type="submit">
                {id ? 'Edit' : 'Submit'}
              </Button>
            </Box>
          </form>
        </ModalLayout>
      </Modal>
    )
  },
  'food-brands',
  {query: {$populate: ['icon']}}
)
