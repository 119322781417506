import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {TextField, Button, FormControlLabel, Checkbox, Typography, FormControl, InputLabel, Select, MenuItem, Divider} from '@mui/material'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import {useNavigate} from 'react-router-dom'
import {AutoCompleteSearch} from './AutoCompleteSearch'
import {ConnectedComponent} from 'components/service/ConnectedComponent'
import {AutoCompleteChipInput} from './AutocompleteChipInput'
import { ImagePreview } from 'components/ImagePreview'
import PetDietFieldsCard from './PetDietFieldsCard'
import { map, pick } from 'lodash'

const validationSchema = Yup.object({
  name: Yup.string().required('Please enter pet name'),
  birthdate: Yup.date().required('Please select Birth Date'),
  age: Yup.number().required('Please enter age'),
  size: Yup.number().required('Please enter pet weight'),
  privacy: Yup.boolean()
})

export const PetEditForm = props => {
  const {data, setData, patch} = props

  const navigate = useNavigate()
  const [activities, setActivities] = useState([])
  const [wellness, setWellness] = useState([])
  const [diet, setDiet] = useState({
    food: [],
    favoriteTreats: [],
    favoriteFlavours: [],
    sensitivities: [],
    questionnaires: {
      needGrainFreeFood: false,
      needWeightControlFood: false
    }
  })
  
  const initialValues = {
    name: '',
    birthdate: null,
    age: '',
    size: '',
    type: '',
    subtype: '',
    obituary: '',
    status: '',
    description: '',
    privacy: false
  }

  const createPayload = (values) => {
    const activitiesId = activities.map(i => i._id)
    const wellnessId = wellness.map(i => i._id)
    const favoriteTreats = diet?.favoriteTreats?.map(i => i._id)
    const favoriteFlavours = diet?.favoriteFlavours?.map(i => i._id)
    const sensitivities = diet?.sensitivities?.map(i => i._id)

    const payload = {...values, activities: activitiesId, wellness: wellnessId}    
    const food = diet?.food.map(item => 
      pick(item, ['type', 'note', 'brand', 'food', 'servingSize', 'servingQuantity', 'mealTime'])
    )

    payload['diet'] = {
      food,
      favoriteTreats,
      favoriteFlavours,
      sensitivities,
      questionnaires: diet?.questionnaires
    }

    return payload

  }
  const onSubmit = async values => {
    let hasError = false
    const {_id} = data
    
    const _food = map(diet?.food, (item) => {
      if (!item?.type || !item?.brand || !item?.food) hasError = true
        
      return {
        ...item,
        typeError: item?.type ? false : true,
        brandError: !!!item?.brand,
        foodError: !!!item?.food
      }
    })

    if (hasError) {
      setDiet({...diet, food: [..._food]})
      return
    }

    const payload = createPayload(values)

    const result = await patch(_id, {...payload})

    if (result) {
      navigate(-1)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit
  })

  useEffect(() => {
    console.log(data)
    if (data) {
      formik.setFieldValue('name', data?.name)
      formik.setFieldValue('birthdate', data?.birthdate)
      formik.setFieldValue('size', data?.size)
      formik.setFieldValue('age', data?.age)
      formik.setFieldValue('type', data?.type)
      formik.setFieldValue('subtype', data?.subtype)
      formik.setFieldValue('obituary', data?.obituary)
      formik.setFieldValue('status', data?.status)
      formik.setFieldValue('description', data?.description)
      formik.setFieldValue('privacy', data?.privacy)

      if (data?.activities) setActivities(data?.activities)

      if (data?.wellness) setWellness(data?.wellness)

      if (data?.diet) setDiet(data?.diet)
      
    }
  }, [data])

  return (
    <div className="p-8">
      <form onSubmit={formik.handleSubmit}>
        { data?.profile?.path &&
          <ImagePreview media={data?.profile} className="w-[20ch] h-[20ch] object-cover rounded-xl mb-4 mx-2" />
        }
        <div className="w-full lg:w-3/5">
          <div className="flex w-full overflow-auto pt-4">
            <TextField
              className="w-full "
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </div>

          <div className="flex flex-col md:flex-row w-full my-8 justify-between">
            <LocalizationProvider dateAdapter={AdapterMoment} className="!w-full">
              <DemoContainer components={['DatePicker']} className="!w-full">
                <DatePicker
                  className="!w-full"
                  label="Birth Date"
                  value={moment(formik.values.birthdate)}
                  onChange={date => formik.setFieldValue('birthdate', date)}
                  renderInput={params => <TextField {...params} variant="outlined" className="!w-full" />}
                />
              </DemoContainer>
            </LocalizationProvider>
            <TextField
              className="w-full md:w-1/3 !my-8 md:!mt-2 md:!mb-0 md:!mx-1"
              id="age"
              name="age"
              label="Age"
              variant="outlined"
              type="number"
              value={formik.values.age}
              onChange={formik.handleChange}
              error={formik.touched.age && Boolean(formik.errors.age)}
              helperText={formik.touched.age && formik.errors.age}
            />

            <TextField
              className="w-full md:w-1/3 md:!mt-2 md:!mb-0"
              id="size"
              name="size"
              label="Size"
              type="number"
              variant="outlined"
              value={formik.values.size}
              onChange={formik.handleChange}
              error={formik.touched.size && Boolean(formik.errors.size)}
              helperText={formik.touched.size && formik.errors.size}
            />
          </div>

          <div className="flex flex-col md:flex-row w-full md:my-8 justify-between">
            <ConnectedComponent
              Component={AutoCompleteSearch}
              id={formik?.values?.type}
              label="Pet Types"
              service="pet-types"
              className="w-full md:w-1/2 md:mr-4"
              onChange={value => formik.setFieldValue('type', value?._id)}
            />

            <ConnectedComponent
              label="Pet Sub Types"
              Component={AutoCompleteSearch}
              service="pet-subtypes"
              id={formik?.values?.subtype}
              className="w-full md:w-1/2 !my-8 md:!my-0 md:ml-4"
              onChange={value => formik.setFieldValue('subtype', value?._id)}
            />
          </div>

          <div className="flex flex-col md:flex-row w-full md:my-8 ">
            <TextField
              className="!w-full md:!w-1/2 !mr-4"
              id="obituary"
              name="obituary"
              label="Obituary"
              variant="outlined"
              value={formik.values.obituary}
              onChange={formik.handleChange}
            />

            <TextField
              className="!w-full md:!w-1/2 !my-8 md:!my-0 md:!ml-4"
              id="status"
              name="status"
              label="Status"
              variant="outlined"
              value={formik.values.status}
              onChange={formik.handleChange}
            />
          </div>

          <div className="w-full my-8">
            <TextField
              className="w-full "
              id="description"
              name="description"
              label="Description"
              variant="outlined"
              multiline
              rows={4}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </div>

          <div className="my-8">
            <ConnectedComponent
              Component={AutoCompleteChipInput}
              service="activities"
              label="Search Activities"
              heading="Activities"
              items={activities}
              onChange={values => setActivities(values)}
            />
          </div>

          <div className="my-8">
            <ConnectedComponent
              Component={AutoCompleteChipInput}
              service="pet-wellness-types"
              label="Search Wellness"
              heading="Wellness"
              items={wellness}
              onChange={values => setWellness(values)}
            />
          </div>

          <Typography variant="h5" textAlign="left">Manage Diet</Typography> 
          <Divider />
          <div className='text-start flex flex-col'>
            <PetDietFieldsCard 
              dietFood={diet?.food} 
              onChange={(food) => setDiet({...diet, food})} 
            />
          </div>

          <div className="mb-4">
            <ConnectedComponent
              Component={AutoCompleteChipInput}
              service="food-types"
              label="Search Favorite Treats"
              heading="Favorite Treats"
              items={diet?.favoriteTreats}
              titleStyle={{fontSize: '18px'}}
              onChange={favoriteTreats => setDiet({...diet, favoriteTreats})}
            />
          </div>

          <div className="mb-4">
            <ConnectedComponent
              Component={AutoCompleteChipInput}
              service="flavour-types"
              label="Search Favorite Flavours"
              heading="Favorite Flavours"
              titleStyle={{fontSize: '18px'}}
              items={diet?.favoriteFlavours}
              onChange={favoriteFlavours => setDiet({...diet, favoriteFlavours})}
            />
          </div>

          <div className="mb-4">
            <ConnectedComponent
              Component={AutoCompleteChipInput}
              service="sensitivities"
              label="Search sensitivities"
              heading="Sensitivities"
              titleStyle={{fontSize: '18px'}}
              items={diet?.sensitivities}
              onChange={sensitivities => setDiet({...diet, sensitivities})}
            />
          </div>

          <Typography variant="h5" textAlign="left" style={{fontSize: '18px'}}>Questionnaires</Typography> 
          <div className="mx-2 flex flex-col md:flex-row justify-between mb-8">
            <FormControlLabel
              control={
                <Checkbox
                  id="privacy"
                  name="needGrainFreeFood"
                  color="primary"
                  checked={diet?.questionnaires?.needGrainFreeFood}
                  onChange={(e) => setDiet({
                    ...diet, 
                    questionnaires: {
                      ...diet?.questionnaires,    
                      needGrainFreeFood: e.target.checked
                    }
                  })}
                />
              }
              label="Need Grain Free Food"
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="privacy"
                  name="needWeightControlFood"
                  color="primary"
                  checked={diet?.questionnaires?.needWeightControlFood}
                  onChange={(e) => setDiet({
                    ...diet, 
                    questionnaires: {
                      ...diet?.questionnaires,    
                      needWeightControlFood: e.target.checked
                    }
                  })}
                />
              }
              label="Need Weight Control Food"
            />
          </div>

          <div className="mx-2 flex justify-between my-8">
            <FormControlLabel
              control={
                <Checkbox
                  id="privacy"
                  name="privacy"
                  color="primary"
                  checked={formik.values.privacy}
                  onChange={formik.handleChange}
                />
              }
              label="Privacy"
            />
          </div>
          <div className="flex justify-start">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{width: '10rem'}}
              className="mt-4"
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}
