import {PlaceHolderImage} from 'components/PlaceHolderImage'
import React, {useRef} from 'react'
import {PreviewImageDialog} from './PreviewImageDialog'

export const PetProfileImage = props => {
  const dialogRef = useRef(null)

  const showPreview = () => {
    dialogRef.current.openDialog()
  }
  return (
    <div className="flex w-40 lg:w-44 h-40 lg:h-44 ">
      {props?.src ? (
        <img
          src={props?.src}
          alt="Pet Profile"
          className="w-40 h-40 rounded-xl object-cover"
          onClick={showPreview}
        />
      ) : (
        <PlaceHolderImage />
      )}

      {props?.src && <PreviewImageDialog src={props?.src} ref={dialogRef} />}
    </div>
  )
}
