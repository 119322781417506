import { createContext } from 'react'

export const SnackbarContext = createContext({
  config: {
    open: false,
    verticalPosition: 'bottom',
    horizontalPosition: 'right'
  },
  message: '',
  severity: 'info',
  setConfig: () => {},
  setSeverity: () => {},
  showToast: () => {}
})
