import {useContext, useEffect, useState} from 'react'
import {socketClient as feathers} from 'client/socket.client'
import {CircularProgress, TablePagination} from '@mui/material'
import {Navigate, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {isEmpty, pickBy} from 'lodash'

const configPrototype = {
  query: {}
}
export const Loader = () => {
  return <CircularProgress />
}

export const connectService = (Component, service, config = configPrototype) => {
  return props => {
    const user = useSelector(state => state.authentication.user)
    const location = useLocation()
    const {setSeverity, showToast} = useContext(SnackbarContext)

    const {id, handleModal} = props
    const [page, setPage] = useState(1)
    const [skip, setSkip] = useState(0)
    const [total, setTotal] = useState(0)

    const [data, setData] = useState({})
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const rowsPerPageOptions = [5, 10, 15, 20, 25, 50, 100]
    const [limit, setLimit] = useState(rowsPerPageOptions[1])

    const {searchProperty = 'name'} = config
    const [search, setSearch] = useState(null)
    const [additionalFilters, setAdditionalFilters] = useState({})

    const find = async query => {
      try {
        setLoading(true)

        const newQuery = {
          ...query,
          ...config.query,
          ...additionalFilters
        }
        console.log('query', newQuery)
        const result = await feathers.service(service).find({
          query: {...newQuery}
        })
        console.log('result::', result)
        setList(result.data)
        setTotal(result.total)
        setLoading(false)
      } catch (error) {
        handleToast(error, 'error')
        setLoading(false)
      }
    }

    const handleToast = (e, status) => {
      setSeverity(status)
      showToast(e?.message ? e.message : 'Something Went wrong')
    }

    const get = async id => {
      try {
        setLoading(true)
        const result = config.query
          ? await feathers.service(service).get(id, {query: {...config.query}})
          : await feathers.service(service).get(id)
        setData(result)
        setLoading(false)
      } catch (error) {
        handleToast(error, 'error')
        setLoading(false)
      }
    }

    const create = async (data, validator) => {
      try {
        const result = await feathers.service(service).create(data)
        if (result && handleModal) {
          setData({})
          handleToast({message: 'Data created successfully'}, 'success')
          handleModal(result)
        }
      } catch (error) {
        handleToast(error, 'error')
        setLoading(false)
      }
    }

    const patch = async (id, data, validator) => {
      try {
        const result = await feathers.service(service).patch(id, data)
        if (result && handleModal) {
          setData({})
          handleToast({message: 'Data updated successfully'}, 'success')
          handleModal(result)
        }
      } catch (error) {
        handleToast(error)
        setLoading(false, 'error')
      }
    }

    const remove = async id => {
      try {
        const result = await feathers.service(service).remove(id)
        if (result && handleModal) {
          setData({})
          handleToast({message: 'Data removed successfully'}, 'success')
          handleModal(result)
        }
      } catch (error) {
        handleToast(error, 'error')
        setLoading(false)
      }
    }

    useEffect(() => {
      if (id) {
        get(id)
      } else {
        const query = {
          $limit: limit,
          $skip: skip
        }

        if (search && searchProperty) {
          query[searchProperty] = {}
          query[searchProperty]['$regex'] = search
          query[searchProperty]['$options'] = 'i'
        }

        if (!loading) {
          find(query)
        }
      }
    }, [id, limit, skip])

    useEffect(() => {
      if (search) {
        const query = {}
        query[searchProperty] = {}
        query[searchProperty]['$regex'] = search
        query[searchProperty]['$options'] = 'i'
        find(query)
      } else {
        const query = {
          $limit: limit,
          $skip: 0
        }
        find(query)
      }
    }, [search, additionalFilters])

    useEffect(() => {
      page === 0 ? setSkip(0) : setSkip((page - 1) * limit)
    }, [limit, page])

    const onSave = () => {
      if (id) {
        const {_id, __v, ...payload} = data
        patch(id, payload)
      } else {
        create(data)
      }
    }

    const refreshTable = () => {
      const query = {
        $limit: limit,
        $skip: skip
      }
      console.log('onrefresh')
      find(query)
    }

    const removeBlankAttribute = obj => {
      return pickBy(obj, value => !isEmpty(value))
    }

    const Paginator = additionalProps => {
      /*
       * Note: page minus and plus by one added to support TablePagination zero-indexing
       */
      return (
        <TablePagination
          component="div"
          count={total}
          page={page - 1}
          onPageChange={(event, newPage) => setPage(newPage + 1)}
          rowsPerPage={limit}
          rowsPerPageOptions={rowsPerPageOptions}
          onRowsPerPageChange={event => {
            setLimit(parseInt(event.target.value, 10))
            setPage(1)
          }}
          {...additionalProps}
        />
      )
    }

    return user && user?.roles?.includes('admin') ? (
      <Component
        data={data}
        setData={setData}
        list={list}
        setList={setList}
        loading={loading}
        Loader={Loader}
        Paginator={Paginator}
        find={find}
        get={get}
        create={create}
        patch={patch}
        remove={remove}
        onSave={onSave}
        refreshTable={refreshTable}
        search={search}
        setSearch={setSearch}
        removeBlankAttribute={removeBlankAttribute}
        additionalFilters={additionalFilters}
        setAdditionalFilters={setAdditionalFilters}
        {...props}
      />
    ) : (
      <Navigate to="/authentication/login" state={{from: location}} replace />
    )
  }
}
