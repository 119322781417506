import {Avatar, Box, Grid, Stack, Typography} from '@mui/material'
import avatar2 from 'images/avtarimg.png'
import VerifiedIcon from '@mui/icons-material/Verified'
import moment from 'moment'
import {ImagePreview} from 'components/ImagePreview'
import {PlaceHolderImage} from 'components/PlaceHolderImage'
export const UserHeaderInfo = props => {
  return (
    <Grid
      container
      columnGap={2}
      p={3}
      pt={5}
      direction="row"
      justifyContent="space-between"
      sx={{boxShadow: 2}}
    >
      <Grid item md={6} sm={12} xs={12}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item md={3}>
            {props?.data?.profile ? (
              <ImagePreview media={props?.data?.profile} className="w-20 h-20 object-cover rounded-xl" />
            ) : (
              <PlaceHolderImage containerWidth="5rem" containerHeight="5rem" iconSize="2.5rem" />
            )}
          </Grid>
          <Grid item md={9}>
            {(props?.data?.firstName || props?.data?.lastName) && (
              <Box sx={{fontSize: 16, fontWeight: 600}}>
                {`${props?.data?.firstName} ${props?.data?.lastName}`}{' '}
                <VerifiedIcon style={{color: '#2F96D5', fontSize: 18}} />
              </Box>
            )}
            {props?.data?.createdAt && (
              <Box>
                <Box sx={{fontSize: 16, fontWeight: 500, color: 'primary.main'}}>Pet Owner</Box>
                <Typography variant="h6" component="h6" sx={{fontSize: '16px', fontWeight: '600'}}>
                  Member Since: {moment(props?.data?.createdAt).format('MMM Do YY')}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={3} sm={12} xs={12} sx={{textAlign: {xs: 'left', sm: 'left'}, pt: {xs: 2, sm: 2, md: 0}}}>
        <Typography variant="h6" component="h6" sx={{fontSize: '16px', fontWeight: '600'}}>
          BALANCE:{' '}
          <Typography component="span" sx={{fontSize: '16px', fontWeight: '600', color: 'primary.main'}}>
            $100.85
          </Typography>
        </Typography>
        <Typography variant="h6" component="h6" sx={{fontSize: '16px', fontWeight: '600'}}>
          DONATIONS:{' '}
          <Typography component="span" sx={{fontSize: '16px', fontWeight: '600', color: 'primary.main'}}>
            $53.23
          </Typography>
        </Typography>
        <Typography variant="h6" component="h6" sx={{fontSize: '16px', fontWeight: '600'}}>
          ORDERS:{' '}
          <Typography component="span" sx={{fontSize: '16px', fontWeight: '600', color: 'primary.main'}}>
            $25.23
          </Typography>
        </Typography>
      </Grid>
      <Grid
        item
        md={3}
        sm={12}
        xs={12}
        sx={{
          textAlign: {xs: 'left', sm: 'left', md: 'center'},
          pr: {xs: 0, sm: 0, md: 5},
          pt: {xs: 1, sm: 1, md: 0}
        }}
      ></Grid>
    </Grid>
  )
}
