import {Close} from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  TextField,
  Typography
} from '@mui/material'
import { NumberTextField } from 'components/NumberTextField'
import {StoreMediaUploader} from 'components/StoreMediaUploader'
import {useFormik} from 'formik'
import {keys, pick} from 'lodash'
import {storeSocketClient} from 'modules/store/client/socket.client'
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import * as yup from 'yup'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,

  width: 600,

  '@media (max-width:767px)': {
    width: '95%'
  }
}
const validationSchema = yup.object({
  order: yup.string().required('Order is required'),
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required')
})

const initialValues = {
  order: '',
  title: '',
  description: '',
  isActive: true,
}

export const AddEditModal = forwardRef((props, ref) => {
  const {onAddEdit} = props
  const [open, setOpen] = useState(false)
  const [media, setMedia] = useState(null)
  const [id, setId] = useState(null)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setData(null)
    setId(null)
    setOpen(false)
    setMedia(null)
    formik.resetForm({values: initialValues})
  }

  const service = storeSocketClient.service('banner')

  useImperativeHandle(
    ref,
    () => ({
      show(id = null) {
        setId(id)
        handleOpen()
      }
    }),
    []
  )

  useEffect(() => {
    if (id) {
      setLoading(true)
      service
        .get(id, {query: {$populate: ['banner']}})
        .then(result => {
          setData(result)
          formik.resetForm({values: {...pick(result, keys(initialValues))}})
          setMedia(result.banner)
          setLoading(false)
        })
        .catch(error => {
          console.log(error)
          setLoading(false)
        })
    }
  }, [id])

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      if (media?._id) {
        values['banner'] = media?._id
      }

      id
        ? service
            .patch(id, values)
            .then(result => {
              handleClose()
              onAddEdit()
            })
            .catch(console.error)
        : service
            .create(values)
            .then(result => {
              handleClose()
              onAddEdit()
            })
            .catch(console.error)
    }
  })

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box sx={{p: {lg: 5, md: 5, sm: 4, xs: 4}}}>
            <Box display="flex" alignItems="center" justifyContent="space-between" pb={2.5}>
              <Typography
                variant="h5"
                component="h5"
                color="transparent.contrastText"
                sx={{fontSize: '24px', fontWeight: '600'}}
                style={{width: '100%'}}
              >
                {id ? 'Edit Banner Detail' : 'Create Banner'}
              </Typography>
              <Box textAlign="right" width="100%">
                <IconButton onClick={handleClose}>
                  <Close onClick={handleClose} />
                </IconButton>
              </Box>
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                id="title"
                label="Title"
                variant="outlined"
                {...formik.getFieldProps('title')}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                fullWidth
              />

              <NumberTextField
                id="standard-basic"
                label="Order"
                variant="outlined"
                type='number'
                {...formik.getFieldProps('order')}
                error={formik.touched.order && Boolean(formik.errors.order)}
                helperText={formik.touched.order && formik.errors.order}
                className='!my-2'
                fullWidth
              />

              <TextField
                id="description"
                label="Description"
                variant="outlined"
                {...formik.getFieldProps('description')}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                rows={3}
                multiline
                fullWidth
              />

              <div className="flex justify-between">
                <StoreMediaUploader title="Upload Image" media={media} setMedia={setMedia} />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.isActive || false}
                      onChange={e => formik.setFieldValue('isActive', e.target.checked)}
                    />
                  }
                  label="Active"
                />
              </div>

              <Box mt={4}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  disabled={loading}
                >
                  {id ? 'Edit' : 'Submit'}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  )
})
