import {DataTable} from '../../components/DataTable'
import {useState} from 'react'
import {DetailView} from './DetailView'
import {ConnectedComponent} from 'components/service/ConnectedComponent'

export const CommentList = props => {
  const [open, setOpen] = useState(false)
  const [comment, setComment] = useState(false)

  const handleRowClick = value => {
    setComment(value)
    setOpen(!open)
  }
  return (
    <div>
      <ConnectedComponent
        Component={DataTable}
        service="reports"
        handleRowClick={handleRowClick}
        config={{query: {$populate: 'user', contentType: 'comments'}}}
      />

      {open && (
        <ConnectedComponent
          Component={DetailView}
          service="reports"
          id={comment?._id}
          config={{
            query: {
              $populate: [
                {
                  path: 'content',
                  populate: {
                    path: 'content',
                    model: 'posts',
                    populate: [
                      {
                        path: 'pet',
                        populate: ['profile']
                      },
                      {
                        path: 'user',
                        populate: ['profile']
                      },
                      {path: 'media'}
                    ]
                  }
                },
                {path: 'user'}
              ]
            }
          }}
          open={open}
          onClose={() => setOpen(false)}
        />
      )}
    </div>
  )
}
