import { Box, Button, Typography } from '@mui/material'
import { AppLayout } from 'modules/layout/AppLayout'
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import edit from 'images/newimages/edit-rectangle.svg';
import deleteicon from 'images/newimages/trash.svg'
import { Link } from 'react-router-dom';
import { CreateEditModal } from './modals/CreateEditModal';

const mealDetaildata = [
  {
    id: 1,
    time: 'Morning (Before 12:00 PM)',
    edit:  edit,
    delete: deleteicon,
  },
  {
    id: 2,
    time: 'Afternoon (12:00 PM to 5:00 PM)',
    edit:  edit,
    delete: deleteicon,
  },
  {
    id: 3,
    time: 'Evening (After 5:00 PM)',
    edit:  edit,
    delete: deleteicon,
  },
  {
    id: 4,
    time: 'Other/Free ProfileFeed',
    edit:  edit,
    delete: deleteicon,
  },
  {
    id: 5,
    time: 'Morning (Before 12:00 PM)',
    edit:  edit,
    delete: deleteicon,
  },
  {
    id: 6,
    time: 'Afternoon (12:00 PM to 5:00 PM)',
    edit:  edit,
    delete: deleteicon,
  },
]

export const Meal = () => {
  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const handleModal = () => {
    setOpen(!open)
  }

  const handleEditModal = (item) => {
    setSelectedItem(item)
    setOpen(!open)
  }

  useEffect(() => {
    if (!open) {
      setSelectedItem(null)
    }
  }, [open])
  
  const mealDetailcolumns = [
    {
      name: <Typography variable="h6"  sx={{ fontSize: '15px', fontWeight: '600',}}>Meal Time</Typography>,
      selector: row => <Typography variable="h6" color="phylumTable.main" sx={{fontSize: '12px'}}>{row.time}</Typography>,
    },

    {
      name: <Typography variable="h6"  sx={{ fontSize: '15px', fontWeight: '600',}}>Edit</Typography>,
      selector: row =><button onClick={() => handleEditModal(row)}><img src={row.edit} alt="Image 1" style={{ width: '17px', marginRight: '10px' }} /></button>,
      width: '60px',
    },
    {
      name: <Typography variable="h6"  sx={{ fontSize: '14px', fontWeight: '600',}}>Delete</Typography>,
      selector: row =><Link to="/" sx={{cursor:'pointer'}}><img src={row.delete} alt="Image 2" style={{ width: '15px' }} /></Link>,
      width: '90px',
    },
  ];

  return (
    <AppLayout heading='Meal Details'>
      <Box py={2.5}>
        <Box mb={3} textAlign="right">
          <Button variant="contained" color='primary' size="large" onClick={ handleModal }>Add Meal Detail</Button>
        </Box>
        <Box sx={{boxShadow:'0px 4px 15px 0px rgba(0, 0, 0, 0.10)', borderRadius:'10px', overflow: 'auto'}}>  
          <DataTable columns={mealDetailcolumns} data={mealDetaildata} striped />
        </Box>
      </Box>

      <CreateEditModal item={selectedItem} open={open} handleModal={handleModal}></CreateEditModal>
    </AppLayout>
  )
}