import Button from "@mui/material/Button";
import { alpha } from "@mui/material/styles";
import React from "react";
import { keyframes } from "@emotion/react";
import { styled } from "@mui/system";

// Define the keyframes for the shimmer effect
const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

// Create a styled component for the shimmering button
const ShimmerButton = styled(Button)({
  backgroundImage: `linear-gradient(90deg, transparent, #f0f0f0, transparent)`,
  backgroundSize: "200% 100%",
  animation: `${shimmer} 8s linear infinite`,
});

// ShimmeringButton component with MUI Button inside
const ShimmeringButton = ({ children, ...props }) => {
  return <ShimmerButton {...props}>{children}</ShimmerButton>;
};

export default ShimmeringButton
