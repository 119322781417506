import {Routes, Route} from 'react-router-dom'
import {Login} from './Login'
import {VerifyEmail} from './VerifyEmail'

export const Auth = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/:email/verify-email" element={<VerifyEmail />} />
    </Routes>
  )
}
