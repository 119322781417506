import {ConnectedComponent} from 'components/service/ConnectedComponent'
import {useEffect} from 'react'
import {Browse} from './Browse'
import {storeSocketClient} from '../client/socket.client'

export const Categories = props => {
  const {setNavigationTitle} = props
  useEffect(() => {
    setNavigationTitle('Category')
  }, [])

  return (
    <ConnectedComponent
      Component={Browse}
      service="categories"
      client={storeSocketClient}
      config={{
        query: {
          $populate: ['vendor', 'icon']
        }
      }}
    />
  )
}
