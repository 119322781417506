import CakeIcon from '@mui/icons-material/Cake'
import moment from 'moment'

export const BirthDate = props => {
  return (
    <div className="flex items-center mr-4">
      <CakeIcon style={{color: '#ED1690', fontSize: '15px', marginRight: '8px'}} />
      <span className="text-sm">{props?.data ? moment.utc(props.data).format('DD MMM YYYY') : ''} </span>
    </div>
  )
}
