import React, {useContext, useState} from 'react'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {Button, Modal, Box, FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material'
import {startCase} from 'lodash'
import {socketClient} from 'client/socket.client'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'

const Roles = [
  {
    label: 'User',
    role: 'user'
  },
  {
    label: 'Admin',
    role: 'admin'
  },
  {
    label: 'Vendor',
    role: 'vendor'
  },
  {
    label: 'Finder',
    role: 'finder'
  },
  {
    label: 'Staff',
    role: 'staff'
  }
]

const validationSchema = yup.object({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  roles: yup.array().min(1, 'Select at least one role').required('Roles are required')
})

const initialValues = {
  firstName: '',
  lastName: '',
  contactType: 'email', // Initial value for isPhone
  contactValue: '',
  roles: []
}

const InviteModal = props => {
  const {handleSuccess} = props
  const {setSeverity, showToast} = useContext(SnackbarContext)

  const [open, setOpen] = useState(false)
  const [contactError, setContactError] = useState('')
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (isError(values)) {
        return
      }

      const {contactType, contactValue, roles, firstName, lastName} = values

      const enrollData = await socketClient.service('invites').find(
        {
          query: {
            $or: [{ email:  contactValue}, { phone: contactValue}]
          }
        }
      )

      if (enrollData?.data?.length) {
        setSeverity('error')
        showToast(`Invitation is already sent on this ${contactType}`)
      }

      const payload = {
        firstName,
        lastName,
        roles: roles.includes('user') ? [...roles] : [...roles, 'user']
      }
      payload[contactType] = contactValue

      console.log(payload)
      setLoading(true)
      socketClient
        .service('invites')
        .create(payload)
        .then(result => {
          console.log(result)

          setLoading(false)
          setSeverity('success')
          showToast('Invitation sent successfully')
          handleSuccess()
          handleClose()
        })
        .catch(e => {
          console.log(e.message)
          setLoading(false)
          setSeverity('error')
          showToast('Failed to send invitation')
          handleSuccess()
          handleClose()
        })
    }
  })

  const isError = values => {
    if (!values.contactValue) {
      setContactError(`Please enter ${values.contactType}`)
      return true
    }

    if (values.contactType === 'phone') {
      const pattern = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/
      const valid = pattern.test(values.contactValue)
      if (!valid) {
        setContactError(`Invalid ${values.contactType}`)
        return true
      }
    }

    if (values.contactType === 'email') {
      const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      const valid = pattern.test(values.contactValue)
      if (!valid) {
        setContactError(`Invalid ${values.contactType}`)
        return true
      }
    }
    return false
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    formik.resetForm({values: initialValues})
    setOpen(false)
  }

  return (
    <div className="w-full flex justify-end">
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Invite
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            padding: 4,
            width: 600,

            '@media (max-width:767px)': {
              width: '95%'
            }
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className="flex w-full">
              <TextField
                className="!w-1/2 !mr-1"
                type="text"
                label="First Name"
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName || ''}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />

              <TextField
                className="!w-1/2 !ml-1"
                type="text"
                label="Last Name"
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName || ''}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </div>
            <div className="flex w-full mt-4">
              <div className="!w-1/2 !mr-1">
                <FormControl fullWidth>
                  <InputLabel>Contact Type</InputLabel>
                  <Select
                    label="Contact Type"
                    name="contactType"
                    value={formik.values.contactType}
                    onChange={e => {
                      setContactError('')
                      formik.setFieldValue('contactType', e.target.value)
                    }}
                  >
                    <MenuItem value="email">Email</MenuItem>
                    <MenuItem value="phone">Phone</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <TextField
                className="!w-1/2 !ml-1"
                type={formik.values.contactType === 'phone' ? 'number' : 'text'}
                name="contactValue"
                label={startCase(formik.values.contactType)}
                onChange={e => {
                  setContactError('')
                  formik.setFieldValue('contactValue', e.target.value)
                }}
                onBlur={formik.handleBlur}
                value={formik.values.contactValue}
                error={Boolean(contactError)}
                helperText={contactError}
              />
            </div>

            <FormControl
              fullWidth
              margin="normal"
              error={formik.touched.phone && Boolean(formik.errors.phone)}
            >
              <InputLabel>Roles</InputLabel>
              <Select
                name="roles"
                multiple
                label="Roles"
                value={formik.values.roles}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputProps={{'aria-label': 'roles'}}
              >
                {Roles.map(item => (
                  <MenuItem key={item.role} value={item.role}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.roles && formik.errors.roles && (
                <div style={{color: '#d32f2f', fontSize: '0.75rem', textAlign: 'left'}}>
                  {formik.errors.roles}
                </div>
              )}
            </FormControl>

            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading ? 'sending' : 'send'}
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  )
}

export default InviteModal
