import { Box, Button, Grid, MenuItem, Tab, Tabs, TextField, Typography } from "@mui/material"
import { AppLayout } from "modules/layout/AppLayout"
import { useEffect, useState } from "react";
import tableicon from 'images/newimages/tableicon.svg';
import tableedit from 'images/newimages/edit-rectangle.svg';
import deleteicon from 'images/newimages/trash.svg';
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AddEditModal } from "./modals/AddEditModal";

const Role = [
  {
    value: 'Menu Items',
    label: 'Menu Items',
  },{
    value: 'Role',
    label: 'Role',
  },
];

const petOwner = [
  {
    value: 'Pet Owner',
    label: 'Rose',
  },{
    value: 'Pet Owner2',
    label: 'Johnson',
  },
];
const Status = [
  {
    value: 'Status',
    label: 'Enable',
  },{
    value: 'status2',
    label: 'Disable',
  },
];


const data = [
  {
    icon: tableicon ,
    name: 'Human',
    group: 'Chordata',
    lastupdate: '04/07/2023 20:30:00',
    sortorder: '1',
    status: 'Active',
    edit: tableedit,
  },{
    icon: tableicon ,
    name: 'Human',
    group: 'Chordata',
    lastupdate: '04/07/2023 20:30:00',
    sortorder: '1',
    status: 'Active',
    edit: tableedit,
  },{
    icon: tableicon ,
    name: 'Human',
    group: 'Chordata',
    lastupdate: '04/07/2023 20:30:00',
    sortorder: '1',
    status: 'Active',
    edit: tableedit,
  },
]


export const PetInterests = () => {
  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleModal = () => {
    setOpen(!open)
  }

  const handleEditModal = (item) => {
    setSelectedItem(item)
    setOpen(!open)
  }

  useEffect(() => {
    if (!open) {
      setSelectedItem(null)
    }
  }, [open])


const columns = [
  {
    name: < img src={tableicon } /> ,
    selector: row => <img src={row.icon} alt="Image" style={{ width: '24px' }} />,
  },
  {
    name: 'Name',
    selector: row => <Typography variable="h6" color="phylumTable.main" sx={{fontSize: '12px'}}>{row.name}</Typography>,
  },
  {
    name: 'Group',
    selector: row =><Typography variable="h6" color="phylumTable.main" sx={{fontSize: '12px'}}>{row.group}</Typography>,
  },
  {
    name: 'Last Update',
    selector: row =><Typography variable="h6" color="phylumTable.main" sx={{fontSize: '12px'}}>{row.lastupdate}</Typography>,
  },
  {
    name: 'Sort Order',
    selector: row =><Typography variable="h6" color="phylumTable.main" sx={{fontSize: '12px'}}>{row.sortorder}</Typography>,
  },
   {
    name: 'Status',
    selector: row => <Button variant="contained" color="transparent" sx={{ fontSize:'12px', border: '1px solid #00E332', borderRadius: '20px', boxShadow: '0', color: '#00E332', padding: '1px', textTransform:'capitalize' }}>{row.status}</Button>,
  },
  {
    name: '',
    selector: row =><Button onClick={() => handleEditModal(row)}><img src={row.edit}  alt="Image" style={{ width: '18px' }} /></Button>
  },
];

  return (
    <AppLayout heading='Pet Interests Types & Sub Types Configuration Panel'>
      <Box component="form" p="6px" mt="8px" borderRadius="10px" boxShadow="custom" sx={{ textAlign: "center", width: "100%", backgroundColor: "#fff",
        "& .MuiTextField-root": { m: 1, width: "95%", textAlign: "left" },}} noValidate autoComplete="off">
        <Grid container display="flex" justifyContent="space-between">
          <Grid lg={8} md={8} xs={12} sm={12} sx={{ pr:{ lg:3.5, md:0, sm:0, xs: 0 }, md:{lg: 0, md: 2.5, xs: 2.5, sm: 2.5 } }}>
            <Grid container>
              <Grid item xs={12} lg={3} md={4} gap="10px">
              <TextField id="role" select label="Status" defaultValue="Active">
                {Role.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={12} lg={3} md={4}>
                <TextField id="owner" select label="Tag #" defaultValue="Tag">
                  {petOwner.map((option) => (
                      <MenuItem
                      key={option.value}
                      value={option.value}
                      textAlign="left"
                      width="auto"
                      >
                      {option.label}
                      </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} lg={3} md={4}>
                <TextField id="status" select label="Tag Status" defaultValue="Status">
                  {Status.map((option) => (
                      <MenuItem
                      key={option.value}
                      value={option.value}
                      style={{ textAlign: "left", width: "auto" }}
                      >
                      {option.label}
                      </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={4} md={4} xs={12} sm={12} sx={{ pl:{ lg:3.5, md:0, sm:0, xs: 0 } }}>
            <Box py={2.5} sx={{ textAlign: {lg: 'right', md: 'right', sm: 'left', xs: 'left'} }}>
              <Button onClick={ handleModal }  variant="contained" color='primary' size="large" sx={{ width: '220px' }}>
                  Add Wellness Type
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box component="form" p={3} mt={2} borderRadius="10px" boxShadow="custom" sx={{ textAlign: "center", width: "100%", backgroundColor: "#fff",
          "& .MuiTextField-root": { m: 1, width: "95%", textAlign: "left" },}} noValidate autoComplete="off">
        <Grid container display="flex" justifyContent="space-between" alignItems="center">
          <Grid lg={12} md={12} xs={12} sm={12} sx={{ pr:{ lg:3.5, md:0, sm:0, xs: 0 }, md:{lg: 0, md: 2.5, xs: 2.5, sm: 2.5 } }}>
            <Tabs value={activeTab} onChange={handleTabChange}  variant="scrollable"  scrollButtons="auto">
              <Tab label="Interest Types" sx={{ fontWeight: 'bold' }}/>
              <Tab label="Activity Types" sx={{ fontWeight: 'bold' }}/>
            </Tabs>
          </Grid>
        </Grid>
      </Box>
      <Typography component="h5" variable="h5" textAlign="left"  color="grey3.main" sx={{ fontSize: '14px' }}>* Specifics may vary. Dogs and older puppies who have an unknown vaccination history generally can be caught up in two visits for vaccines given 3-4 weeks apart.</Typography> 

      {(activeTab === 0 || activeTab === 1) && <Box> 
        <Box>    
          <Box display="flex" justifyContent="start" alignItems="center">                 
            <Typography display="flex" component="h5" variable="h5" textAlign="left" py={2.5} mr={1} sx={{fontSize:'20px', fontWeight:'600'}}>Canines</Typography>   
            <Link href="#" onClick={ handleModal }><img src={tableedit} /></Link>
          </Box> 
          <Box sx={{ boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)', borderRadius: '10px',  overflowX: 'auto'  }}>
              <DataTable columns={columns} data={data} striped />
          </Box>                 
        </Box> 
        <Box>    
          <Box display="flex" justifyContent="start" alignItems="center">                 
              <Typography  display="flex" component="h5" variable="h5" textAlign="left" py={2.5} mr={1} sx={{fontSize:'20px', fontWeight:'600'}}>Equidae</Typography>
              <Link href="#" onClick={ handleModal } ><img src={tableedit} /></Link>   
          </Box> 
          <Box sx={{ boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)', borderRadius: '10px'}}>
              <DataTable columns={columns} data={data} striped />
          </Box>                 
        </Box>
        <Box>    
          <Box display="flex" justifyContent="start" alignItems="center">                 
              <Typography  display="flex" component="h5" variable="h5" textAlign="left" py={2.5} mr={1} sx={{fontSize:'20px', fontWeight:'600'}}>Felines</Typography>  
              <Link href="#"  onClick={ handleModal }><img src={tableedit} /></Link>
          </Box> 
          <Box sx={{ boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)', borderRadius: '10px'}}>
              <DataTable columns={columns} data={data} striped />
          </Box>                 
        </Box>
      </Box>}
        <AddEditModal open={open} handleModal={handleModal} item={selectedItem}/>
    </AppLayout>
  )
}