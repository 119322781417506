import { LoadingDialog } from "components/LodingDialog"
import { SnackbarContext } from "components/snackbar/SnackbarContext"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export const InviteVerify = () => {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const {setSeverity, showToast} = useContext(SnackbarContext)

  useEffect(() => {
    setTimeout(function(){
      setLoading(false)
      setSeverity('info')
      showToast('Please sign in your account.')
      navigate('/', {replace: true})
    }, 2000);

  }, [])
  return (
    loading &&
      <LoadingDialog />
  )
}
