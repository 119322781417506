export const actions = {
  LOGIN: 'LOGIN',
  RESET: 'RESET',
  RE_AUTHENTICATED: 'RE_AUTHENTICATED',
  SET_CREDENTIAL: 'SET_CREDENTIAL',
  REMOVE_CREDENTIAL: 'REMOVE_CREDENTIAL',

  login ({ token, onSuccess = () => {}, onError = () => {}}) {
    return {
      type: actions.LOGIN,
      token,
      onSuccess,
      onError
    }
  },

  reAuthenticated (result) {
    return {
      type: actions.RE_AUTHENTICATED,
      ...result
    }
  },

  resetAuth () {
    return {
      type: actions.RESET
    }
  },

  setCredential(credential) {
    return {
      type: actions.SET_CREDENTIAL,
      credential,
    }
  },

  removeCredential() {
    return {
      type: actions.REMOVE_CREDENTIAL,
    }
  }
}

export const AuthActions = actions
