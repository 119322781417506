import {Close} from '@mui/icons-material'
import {Box, Button, IconButton, Modal, TextField, Typography} from '@mui/material'
import {StoreMediaUploader} from 'components/StoreMediaUploader'
import {useFormik} from 'formik'
import {isEmpty, keys, pick, set} from 'lodash'
import {storeSocketClient} from 'modules/store/client/socket.client'
import {UserAutoCompleteSearch} from 'modules/store/products/components/UserAutoCompleteSearch'
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import * as yup from 'yup'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,

  width: 600,

  '@media (max-width:767px)': {
    width: '95%'
  }
}
const validationSchema = yup.object({
  name: yup.string().required('Name is required')
})

const initialValues = {
  name: '',
  vendor: ''
}

export const AddEditModal = forwardRef((props, ref) => {
  const {onAddEdit} = props
  const [open, setOpen] = useState(false)

  const [id, setId] = useState(null)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [vendorError, setVendorError] = useState(false)
  const [media, setMedia] = useState(null)

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setData(null)
    setId(null)
    setVendorError(false)
    setOpen(false)
    setMedia(null)
    formik.resetForm({values: initialValues})
  }
  const service = storeSocketClient.service('brands')
  useImperativeHandle(
    ref,
    () => ({
      show(id = null) {
        setId(id)
        handleOpen()
      }
    }),
    []
  )

  useEffect(() => {
    if (id) {
      setLoading(true)
      service
        .get(id, {
          query: {
            $populate: ['icon']
          }
        })
        .then(result => {
          setData(result)
          formik.resetForm({values: {...pick(result, keys(initialValues))}})
          setLoading(false)
          setMedia(result.icon)
        })
        .catch(error => {
          console.log(error)
          setLoading(false)
        })
    }
  }, [id])

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      if (isEmpty(values?.vendor)) {
        setVendorError(true)
        return
      }

      if (media) {
        values['icon'] = media?._id
      }
      console.log(values, media)
      id
        ? service
            .patch(id, values)
            .then(result => {
              handleClose()
              onAddEdit()
            })
            .catch(console.error)
        : service
            .create(values)
            .then(result => {
              handleClose()
              onAddEdit()
            })
            .catch(console.error)
    }
  })

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box sx={{p: {lg: 5, md: 5, sm: 4, xs: 4}}}>
            <Box display="flex" alignItems="center" justifyContent="space-between" pb={2.5}>
              <Typography
                variant="h5"
                component="h5"
                color="transparent.contrastText"
                sx={{fontSize: '24px', fontWeight: '600'}}
                style={{width: '100%'}}
              >
                {id ? 'Edit Brand Detail' : 'Add Brand Detail'}
              </Typography>
              <Box textAlign="right" width="100%">
                <IconButton onClick={handleClose}>
                  <Close onClick={handleClose} />
                </IconButton>
              </Box>
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                id="standard-basic"
                label="Name"
                variant="outlined"
                {...formik.getFieldProps('name')}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
              />

              <div className="w-full flex my-4 ">
                <UserAutoCompleteSearch
                  id={data?.vendor}
                  service="user"
                  label="Search vendor"
                  client={storeSocketClient}
                  onChange={id => {
                    formik.setFieldValue('vendor', id)
                    if (id) {
                      setVendorError(false)
                    }
                  }}
                  error={vendorError}
                  helperText="Please Select vendor"
                />
              </div>

              <StoreMediaUploader title="Upload Image" media={media} setMedia={setMedia} />

              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  disabled={loading}
                >
                  {id ? 'Edit' : 'Submit'}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  )
})
