import {Box, Divider, Avatar, Typography, Checkbox} from '@mui/material'
import { useMemo } from "react"
import placeholder from 'images/avatar_placeholder.svg'

export const ContactItem = (props) => {
  const {item, onClick, selected} = props

  const checked = useMemo(() => {
    if (!selected) return false

    return selected.id === item.id ? true : false
  }, [selected, item])

  return (
    <Box
      className="!flex flex-col cursor-pointer "
      onClick={() => {
        if (!selected) {
          onClick(item)
          return
        }
        checked ? onClick(null) : onClick(item)
      }}
    >
      <Box sx={{display: 'flex', alignItems: 'center', my: 1}}>
        <Checkbox checked={checked} sx={{p: 0}}/>
        <Avatar src={placeholder} sx={{ width: 32, height: 32, mx: 1 }}></Avatar>
        <div>
          <Typography fontSize="12px" fontWeight="bold">{item?.user?.firstName} {item?.user?.lastName}</Typography>
          <Typography fontSize="10px">@{item?.user?.username}</Typography>
        </div>
      </Box>
      <Divider />
    </Box>
  )
}
