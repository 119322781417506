import {Button} from '@mui/material'
import TourIcon from '@mui/icons-material/Tour'
import {PostContent} from './PostContent'
import {useEffect} from 'react'
export const Post = props => {
  const {content} = props

  return (
    <div className="m-4 h-full flex flex-col justify-between">
      <div className="rounded-xl p-4" style={{border: '1px solid #E0E0E0'}}>
        <PostContent content={content} />
      </div>
      <div className=" mt-4 flex ">
        <Button variant="contained">Looks Safe</Button>
        <Button variant="outlined" className="!mx-4">
          <TourIcon />
        </Button>
      </div>
    </div>
  )
}
