import React from 'react';
import {Grid, Divider} from '@mui/material';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { OwnerDetailCard } from './OwnerDetailCard';
import { GridItemTypography } from './GridItemTypography';
import { CustomTypography } from './CustomTypography';

export const BusinessDetail = (props) => {
  const {data} = props
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div style={{ padding: 10, margin: '0 10px', width: isMobile ? '100%' : '70%' }}>
      <Typography variant="h5" textAlign="left" sx={{ mb: '8px' }} gutterBottom>
        Business Detail
      </Typography>
      <Divider sx={{ my: '1rem' }}/>
      <Grid container spacing={1} direction={isMobile ? 'column' : 'row'}>
        <GridItemTypography label="Business Type" value={data?.businessType} />
        <GridItemTypography label="Business Name" value={data?.businessName} />
        <GridItemTypography label="Doing Business As" value={data?.doingBusinessAs} />
        <GridItemTypography label="Business Classification" value={data?.businessClassification} />
        <GridItemTypography label="Address1" value={data?.address1} />
        <GridItemTypography label="Address2" value={data?.address2} />
        <GridItemTypography label="City" value={data?.city} />
        <GridItemTypography label="State" value={data?.state} />
        <GridItemTypography label="Country" value={data?.country} />
        <GridItemTypography label="Postal Code" value={data?.postalCode} />
        <GridItemTypography label="Phone" value={data?.phone} />
        <GridItemTypography label="Website" value={data?.website} />
        {
          data?.businessType !== "Sole Proprietorship" &&
            <GridItemTypography label="Ein" value={data?.ein} />
        }
        <Grid item xs={12}>
          <CustomTypography label="Industry Type" value={data?.industry}  />
        </Grid>
      </Grid>

      <Typography variant="h5" textAlign="left" sx={{ mt: '1rem' }} gutterBottom>
        Controller Detail
      </Typography>
      <Divider sx={{ my: '1rem' }}/>
      <Grid container spacing={1} direction={isMobile ? 'column' : 'row'}>
        <GridItemTypography label="First Name" value={data?.firstName} />
        <GridItemTypography label="Last Name" value={data?.lastName} />
        <GridItemTypography label="Date of Birth" value={data?.dateOfBirth} />
        <GridItemTypography label="Email" value={data?.email} />
        <GridItemTypography label="SSN" value={data?.ssn} />
        {data?.businessType !== "Sole Proprietorship" &&
          <>
            <GridItemTypography label="Title" value={data?.title} />
            <GridItemTypography label="Address1" value={data?.address1Controller} />
            <GridItemTypography label="Address2" value={data?.address2Controller} />
            <GridItemTypography label="City" value={data?.cityController} />
            <GridItemTypography label="State" value={data?.selectStateController} />
            <GridItemTypography label="Postal Code" value={data?.postalCodeController} />
            <GridItemTypography label="Ownership Percentage" value={data?.ownershipPercent} />
          </>
        }
      </Grid>

      {data?.businessType !== "Sole Proprietorship" &&
        <>
          <Typography variant="h5" textAlign="left" sx={{ mt: '1rem' }} gutterBottom>
            Owner Details
          </Typography>
          <Divider sx={{ my: '1rem' }}/>
          {data?.owners?.map((owner, index) => (
            <OwnerDetailCard key={index} owner={owner} />
          ))}
        </>
      }
    </div>
  )
}
