export const PrivacyPolicy = () => {
  return (
    <div className="!mt-2 flex w-full justify-center items-center ">
      <a href={process.env.REACT_APP_PRIVACY_URL} style={{color: '#ED1690'}} target='_blank' rel="noreferrer">
        Privacy policy
      </a>
      <span className="mx-4"></span>
      <a href={process.env.REACT_APP_TERMS_URL} style={{color: '#ED1690'}} target='_blank' rel="noreferrer">
        Terms & conditions
      </a>
    </div>
  )
}
